import { takeLatest, put } from "redux-saga/effects";
import TerrapayRepository, { GetCountryListResponse } from "../../../domain/repositories/TerrapayRepository";


import {
  TRIGGER_GET_COUNTRY_LIST,
  TriggerGetCountryList,

} from "./types";

import {
  getCountryListPending,
  getCountryListSuccess,
  getCountryListError
} from "./actions";
import { AxiosError } from "axios";



function* handleTriggerGetCountryList(action: TriggerGetCountryList) {

  yield put(getCountryListPending());

  let response: GetCountryListResponse;

  try {
    response = yield TerrapayRepository.getCountryList();
  } catch (err) {
    yield put(getCountryListError(err as AxiosError));
    return;
  }

  yield put(getCountryListSuccess(response));
}


export function* getCountryListSaga() {
  yield takeLatest(TRIGGER_GET_COUNTRY_LIST, handleTriggerGetCountryList);
}
