import React from "react";
import styled from "styled-components";
import { ScreenSize } from "../../styling/constants";

const Wrapper = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.64);
  z-index: 100;

  @media (min-width: ${ScreenSize.XS}px) {
    position: absolute;
  }
`;

const Container = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
`;

const MessageContainer = styled.div<{ error?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;

  h4 {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.4rem;
    color: ${(props) => (props.error ? "#8a6d3b" : "#6b6b6b")};
    text-align: center;
  }
`;

const Button = styled.button`
  background: transparent;
  border: none;
  outline: none;
  height: 45px;
  border-top: 1px solid #e6e6e6;
  cursor: pointer;
  font-family: inherit;
`;

const CancelButton = styled(Button)`
  color: rgb(26, 167, 232);
  font-weight: 700;
`;

interface Props {
  proceedButtonHandler: () => void;
  cancelButtonHandler: () => void;
  onGoingRequest?: string;
}

const texts: { [key: string]: { subTitle: string; closeText: string } } = {
  default: {
    subTitle: "Are you sure you want to cancel this payment?",
    closeText: "Close",
  },
  transfer: {
    subTitle:
      "If you have done a successful transfer, please do not cancel or click the back button",
    closeText: "I have done a Transfer",
  },
  ussd: {
    subTitle:
      "If you have done a successful ussd transaction, please do not cancel or click the back button",
    closeText: "Close",
  },
  qr: {
    subTitle:
      "If you have done a successful qr transaction, please do not cancel or click the back button",
    closeText: "Close",
  },
};

export default function CancelTransactionModal(props: Props) {
  const {
    proceedButtonHandler,
    cancelButtonHandler,
    onGoingRequest = "default",
  } = props;

  const text = texts[onGoingRequest];

  return (
    <Wrapper>
      <Container>
        <MessageContainer error={!!onGoingRequest}>
          <h4>Cancel Payment?</h4>
          <p>{text.subTitle}</p>
        </MessageContainer>
        <CancelButton onClick={proceedButtonHandler}>
          Cancel Payment
        </CancelButton>

        <Button onClick={cancelButtonHandler}>{text.closeText}</Button>
      </Container>
    </Wrapper>
  );
}
