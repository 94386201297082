import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as SuccessIcon } from "../../../../../../assets/icons/success-icon.svg";
import { PaymentParams } from "../../../../../payment/presentation/store/paymentProperties/types";

interface Props {
  params: PaymentParams;
}

export const Success = ({ params }: Props) => {
  const redirectUrl = params.siteRedirectUrl;

  useEffect(() => {
    setTimeout(() => {
      window.location.href = redirectUrl;
    }, 5000);
  }, [redirectUrl]);

  return (
    <Container>
      <SuccessIcon
        style={{ width: "48px", height: "48px", marginBottom: "32px" }}
      />
      <H4>Payment Successful!</H4>
      <P>You’re being redirected to </P>
      <RedirectURL>{redirectUrl}</RedirectURL>
      <P style={{ marginTop: "4px" }}> Please do not close this page. </P>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;
const H4 = styled.h4`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  opacity: 0.8;
`;
const P = styled.p`
  width: 100%;
  font-size: 16px;
  margin-bottom: 4px;
  text-align: center;
  opacity: 0.7;
`;
const RedirectURL = styled.span`
  font-size: 14px;
  max-width: 100%;
  color: #009fe4;
  opacity: 0.7;
  text-align: center;
  word-break: break-all;
  font-weight: 500;
`;
