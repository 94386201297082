import {
  CREATE_PAYMENT_PENDING,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_ERROR,
  RESET_CREATE_PAYMENT,
  CreatePaymentState,
  CreatePaymentActionType,
} from "./types";

const initialState: CreatePaymentState = {
  loading: false,
  loadingError: null,
  data: "",
  transactionResponse: null
}

export function createPaymentReducer(
  state: CreatePaymentState = initialState,
  action: CreatePaymentActionType
): CreatePaymentState {
  switch (action.type) {

    case CREATE_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case CREATE_PAYMENT_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      const { data } = response;
      data["responseCode"] = response.code;

      return {
        ...state,
        loading: false,
        loadingError: null,
        transactionResponse: data
      };
    }

    case CREATE_PAYMENT_ERROR: {
      const { payload } = action;
      const { error } = payload;

      return {
        ...state,
        loading: false,
        loadingError: error,
      };
    }

    case RESET_CREATE_PAYMENT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
