import { takeLatest, put } from "redux-saga/effects";

import opayRepository, {
  InitializeResponse,
} from "../../../domain/repositories/OpayRepository";

import {
  TRIGGER_INITIALIZE,
  TriggerInitialize,
  RESET_INITIALIZE,
} from "./types";

import {
  initializePending,
  initializeSuccess,
  initializeError,
} from "./actions";
import { createCancellableSaga } from "../../../../../store/cancellableSaga";

function* handleTriggerInitialize(action: TriggerInitialize) {
  const { payload } = action;
  const { request } = payload;

  yield put(initializePending());

  let response: InitializeResponse;

  try {
    response = yield opayRepository.initialize(request);
  } catch (err) {
    yield put(initializeError());
    return;
  }

  yield put(initializeSuccess(response));
}

function* watchInitialize() {
  yield takeLatest(TRIGGER_INITIALIZE, handleTriggerInitialize);
}

export const initializeSaga = createCancellableSaga(
  watchInitialize,
  RESET_INITIALIZE
);
