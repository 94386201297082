import { SERVICE_BASE_URL } from "../../../../config/properties";
import httpClient, { HttpClient } from "../../../../core/api/httpClient";
import { AcceptOfferRequest } from "./OffersRepository";

interface Error {
  message: string;
  fieldName: string;
}

interface IswError {
  code: string;
  message: string;
  description: string;
}

interface BaseCreditRequest {
  paymentId?: number;
  customerId?: string;
  offerId?: string;
  providerCode?: string;
  accountNumber?: string
}

export interface Response {
  code: string;
  error: Error;
  logId: string;
  errors: Error[];
  errorBody: string;
  description: string;
  responseCode: string;
  responseEnabled: boolean;
  responseDescription: string;
  otpRequestReference: string;
}

export interface GetTokenWithOtpResponse {
  token: string;
  error: IswError;
  balance: string;
  message: string;
  cardType: string;
  errors: IswError[];
  responseCode: string;
  accountNumber: string;
  panLast4Digits: string;
  transactionRef: string;
  responseMessage: string;
  tokenExpiryDate: string;
  responseDescription: string;
}

export interface ValidateOtpRequest extends BaseCreditRequest {
  otp: string;
}

export type GenerateOtpRequest = BaseCreditRequest;
export type GetTokenWithOtpRequest = AcceptOfferRequest | null;
export type AuthorizeTokenWithOtpRequest = AcceptOfferRequest;
export type AuthorizeTokenWithOtpResponse = GetTokenWithOtpResponse;

interface OtpRepository {
  generateOtp: (request: GenerateOtpRequest) => Promise<Response>;
  validateOtp: (request: ValidateOtpRequest) => Promise<Response>;
  getTokenWithOtp: (request: GetTokenWithOtpRequest) => Promise<GetTokenWithOtpResponse>;
  authorizeTokenWithOtp: (
    request: AuthorizeTokenWithOtpRequest
  ) => Promise<AuthorizeTokenWithOtpResponse>;
}

class OtpRepositoryImpl implements OtpRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async generateOtp(request: GenerateOtpRequest) {
    const response = await this.httpClient.post<Response>(
      `${SERVICE_BASE_URL}/api/v1/credit/generate`,
      request
    );
    return response.data;
  }

  async generateOtpBank(request: GenerateOtpRequest) {
    const response = await this.httpClient.post<Response>(
      `${SERVICE_BASE_URL}/api/v1/credit/banks/otp`,
      request
    );
    return response.data;
  }

  async validateOtp(request: ValidateOtpRequest) {
    const response = await this.httpClient.post<Response>(
      `${SERVICE_BASE_URL}/api/v1/credit/validate`,
      request
    );
    return response.data;
  }

  async getTokenWithOtp(request: GetTokenWithOtpRequest) {
    const response = await this.httpClient.post<GetTokenWithOtpResponse>(
      `${SERVICE_BASE_URL}/api/v1/credit/token`,
      request
    );
    return response.data;
  }

  async authorizeTokenWithOtp(request: AuthorizeTokenWithOtpRequest) {
    const response = await this.httpClient.post<AuthorizeTokenWithOtpResponse>(
      `${SERVICE_BASE_URL}/api/v1/credit/token/validate`,
      request
    );
    return response.data;
  }
}

export default new OtpRepositoryImpl(httpClient);
