import { combineReducers } from "redux";

import { NotificationWidgetReducer } from "../features/components/NotificationWidget/store/reducer";
import { PaymentReducer } from "../features/payment/presentation/store";
import { CardReducer } from "../features/card/presentation/store";
import { WalletReducer } from "../features/wallet/presentation/store";
import { TransferReducer } from "../features/transfer/presentation/store";
import { QrReducer } from "../features/qr/presentation/store";
import { UssdReducer } from "../features/ussd/presentation/store";
import { TerrapayWalletReducer } from "../features/customerWallet/presentation/store";
import { CreditReducer } from "../features/credit/presentation/store";
import { PayWithAppReducer } from "../features/payWithApp/presentation/store";
import { OpayReducer } from "../features/opay/presentation/store";
import { EnairaReducer } from "../features/enaira/presentation/store";
import { PalmpayReducer } from "../features/palmpay/presentation/store";

const RootReducer = combineReducers({
  notificationWidget: NotificationWidgetReducer,

  payment: PaymentReducer,

  card: CardReducer,
  wallet: WalletReducer,
  terrapayWallet: TerrapayWalletReducer,
  transfer: TransferReducer,
  qr: QrReducer,
  ussd: UssdReducer,
  credit: CreditReducer,
  payWithApp: PayWithAppReducer,
  opay: OpayReducer,
  enaira: EnairaReducer,
  palmpay: PalmpayReducer,
});

export default RootReducer;

export type AppState = ReturnType<typeof RootReducer>;
