import { AxiosError } from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  generateCreditOtpError,
  validateCreditOtpError,
  validateCreditOtpPending,
  generateCreditOptPending,
  validateCreditOtpSuccessful,
  generateCreditOtpSuccessful,
  setOtpReference
} from "./actions";
import {
  TriggerGenerateCreditOtp,
  TriggerValidateCreditOtp,
  TRIGGER_VALIDATE_CREDIT_OTP,
  TRIGGER_GENERATE_CREDIT_OTP
} from "./types";
import otpRepository, { Response } from "../../../domain/repositories/OtpRepository";
import { SigninSuccessfulResponse } from "../offers/types";
import { initializeAxiosError } from "../../../../../core/api/httpClient";

function* generateCreditOtp(action: TriggerGenerateCreditOtp) {
  const { payload } = action;
  const { request, onSuccessful, isBank } = payload;
  let response: Response;

  yield put(generateCreditOptPending());

  try {
    if(isBank){
      response = yield otpRepository.generateOtpBank(request);
    } else {
      response = yield otpRepository.generateOtp(request);
    }
  } catch (error) {
    yield put(generateCreditOtpError(error as AxiosError));
    return;
  }

  if (response?.responseCode === "00") {
    if(isBank){
      yield put(setOtpReference(response.otpRequestReference));
    }

    yield put(generateCreditOtpSuccessful());
    if (onSuccessful && typeof onSuccessful === "function") yield call(onSuccessful);
  } else {
    let error: AxiosError = initializeAxiosError();
    if (error.response) error.response.data = response;
    yield put(generateCreditOtpError(error));
  }
}

function* validateCreditOtp(action: TriggerValidateCreditOtp) {
  const { payload } = action;
  const { request, onSuccessful } = payload;
  let response: Response;

  yield put(validateCreditOtpPending());

  try {
    response = yield otpRepository.validateOtp(request);
  } catch (error) {
    yield put(validateCreditOtpError(error as AxiosError));
    return;
  }

  if (response?.responseCode === "00") {
    yield put(validateCreditOtpSuccessful());
    if (onSuccessful && typeof onSuccessful === "function") {
      let data: SigninSuccessfulResponse = {
        customerId: request.customerId
      };
      yield call(onSuccessful, data);
    }
  } else {
    let error = initializeAxiosError();
    if (error.response) error.response.data = response;
    yield put(validateCreditOtpError(error));
  }
}

export function* generateCreditOtpSaga() {
  yield takeLatest(TRIGGER_GENERATE_CREDIT_OTP, generateCreditOtp);
}

export function* validateCreditOtpSaga() {
  yield takeLatest(TRIGGER_VALIDATE_CREDIT_OTP, validateCreditOtp);
}
