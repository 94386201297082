import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PocketView } from "..";
import {
  FormControlLabel,
  FormFieldStyles,
} from "../../../../../components/Layout";
import { Button } from "../../../../../components/Button";
import { ReactComponent as InfoIcon } from "../../../../../../assets/icons/info.svg";
import { useValidatePocketId } from "../hooks/useValidatePocketId";
import { PaymentParams } from "../../../../../payment/presentation/store/paymentProperties/types";

interface Props {
  setView: React.Dispatch<React.SetStateAction<PocketView>>;
  params: PaymentParams;
}

export const PocketId = ({ setView, params }: Props) => {
  const [pocketId, setPocketId] = useState("");
  const { loading, validateId, success, error } = useValidatePocketId();

  useEffect(() => {
    if (success) {
      setView("authorization");
    }
  }, [success, setView]);

  return (
    <Container>
      <FormControlLabel>Your Pocket ID</FormControlLabel>

      <InputField
        value={pocketId}
        onChange={(e) => setPocketId(e.target.value)}
        type="text"
        placeholder="@pocketId"
      />

      {error && (
        <FormControlLabel style={{ color: "red" }}>{error}</FormControlLabel>
      )}

      <HelperContainer onClick={() => setView("how-to-pay")} tabIndex={0}>
        <InfoIcon />

        <p>How to pay with Pocket by PiggyVest</p>
      </HelperContainer>

      <Button
        type="FLAT"
        color="PRIMARY"
        text="Next"
        containerStyle={ButtonStyles}
        onClick={() => validateId(pocketId, params)}
        loading={loading}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;
const InputField = styled.input`
  ${FormFieldStyles};
  margin-top: 8px;
  font-size: 1.3rem;
`;
const HelperContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;

  p {
    font-size: 13px;
    text-decoration: underline;
    color: #009fe4;
    margin-left: 10px;
  }
`;
const ButtonStyles = {
  width: "100%",
  marginTop: "32px",
};
