import { takeLatest, put } from "redux-saga/effects";

import qrRepository, { GenerateQrResponse } from "../../../domain/repositories/QrRepository";

import {
  TRIGGER_GENERATE_QR,
  TriggerGenerateQr,

  RESET_GENERATE_QR,
} from "./types";

import {
  generateQrPending,
  generateQrSuccess,
  generateQrError
} from "./actions";
import { createCancellableSaga } from "../../../../../store/cancellableSaga";


function* handleTriggerGenerateQr(action: TriggerGenerateQr) {
  const { payload } = action;
  const { request } = payload;

  yield put(generateQrPending());

  let response: GenerateQrResponse;

  try {
    response = yield qrRepository.generateQr(request);
  } catch (err) {
    yield put(generateQrError());
    return;
  }

  yield put(generateQrSuccess(response));
}



function* watchGenerateQr() {
  yield takeLatest(TRIGGER_GENERATE_QR, handleTriggerGenerateQr);
}


export const generateQrSaga = createCancellableSaga(
  watchGenerateQr, RESET_GENERATE_QR
);