export const MESSAGE_KEY = "WEBPAY_UI_MESSAGE";

type BusMessageType = "INITIALIZED" | "COMPLETED";

export interface BusMessage {
  key?: string;
  messageType: BusMessageType;
  data?: any;
}

interface SendMessageParams {
  target: Window;
  targetOrigin: string;
  messageType: BusMessageType;
  data?: any;
}

export default class MessageBus {
  static sendMessage(params: SendMessageParams) {
    const {target, targetOrigin, messageType, data} = params;

    const message: BusMessage = {
      key: MESSAGE_KEY,
      messageType,
      data,
    };

    target.postMessage(message, targetOrigin);
  }

  static getBusMessage(evt: MessageEvent): BusMessage | null {
    try {
      const busMessage: BusMessage | null = evt.data;

      if (busMessage === null || typeof busMessage != 'object') {
        return null;
      }

      if (busMessage.key != MESSAGE_KEY) return null;

      return busMessage;
    } catch (err) {
      return null;
    }
  }

}