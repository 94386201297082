import { AxiosError } from "axios";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosErrorData } from "../../../../../core/api/helpers";
import useIsMountedRef from "../../../../../hooks/useIsMountedRef";
import { triggerShowNotification } from "../../../../components/NotificationWidget/store/actions";
import RequestState from "../../../../payment/domain/RequestState";
import {
  GetStatusPayload,
  PaymentNotificationResponse,
  getStatus,
  postPayment,
} from "../../../domain/repositories/UgandaRepository";
import { AppState } from "../../../../../store/RootReducer";
import { completeTransaction } from "../../../../payment/presentation/store/paymentStatus/actions";
import { asyncDelay } from "../../../../../core/util/asyncUtil";

export default function usePay() {
  const { paymentParams } = useSelector((state: AppState) => ({
    paymentParams: state.payment.paymentProperties.paymentParams,
  }));

  const [req, setReq] = useState<RequestState<PaymentNotificationResponse>>({
    loading: false,
  });

  const isMounted = useIsMountedRef().current;

  const dispatch = useDispatch();

  const handleError = useCallback(
    (error: any) => {
      if (!isMounted) return;

      setReq({
        loading: false,
        error: getAxiosErrorData(error as AxiosError).toString(),
      });

      dispatch(triggerShowNotification({ type: "ERROR" }));
    },
    [dispatch, isMounted]
  );

  const fetchStatus = useCallback(
    async (p: GetStatusPayload) => {
      setReq({ loading: true });

      try {
        const res = await getStatus(p);

        if (isMounted) {
          const responseCode = res.data.responseCode;

          switch (responseCode) {
            case "09":
              await asyncDelay(2000);
              fetchStatus(p);
              return;
            case "00":
              dispatch(completeTransaction(res.data));
              break;
            default:
              dispatch(completeTransaction(res.data));
              break;
          }

          setReq({ loading: false, data: res.data });
        }
      } catch (error) {
        handleError(error);
      }
    },
    [dispatch, handleError, isMounted]
  );

  const makePayment = useCallback(
    async (p: { billerId: string; customerId: string }) => {
      if (!paymentParams) return;
      setReq({ loading: true });

      try {
        const res = await postPayment({
          ...p,
          merchantCode: paymentParams.merchantCode,
          payableCode: paymentParams.payableCode,
          transactionReference: paymentParams.merchantTransactionReference,
          paymentId: paymentParams.paymentId,
        });

        if (isMounted) {
          const responseCode = res.data.responseCode;

          switch (responseCode) {
            case "09":
              fetchStatus({
                merchantCode: paymentParams.merchantCode,
                transactionReference:
                  paymentParams.merchantTransactionReference,
              });
              break;
            default:
              dispatch(completeTransaction(res.data));
              break;
          }

          setReq({ loading: false, data: res.data });
        }
      } catch (error) {
        handleError(error);
      }
    },
    [isMounted, dispatch, paymentParams, handleError, fetchStatus]
  );
  return { makePayment, ...req };
}
