import { GenerateQrRequest, GenerateQrResponse } from "../../../domain/repositories/QrRepository";

export const TRIGGER_GENERATE_QR = 'QR.TRIGGER_GENERATE_QR';
export const GENERATE_QR_PENDING = 'QR.GENERATE_QR_PENDING';
export const GENERATE_QR_SUCCESS = 'QR.GENERATE_QR_SUCCESS';
export const GENERATE_QR_ERROR = 'QR.GENERATE_QR_ERROR';

export const RESET_GENERATE_QR = 'QR.RESET_GENERATE_QR';

export interface TriggerGenerateQr {
  type: typeof TRIGGER_GENERATE_QR;
  payload: {
    request: GenerateQrRequest;
  }
}

export interface GenerateQrPending {
  type: typeof GENERATE_QR_PENDING;
}

export interface GenerateQrSuccess {
  type: typeof GENERATE_QR_SUCCESS;
  payload: {
    response: GenerateQrResponse;
  }
}

export interface GenerateQrError {
  type: typeof GENERATE_QR_ERROR;
}

export interface ResetGenerateQr {
  type: typeof RESET_GENERATE_QR;
}


export type GenerateQrActionType =
  | TriggerGenerateQr
  | GenerateQrPending
  | GenerateQrSuccess
  | GenerateQrError
  | ResetGenerateQr;


export interface GenerateQrState {
  generateQrPending: boolean;
  generateQrError: boolean;
  generateQrResponse: GenerateQrResponse | null;
}