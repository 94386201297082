import { GenerateUssdRequest, GenerateUssdResponse } from "../../../domain/repositories/UssdRepository";

import {
  TRIGGER_GENERATE_USSD,
  TriggerGenerateUssd,

  GENERATE_USSD_PENDING,
  GenerateUssdPending,

  GENERATE_USSD_SUCCESS,
  GenerateUssdSuccess,

  GENERATE_USSD_ERROR,
  GenerateUssdError,

  RESET_GENERATE_USSD,
  ResetGenerateUssd,
} from "./types";

export const triggerGenerateUssd = (request: GenerateUssdRequest): TriggerGenerateUssd => {
  return {
    type: TRIGGER_GENERATE_USSD,
    payload: { request }
  };
};

export const generateUssdPending = (): GenerateUssdPending => {
  return {
    type: GENERATE_USSD_PENDING
  };
};

export const generateUssdSuccess = (response: GenerateUssdResponse): GenerateUssdSuccess => {
  return {
    type: GENERATE_USSD_SUCCESS,
    payload: {response}

  };
};

export const generateUssdError = (): GenerateUssdError => {
  return {
    type: GENERATE_USSD_ERROR,
  };
};

export const resetGenerateUssd = (): ResetGenerateUssd => {
  return {
    type: RESET_GENERATE_USSD,
  };
};