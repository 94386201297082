import { race, call, take } from 'redux-saga/effects';

export function createCancellableSaga(sagaGenerator: any, cancelToken: string) {

  const cancellationInterceptor = function* () {
    while (true) {
      yield race({
        task: call(sagaGenerator),
        cancel: take(cancelToken)
      });
    }
  }

  return function* () {
    yield cancellationInterceptor();
  }
}