import * as React from "react";
import styled from "styled-components";

import { ReactComponent as DropdowIcon } from "../icons/chevron-down.svg";

interface Props {
  value?: string;
  label: string;
  options: { label: string; value: string }[];
  onChange: (val: string) => void;
  placeholder?: string;
  className?: string;
}
export default function Select(props: Props) {
  const { label, value, onChange, options, placeholder, className } = props;

  const [show, setShow] = React.useState(false);
  const toggleShow = () => setShow((s) => !s);

  const selected = options.find((o) => o.value === value);

  const onSelect = (v: string) => () => {
    onChange(v);
    toggleShow();
  };

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClick = (e: any) => {
      const refWrapper = ref.current;
      if (refWrapper && !refWrapper.contains(e.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClick, false);

    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, []);

  return (
    <Container ref={ref} className={className}>
      <p className="label">{label}</p>

      <div
        role="button"
        className={`activator${show ? " show" : ""}`}
        onClick={toggleShow}
      >
        {selected ? (
          selected.label
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}

        <DropdowIcon />
      </div>

      {show && (
        <div className="dropdown">
          {options.map((option) => (
            <button
              key={option.label}
              type="button"
              onClick={onSelect(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  &,
  * {
    box-sizing: border-box;
  }

  position: relative;
  width: 100%;

  .label {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #232425;
  }

  .activator {
    border: solid 1px #cdcdcd;
    background-color: #fff;
    height: 51px;
    padding: 0px 15px;
    border-radius: 4px;
    font-size: 1.4rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 14px;
      height: 7px;
    }

    span {
      opacity: 0.5;
    }

    &.show {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    width: 100%;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border: solid 1px #cdcdcd;
    background-color: #fff;
    z-index: 10;

    button {
      cursor: pointer;
      font: inherit;
      height: 49px;
      width: 100%;
      background-color: transparent;
      display: flex;
      align-items: center;
      text-align: left;
      border: none;
      border-top: solid 1px rgba(151, 151, 151, 0.2);
      font-size: 1.4rem;
      font-weight: bold;
      padding: 0px 15px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }

      &:first-of-type {
        border-top: none;
      }
    }
  }
`;
