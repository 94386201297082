import * as React from "react";
import styled from "styled-components";

import { ReactComponent as CautionIcon } from "../../../../../../assets/icons/danger.svg";

export default function TransferStatus() {
  return (
    <Container>
      <CautionIcon />

      {/* <h6 className="delayed">Delayed Confirmation</h6> */}
      <h6 className="delayed">Transaction Error</h6>

      {/* <p>
        We are unable to complete your transaction due to an unusual delay in
        confirming your transaction. Your funds would be reversed within 24
        hours once we are able to confirm your transfer.
      </p> */}
      <p>
        If you have transferred the wrong amount. Please note that reversals may
        take up to 24 hours.
      </p>
    </Container>
  );
}

const Container = styled.div`
  padding: 76px 0px 85px;

  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    margin-bottom: 17px;
  }

  > h6 {
    font-weight: normal;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 17px;

    &.delayed {
      color: #f2b34f;
    }

    &.error {
      color: #ff0000;
    }
  }

  > p {
    width: 289px;
    font-size: 1.4rem;
    text-align: center;
    opacity: 0.7;
  }
`;
