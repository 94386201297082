import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import getCardBrandFromCode from "../../../../../core/util/cardBrandUtils";
import { AppState } from "../../../../../store/RootReducer";
import { ScreenSize } from "../../../../../styling/constants";
import {
  PAYMENT__WALLET_CARD_PAGE,
  PAYMENT__CARD_ROOT,
  PAYMENT__WALLET_LOGIN,
} from "../../../../Routes";
import {
  WalletPaymentMethod,
  WalletUserData,
} from "../../../domain/repositories/WalletRepository";

import {
  PageView,
  PageTitle,
  PageSubTitle,
} from "../../../../components/Layout";
import WalletCardWidget from "../WalletCardWidget";
import { Button } from "../../../../components/Button";
import BackControl from "../../../../components/BackControl";
import { resetSigninUser } from "../../store/signinUser/actions";
import { PaymentChannelType } from "../../../../payment/presentation/store/paymentProperties/types";
import { setPaymentChannel } from "../../../../payment/presentation/store/currentPaymentChannel/actions";

const Container = styled(PageView)`
  display: flex;
  flex-direction: column;
`;

const WalletCardsContainer = styled.div`
  margin-bottom: 20px;

  @media (min-width: ${ScreenSize.XS}px) {
    max-height: 220px;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
`;

const WalletCardWidgetContainer = styled.div`
  margin-bottom: 15px;
`;

interface StoreStateProps {
  userData: WalletUserData | null;
  walletCards: WalletPaymentMethod[];
}

interface StoreDispatchProps {
  resetSigninUser: () => void;
  setPaymentChannel: (channelType: PaymentChannelType) => void;
}

interface OwnProps {}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function WalletRootPage(props: Props) {
  const { userData, walletCards, resetSigninUser, setPaymentChannel } = props;

  const history = useHistory();

  const signoutButtonHandler = () => {
    resetSigninUser();
    history.push(PAYMENT__WALLET_LOGIN);
  };

  const walletCardSelectHandler = (
    walletPaymentMethod: WalletPaymentMethod
  ) => {
    history.push({
      pathname: PAYMENT__WALLET_CARD_PAGE,
      state: { walletPaymentMethod },
    });
  };

  const newCardButtonHandler = () => {
    setPaymentChannel("CARD");
    history.push(PAYMENT__CARD_ROOT);
  };

  const userFullName = userData
    ? `${userData.firstName} ${userData.lastName}`
    : "";

  return (
    <Container>
      <BackControl
        text={`Sign out${userFullName ? ` (${userFullName})` : ""}`}
        onClick={signoutButtonHandler}
      />

      <PageTitle>Pay with Quickteller</PageTitle>
      <PageSubTitle>
        Select a card from the list below or add one to start the payment.
      </PageSubTitle>

      <WalletCardsContainer>
        {walletCards.map((walletCard) => {
          const { cardTypeCode, name, maskedPan } = walletCard;

          return (
            <WalletCardWidgetContainer
              key={`${name}-${maskedPan}`}
              onClick={() => walletCardSelectHandler(walletCard)}
            >
              <WalletCardWidget
                cardBrand={getCardBrandFromCode(cardTypeCode) || undefined}
                title={name}
                cardNumber={maskedPan}
                isEcash={cardTypeCode === "QTM"}
              />
            </WalletCardWidgetContainer>
          );
        })}
      </WalletCardsContainer>

      <Button
        text={"Pay with a new card"}
        color="SECONDARY"
        containerStyle={{ width: "100%" }}
        onClick={newCardButtonHandler}
      />
    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  userData: state.wallet.userWalletData.userData,
  walletCards: state.wallet.userWalletData.walletCards as WalletPaymentMethod[],
});

const mapDispatchToProps = (
  dispatch: (action: any) => void
): StoreDispatchProps => ({
  resetSigninUser() {
    dispatch(resetSigninUser());
  },
  setPaymentChannel(channelType: PaymentChannelType) {
    dispatch(setPaymentChannel(channelType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletRootPage);
