import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;
    font-family: POPPINS, sans-serif;
    font-weight: 300;
    /* font-family: 'Roboto', sans-serif; */
  }

  body {
    margin: 0px;
  }

  html, body, #root {
  }

  p, h1, h2, h3, h4, h5, h6 {
    padding: 0px;
    margin: 0px;
    line-height: 2rem;
  }

  h6 {
    font-size: 1.6rem;
  }

  p {
    font-size: 1.6rem;
    color: #181b24;
  }

  input, select {  
    font-family: POPPINS, sans-serif;
    font-size: 1.6rem;
    -webkit-appearance: none;
  }

  input[type=password] {
    letter-spacing: 3px;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  a {
    text-decoration: none;
    color: #1aa7e8;
    font-size: 1.6rem;
  }
`;