import React, { useEffect } from "react";
import Offers from "./Offers";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PhoneNumberSignin from "./PhoneNumberSignin";
import VerveWalletSignin from "./VerveWalletSignin";
import { PAYMENT_METHODS__ROOT } from "../../../Routes";
import { AppState } from "../../../../store/RootReducer";
import BackControl from "../../../components/BackControl";
import { PageTitle, PageSubTitle } from "../../../components/Layout";
import {
  displayPhoneNumberSigninPage,
  resetPhoneNumberSigninPage
} from "../store/phoneNumberSignin/actions";
import { Container } from "../../../wallet/presentation/pages/WalletLoginPage/style";
import { hideVerveWalletSignin, resetVerveWalletSignin } from "../store/verveWalletSignin/actions";
import { resetCreditCardDetails } from "../store/cardDetails/actions";
import { resetOffersPage } from "../store/offers/actions";
import { resetCreditOtpPage } from "../store/otpPage/actions";
import SelectBankPage from "./SelectBankPage";
import { requestShowLoanOffers } from "../store/selectBankPage/actions";
import { hideNotification } from "../../../components/NotificationWidget/store/actions";

export interface PageTitleProps {
  setPageTitle: (title: string) => void;
  setPageSubTitle: (subtitle: string) => void;
}

interface StoreStateProps {
  authenticated: boolean;
  pageTitle: string | null;
  pageSubTitle: string | null;
  phoneNumberSignin: boolean;
  verveWalletSignin: boolean;
  showLoanOffers: boolean;
}

interface StoreDispatchProps {
  reset: () => void;
  displayPhoneNumberSignin: () => void;
  setShowLoanOffers: (status: boolean) => void;
  hideNotification: () => void;
}

interface OwnProps {}
type Props = StoreStateProps & StoreDispatchProps & OwnProps;

function PayWithCreditPage(props: Props) {
  const {
    reset,
    pageTitle,
    pageSubTitle,
    authenticated,
    phoneNumberSignin,
    verveWalletSignin,
    displayPhoneNumberSignin,
    showLoanOffers,
    setShowLoanOffers,
    hideNotification
  } = props;
  const history = useHistory();
  const navigateToPaymentMethods = () => {
    reset();
    hideNotification();
    history.push(PAYMENT_METHODS__ROOT);
  };

  useEffect(() => setShowLoanOffers(false), [setShowLoanOffers]);

  return (
    <>
      <Container>
        <BackControl text="Change payment method" onClick={navigateToPaymentMethods} />

        <PageTitle>{pageTitle}</PageTitle>
        <PageSubTitle>{pageSubTitle}</PageSubTitle>

        {/* {!authenticated && phoneNumberSignin && <SelectBankPage />} */}

        {!authenticated && phoneNumberSignin && <PhoneNumberSignin />}

        {!authenticated && !phoneNumberSignin && verveWalletSignin && (
          <VerveWalletSignin backButtonCallback={displayPhoneNumberSignin} />
        )}

        {authenticated && !showLoanOffers && <SelectBankPage />}

        {authenticated && showLoanOffers && <Offers />}
      </Container>
    </>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => {
  return {
    pageTitle: state.credit.offers.pageTitle,
    pageSubTitle: state.credit.offers.pageSubtitle,
    authenticated: state.credit.offers.authenticated,
    phoneNumberSignin: state.credit.PhoneNumberSignin.displayPhoneNumberSignin,
    verveWalletSignin: state.credit.VerveWalletSignin.displayVerveWalletSignin,
    showLoanOffers: state.credit.selectBanksPage.showLoanOffers,
  }; 
};

const mapDispatchToProps = (dispatch: (action: any) => void): StoreDispatchProps => {
  return {
    displayPhoneNumberSignin: () => {
      dispatch(displayPhoneNumberSigninPage());
      dispatch(hideVerveWalletSignin());
    },

    reset: () => {
      dispatch(resetCreditCardDetails());
      dispatch(resetOffersPage());
      dispatch(resetCreditOtpPage());
      dispatch(resetPhoneNumberSigninPage());
      dispatch(resetVerveWalletSignin());
    },
    setShowLoanOffers: (status: boolean) => dispatch(requestShowLoanOffers(status)),
    hideNotification() {
      dispatch(hideNotification());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayWithCreditPage);
