import { AxiosError } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { initializeAxiosError } from "../../../../../core/api/httpClient";
import otpRepository, {
  AuthorizeTokenWithOtpResponse,
  GetTokenWithOtpResponse
} from "../../../domain/repositories/OtpRepository";
import {
  authorizeTokenWithOtpError,
  authorizeTokenWithOtpPending,
  authorizeTokenWithOtpSuccessful,
  getTokenWithOtpError,
  getTokenWithOtpPending,
  getTokenWithOtpSuccessful
} from "./actions";
import {
  TriggerAuthorizeTokenWithOtp,
  TriggerGetTokenWithOtp,
  TRIGGER_AUTHORIZE_TOKEN_WITH_OTP,
  TRIGGER_GET_TOKEN_WITH_OTP
} from "./types";

function* getTokenWithOtp(action: TriggerGetTokenWithOtp) {
  const { payload } = action;
  const { request, onSuccessful } = payload;
  yield put(getTokenWithOtpPending());
  let response: GetTokenWithOtpResponse;

  try {
    response = yield otpRepository.getTokenWithOtp(request);
  } catch (error) {
    yield put(getTokenWithOtpError(error as AxiosError));
    return;
  }

  if (response.responseCode === "T0") {
    yield put(getTokenWithOtpSuccessful());
    if (onSuccessful) yield call(onSuccessful);
    return;
  }

  let error: AxiosError = initializeAxiosError();
  if (error.response) error.response.data = response;
  yield put(getTokenWithOtpError(error as AxiosError));
}

function* authorizeTokenWithOtp(action: TriggerAuthorizeTokenWithOtp) {
  const { payload } = action;
  const { request, onSuccessful } = payload;
  yield put(authorizeTokenWithOtpPending());
  let response: AuthorizeTokenWithOtpResponse;

  try {
    response = yield otpRepository.authorizeTokenWithOtp(request);
  } catch (error) {
    yield put(authorizeTokenWithOtpError(error as AxiosError));
    return;
  }

  if (response.responseCode === "00") {
    yield put(authorizeTokenWithOtpSuccessful());
    if (onSuccessful && typeof onSuccessful === "function") yield call(onSuccessful);
    return;
  }

  let error: AxiosError = initializeAxiosError();
  if (error.response) error.response.data = response;
  yield put(authorizeTokenWithOtpError(error as AxiosError));
}

export function* getTokenWithOtpSaga() {
  yield takeLatest(TRIGGER_GET_TOKEN_WITH_OTP, getTokenWithOtp);
}

export function* authorizeTokenWithOtpSaga() {
  yield takeLatest(TRIGGER_AUTHORIZE_TOKEN_WITH_OTP, authorizeTokenWithOtp);
}
