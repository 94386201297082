const forge = require("./forge.custom.js");

export interface SecureData {
  secure: string;
  pinBlock: string;
}

let SECURE_CONFIG = {
  PUBLIC_KEY_MODULUS: "009c7b3ba621a26c4b02f48cfc07ef6ee0aed8e12b4bd11c5cc0abf80d5206be69e1891e60fc88e2d565e2fabe4d0cf630e318a6c721c3ded718d0c530cdf050387ad0a30a336899bbda877d0ec7c7c3ffe693988bfae0ffbab71b25468c7814924f022cb5fda36e0d2c30a7161fa1c6fb5fbd7d05adbef7e68d48f8b6c5f511827c4b1c5ed15b6f20555affc4d0857ef7ab2b5c18ba22bea5d3a79bd1834badb5878d8c7a4b19da20c1f62340b1f7fbf01d2f2e97c9714a9df376ac0ea58072b2b77aeb7872b54a89667519de44d0fc73540beeaec4cb778a45eebfbefe2d817a8a8319b2bc6d9fa714f5289ec7c0dbc43496d71cf2a642cb679b0fc4072fd2cf",
  PUBLIC_KEY_EXPONENT: "010001"
};

/**
 * generates random number between 100 and 999 for ttId
 */
let randomNum = () => {
  let rand = Math.floor(Math.random() * 900) + 100;
  return rand;
};

/**
 * generate random Keys of 16bytes using AES-128 algorithm which is used as the pinKey
 */
let generateKey = () => {
  // let bytes = forgeRandom.getBytesSync(16);
  let bytes = forge.random.getBytesSync(16);
  return bytes;
};

/**
 * checks whether value is not empty or null
 * @param {*string} pan
 */
let isValueSet = (value: any) => {
  if (value == null || value == "") {
    return false;
  } else {
    return true;
  }
};

/**
 * pad the value with zero to the right side
 * @param {*string} value
 * @param {*number} maxLength
 */
let padRight = (value: any, maxLength: any) => {
  if (!isValueSet(value) || value.length >= maxLength) {
    return value;
  }

  let diffInLength = maxLength - value.length;

  for (let i = 0; i < diffInLength; i++) {
    value += "0";
  }

  return value;
};

let getMac = (macData: any, macKey: any) => {
  let hmac = forge.hmac.create();
  hmac.start("sha256", macKey);
  hmac.update(macData);
  return hmac.digest().toHex();
};

let getMacData = (app: any, options: any) => {
  let macData = "";
  if (!isValueSet(app)) {
    return macData;
  }
  if (isValueSet(options.cardName)) {
    macData += options.cardName;
  }
  if (isValueSet(options.ttid)) {
    macData += String(options.ttid);
  }
  if (!isValueSet(options.additionalInfo)) {
    return macData;
  }

  if (isValueSet(options.additionalInfo.transferInfo)) {
    if (isValueSet(options.additionalInfo.transferInfo.toAccountNumber)) {
      macData += options.additionalInfo.transferInfo.toAccountNumber;
    }

    if (isValueSet(options.additionalInfo.transferInfo.toBankCode)) {
      macData += options.additionalInfo.transferInfo.toBankCode;
    }
  }

  if (isValueSet(options.additionalInfo.billInfo)) {
    if (isValueSet(options.additionalInfo.billInfo.phoneNumber)) {
      macData += options.additionalInfo.billInfo.phoneNumber;
    }

    if (isValueSet(options.additionalInfo.billInfo.customerNumber)) {
      macData += options.additionalInfo.billInfo.customerNumber;
    }

    if (isValueSet(options.additionalInfo.billInfo.billCode)) {
      macData += options.additionalInfo.billInfo.billCode;
    }
  }

  if (isValueSet(options.additionalInfo.rechargeInfo)) {
    if (isValueSet(options.additionalInfo.rechargeInfo.tPhoneNumber)) {
      macData += options.additionalInfo.rechargeInfo.tPhoneNumber;
    }
    if (isValueSet(options.additionalInfo.rechargeInfo.productionCode)) {
      macData += options.additionalInfo.rechargeInfo.productionCode;
    }
  }

  if (isValueSet(options.additionalInfo.atmTransferInfo)) {
    if (isValueSet(options.additionalInfo.atmTransferInfo.customerId)) {
      let custId = String(options.additionalInfo.atmTransferInfo.customerId);
      macData += custId;
    }
    if (isValueSet(options.additionalInfo.atmTransferInfo.institutionCode)) {
      macData += options.additionalInfo.atmTransferInfo.institutionCode;
    }
  }
  return macData;
};

/**
 * it generates the pin block using the params
 * @param {*string} pin
 * @param {*string} cvv2
 * @param {*string} expiry
 * @param {*string} pinKey
 * @param {*string} ttId
 */
function getPinBlock(pin: any, cvv2: any, expiry: any, pinKey: any, ttId: any): string {
  let pinBlockString = pin + cvv2 + expiry;

  let clearPinBlock = String(String(pinBlockString.length).length) + String(pinBlockString.length) + pinBlockString;

  let pinPadLen = 16 - clearPinBlock.length;
  for (let i = 0; i < pinPadLen; i++) {
    clearPinBlock += ttId;
  }

  let iv = 0x00;
  iv = hexToBytes(iv);

  var pinKeyBuffer = forge.util.createBuffer(pinKey);
  pinKeyBuffer.putBytes(pinKey);
  pinKey = pinKeyBuffer.getBytes(24);

  let cipher = forge.cipher.createCipher("3DES-CBC", pinKey);
  let clearPinBlockBytes = hexToBytes(clearPinBlock);

  cipher.start({
    iv: iv
  });

  cipher.update(forge.util.createBuffer(clearPinBlockBytes));
  cipher.finish();

  let encrypted = cipher.output;
  let encryptedPinBlock = String(encrypted.toHex());
  return encryptedPinBlock.substring(0, 16);
}

let customerIdLen: string;
let customerIdLenLen: number;
let customerIdBlock: string;
let customerIdBlockLen: number;
let secure: string;
let pinBlock: string;

/**
 *
 * @param {*object} options
 * @param {*string} app
 * @param {*} isActivate
 */
function getSecure(options: any, app: any): string {
  let version = "12";

  let headerBytes = hexToBytes("4D");
  let formatVersionBytes = hexToBytes(version);
  let macVersionBytes = hexToBytes(version);
  let pinDESKey = options.pinKey;
  let macDESKey = options.pinKey;
  let customerIdBytes;
  let otherBytes;

  if (isValueSet(options.pan)) {
    customerIdLen = String(options.pan.length);
    customerIdLenLen = customerIdLen.length;
    customerIdBlock = String(customerIdLenLen) + customerIdLen + options.pan;
    customerIdBlockLen = customerIdBlock.length;

    let panDiff = 40 - customerIdBlockLen;

    for (let i = 0; i < panDiff; i++) {
      customerIdBlock += "F";
    }

    customerIdBytes = hexToBytes(padRight(customerIdBlock, 40));
  }

  otherBytes = hexToBytes("00000000");
  let macData = getMacData(app, options);
  let mac = getMac(macData, macDESKey);
  let macBytes = hexToBytes(mac);
  let footerBytes = hexToBytes("5A");

  let clearSecureBytes = forge.util.createBuffer();
  clearSecureBytes.putBytes(headerBytes);
  clearSecureBytes.putBytes(formatVersionBytes);
  clearSecureBytes.putBytes(macVersionBytes);
  clearSecureBytes.putBytes(pinDESKey);
  clearSecureBytes.putBytes(macDESKey);
  clearSecureBytes.putBytes(customerIdBytes);

  macBytes = hexToBytes("00000000");

  clearSecureBytes.putBytes(macBytes);
  clearSecureBytes.putBytes(otherBytes);
  clearSecureBytes.putBytes(footerBytes);

  let rsa = forge.pki.rsa;
  let modulus = new forge.jsbn.BigInteger(SECURE_CONFIG.PUBLIC_KEY_MODULUS, 16, null);
  let exp = new forge.jsbn.BigInteger(SECURE_CONFIG.PUBLIC_KEY_EXPONENT, 16, null);
  let publicKey = rsa.setPublicKey(modulus, exp);

  let vvvv = clearSecureBytes.getBytes();

  let secureBytes = publicKey.encrypt(vvvv, null);
  let secureHex = forge.util.bytesToHex(secureBytes);

  return secureHex;
}
/**
 * options contains the credit card details, it returns secure and pinBlock
 * @param {*object} options
 */
export function generateSecureData(
  cardNumber: string,
  expirationDate: string,
  cvv2: string,
  cardPin?: string
): SecureData {
  let CREATE_CARD = "createcard";

  let expiry = expirationDate;
  
  if (expiry.length === 4) {
    expiry =
      expiry.charAt(2) + expiry.charAt(3) + expiry.charAt(0) + expiry.charAt(1);
  } else {
    expiry =
      expiry.charAt(4) + expiry.charAt(5) + expiry.charAt(0) + expiry.charAt(1);
  }


  let pan = cardNumber;
  let pin = cardPin || "FFFF";
  let cvv = cvv2 || "FFF";

  let ttId = randomNum();
  let pinKey = generateKey();

  let secureOptions = {
    publicKeyModulus: SECURE_CONFIG.PUBLIC_KEY_MODULUS,
    publicKeyExponent: SECURE_CONFIG.PUBLIC_KEY_EXPONENT,
    pinKey: pinKey,
    macKey: pinKey,
    ttid: ttId.toString(),
    pan: pan,
    accountNumber: "",
    expiryDate: expiry,
    cardName: "default",
    additionalInfo: {
      transferInfo: {
        toAccountNumber: "",
        toBankCode: ""
      },
      rechargeInfo: {
        tPhoneNumber: "",
        productionCode: ""
      },
      billInfo: {
        phoneNumber: "",
        customerNumber: "",
        billCode: ""
      },
      atmTransferInfo: {
        customerId: "",
        transferCode: "",
        institutionCode: ""
      }
    }
  };

  pinBlock = getPinBlock(pin, cvv, expiry, pinKey, ttId);
  secure = getSecure(secureOptions, CREATE_CARD);

  return {
    secure: secure,
    pinBlock: pinBlock
  };
}







//FORGE stripped functions
function hexToBytes(hex: any): any {
  // TODO: deprecate: "Deprecated. Use util.binary.hex.decode instead."
  var rval = '';
  var i = 0;
  if (hex.length % 2 !== 0) {
    // odd number of characters, convert first character alone
    i = 1;
    rval += String.fromCharCode(parseInt(hex[0], 16));
  }
  // convert 2 characters (1 byte) at a time
  for (; i < hex.length; i += 2) {
    rval += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }
  return rval;
};

