import { CardBrand, CardTypeCode } from "../../config/cardTypesConfig";

const verveCardTypeCodes: CardTypeCode[] = ["VVC", "VMP", "QTM"];

export default function getCardBrandFromCode(
  code: CardTypeCode
): CardBrand | null {
  // Check if card is a Verve card
  if (verveCardTypeCodes.indexOf(code) !== -1) return "Verve";

  // Check if card is a MasterCard
  if (code === "MCN") return "MasterCard";

  // Check if card is a MasterCard
  if (code === "VVV" || code === "VCC" || code === "VCI") return "Visa";

  return null;
}
