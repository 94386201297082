import { AxiosError } from "axios";

export function getAxiosErrorData(error: AxiosError): any | undefined {
  const { response } = error;

  if (!response) return undefined;

  if (!response.data) return undefined;

  return response.data;
}