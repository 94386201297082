import httpClient from "../../../../core/api/httpClient";
import { SERVICE_BASE_URL } from "../../../../config/properties";

export interface OptInPayload {
  mobileNumber: string;
  merchantCode: string;
  paymentId: number;
}

export interface OptInResponse {
  code: string;
  description: string;
}

const otpIn = async (data: OptInPayload) => {
  return httpClient.post<OptInResponse>(
    `${SERVICE_BASE_URL}/api/v1/pay-page/store-tucpayment`,
    data
  );
};

const TUCRepository = {
  otpIn,
};

export default TUCRepository;
