import * as React from "react";
import { useSelector } from "react-redux";

import { DEFAULT_ERROR_MESSAGE } from "../../../../../../config/properties";
import useIsMountedRef from "../../../../../../hooks/useIsMountedRef";
import { AppState } from "../../../../../../store/RootReducer";
import TUCRepository, { OptInResponse } from "../../../../domain/repositories/TUCRepository";
import RequestState from "../../../../domain/RequestState";


export default function useTUCAction() {
  const [reqState, setReqState] = React.useState<RequestState<OptInResponse>>({
    loading: false,
  });

  const { paymentParams } = useSelector((state: AppState) => ({
    paymentParams: state.payment.paymentProperties.paymentParams,
  }));

  const isMounted = useIsMountedRef();

  const optInForTUC = React.useCallback(
    async (phone: string) => {
      if (!paymentParams) return;
      setReqState({ loading: true });

      try {
        const res = await TUCRepository.otpIn({
          merchantCode: paymentParams.merchantCode,
          mobileNumber: phone,
          paymentId: paymentParams.paymentId
        });

        if (isMounted.current) {
          const data = res.data;
          setReqState({ loading: false, data });
        }
      } catch (error: any) {
        console.log({ error });
        if (isMounted.current) {
          let errorMessage = DEFAULT_ERROR_MESSAGE;
          if (error.response) {
            errorMessage = error.response.data.description;
          }
          setReqState({ loading: false, error: errorMessage });
        }
      }
    },
    [paymentParams, isMounted]
  );

  return {
    reqState,
    optInForTUC,
  };
}
