import { SERVICE_BASE_URL } from "../../../../config/properties";
import httpClient, { HttpClient } from "../../../../core/api/httpClient";

export interface GenerateAccountRequest {
  amount: number;
  checkoutAmount: number;
  currencyCode: string;
  merchantCode: string;
  merchantTransactionReference: string;
  payableCode: string;
  paymentId: number;
  surcharge: number;
}

export interface GenerateAccountResponse {
  accountName: string;
  accountNumber: string;
  amount: number;
  bankName: string;
  responseCode: string;
  transactionReference: string;
  validityPeriodMins: number;
}

export interface GetTransactionStatusRequest {
  merchantCode: string;
  transactionReference: string;
}

export interface GetTransactionStatusResponse {
  responseCode: string;
  responseDescription: string;
}


interface TransferRepository {
  generateAccount: (request: GenerateAccountRequest) => Promise<GenerateAccountResponse>;
  getTransactionStatus: (request: GetTransactionStatusRequest) => Promise<GetTransactionStatusResponse>;
}

class TransferRepositoryImpl implements TransferRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async generateAccount(request: GenerateAccountRequest): Promise<GenerateAccountResponse> {
    const response = await this.httpClient.post<GenerateAccountResponse>(
      `${SERVICE_BASE_URL}/api/v1/virtualaccount/generate`,
      request
    );

    return response.data;
  }

  async getTransactionStatus(request: GetTransactionStatusRequest) {
    const response = await this.httpClient.get<GetTransactionStatusResponse>(
      `${SERVICE_BASE_URL}/api/v1/virtualaccount/status`,
      { params: request }
    );

    return response.data;
  }
}

const transferRepository = new TransferRepositoryImpl(httpClient);

export default transferRepository;
