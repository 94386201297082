import httpClient, { HttpClient } from "../../../../core/api/httpClient";
import { SERVICE_BASE_URL } from "../../../../config/properties";

export interface ValidateRequestData {
    msisdn: string;
    countryCode: string;
    merchantCode: string;
    transactionReferenceId: string;
}

export interface CreatePaymentRequest {
    orderId: string;
    orderAmount: number;
    orderCurrency: string;
    customerName: string;
    customerMobileNumber: string;
    customerWalletName: string;
    customerVirtualHandle: string;
    customerPhoneNumber: string;
    merchantId: string;
    merchantCategoryCode: string;
    merchantTillId: string;
    transType: string;
    paymentId: string;
}

export interface ProviderData {
    name?: string;
    id: null;
    merchantName: string;
    merchantID: string;
    merchantCategory: string;
}

export interface CountryData {
    id: any,
    label: string,
    countryCode: string,
    icon?: string
}

export interface ValidateRequestResponse {
    data: string;
}

export interface CreatePaymentResponse {
    data: any;
    code: string;
}

export interface GetProvidersResponse {
    data: ProviderData[];
}

export interface GetCountryListResponse {
    data: CountryData[];
}

interface TerrapayRepository {
    validateRequest: (request: ValidateRequestData) => Promise<ValidateRequestResponse>;
    createPayment: (request: CreatePaymentRequest) => Promise<CreatePaymentResponse>;
    getProviders: () => Promise<GetProvidersResponse>;
    getProvidersSearch: (searchParam: string) => Promise<GetProvidersResponse>;
    getCountryList: () => Promise<GetCountryListResponse>;
}

class TerrapayRepositoryImpl implements TerrapayRepository {
    private httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    async validateRequest(request: ValidateRequestData) {

        const response = await this.httpClient.post<ValidateRequestResponse>(
        `${SERVICE_BASE_URL}/api/v1/mobile-wallet/terrapay/initialize`, request);

        return response.data;
    }

    async createPayment(request: CreatePaymentRequest) {
        const response = await this.httpClient.post<CreatePaymentResponse>(
        `${SERVICE_BASE_URL}/api/v1/mobile-wallet/terrapay/payment`, request);

        return response.data;
    }

    async getProviders() {
        const response = await this.httpClient.get<GetProvidersResponse>(
        `${SERVICE_BASE_URL}/api/v1/mobile-wallet/terrapay/wallets/list`
        );
        return response.data;
    }

    async getProvidersSearch(queryParameter: string) {
        const response = await this.httpClient.get<GetProvidersResponse>(
        `${SERVICE_BASE_URL}/api/v1/mobile-wallet/terrapay/wallets/search/wallet?queryParameter=${queryParameter}`
        );
        return response.data;
    }

    async getCountryList () {
        const response = await this.httpClient.get<GetCountryListResponse>(
        `${SERVICE_BASE_URL}/api/v1/mobile-wallet/terrapay/wallets/countryList`
        );
        return response.data;
    }
}

const TerrapayRepository = new TerrapayRepositoryImpl(httpClient);

export default TerrapayRepository;

