import React from 'react';
import styled from 'styled-components';
import ErrorView from './features/components/ErrorView';
import { PageView } from './features/components/Layout';

const Container = styled(PageView)``;

interface State {
  hasError: boolean;
}

export default class ErrorBoundaryView extends React.Component<any, State, any> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error);
    console.log(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <ErrorView message="Oops, something went wrong. Please contact support" />
        </Container>
      );
    }

    return this.props.children;
  }
}
