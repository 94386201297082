import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../../../../store/RootReducer";
import { getIsCardPinValid } from "../../../../../../../card/utils/fieldValidators";
import { generateSecureData } from "../../../../../../../card/utils/secureGenerator";
import { Button } from "../../../../../../../components/Button";
import { FieldErrorText, RowContainer } from "../../../../../../../components/Layout";
import { triggerShowNotification } from "../../../../../../../components/NotificationWidget/store/actions";
import { TriggerShowNotificationPayload } from "../../../../../../../components/NotificationWidget/store/types";
import PinInputField from "../../../../../../../components/PinInputField";
import { PaymentParams } from "../../../../../../../payment/presentation/store/paymentProperties/types";
import { Form } from "../../../../../../../wallet/presentation/pages/WalletLoginPage/style";
import { AcceptOfferRequest, Offer } from "../../../../../../domain/repositories/OffersRepository";
import { GetTokenWithOtpRequest } from "../../../../../../domain/repositories/OtpRepository";
import { triggerGetTokenWithOtp } from "../../../../../store/cardDetails/actions";
import {
  setCreditPageTitle,
  setCreditPageSubTitle,
  displayScreen,
  updateAcceptOfferRequest
} from "../../../../../store/offers/actions";
import { OffersScreenType } from "../../../../../store/offers/types";
import { PinFieldContainer } from "../../../style";
import { CardDetails } from "../CardRootPage";

interface Ownprops {
  offer: Offer;
  cardDetails: CardDetails | null;
  setShowCardPin: (value: boolean) => void;
}

interface CardPinStoreStateProps {
  loading: boolean;
  error: AxiosError | null;
  customerId?: string | null;
  paymentParams: PaymentParams | null;
}

interface CardPinStoreDispatchProps {
  setPageTitle: (title: string) => void;
  setPageSubtitle: (subtitle: string) => void;
  displayScreen: (screenType: OffersScreenType) => void;
  updateAcceptOfferRequest: (request: AcceptOfferRequest) => void;
  showNotification: (payload: TriggerShowNotificationPayload) => void;
  tokenizeCardDetails: (request: GetTokenWithOtpRequest, onSuccessful: () => void) => void;
}

type Props = CardPinStoreStateProps & CardPinStoreDispatchProps & Ownprops;

function CardPinPage(props: Props) {
  const {
    setPageSubtitle,
    setPageTitle,
    tokenizeCardDetails,
    loading,
    offer,
    error,
    cardDetails,
    paymentParams,
    showNotification,
    displayScreen,
    customerId,
    setShowCardPin,
    updateAcceptOfferRequest
  } = props;
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [pinValue, setPinValue] = useState("");
  const { isValid: isCardPinValid, message: cardPinErrorMessage } = getIsCardPinValid(pinValue);

  const onTokenizationSuccessful = (request: AcceptOfferRequest) => {
    updateAcceptOfferRequest(request);
    displayScreen(OffersScreenType.otp);
  };

  const tokenizeButtonHandler = () => {
    if (!isCardPinValid || !cardDetails) {
      setShowFormErrors(true);
      return;
    }

    if (!paymentParams || !offer || !customerId) {
      showNotification({
        type: "ERROR",
        message: "Sorry you do not have sufficient permission to perform this operation"
      });
      return;
    }

    const { cardPanValue, expValue, cvvValue } = cardDetails;
    const { paymentId, payableCode, merchantCode, merchantTransactionReference } = paymentParams;
    const { secure, pinBlock } = generateSecureData(cardPanValue, expValue, cvvValue, pinValue);


    let request = {
      pinBlock,
      paymentId,
      payableCode,
      merchantCode,
      secureData: secure,
      offerId: offer.offerId,
      customerId: customerId,
      providerCode: offer.provider.code,
      transactionRef: merchantTransactionReference,
    };


    tokenizeCardDetails(request, () => onTokenizationSuccessful(request));
  };

  const backButtonHandler = () => {
    setShowCardPin(false);
  };

  useEffect(() => {
    setPageTitle("Enter PIN");
    setPageSubtitle("Enter your 4-digit card PIN to complete this payment.");
  }, [setPageSubtitle, setPageTitle]);

  useEffect(() => {
    if (error) {
      if (!error.response || error.response.status !== 200) {
        showNotification({
          type: "ERROR",
          message: "Something went wrong. Please try again."
        });
        return;
      }

      if (error.response.data.responseCode === "LS3") {
        showNotification({
          type: "ERROR",
          message: "Error generating OTP. Please try again"
        });
        return;
      }
    }
  }, [error, showNotification]);

  return (
    <Form>
      <PinFieldContainer>
        <PinInputField
          value={pinValue}
          onValueChange={(value) => setPinValue(value)}
          lengthLimit={4}
          error={showFormErrors && !isCardPinValid}
        />

        {showFormErrors && !isCardPinValid && (
          <FieldErrorText>{cardPinErrorMessage}</FieldErrorText>
        )}
      </PinFieldContainer>

      <RowContainer>
        <Button
          text="Back"
          type="OUTLINE"
          color="SECONDARY"
          onClick={backButtonHandler}
          containerStyle={{ width: "30%", marginRight: "5px" }}
        />

        <Button
          text="Continue"
          color="PRIMARY"
          loading={loading}
          onClick={tokenizeButtonHandler}
          containerStyle={{ width: "70%", marginLeft: "5px" }}
        />
      </RowContainer>
    </Form>
  );
}

const mapStateToProps = (state: AppState): CardPinStoreStateProps => {
  return {
    customerId: state.credit.offers.userData?.mobileNo,
    error: state.credit.creditCardDetails.getTokenWithOtpError,
    loading: state.credit.creditCardDetails.getTokenWithOtpPending,
    paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams
  };
};

const mapDispatchToProps = (dispatch: (action: any) => void): CardPinStoreDispatchProps => {
  return {
    setPageTitle: (title: string) => dispatch(setCreditPageTitle(title)),
    setPageSubtitle: (subtitle: string) => dispatch(setCreditPageSubTitle(subtitle)),
    displayScreen: (screenType: OffersScreenType) => dispatch(displayScreen(screenType)),

    updateAcceptOfferRequest: (request: AcceptOfferRequest) => {
      dispatch(updateAcceptOfferRequest(request));
    },

    showNotification: (payload: TriggerShowNotificationPayload) => {
      dispatch(triggerShowNotification(payload));
    },

    tokenizeCardDetails: (request: GetTokenWithOtpRequest, onSuccessful: () => void) => {
      dispatch(triggerGetTokenWithOtp(request, onSuccessful));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPinPage);
