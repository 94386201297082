import * as React from 'react';
import { PageSubTitle, PageTitle } from '../../../../components/Layout';
import ConfirmStatusMessage from '../../../../components/ConfirmStatusMessage';
import ErrorView from '../../../../components/ErrorView';
import useRequery from '../hooks/useRequery';
import { useHistory } from 'react-router-dom';
import { PAYMENT_METHODS__ROOT } from '../../../../Routes';
import { asyncDelay } from '../../../../../core/util/asyncUtil';
import useIsMountedRef from '../../../../../hooks/useIsMountedRef';

export default function UssdView() {
	const history = useHistory();

	const {
		loading: requeryingTranx,
		getStatus,
		error,
		pollingTimeout,
	} = useRequery();

	const isMounted = useIsMountedRef().current;

	React.useEffect(() => {
		if (!isMounted) return;
		async function fetchData() {
			await asyncDelay(4000);
			getStatus();
		}
		fetchData();
	}, [getStatus, isMounted]);

	const goBack = () => {
		history.push(PAYMENT_METHODS__ROOT);
	};

	return (
		<>
			<PageTitle>USSD Notification</PageTitle>
			<PageSubTitle>
				Kindly check your mobile phone and enter your pin.
			</PageSubTitle>

			{requeryingTranx && <ConfirmStatusMessage />}

			{((!requeryingTranx && pollingTimeout === 0) || error) && (
				<ErrorView
					action={goBack}
					actionText="Retry"
					message="Oops, something went wrong. Please try again."
				/>
			)}
		</>
	);
}
