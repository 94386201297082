export function isDigitNumber(character: string) {
  let charCode: number = character.charCodeAt(0);

  if (charCode >= 48 && charCode <= 57) {
    return true;
  }

  return false;
}


/**
 * Filters out all non-digit characters and 
 * returns a digit only string
 */
export function getDigitsOnly(valueString: string): string {
  let filteredDigits = [];

  filteredDigits = valueString.split('').filter(character => {
    let charCode: number = character.charCodeAt(0);
    return charCode >= 48 && charCode <= 57
  });

  return filteredDigits.join('');
}


export function isEmptyInvalidValue(value: string | number) {
  if (value === undefined || value === null || value === '') {
    return true;
  }

  return false;
}