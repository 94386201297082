import { 
    PhoneNumberSigninState,
    PhoneNumberSigninActionTypes,

    HIDE_CREDIT_OTP_PAGE,
    DISPLAY_CREDIT_OTP_PAGE,
    HIDE_PHONE_NUMBER_SIGNIN,
    DISPLAY_PHONE_NUMBER_SIGNIN,
    RESET_PHONE_NUMBER_SIGNIN_PAGE
} from "./types"

const initialState: PhoneNumberSigninState = {
    displayOtpPage: false,
    displayPhoneNumberSignin: true
}

export default function PhoneNumberSigninReducer( state: PhoneNumberSigninState = initialState, action: PhoneNumberSigninActionTypes): PhoneNumberSigninState {

    switch(action.type) {
        case DISPLAY_CREDIT_OTP_PAGE: {
            return { ...state, displayOtpPage: true }
        }

        case HIDE_CREDIT_OTP_PAGE: {
            return { ...state, displayOtpPage: false }
        }

        case DISPLAY_PHONE_NUMBER_SIGNIN: {
            return { ...state, displayPhoneNumberSignin: true }
        }

        case HIDE_PHONE_NUMBER_SIGNIN: {
            return { ...state, displayPhoneNumberSignin: false }
        }

        case RESET_PHONE_NUMBER_SIGNIN_PAGE: {
            return initialState
        }

        default: {
            return state
        }
    }
}
