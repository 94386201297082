import React, { useState } from "react";
import styled from "styled-components";

import { PocketView } from "../..";
import { BatchA, BatchB } from "./steps";
import { Button } from "../../../../../../components/Button";

interface Props {
  setView: React.Dispatch<React.SetStateAction<PocketView>>;
}
type Batch = "BatchA" | "BatchB";
export const HowToPay = ({ setView }: Props) => {
  const [batch, setBatch] = useState<Batch>("BatchA");

  const renderBatch = () => {
    switch (batch) {
      case "BatchA":
        return BatchA;
      case "BatchB":
        return BatchB;
      default:
        return BatchA;
    }
  };

  const handleButtonClick = () => {
    if (batch === "BatchA") {
      setBatch("BatchB");
    } else {
      setView("pocket-id");
    }
  };

  return (
    <>
      <Container>
        {batch === "BatchB" && (
          <p>
            Once you are registered on PocketApp, proceed to complete your
            transaction:
          </p>
        )}
        {renderBatch().map((step, idx) => (
          <li key={idx}>{step}</li>
        ))}
      </Container>

      <IndicatorWrapper>
        <Indicator isActive={batch === "BatchA"} />
        <Indicator isActive={batch === "BatchB"} />
      </IndicatorWrapper>

      <Button
        type="OUTLINE"
        color="SECONDARY"
        text={batch === "BatchA" ? "Next" : "Back to payment"}
        containerStyle={ButtonStyles}
        onClick={handleButtonClick}
      />
    </>
  );
};

const Container = styled.div`
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px 8px;
  p {
    font-size: 14px;
    color: #666666;
    margin-bottom: 8px;
  }

  li {
    font-size: 14px;
    color: #666666;
    margin-bottom: 4px;
    line-height: 24px;
  }
`;

const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  column-gap: 8px;
`;

const Indicator = styled.div<{ isActive: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ isActive }) => (isActive ? "#009FE4" : "#EBEBEB")};
`;

const ButtonStyles = {
  width: "100%",
  marginTop: "32px",
  color: "#252B33",
  fontWeight: 600,
};
