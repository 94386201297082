import { takeLatest, put } from "redux-saga/effects";
import TerrapayRepository, { GetProvidersResponse } from "../../../domain/repositories/TerrapayRepository";


import {
  TRIGGER_GET_PROVIDERS,
  TriggerGetProviders,

  TRIGGER_GET_PROVIDERS_SEARCH,
  TriggerGetProvidersSearch
} from "./types";

import {
  getProvidersPending,
  getProvidersSuccess,
  getProvidersError
} from "./actions";
import { AxiosError } from "axios";



function* handleTriggerGetProviders(action: TriggerGetProviders) {

  yield put(getProvidersPending());

  let response: GetProvidersResponse;

  try {
    response = yield TerrapayRepository.getProviders();
  } catch (err) {
    yield put(getProvidersError(err as AxiosError));
    return;
  }

  yield put(getProvidersSuccess(response));
}

function* handleTriggerGetProvidersSearch(action: TriggerGetProvidersSearch) {
  const { payload } = action;
  yield put(getProvidersPending());

  let response: GetProvidersResponse;

  try {
    response = yield TerrapayRepository.getProvidersSearch(payload);
  } catch (err) {
    yield put(getProvidersError(err as AxiosError));
    return;
  }

  yield put(getProvidersSuccess(response));
}


export function* getProvidersSaga() {
  yield takeLatest(TRIGGER_GET_PROVIDERS, handleTriggerGetProviders);
  yield takeLatest(TRIGGER_GET_PROVIDERS_SEARCH, handleTriggerGetProvidersSearch);
}
