import { AxiosError } from "axios";
import { TriggerShowNotificationPayload } from "../../../../components/NotificationWidget/store/types";
import { PaymentParams } from "../../../../payment/presentation/store/paymentProperties/types";
import { SigninUserRequest } from "../../../../wallet/domain/repositories/WalletRepository";
import { SigninSuccessfulResponse } from "../offers/types";

export const RESET_VERVE_WALLET_SIGNIN = "CREDIT.RESET_VERVE_WALLET_SIGNIN";
export const TRIGGER_VERVE_WALLET_SIGNIN = "CREDIT.TRIGGER_VERVE_WALLET_SIGNIN";
export const VERVE_WALLET_SIGNIN_PENDING = "CREDIT.VERVE_WALLET_SIGNIN_PENDING";
export const VERVE_WALLET_SIGNIN_SUCCESSFUL = "CREDIT.VERVE_WALLET_SIGNIN_SUCCESSFUL";
export const VERVE_WALLET_SIGNIN_ERROR = "CREDIT.VERVE_WALLET_SIGNIN_ERROR";
export const DISPLAY_VERVE_WALLET_SIGNIN = "CREDIT.DISPLAY_VERVE_WALLET_SIGNIN";
export const HIDE_VERVE_WALLET_SIGNIN = "CREDIT.HIDE_VERVE_WALLET_SIGNIN";

export interface ResetVerveWalletSignin {
  type: typeof RESET_VERVE_WALLET_SIGNIN;
}

export interface TriggerVerveWalletSignin {
  type: typeof TRIGGER_VERVE_WALLET_SIGNIN;
  payload: {
    request: SigninUserRequest;
    onSuccessful?: OnSigninSuccessfulCallback;
  };
}

export interface VerveWalletSigninPending {
  type: typeof VERVE_WALLET_SIGNIN_PENDING;
}

export interface VerveWalletSigninSuccessful {
  type: typeof VERVE_WALLET_SIGNIN_SUCCESSFUL;
}

export interface VerveWalletSigninError {
  type: typeof VERVE_WALLET_SIGNIN_ERROR;
  payload: { error: AxiosError };
}

export interface VerveWalletSigninState {
  signinPending: boolean;
  signinError: AxiosError | null;
  displayVerveWalletSignin: boolean;
}

export interface DisplayVerveWalletSignin {
  type: typeof DISPLAY_VERVE_WALLET_SIGNIN;
}

export interface HideVerveWalletSignin {
  type: typeof HIDE_VERVE_WALLET_SIGNIN;
}

export interface StoreStateProps {
  signinPending: boolean;
  paymentParams: PaymentParams;
  signinError: AxiosError | null;
}

export type OnSigninSuccessfulCallback = (response: SigninSuccessfulResponse) => void;

export interface StoreDispatchProps {
  reset: () => void;
  setPageTitle: (title: string) => void;
  setPageSubTitle: (subtitle: string) => void;
  verveWalletSigninSuccessful: OnSigninSuccessfulCallback;
  triggerShowNotification: (payload: TriggerShowNotificationPayload) => void;
  signinUser: (request: SigninUserRequest, onSuccessful: OnSigninSuccessfulCallback) => void;
}

export type VerveWalletSigninActionTypes =
  | ResetVerveWalletSignin
  | VerveWalletSigninPending
  | TriggerVerveWalletSignin
  | VerveWalletSigninSuccessful
  | VerveWalletSigninError
  | DisplayVerveWalletSignin
  | HideVerveWalletSignin;
