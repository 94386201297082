export const MAX_STORE_VIEW_WIDTH = 1160;

export const PAGE_VERTICAL_PADDING = 25;
export const PAGE_SIDE_PADDING = 20;

export enum SupportedScreenSize {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
  HD = "HD",
  FHD = "FHD"
}

export const ScreenSize: { [key in SupportedScreenSize]: number } = {
  XS: 480,
  S: 768,
  M: 992,
  L: 1200,
  XL: 1440,
  HD: 1660,
  FHD: 1660
};