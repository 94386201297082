import { SERVICE_BASE_URL } from "../../../../config/properties";
import httpClient, { HttpClient } from "../../../../core/api/httpClient";

export interface Bank {
    id: number;
    code: string;
    name: string;
    domainCode: string;
    description: string;
    clientId: string;
    displayOrder: number;
    providerType: string;
    settleDirectPayment: string;
    insecure: string;
    recipientsEmailAddresses: string;
    isBank: boolean;
    auditableId: number;
    active: boolean;
    bankCode: string;
}

export interface BankResponse {
  banks: Bank[];
}

class BanksRepository  {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getBanks() {
    const url = `${SERVICE_BASE_URL}/api/v1/credit/banks`;
    const response = await this.httpClient.get<BankResponse>(url);
    return response.data;
  }
}

export default new BanksRepository(httpClient);
