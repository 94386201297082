import styled from "styled-components";
import { PageView } from "../../../components/Layout";

type TTimerWrapper = {
  top: number;
  bottom: number;
};

export const Container = styled(PageView)``;

export const ViewContainer = styled(PageView)`
  padding: 37px 0 37px 0;
  p {
    font-size: 14px;
    line-height: 17px;
    &:first-child {
      font-weight: 400;
      color: #6b7280;
      margin-right: 3px;
    }
  }
  .bold {
    font-weight: 700;
    color: black;
  }
`;

export const TimerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${({ top }: TTimerWrapper) => `${top}px`};
  margin-bottom: ${({ bottom }: TTimerWrapper) => `${bottom}px`};
`;

export const SixDigitCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    &:first-child {
      margin-bottom: 20px;
    }
  }
  .boldText {
    font-weight: 700;
    color: black;
  }
`;

export const DynamicLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    text-align: center;
    padding: 0 20px 0 20px;
  }
`;

export const ListContainer = styled.ul`
  list-style: decimal;
  list-style-position: inside;
  padding-left: 0;
  > li {
    text-align: left;
    font-size: 14px;
    line-height: 22px;

    > p {
      font-size: 14px;
      line-height: 22px;
      color: #0275d8;
      cursor: pointer;
      &:hover {
        color: #004077;
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 50px 0;
`;
