import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { AppState } from "../../../../../store/RootReducer";

import BackControl from "../../../../components/BackControl";
import ErrorView from "../../../../components/ErrorView";

import {
  DisclaimerContainer,
  PageTitle,
  PageView,
} from "../../../../components/Layout";
import { PAYMENT_METHODS__ROOT } from "../../../../Routes";
import Loader from "../../../../components/Loader";
import BankInfo from "./components/BankInfo";
import TimerView from "./components/TimerView";
import TransferStatus from "./components/TransferStatus";
import useGenerateBankAccount from "./hooks/useGenerateBankAccount";
import useGetTransferStatus from "./hooks/useGetTransferStatus";
import { asyncDelay } from "../../../../../core/util/asyncUtil";
import useCountdownTimer from "../../../../../hooks/useCountdownTimer";
import DisclaimerView from "../../../../components/DisclaimerView";

const INITIAL_POLL_TIMEOUT = 10000 * 60;
const ADDITIONAL_POLL_TIMEOUT = 5000 * 60;

export default function TransferPage() {
  const history = useHistory();

  const navigateToPaymentMethods = () => {
    history.push(PAYMENT_METHODS__ROOT);
  };

  const { paymentParams } = useSelector((state: AppState) => ({
    paymentParams: state.payment.paymentProperties.paymentParams!,
  }));

  const { accountNumber, error, generateAccountNumber, loading } =
    useGenerateBankAccount(paymentParams);

  const [activeView, setActiveView] = React.useState<"account" | "status">(
    "account"
  );

  const shouldPollTransferStatus = React.useRef(false);

  // const [pollTimeout, setPollTimeout] = React.useState(INITIAL_POLL_TIMEOUT);
  // React.useEffect(() => {
  //   if (accountNumber) {
  //     // setPollTimeout(20000);
  //     setPollTimeout((accountNumber.validityPeriodMins - 5) * 60 * 1000);
  //   }
  // }, [accountNumber]);

  const [numberOfTimeouts, setNumberOfTimeouts] = React.useState(0);

  const { time, restart } = useCountdownTimer(
    {
      timeout: INITIAL_POLL_TIMEOUT,
      onCompleted: () => {
        shouldPollTransferStatus.current = false;
        // console.log("cancelled");
      },
    },
    shouldPollTransferStatus.current
  );

  const {
    pollTransactionStatus,
    // error: statusError,
    hasError,
    // loading: fetchingStatus,
    // response,
    completeTransfer,
  } = useGetTransferStatus(paymentParams);

  const transferTimeout = React.useRef<any>();
  React.useEffect(() => {
    if (accountNumber && shouldPollTransferStatus.current) {
      const TIMEOUT = (accountNumber.validityPeriodMins + 5) * 60 * 1000;

      transferTimeout.current = setTimeout(() => {
        shouldPollTransferStatus.current = false;
        completeTransfer();
      }, TIMEOUT);
    }

    return () => {
      if (transferTimeout.current) {
        clearTimeout(transferTimeout.current);
      }
    };
  }, [completeTransfer, accountNumber]);

  const poll = async () => {
    while (true) {
      if (!shouldPollTransferStatus.current) {
        console.log("break!!");
        setNumberOfTimeouts((n) => n + 1);
        break;
      }

      // console.log("polling!!!");
      pollTransactionStatus();

      // await asyncDelay(1000);
      await asyncDelay(20000);
    }
  };

  const startTransferStatusPolling = () => {
    shouldPollTransferStatus.current = true;

    setActiveView("status");
    poll();
  };

  const waitFiveMinutesMore = () => {
    // setPollTimeout(ADDITIONAL_POLL_TIMEOUT);
    restart(ADDITIONAL_POLL_TIMEOUT);
    // restart(40000);
    startTransferStatusPolling();
  };

  // console.log("shouldPollTransferStatus :>> ", shouldPollTransferStatus);

  React.useEffect(() => {
    return () => {
      shouldPollTransferStatus.current = false;

      setActiveView("account");
    };
  }, []);

  let paymentProps = window.iswPaymentProps;
  const { acquiredBy } = paymentProps;

  return (
    <PageView>
      <BackControl
        text="Change payment method"
        onClick={navigateToPaymentMethods}
      />

      <PageTitle>Pay with Transfer</PageTitle>

      {activeView === "account" && (
        <>
          {loading && (
            <LoaderBox>
              <Loader />
            </LoaderBox>
          )}

          {!loading && error && <ErrorView action={generateAccountNumber} />}

          {accountNumber && !error && (
            <BankInfo
              accountNumber={accountNumber}
              currencyCode={paymentParams.currencyCode}
              onDone={startTransferStatusPolling}
            />
          )}
        </>
      )}

      {accountNumber && activeView === "status" && (
        <>
          {!hasError && (
            <TimerView
              time={time}
              onWait={waitFiveMinutesMore}
              hideRetry={numberOfTimeouts >= 3}
              accountNumber={accountNumber}
            />
          )}

          {hasError && <TransferStatus />}
        </>
      )}

      {acquiredBy === "ZIB" && (
        <DisclaimerContainer>
          <DisclaimerView />
        </DisclaimerContainer>
      )}
    </PageView>
  );
}

const LoaderBox = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
