import { SERVICE_BASE_URL } from "../../config/properties";
import httpClient from "./httpClient";

export interface CacellationPayload {
  paymentId: number;
  customerCancellationReason: string;
}

export const cancelPayment = (payload: CacellationPayload) => {
  return httpClient.post(
    `${SERVICE_BASE_URL}/api/v1/pay/customerCancellation`,
    payload
  );
};

const api = { cancelPayment };

export default api;
