import {
  SIGNIN_USER_PENDING,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_ERROR,
  RESET_SIGNIN_USER,
  SigninUserState,
  SigninUserActionType,
} from "./types";

const initialState: SigninUserState = {
  loading: false,
  loadingError: null,
  userData: null,
  walletCards: null
}

export function SigninUserReducer(
  state: SigninUserState = initialState,
  action: SigninUserActionType
): SigninUserState {
  switch (action.type) {

    case SIGNIN_USER_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case SIGNIN_USER_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      const { user, paymentMethods } = response;

      return {
        ...state,
        loading: false,
        loadingError: null,
        userData: user || null,
        walletCards: paymentMethods
      };
    }

    case SIGNIN_USER_ERROR: {
      const { payload } = action;
      const { error } = payload;

      return {
        ...state,
        loading: false,
        loadingError: error,
      };
    }

    case RESET_SIGNIN_USER: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
