import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { getStore } from "./store";
import { Provider } from "react-redux";
import { MemoryRouter } from 'react-router-dom';

import { initCybersourceFingerprint } from './cybersource';
import RootView from './features/RootView';
import { PAYMENT_METHODS__ROOT } from './features/Routes';


import { GlobalStyle } from './styling/global';
import './styling/fonts.css';

import testPaymentProps from './test/testPaymentProps';


if(!(window as any).iswPaymentProps) {
  (window as any).iswPaymentProps = testPaymentProps;
}

initCybersourceFingerprint(window.iswPaymentProps);

const store = getStore();

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />

    <Provider store={store}>

      <MemoryRouter
        initialEntries={[PAYMENT_METHODS__ROOT]}
        initialIndex={0}
      >
        <RootView />
      </MemoryRouter>

    </Provider>

  </React.StrictMode>,
  document.getElementById('root')
);

