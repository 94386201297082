import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { NotificationMessage } from '../components/NotificationWidget/store/types';
import { completeTransaction } from '../payment/presentation/store/paymentStatus/actions';
import { asyncDelay } from '../../core/util/asyncUtil';
import { AppState } from '../../store/RootReducer';
import { triggerInitialize } from '../payment/presentation/store/paymentProperties/actions';
import {
  AppDisplayMode,
  PaymentChannelType,
  PaymentParams,
} from '../payment/presentation/store/paymentProperties/types';

import {
  PAYMENT_METHODS__ROOT,
  PAYMENT__CARD_OTP,
  PAYMENT__CARD_PIN,
  PAYMENT__CARD_ROOT,
  PAYMENT__CARD_CARDINAL,
  PAYMENT__WALLET_LOGIN,
  PAYMENT__WALLET_ROOT,
  PAYMENT__WALLET_CARD_PAGE,
  PAYMENT__TRANSFER_ROOT,
  PAYMENT__QR_ROOT,
  PAYMENT__USSD_ROOT,
  PAYMENT__CUSTOMER_WALLET_ROOT,
  PAYMENT__CREDIT,
  PAYMENT__GOOGLE_PAY,
  PAYMENT__PAY_WITH_APP,
  PAYMENT__UGANDA_MOBILE_MONEY,
  PAYMENT__OPAY_ROOT,
  PAYMENT__ENAIRA_ROOT,
  PAYMENT__MOMO_ROOT,
  PAYMENT__POCKET_ROOT,
  PAYMENT__PALMPAY_ROOT,
  ZIB__CUSTOMER_DISCLAIMER,
  PAYMENT__DRC_MOBILE_MONEY_ROOT,
	PAYMENT__DRC_MOBILE_MONEY_USSD,
} from '../Routes';

// Payment routes
import SelectPaymentMethodPage from '../payment/presentation/pages/SelectPaymentMethodPage';
import PaymentCompleteView from '../payment/presentation/PaymentCompleteView';

// Card routes
import CardRootPage from '../card/presentation/pages/CardRootPage';
import CardPinPage from '../card/presentation/pages/CardPinPage';
import CardOtpPage from '../card/presentation/pages/CardOtpPage';
import CardCardinalPage from '../card/presentation/pages/CardCardinalPage';

// Wallet routes
import WalletLoginPage from '../wallet/presentation/pages/WalletLoginPage';
import WalletRootPage from '../wallet/presentation/pages/WalletRootPage';
import WalletCardPage from '../wallet/presentation/pages/WalletCardPage';

// Transfer routes
import TransferRootPage from '../transfer/presentation/pages/TransferRootPage';
import TransferPage from '../transfer/presentation/pages/TransferPage';

// Qr routes
import QrRootPage from '../qr/presentation/pages/QrRootPage';

// Ussd routes
import UssdRootPage from '../ussd/presentation/pages/UssdRootPage';

// PAY-WITH-APP
import PayWithAppRootPage from '../payWithApp/presentation/pages';

// Customer Waller
// import CustomerWalletRoutes from "../customerWallet/presentation/pages";
import CustomerWallet from '../customerWallet/presentation/pages/RootPage';

// Zib Disclaimer
import CustomerDisclaimerView from '../components/CustomerDisclaimer';

import OpayRootPage from '../opay/presentation/pages/RootPage';
import EnairaRootPage from '../enaira/presentation/pages/RootPage';
import MomoRootPage from '../momo';
import PocketRootPage from '../pocket';
import PalmpayRootPage from '../palmpay/presentation/pages/RootPage';

import NotificationWidget from '../components/NotificationWidget';
import PaymentHeader from '../components/PaymentHeader';
import DemoWidget from '../demo/DemoWidget';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import GlobalPayLogo from '../../assets/images/globalpay-logo.png';
import AppLogo from '../../assets/images/interswitch-logo.png';
import MultipayLogo from '../../assets/images/multipay-logo.png';
import MessageBus from '../../core/MessageBus';
import {
  ActivePageContainer,
  BrandContainer,
  CancelHeaderContainer,
  Container,
  FooterContainer,
  PaymentWidgetContainer,
} from './style';
import cardRepository from '../card/domain/repositories/CardRepository';
import { hideNotification } from '../components/NotificationWidget/store/actions';
import CancelTransactionModal from './CancelTransactionModal';
import ErrorBoundaryView from '../../ErrorBoundaryView';
import walletRepository, {
  SigninUserResponse,
} from '../wallet/domain/repositories/WalletRepository';
import { signinUserSuccess } from '../wallet/presentation/store/signinUser/actions';
import LoadingView from '../components/LoadingView';
import { setPaymentChannel } from '../payment/presentation/store/currentPaymentChannel/actions';

import PayWithCreditPage from '../credit/presentation/pages/index';
import useCancelPayment from './useCancelPayment';
import GooglePayRootPage from '../googlePay/presentation/pages';
import UgandaMobileMoney from '../ugandaMobileMoney/presentation/pages';
import DrcMobileMoney from '../drcMobileMoney/presentation/pages';
import DrcRequeryView from '../drcMobileMoney/presentation/pages/RequeryView';

export interface MerchantTransactionResponse {
  payRef: string;
  txnref: string;
  amount: string;
  apprAmt: string;
  resp: string;
  desc: string;
  retRef: string;
  cardNum: string;
  mac: string;
  bpResp?: string;
  bpTrxnRef?: string;
  rechPin?: string;
}

const getMerchantTransactionResponse = (
  transactionResponse: any
): MerchantTransactionResponse | null => {
  if (!transactionResponse) return null;

  return {
    payRef: transactionResponse.channelTransactionReference,
    txnref: transactionResponse.transactionReference,
    amount: transactionResponse.approvedAmount,
    apprAmt: transactionResponse.amount,
    resp: transactionResponse.responseCode,
    desc: transactionResponse.responseDescription,
    retRef: transactionResponse.retrievalReferenceNumber,
		cardNum: '',
		mac: '',
    bpResp: transactionResponse.bpResp,
    bpTrxnRef: transactionResponse.bpTrxnRef,
    rechPin: transactionResponse.rechPin,
  };
};

const postResponseToMerchant = (
	appDisplayMode: AppDisplayMode | undefined = 'REDIRECT',
  siteOrigin: string | undefined,
  redirectUrl: string | undefined,
  transactionResponse: any
) => {
  const merchantTransactionResponse =
    getMerchantTransactionResponse(transactionResponse);

  if (!merchantTransactionResponse) return;

	if (appDisplayMode === 'INLINE') {
    const targetWindow = window.parent;
    if (!siteOrigin) return;

    MessageBus.sendMessage({
      target: targetWindow,
      targetOrigin: siteOrigin,
			messageType: 'COMPLETED',
      data: merchantTransactionResponse,
    });

    return;
  }

  if (!redirectUrl) return;

	const formEl = document.createElement('form');
	formEl.method = 'POST';
  formEl.action = redirectUrl;
	formEl.style.display = 'none';

  for (const [key, value] of Object.entries(merchantTransactionResponse)) {
    if (value === undefined) continue;

		const inputEl = document.createElement('input');
    inputEl.name = key;
    inputEl.value = value;
    formEl.appendChild(inputEl);
  }

  document.body.appendChild(formEl);
  formEl.submit();
};

interface StoreStateProps {
  appInitialized: boolean;
  appDisplayMode: AppDisplayMode;
  paymentParams: PaymentParams | null;

  transactionCompleted: boolean;
  transactionResponse: any;

  escrowAccepted?: boolean;

  showNotification: boolean;
  notificationMessage: NotificationMessage;

  hasPendingTransfer: boolean;
  hasPendingQR: boolean;
  hasPendingUSSD: boolean;
}

interface StoreDispatchProps {
  initializePaymentProperties: (paymentProps: any) => void;
  hideNotification: () => void;
  completeTransaction: (transactionResponse: any) => void;
  signinUserSuccess: (response: SigninUserResponse) => void;
  setPaymentChannel: (channelType: PaymentChannelType) => void;
}

interface OwnProps {}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function RootView(props: Props) {
  const {
    appInitialized,
    appDisplayMode,
    paymentParams,

    transactionCompleted,
    transactionResponse,

    escrowAccepted,

    showNotification,
    notificationMessage,

    initializePaymentProperties,
    hideNotification,
    completeTransaction,
    signinUserSuccess,
    setPaymentChannel,

    hasPendingTransfer,
    hasPendingQR,
    hasPendingUSSD,
  } = props;

	const acquiredBy = window.iswPaymentProps.acquiredBy || '';
	const isDrc = window.iswPaymentProps.currencyCode || '';

  const history = useHistory();

  const rawAppDisplayMode: AppDisplayMode =
		window.iswPaymentProps.displayMode || 'REDIRECT';

  const [autoFetchingWalletData, setAutoFetchingWalletData] = useState(false);

  const [showCancelModal, setShowCancelModal] = useState(false);

  const openCancelTransationModal = () => {
    if (transactionCompleted || !paymentParams) return;

    setShowCancelModal(true);
  };

  const closeCancelTransationModal = () => setShowCancelModal(false);

  const initiateCancelTransaction = () => {
    if (transactionCompleted || !paymentParams) return;

    setShowCancelModal(false);

    const { merchantTransactionReference } = paymentParams;

    completeTransaction({
			responseCode: 'Z6',
      transactionReference: merchantTransactionReference,
    });
  };

  const handleTransactionCompletion = async () => {
    if (!paymentParams) return;

    // delay to let user see success/error view for the transaction
    await asyncDelay(2000);

    const { siteOrigin, siteRedirectUrl } = paymentParams;

    postResponseToMerchant(appDisplayMode, siteOrigin, siteRedirectUrl, {
      ...transactionResponse,
      transactionReference: paymentParams.merchantTransactionReference,
    });
  };

  const tryAutoFetchWalletData = async () => {
    if (!paymentParams) return;

    // check if access token is present for auto loading wallet
    const { merchantCode, payableCode, accessToken, accessTokenType } =
      paymentParams;

    if (!accessToken || !accessTokenType) return;

    setAutoFetchingWalletData(true);

    let response;

    try {
      response = await walletRepository.authenticateToken({
        merchantCode: merchantCode,
        payableCode: payableCode,
        accessToken: accessToken,
        accessTokenType: accessTokenType,
      });
    } catch (err) {
      setAutoFetchingWalletData(false);
      return;
    }

    setAutoFetchingWalletData(false);

    signinUserSuccess(response);

    //
		setPaymentChannel('WALLET');

    history.push(PAYMENT__WALLET_ROOT);
  };

  useEffect(() => {
    let paymentProps = window.iswPaymentProps;

    const { responseCode } = paymentProps;

		if (responseCode === 'Z4' || responseCode === 'Z5') {
      const { displayMode, siteOrigin, siteRedirectUrl } = paymentProps;
      postResponseToMerchant(displayMode, siteOrigin, siteRedirectUrl, {
        ...paymentProps,
        transactionReference: paymentProps.merchantTransactionReference,
      });
      return;
    }

    initializePaymentProperties(paymentProps);
  }, []);

  useEffect(() => {
    if (!paymentParams) return;

    // try to auto load wallet
    tryAutoFetchWalletData();

		if (appDisplayMode === 'REDIRECT') return;

    const { siteOrigin } = paymentParams;

    const targetWindow = window.parent;

    MessageBus.sendMessage({
      target: targetWindow,
      targetOrigin: siteOrigin,
			messageType: 'INITIALIZED',
    });
  }, [paymentParams]);

  // Handles payment completion for the app
  useEffect(() => {
    if (!transactionCompleted) return;

    handleTransactionCompletion();
  }, [transactionCompleted]);

  useCancelPayment();

  const hasEscrowEnabled =
    !!paymentParams &&
    !!(
      paymentParams.escrowFee &&
      paymentParams.cancellationPeriod &&
      paymentParams.refundDuration
    ) &&
    !escrowAccepted;

  const ZibLogoUrl =
    "https://www.zenithbank.com/media/1400/zenith-bank-logo_1.png";

  return (
    <Container displayMode={rawAppDisplayMode}>
			{process.env.NODE_ENV === 'development' && <DemoWidget />}

      {appInitialized && (
        <CancelHeaderContainer displayMode={rawAppDisplayMode}>
          <div onClick={openCancelTransationModal}>
            <CloseIcon />
            <p>Cancel payment and return to {paymentParams?.merchantName}</p>
          </div>
        </CancelHeaderContainer>
      )}

      <PaymentWidgetContainer displayMode={rawAppDisplayMode}>
        {showCancelModal && (
          <CancelTransactionModal
            proceedButtonHandler={initiateCancelTransaction}
            cancelButtonHandler={closeCancelTransationModal}
            onGoingRequest={
              hasPendingTransfer
								? 'transfer'
                : hasPendingQR
								? 'qr'
                : hasPendingUSSD
								? 'ussd'
                : undefined
            }
          />
        )}

        {showNotification && (
          <NotificationWidget
            type={notificationMessage.type}
            message={notificationMessage.message}
            closeHandler={hideNotification}
          />
        )}

        {appInitialized && !hasEscrowEnabled && !transactionCompleted && (
          <PaymentHeader
            transactionCompleted={transactionCompleted}
            logoUrl={
							(acquiredBy === 'ZIB'
                ? ZibLogoUrl
                : paymentParams?.logoUrl) as string
            }
            currencyCode={paymentParams?.currencyCode as string}
            amount={paymentParams?.amount as number}
            customerEmail={paymentParams?.customerName}
            cancelTransactionHandler={openCancelTransationModal}
            surcharge={paymentParams?.surcharge as number}
            passFeeToCustomer={paymentParams?.passFeeToCustomer as boolean}
          />
        )}

        {appInitialized && !transactionCompleted && (
          <>
            <ActivePageContainer>
              <ErrorBoundaryView>
                {autoFetchingWalletData && <LoadingView />}

                {!autoFetchingWalletData && (
                  <Switch>
                    <Route
                      exact
                      path={PAYMENT_METHODS__ROOT}
                      component={SelectPaymentMethodPage}
                    />

                    <Route
                      exact
                      path={PAYMENT__CARD_ROOT}
                      component={CardRootPage}
                    />
                    <Route
                      exact
                      path={PAYMENT__CARD_PIN}
                      component={CardPinPage}
                    />
                    <Route
                      exact
                      path={PAYMENT__CARD_OTP}
                      component={CardOtpPage}
                    />
                    <Route exact path={PAYMENT__CARD_CARDINAL}>
                      <CardCardinalPage cardRepository={cardRepository} />
                    </Route>

                    <Route
                      exact
                      path={PAYMENT__WALLET_LOGIN}
                      component={WalletLoginPage}
                    />
                    <Route
                      exact
                      path={PAYMENT__WALLET_ROOT}
                      component={WalletRootPage}
                    />
                    <Route
                      exact
                      path={PAYMENT__WALLET_CARD_PAGE}
                      component={WalletCardPage}
                    />

                    <Route
                      exact
                      path={PAYMENT__TRANSFER_ROOT}
                      component={TransferPage}
                    />

                    <Route
                      exact
                      path={PAYMENT__QR_ROOT}
                      component={QrRootPage}
                    />

                    <Route
                      exact
                      path={PAYMENT__USSD_ROOT}
                      component={UssdRootPage}
                    />

                    <Route
                      path={PAYMENT__CUSTOMER_WALLET_ROOT}
                      component={CustomerWallet}
                    />
                    <Route path={PAYMENT__OPAY_ROOT} component={OpayRootPage} />

                    <Route
                      path={PAYMENT__ENAIRA_ROOT}
                      component={EnairaRootPage}
                    />

                    <Route path={PAYMENT__MOMO_ROOT} component={MomoRootPage} />
                    <Route
                      path={PAYMENT__POCKET_ROOT}
                      component={PocketRootPage}
                    />

                    <Route
                      path={PAYMENT__PALMPAY_ROOT}
                      component={PalmpayRootPage}
                    />

                    <Route
                      exact
                      path={PAYMENT__CREDIT}
                      component={PayWithCreditPage}
                    />

                    <Route
                      exact
                      path={PAYMENT__GOOGLE_PAY}
                      component={GooglePayRootPage}
                    />
                    {/* pay-with-app */}
                    <Route
                      path={PAYMENT__PAY_WITH_APP}
                      component={PayWithAppRootPage}
                    />

                    <Route
                      path={PAYMENT__UGANDA_MOBILE_MONEY}
                      component={UgandaMobileMoney}
                    />

										{acquiredBy === 'ZIB' && (
                      <Route
                        path={ZIB__CUSTOMER_DISCLAIMER}
                        component={CustomerDisclaimerView}
                      />
                    )}

                    <Route
                      exact
                      path={PAYMENT__DRC_MOBILE_MONEY_ROOT}
                      component={DrcMobileMoney}
                    />
                    <Route 
                      exact
                      path={PAYMENT__DRC_MOBILE_MONEY_USSD}
                      component={DrcRequeryView}
                    />
                  </Switch>
                )}
              </ErrorBoundaryView>
            </ActivePageContainer>
          </>
        )}

        {appInitialized && transactionCompleted && (
          <PaymentCompleteView
            appDisplayMode={appDisplayMode}
            merchantName={paymentParams?.merchantName as string}
            transactionResponse={transactionResponse}
          />
        )}
      </PaymentWidgetContainer>

      {appInitialized && (
        <FooterContainer>
          <BrandContainer displayMode={rawAppDisplayMode}>
            <>
              <p>powered by</p>
							{acquiredBy === 'ZIB'
								? sessionStorage.setItem('acquiredBy', acquiredBy)
                : null}
							{acquiredBy === 'ZIB' ? (
                <img src={GlobalPayLogo} alt="GlobalPay logo" />
              ) : isDrc === '976' ? (<img src={MultipayLogo} alt="Multipay logo" />) : (
                (acquiredBy === "HALO" || acquiredBy === "") && (
                  <img src={AppLogo} alt="Interswitch logo" />
                )
              )}
            </>
          </BrandContainer>
        </FooterContainer>
      )}
    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  appInitialized: state.payment.paymentProperties.initialized,
  appDisplayMode: state.payment.paymentProperties.displayMode,
  paymentParams: state.payment.paymentProperties.paymentParams,

  escrowAccepted: state.payment.paymentProperties.escrowAccepted,

  transactionCompleted: state.payment.paymentStatus.transactionCompleted,
  transactionResponse: state.payment.paymentStatus.transactionResponse,

  showNotification: state.notificationWidget.showNotification,
  notificationMessage: state.notificationWidget
    .notificationMessage as NotificationMessage,

  hasPendingTransfer:
    state.transfer.getTransactionStatus.getTransactionStatusPending,
  hasPendingUSSD: state.ussd.getTransactionStatus.getTransactionStatusPending,
  hasPendingQR: state.qr.getTransactionStatus.getTransactionStatusPending,
});

const mapDispatchToProps = (
  dispatch: (action: any) => void
): StoreDispatchProps => ({
  initializePaymentProperties(paymentProps: any) {
    dispatch(triggerInitialize(paymentProps));
  },
  hideNotification() {
    dispatch(hideNotification());
  },
  completeTransaction(transactionResponse: any) {
    dispatch(completeTransaction(transactionResponse));
  },
  signinUserSuccess(response: SigninUserResponse) {
    dispatch(signinUserSuccess(response));
  },
  setPaymentChannel(channelType: PaymentChannelType) {
    dispatch(setPaymentChannel(channelType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RootView);
