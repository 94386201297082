import * as React from "react";
import styled, { keyframes } from "styled-components";

interface Props extends React.BaseHTMLAttributes<HTMLDivElement> {
  animate?: boolean;
}
export default function Loader(props: Props) {
  return (
    <LoaderBox {...props}>
      <span />
      <span />
    </LoaderBox>
  );
}

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LoaderBox = styled.div<Props>`
  width: 52px;
  height: 52px;
  background-color: #ececec;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  animation: ${spin} 500ms linear infinite;

  animation-play-state: ${({animate = true}) => (animate ? "running" : "paused")};

  &::before {
    content: "";
    display: block;
    height: 40px;
    width: 40px;
    border-radius: 50%;

    position: absolute;
    left: 50%;
    top: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  &::after {
    content: "";
    display: block;
    height: 100px;
    width: 100px;

    position: absolute;
    left: 50%;
    top: 50%;
    background-color: #009fe4;
    transform-origin: top left;
    transform: rotate(180deg);
  }

  > span:first-of-type {
    position: absolute;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: #009fe4;
    z-index: 2;
    left: 24px;
  }

  > span:nth-of-type(2) {
    position: absolute;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: #009fe4;
    z-index: 2;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
