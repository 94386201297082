import { takeLatest, put } from "redux-saga/effects";

import transferRepository, { Issuer } from "../../../domain/repositories/UssdRepository";

import {
  TRIGGER_GET_ISSUERS,
  TriggerGetIssuers
} from "./types";

import {
  getIssuersPending,
  getIssuersSuccess,
  getIssuersError
} from "./actions";


function* handleTriggerGetIssuers(action: TriggerGetIssuers) {
  const { payload } = action;
  const { request } = payload;

  yield put(getIssuersPending());

  let response: Issuer[];

  try {
    response = yield transferRepository.getIssuers(request);
  } catch (err) {
    yield put(getIssuersError());
    return;
  }

  yield put(getIssuersSuccess(response));
}


export function* getIssuersSaga() {
  yield takeLatest(TRIGGER_GET_ISSUERS, handleTriggerGetIssuers);
}
