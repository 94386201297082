import { AxiosError } from "axios";
import { SigninUserRequest, SigninUserResponse, WalletPaymentMethod, WalletUserData } from "../../../domain/repositories/WalletRepository";

export const TRIGGER_SIGNIN_USER = 'WALLET.TRIGGER_SIGNIN_USER';
export const SIGNIN_USER_PENDING = 'WALLET.SIGNIN_USER_PENDING';
export const SIGNIN_USER_SUCCESS = 'WALLET.SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_ERROR = 'WALLET.SIGNIN_USER_ERROR';

export const RESET_SIGNIN_USER = 'WALLET.RESET_GET_BIN_CONFIGURATION';

export interface TriggerSigninUser {
  type: typeof TRIGGER_SIGNIN_USER;
  payload: {
    request: SigninUserRequest;
  }
}

export interface SigninUserPending {
  type: typeof SIGNIN_USER_PENDING;
}

export interface SigninUserSuccess {
  type: typeof SIGNIN_USER_SUCCESS;
  payload: {
    response: SigninUserResponse;
  }
}

export interface SigninUserError {
  type: typeof SIGNIN_USER_ERROR;
  payload: {
    error: AxiosError;
  }
}

export interface ResetSigninUser {
  type: typeof RESET_SIGNIN_USER;
}

export type SigninUserActionType =
  TriggerSigninUser
  | SigninUserPending
  | SigninUserSuccess
  | SigninUserError
  | ResetSigninUser;

export interface SigninUserState {
  loading: boolean;
  loadingError: AxiosError | null;
  userData: WalletUserData | null;
  walletCards: WalletPaymentMethod[] | null;
}