import {
  GetTokenRequest,
  GetTokenResponse,
} from "../../../domain/repositories/PayWithAppRepository";

export const TRIGGER_GET_TOKEN = "PAY_WITH_APP.TRIGGER_GET_TOKEN";
export const RESET_GET_TOKEN = "PAY_WITH_APP.RESET_GET_TOKEN";
export const GET_TOKEN_PENDING = "PAY_WITH_APP.GET_TOKEN_PENDING";
export const GET_TOKEN_ERROR = "PAY_WITH_APP.GET_TOKEN_ERROR";
export const GET_TOKEN_SUCCESS = "PAY_WITH_APP.GET_TOKEN_SUCCESS";

export interface TriggerGetToken {
  type: typeof TRIGGER_GET_TOKEN;
  payload: {
    request: GetTokenRequest;
  };
}

export interface ResetGetToken {
  type: typeof RESET_GET_TOKEN;
}

export interface GetTokenPending {
  type: typeof GET_TOKEN_PENDING;
}

export interface GetTokenError {
  type: typeof GET_TOKEN_ERROR;
}

export interface GetTokenSuccess {
  type: typeof GET_TOKEN_SUCCESS;
  payload: {
    response: GetTokenResponse;
  };
}

export type GetTokenActionsType =
  | TriggerGetToken
  | ResetGetToken
  | GetTokenPending
  | GetTokenError
  | GetTokenSuccess;

export interface GetTokenState {
  getTokenPending: boolean;
  getTokenError: boolean;
  getTokenResponse: GetTokenResponse | null;
}
