import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import usePrevious from '../../../../../hooks/usePrevious';
import { AppState } from '../../../../../store/RootReducer';
import { PAYMENT__CARD_CARDINAL, PAYMENT__CARD_PIN } from '../../../../Routes';
import { GetBinConfigurationRequest, GetBinConfigurationResponse } from '../../../../card/domain/repositories/CardRepository';
import { triggerShowNotification } from '../../../../components/NotificationWidget/store/actions';
import { TriggerShowNotificationPayload } from '../../../../components/NotificationWidget/store/types';
import { PaymentParams } from '../../../../payment/presentation/store/paymentProperties/types';
import { getIsCardExpiryValid, getIsCardCvvValid } from '../../../../card/utils/fieldValidators';
import { generateSecureData } from '../../../../card/utils/secureGenerator';
import { resetCachedCardDetails, setCachedCardDetails } from '../../../../card/presentation/store/cachedCardDetails/actions';
import { CachedCardParams } from '../../../../card/presentation/store/cachedCardDetails/types';
import { triggerGetBinConfiguration } from '../../../../card/presentation/store/getBinConfiguration/actions';

import { PageView, PageTitle, PageSubTitle, LabelFieldContainer, FieldLabel, FormFieldsRow, FieldErrorText } from '../../../../components/Layout';
import { Button } from '../../../../components/Button';
import BackControl from '../../../../components/BackControl';
import ExpiryInputField from '../../../../components/ExpiryInputField';
import CvvInputField from '../../../../components/CvvInputField';
import { getFormattedPrice } from '../../../../../util/currencyUtil';
import WalletCardWidget from '../WalletCardWidget';
import { WalletPaymentMethod, WalletUserData } from '../../../domain/repositories/WalletRepository';
import getCardBrandFromCode from '../../../../../core/util/cardBrandUtils';

const Container = styled(PageView)`
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  width: 100%;
`;

interface WalletCardPageLocationState {
  walletPaymentMethod: WalletPaymentMethod;
}

interface StoreStateProps {
  paymentParams: PaymentParams;
  userData: WalletUserData;
  fetchingBinConfiguration: boolean;
  fetchingBinConfigurationError: boolean;
  binConfigurationResponse: GetBinConfigurationResponse;
}

interface StoreDispatchProps {
  showNotification: (payload: TriggerShowNotificationPayload) => void;
  getBinConfiguration: (request: GetBinConfigurationRequest) => void;
  setCachedCardDetails: (cardParams: CachedCardParams) => void;
  resetCachedCardDetails: () => void;
}

interface OwnProps {

}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function WalletCardPage(props: Props) {
  const {
    paymentParams,

    userData,

    fetchingBinConfiguration,
    fetchingBinConfigurationError,
    binConfigurationResponse,

    showNotification,
    getBinConfiguration,
    setCachedCardDetails,
    resetCachedCardDetails,
  } = props;

  const history = useHistory();
  const { location: { state: locationState } } = history;
  const { walletPaymentMethod } = locationState as WalletCardPageLocationState;

  const prevFetchingBinConfiguration = usePrevious(fetchingBinConfiguration);

  const [showFormErrors, setShowFormErrors] = useState(false);


  let initialExpValue = '';

  if (walletPaymentMethod.expiryMonth && walletPaymentMethod.expiryYear) {
    const { expiryMonth, expiryYear } = walletPaymentMethod;
    initialExpValue = `${expiryMonth}${expiryYear}`;
  }
  const [expValue, setExpValue] = useState(initialExpValue);


  const [cvvValue, setCvvValue] = useState('');

  const goBack = () => {
    resetCachedCardDetails();
    history.goBack();
  }

  const formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.nativeEvent.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    handleSubmit();
  }

  const {
    isValid: isCardExpValid,
    message: cardExpErrorMessage
  } = getIsCardExpiryValid(expValue, new Date());

  const {
    isValid: isCardCvvValid,
    message: cardCvvErrorMessage
  } = getIsCardCvvValid(cvvValue);


  const handleSubmit = () => {
    setShowFormErrors(true);

    const { cardTypeGatewayConfiguration } = walletPaymentMethod;
    const { supportsExpiryDate, supportsCvv2, } = cardTypeGatewayConfiguration;

    const passesCardExpiryCheck = !supportsExpiryDate ? true : supportsExpiryDate && isCardExpValid;
    const passesCardCvvCheck = !supportsCvv2 ? true : supportsCvv2 && isCardCvvValid;

    if (!passesCardExpiryCheck || !passesCardCvvCheck) {
      return;
    }

    const { mobileNo } = userData;

    const { cardHash, cardIdentifier, walletInstrumentIdentifier } = walletPaymentMethod;

    const { secure, pinBlock } = generateSecureData(
      cardHash, expValue, cvvValue
    );

    getBinConfiguration({
      secureData: secure,
      pinBlock: pinBlock,
      merchantCode: paymentParams.merchantCode,
      payableCode: paymentParams.payableCode,
      paymentId: paymentParams.paymentId,

      walletId: mobileNo,
      walletIdentifier: walletInstrumentIdentifier,
      instrumentIdentifier: cardIdentifier,
    });
  };

  useEffect(() => {
    if (!(!fetchingBinConfiguration && prevFetchingBinConfiguration)) return;

    if (fetchingBinConfigurationError) {
      showNotification({ type: 'ERROR', });
      return;
    };

    const { cardHash } = walletPaymentMethod;

    // Cache card details
    setCachedCardDetails({
      cardNumber: cardHash,
      cardExpiry: expValue,
      cardCvv: cvvValue,

      paymentMethod: walletPaymentMethod,
    });

    const {
      cardTypeGatewayConfiguration,
      cardinalAuthenticationInformation
    } = binConfigurationResponse;
    const { supportsCardinalAuthentication, supportsPin } = cardTypeGatewayConfiguration;

    // if card supports cardinal authentication 
    // navigate to cardinal route
    if (supportsCardinalAuthentication) {
      history.push({
        pathname: PAYMENT__CARD_CARDINAL,
        state: { cardinalAuthenticationInformation }
      });
      return;
    }

    if (supportsPin) {
      history.push(PAYMENT__CARD_PIN);
      return;
    }

  }, [fetchingBinConfiguration]);

  const {
    cardTypeCode,
    name: cardName,
    maskedPan,
    cardTypeGatewayConfiguration
  } = walletPaymentMethod;

  const { supportsExpiryDate, supportsCvv2 } = cardTypeGatewayConfiguration;  

  return (
    <Container>
      <BackControl
        text="Change card"
        onClick={goBack}
      />

      <PageTitle>Pay with Quickteller</PageTitle>
      <PageSubTitle>Enter your card details below</PageSubTitle>

      <Form onSubmit={formSubmitHandler}>
        <LabelFieldContainer>
          <WalletCardWidget
            cardBrand={getCardBrandFromCode(cardTypeCode) || undefined}
            title={cardName}
            cardNumber={maskedPan}
            isEcash={cardTypeCode === "QTM"}
          />
        </LabelFieldContainer>

        <FormFieldsRow>
          {supportsExpiryDate && (
            <LabelFieldContainer>
              <FieldLabel htmlFor="card-exp">Expiry</FieldLabel>

              <ExpiryInputField
                id="card-exp"
                initialValue={expValue}
                onValueChange={(value) => setExpValue(value)}
                disabled={false}
                error={showFormErrors && !isCardExpValid}
              />

              {showFormErrors && !isCardExpValid && (
                <FieldErrorText>{cardExpErrorMessage}</FieldErrorText>
              )}
            </LabelFieldContainer>
          )}

          {supportsCvv2 && (
            <LabelFieldContainer>
              <FieldLabel htmlFor="card-cvv">CVV</FieldLabel>

              <CvvInputField
                id="card-cvv"
                value={cvvValue}
                onValueChange={(value) => setCvvValue(value)}
                error={showFormErrors && !isCardCvvValid}
              />

              {showFormErrors && !isCardCvvValid && (
                <FieldErrorText>{cardCvvErrorMessage}</FieldErrorText>
              )}
            </LabelFieldContainer>
          )}
        </FormFieldsRow>

        <Button
          text={`Pay ${getFormattedPrice(
            paymentParams.checkoutAmount,
            paymentParams.currencyCode
          )}`}
          color="PRIMARY"
          loading={fetchingBinConfiguration}
          onClick={handleSubmit}
          containerStyle={{ width: '100%' }}
        />
      </Form>
    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,

  userData: state.wallet.userWalletData.userData as WalletUserData,

  fetchingBinConfiguration: state.card.getBinConfiguration.fetchingBinConfiguration,
  fetchingBinConfigurationError: state.card.getBinConfiguration.fetchingBinConfigurationError,
  binConfigurationResponse: state.card.getBinConfiguration.binConfigurationResponse as GetBinConfigurationResponse
});

const mapDispatchToProps = (dispatch: (action: any) => void): StoreDispatchProps => ({
  showNotification(payload: TriggerShowNotificationPayload) {
    dispatch(triggerShowNotification(payload));
  },
  getBinConfiguration(request: GetBinConfigurationRequest) {
    dispatch(triggerGetBinConfiguration(request));
  },
  setCachedCardDetails(cardParams: CachedCardParams) {
    dispatch(setCachedCardDetails(cardParams));
  },
  resetCachedCardDetails() {
    dispatch(resetCachedCardDetails());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletCardPage);