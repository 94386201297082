import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../../../../../store/RootReducer";
import { PaymentParams } from "../../../../../payment/presentation/store/paymentProperties/types";
import { completeTransaction } from "../../../../../payment/presentation/store/paymentStatus/actions";
import { PaymentUtil } from "../../../../../payment/util/PaymentUtil";

import { TRIGGER_GET_TRANSACTION_STATUS } from "../../../store/getTransactionStatus/types";

export default function useGetTransferStatus(paymentParams: PaymentParams) {
  const dispatch = useDispatch();

  const { loading, error, hasError, response } = useSelector(
    (state: AppState) => ({
      loading: state.transfer.getTransactionStatus.getTransactionStatusPending,
      hasError: state.transfer.getTransactionStatus.getTransactionStatusError,
      error: state.transfer.getTransactionStatus.errorMessage,
      response:
        state.transfer.getTransactionStatus.getTransactionStatusResponse,
    })
  );

  const pollTransactionStatus = React.useCallback(async () => {
    dispatch({
      type: TRIGGER_GET_TRANSACTION_STATUS,
      payload: {
        request: {
          merchantCode: paymentParams.merchantCode,
          transactionReference: paymentParams.merchantTransactionReference,
        },
      },
    });
  }, [paymentParams, dispatch]);

  const completeTransfer = React.useCallback(() => {
    if(response) {
      dispatch(
        completeTransaction({
          ...response,
          transactionReference: paymentParams.merchantTransactionReference,
        })
      );
    }
  }, [response, dispatch, paymentParams]);

  React.useEffect(() => {
    if (response) {
      const { responseCode } = response;

      if (PaymentUtil.isTransactionComplete(responseCode)) {        
        completeTransfer();
      }
    }
  }, [response, dispatch, paymentParams.merchantTransactionReference, completeTransfer]);

  return { pollTransactionStatus, loading, error, hasError, response, completeTransfer };
}
