export type CardBrand = 'MasterCard' | 'Verve' | 'Visa' | 'AmericanExpress';

export type CardTypeCode = "VVC" | "VCC" | "VMP" | "QTM" | "MCN" | "VVV" | "VCI";

export interface CardBrandConfiguration {
  name: CardBrand;
  range: number[];
  numberLength: number[];
}

export const cardBrandConfigurations: CardBrandConfiguration[] = [

  { name: 'MasterCard', range: [2221, 2720], numberLength: [16] },

  { name: 'MasterCard', range: [51, 55], numberLength: [16] },

  { name: 'Verve', range: [506099, 506198], numberLength: [16, 19] },

  { name: 'Verve', range: [650002, 650027], numberLength: [16, 19] },

  { name: 'Verve', range: [5612], numberLength: [19] },

  { name: 'Verve', range: [507865, 507964], numberLength: [19] },

  { name: 'Visa', range: [4], numberLength: [16] },

  { name: 'AmericanExpress', range: [34], numberLength: [15] },

  { name: 'AmericanExpress', range: [37], numberLength: [15] },

  
  
];