import {
  SET_CACHED_CARD_DETAILS,
  RESET_CACHED_CARD_DETAILS,

  CachedCardDetailsState,
  CachedCardDetailsActionType,
} from "./types";

const initialState: CachedCardDetailsState = {
  cachedCardParams: null
}

export function CachedCardDetailsReducer(
  state: CachedCardDetailsState = initialState,
  action: CachedCardDetailsActionType
): CachedCardDetailsState {
  switch (action.type) {

    case SET_CACHED_CARD_DETAILS: {
      const { payload } = action;
      const { cardParams } = payload;

      return {
        ...state,
        cachedCardParams: cardParams
      };
    }

    case RESET_CACHED_CARD_DETAILS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
