import { AxiosError } from "axios";
import { MakePaymentRequest, MakePaymentResponse } from "../../../domain/repositories/CardRepository";

export const TRIGGER_MAKE_PAYMENT = 'CARD.TRIGGER_MAKE_PAYMENT';
export const MAKE_PAYMENT_PENDING = 'CARD.MAKE_PAYMENT_PENDING';
export const MAKE_PAYMENT_SUCCESS = 'CARD.MAKE_PAYMENT_SUCCESS';
export const MAKE_PAYMENT_ERROR = 'CARD.MAKE_PAYMENT_ERROR';

export const RESET_MAKE_PAYMENT = 'CARD.RESET_MAKE_PAYMENT';

export interface TriggerMakePayment {
  type: typeof TRIGGER_MAKE_PAYMENT;
  payload: {
    request: MakePaymentRequest;
  }
}

export interface MakePaymentPending {
  type: typeof MAKE_PAYMENT_PENDING;
}

export interface MakePaymentSuccess {
  type: typeof MAKE_PAYMENT_SUCCESS;
  payload: {
    response: MakePaymentResponse;
  }
}

export interface MakePaymentError {
  type: typeof MAKE_PAYMENT_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface ResetMakePayment {
  type: typeof RESET_MAKE_PAYMENT;
}


export type MakePaymentActionType =
  | TriggerMakePayment
  | MakePaymentPending
  | MakePaymentSuccess
  | MakePaymentError
  | ResetMakePayment;


export interface MakePaymentState {
  makePaymentPending: boolean;
  makePaymentError: AxiosError | null;
  makePaymentResponse: MakePaymentResponse | null;
}