import React from "react";
import { ReactComponent as CardIcon } from "./MethodItem/icons/card-icon.svg";
import { ReactComponent as CustomerWalletIcon } from "./MethodItem/icons/customer-wallet-icon.svg";
import { ReactComponent as GoogleIcon } from "./MethodItem/icons/google-pay-mark_800.svg";
import { ReactComponent as QrIcon } from "./MethodItem/icons/qr-icon.svg";
import { ReactComponent as TransferIcon } from "./MethodItem/icons/transfer-icon.svg";
import { ReactComponent as UssdIcon } from "./MethodItem/icons/ussd-icon.svg";
import { ReactComponent as WalletIcon } from "./MethodItem/icons/wallet-icon.svg";
import { ReactComponent as MobileMoneyIcon } from "./MethodItem/icons/mobile-money.svg";
import { PaymentChannelProps } from "../payment/util/types";
import ChannelTag from "./ChannelTag";
import { ReactComponent as StarIcon } from "../../assets/icons/star.svg";


const path = window.location.hostname;

export const paymentChannelsProps: PaymentChannelProps[] = [
  {
    type: "CARD",
    title: "Pay with Card",
    imageIcon: <CardIcon />,
    description: path.includes("zivastores")
      ? "Verve, Visa and Mastercard cards are all accepted "
      : "Verve, Visa, Mastercard, discover and Amex cards are all accepted ",
  },
  {
    type: "TRANSFER",
    title: "Pay with Transfer",
    featuredTag: <ChannelTag text="Top choice" icon={<StarIcon />} />,
    imageIcon: <TransferIcon />,
    description:
      "Make a transfer directly from your bank account to complete a transaction",
  },
  {
    type: "OPAY",
    title: "Pay With Opay",
    imageIcon: <TransferIcon />,
    description: "Complete trasaction with OPay",
  },
  {
    type: "WALLET",
    title: "Pay with Quickteller",
    imageIcon: <WalletIcon />,
    description:
      "Login to your quickteller wallet to get access to your saved cards.",
  },
  {
    type: "QR",
    title: "Pay with QR",
    imageIcon: <QrIcon />,
    description: "Generate a QR code you can scan with your bank app to pay.",
  },
  {
    type: "USSD",
    title: "Pay with USSD",
    imageIcon: <UssdIcon />,
    description:
      "Dial a USSD string from any of 17+ banks to complete a transaction",
  },
  // {
  //   type: "PAY_WITH_APP",
  //   title: "Pay with app",
  //   imageIcon: <PayWithAppIcon />,
  //   description:
  //     "Seamlessly make payments from your bank's mobile banking apps, and also your non-bank payment apps",
  // },
  {
    type: "CUSTOMER_WALLET",
    title: "Pay with Wallet",
    imageIcon: <CustomerWalletIcon />,
    description: "Make secure payments using third-party payment solutions.",
  },
  {
    type: "GOOGLE_PAY",
    title: "Google Pay",
    imageIcon: <GoogleIcon />,
    description:
      "Make secure payments using your instruments saved with Google.",
    plainIcon: true,
  },
  {
    type: "CRD",
    title: "Pay on Credit",
    imageIcon: <CustomerWalletIcon />,
    description: "Buy whatever you need today and pay later",
  },
  {
    type: "UGANDA_MOBILE_MONEY",
    title: "Pay with Mobile Money",
    imageIcon: <MobileMoneyIcon />,
    description: "Use your Mobile Money credentials to make secure payments",
  },
];