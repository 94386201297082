import * as React from "react";
import { useSelector } from "react-redux";
import { cancelPayment } from "../../core/api";

import { asyncDelay } from "../../core/util/asyncUtil";
import { AppState } from "../../store/RootReducer";
import { PaymentUtil } from "../payment/util/PaymentUtil";


export default function useCancelPayment() {
  const { paymentParams, transactionResponse } = useSelector(
    (state: AppState) => ({
      paymentParams: state.payment.paymentProperties.paymentParams,
      transactionResponse: state.payment.paymentStatus.transactionResponse,
    })
  );
  React.useEffect(() => {
    const onBeforeOnload = async (e: BeforeUnloadEvent) => {
      const canCancelPayment = transactionResponse
        ? !PaymentUtil.isTransactionComplete(
            transactionResponse.responseCode
          ) &&
          !PaymentUtil.isTransactionInProgress(
            transactionResponse.responseCode
          ) &&
          !PaymentUtil.isTransactionExpired(transactionResponse.responseCode)
        : true;

      if (canCancelPayment && paymentParams) {
        try {
          await cancelPayment({
            paymentId: paymentParams.paymentId,
            customerCancellationReason: "Return to merchant Site",
          });
          await asyncDelay(500);
        } catch (error) {}
      }
    };

    window.addEventListener("beforeunload", onBeforeOnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeOnload);
    };
  }, [paymentParams, transactionResponse]);
}
