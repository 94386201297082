import { takeLatest, put, call } from "redux-saga/effects";
import TerrapayRepository, {  CreatePaymentResponse } from "../../../domain/repositories/TerrapayRepository";
import { AxiosError } from "axios";

import {
  TriggerCreatePayment,
  TRIGGER_CREATE_PAYMENT
} from "./types";

import {
  createPaymentPending,
  createPaymentError,
  createPaymentSuccess
} from "./actions";


function* handleCreatePaymentRequest(action: TriggerCreatePayment) {
  const { payload } = action;
  const { request } = payload;

  yield put(createPaymentPending());

  let response: CreatePaymentResponse;

  try {
    response = yield TerrapayRepository.createPayment(request);
    if (response){
      yield put(createPaymentSuccess(response));
      return
    }
  } catch (err) {
    yield put(createPaymentError(err as AxiosError));
    return;
  }
}


export function* createPaymentSaga() {
  yield takeLatest(TRIGGER_CREATE_PAYMENT, handleCreatePaymentRequest);
}
