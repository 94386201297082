import styled from "styled-components";

export const SelectBankContainer = styled.div`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }
`;

export const BanksDropdownContainer = styled.div`
    width: 100%;
    height: 43px;
    background-color: white;
    border: solid 1px #e2e2e2;
    border-radius: 3px;
    padding: 0px 0px;
`;

export const BanksDropdownHeaderContainer = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1.5rem;

    .icon {
        font-size: 3rem;
        color: #152a38;
        width: 12px;
    }

    .fin-institution {
        flex-basis: 80%;
    }

    input {
        width: 100%;
        border: none;
        font-size: 1.4rem;
        cursor: pointer;
        color: #152a38;

        
        &:focus, &:active {
            outline: none;
        }
    }
`;

export const BanksDropdownBody = styled.div`
    position: absolute;
    width: 100%;
    background: white;
    z-index: 10;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    max-height: 200px;
    overflow-y: auto;
`;

export const BanksDropdownItem = styled.div`
    font-family: POPPINS,sans-serif;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 1.5rem;
    font-size: 1.6rem;

    .no-data {
        flex-basis: 100%;
        text-align: center;
    }

    .dropdown-item-dot {
        opacity: 0;
        color: #152a38;
        transition: all .2s ease-in-out;
    }
    .dropdown-item-dot.selected {
        opacity: 1;
    }

    &:hover, &:active {
        color: #1aa7e8;
    }
`;

export const StyledSkipButton = styled.span`
    width: 18%;
    color: #1aa7e8;
    font-size: 1.45rem;
    display: inline-block;
    text-align: right;
    cursor: pointer;
`;