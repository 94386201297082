/**
 * Wraps native setTimeout. Returns a promise that resolves
 * after a given amount of time
 *  @param miliseconds Amount of time in miliseconds before Promise resolves
 */
export function asyncDelay(miliseconds: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, miliseconds);
  })
}