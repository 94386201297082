import { AxiosError } from "axios";
import { put, takeLatest, takeLeading } from "redux-saga/effects";
import banksRepository, { Bank } from "../../../domain/repositories/BanksRepository";
import {
    setBanks,
    setBanksError,
    setBanksLoading,
    setShowLoanOffers,
} from "./actions";
import { 
    REQUEST_BANKS,
    REQUEST_SHOW_LOAN_OFFER,
    SelectBankPageActionTypes,
} from "./types";

function* getBanks() {
  yield put(setBanksLoading(true));
  
  try {
    const response: Bank[] = yield banksRepository.getBanks();

    yield put(setBanks(response));
    yield put(setBanksLoading(false));

  } catch (error) {
    yield put(setBanksError(error as AxiosError));
    yield put(setBanksLoading(false));
    return;
  }

}

function* showLoanOffers(action: SelectBankPageActionTypes){
    const { payload } = action;
    yield put(setShowLoanOffers(payload));
}

export function* watchGetBanks() {
  yield takeLatest(REQUEST_BANKS, getBanks);
}

export function* watchShowLoanOffers() {
  yield takeLeading(REQUEST_SHOW_LOAN_OFFER, showLoanOffers);
}
