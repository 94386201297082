import styled from "styled-components";

import { PageViewAnimationStyles } from "../../../../components/Layout";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${PageViewAnimationStyles};
`;
