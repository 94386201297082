export const TRIGGER_SHOW_NOTIFICATION = 'NOTIFICATION_WIDGET.TRIGGER_SHOW_NOTIFICATION';
export const SHOW_NOTIFICATION = 'NOTIFICATION_WIDGET.SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'NOTIFICATION_WIDGET.HIDE_NOTIFICATION';

export type NotificationType = "ERROR" | "WARNING" | "INFO";


export interface NotificationMessage {
  type: NotificationType;
  message?: string;
}

export interface TriggerShowNotificationPayload extends NotificationMessage{
  durationMs?: number;
  hasDuration?: boolean;
}

export interface TriggerShowNotification {
  type: typeof TRIGGER_SHOW_NOTIFICATION;
  payload: TriggerShowNotificationPayload;
}

export interface ShowNotification {
  type: typeof SHOW_NOTIFICATION;
  payload: {
    notificationMessage: NotificationMessage;
  }
}

export interface HideNotification {
  type: typeof HIDE_NOTIFICATION;
}


export type NotificationWidgetActionType =
  TriggerShowNotification
  | ShowNotification
  | HideNotification;


export interface NotificationWidgetState {
  showNotification: boolean;
  notificationMessage: NotificationMessage | null;
}