import { GetIssuersRequest, Issuer } from "../../../domain/repositories/UssdRepository";
import {
  TRIGGER_GET_ISSUERS,
  TriggerGetIssuers,

  GET_ISSUERS_PENDING,
  GetIssuersPending,

  GET_ISSUERS_SUCCESS,
  GetIssuersSuccess,

  GET_ISSUERS_ERROR,
  GetIssuersError,

  RESET_GET_ISSUERS,
  ResetGetIssuers,
} from "./types";

export const triggerGetIssuers = (request: GetIssuersRequest): TriggerGetIssuers => {
  return {
    type: TRIGGER_GET_ISSUERS,
    payload: { request }
  };
};

export const getIssuersPending = (): GetIssuersPending => {
  return {
    type: GET_ISSUERS_PENDING
  };
};

export const getIssuersSuccess = (response: Issuer[]): GetIssuersSuccess => {
  return {
    type: GET_ISSUERS_SUCCESS,
    payload: { response }

  };
};

export const getIssuersError = (): GetIssuersError => {
  return {
    type: GET_ISSUERS_ERROR,
  };
};

export const resetGetIssuers = (): ResetGetIssuers => {
  return {
    type: RESET_GET_ISSUERS,
  };
};