import React from 'react';
import styled, { keyframes } from 'styled-components';
import LoadingIndicator from './LoadingIndicator';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default function LoadingView() {
  return (
    <Container>
      <LoadingIndicator 
      color={'#666'} 
      size={40}
      />
    </Container>
  );
}