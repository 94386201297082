import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  NotificationWidgetState,
  NotificationWidgetActionType,
} from "./types";


const initialState: NotificationWidgetState = {
  showNotification: false,
  notificationMessage: null,
}

export function NotificationWidgetReducer(
  state: NotificationWidgetState = initialState,
  action: NotificationWidgetActionType
): NotificationWidgetState {
  switch (action.type) {

    case SHOW_NOTIFICATION: {
      const { payload } = action;
      const { notificationMessage } = payload;

      return {
        ...state,
        showNotification: true,
        notificationMessage: notificationMessage
      };
    }

    case HIDE_NOTIFICATION: {
      return {
        ...state,
        showNotification: false
      };
    }

    default: {
      return state;
    }
  }
}
