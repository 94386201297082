import { PAY_WITH_APP_TOKEN_AUTH } from '../../../../config/properties';
import httpClient, { HttpClient } from '../../../../core/api/httpClient';

export interface GetTokenRequest {
  grand_type: string;
  scope: string;
}

export interface GetTokenResponse {
  access_token: string;
  token_type: string;
  scope: string;
  jti: string;
}

export interface GetInstitutionsRequest {
  body: {
    amount: number;
    bankCode: string;
    currencyCode: string;
    payableCode: string;
    merchantCode: string;
    createTransaction: boolean;
  };
  headers: {
    token: string;
  };
}

export interface GetInstitutionsResponse {
  reference: number;
  transactionReference: string;
  responseCode: number;
  defaultShortCode: string;
  deepLinks: Record<string, string>;
}

export interface GetTransactionStatusRequest {
  body: { merchantCode: string; transactionReference: string };
  headers: { token: string };
}

export interface GetTransactionStatusResponse {
  responseCode: string;
  responseDescription: string;
}

interface PayWithAppRepository {
  getToken: (request: GetTokenRequest) => Promise<GetTokenResponse>;
  getInstitutions: (request: GetInstitutionsRequest) => Promise<GetInstitutionsResponse>;
  getTransactionStatus: (
    request: GetTransactionStatusRequest
  ) => Promise<GetTransactionStatusResponse>;
}

class PayWithAppRepositoryImpl implements PayWithAppRepository {
  private httpClient: HttpClient;
  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getToken(request: GetTokenRequest): Promise<GetTokenResponse> {
    const { grand_type, scope } = request;
    const response = await this.httpClient.post<GetTokenResponse>(
      `https://passport.k8.isw.la/passport/oauth/token?grant_type=${grand_type}&scope=${scope}`,
      {},
      { auth: { ...PAY_WITH_APP_TOKEN_AUTH } }
    );
    return response.data;
  }

  async getInstitutions(request: GetInstitutionsRequest): Promise<GetInstitutionsResponse> {
    const { body, headers } = request;
    const response = await this.httpClient.post<GetInstitutionsResponse>(
      `https://qa.interswitchng.com/paymentgateway/api/v1/pwa/generate`,
      body,
      { headers: { Authorization: `Bearer ${headers.token}` } }
    );
    return response.data;
  }

  async getTransactionStatus(
    request: GetTransactionStatusRequest
  ): Promise<GetTransactionStatusResponse> {
    const { body, headers } = request;
    const response = await this.httpClient.get<GetTransactionStatusResponse>(
      `https://qa.interswitchng.com/paymentgateway/api/v1/transactions/ussd`,
      {
        params: { ...body },
        headers: { Authorization: `Bearer ${headers.token}` },
      }
    );
    return response.data;
  }
}

const payWithAppRepository = new PayWithAppRepositoryImpl(httpClient);

export default payWithAppRepository;
