import { AxiosError } from "axios";
import { AuthenticateOtpRequest, AuthenticateOtpResponse } from "../../../domain/repositories/CardRepository";

import {
  TRIGGER_AUTHENTICATE_OTP,
  TriggerAuthenticateOtp,

  AUTHENTICATE_OTP_PENDING,
  AuthenticateOtpPending,

  AUTHENTICATE_OTP_SUCCESS,
  AuthenticateOtpSuccess,

  AUTHENTICATE_OTP_ERROR,
  AuthenticateOtpError,

  RESET_AUTHENTICATE_OTP,
  ResetAuthenticateOtp
} from "./types";

export const triggerAuthenticateOtp = (request: AuthenticateOtpRequest): TriggerAuthenticateOtp => {
  return {
    type: TRIGGER_AUTHENTICATE_OTP,
    payload: { request }
  };
};

export const authenticateOtpPending = (): AuthenticateOtpPending => {
  return {
    type: AUTHENTICATE_OTP_PENDING
  };
};

export const authenticateOtpSuccess = (response: AuthenticateOtpResponse): AuthenticateOtpSuccess => {
  return {
    type: AUTHENTICATE_OTP_SUCCESS,
    payload: { response }

  };
};

export const authenticateOtpError = (error: AxiosError): AuthenticateOtpError => {
  return {
    type: AUTHENTICATE_OTP_ERROR,
    payload: { error },
  };
};

export const resetAuthenticateOtp = (): ResetAuthenticateOtp => {
  return {
    type: RESET_AUTHENTICATE_OTP,
  };
};