import { AxiosError } from "axios";
import { GetProvidersResponse, ProviderData } from "../../../domain/repositories/TerrapayRepository";
// import { SigninUserRequest, SigninUserResponse } from "../../../domain/repositories/WalletRepository";

import {
  TRIGGER_GET_PROVIDERS,
  TriggerGetProviders,

  TriggerGetProvidersSearch,
  TRIGGER_GET_PROVIDERS_SEARCH,

  SET_PROVIDER,
  SetProvider,

  GET_PROVIDERS_PENDING,
  GetProvidersPending,

  GET_PROVIDERS_SUCCESS,
  GetProvidersSuccess,

  GET_PROVIDERS_ERROR,
  GetProvidersError,

  RESET_GET_PROVIDERS,
  ResetGetProviders,
} from "./types";

export const triggerGetProviders = (): TriggerGetProviders => {
  return {
    type: TRIGGER_GET_PROVIDERS,
  };
};

export const triggerGetProvidersSearch = (searchParams: string): TriggerGetProvidersSearch => {
  return {
    type: TRIGGER_GET_PROVIDERS_SEARCH,
    payload: searchParams
  };
};

export const setProvider = (provider : ProviderData): SetProvider => {
  return {
    type: SET_PROVIDER,
    payload: provider
  };
};

export const getProvidersPending = (): GetProvidersPending => {
  return {
    type: GET_PROVIDERS_PENDING
  };
};

export const getProvidersSuccess = (response: GetProvidersResponse): GetProvidersSuccess => {
  return {
    type: GET_PROVIDERS_SUCCESS,
    payload: { response }
  };
};

export const getProvidersError = (error: AxiosError): GetProvidersError => {
  return {
    type: GET_PROVIDERS_ERROR,
    payload: { error },
  };
};

export const resetGetProviders = (): ResetGetProviders => {
  return {
    type: RESET_GET_PROVIDERS,
  };
};