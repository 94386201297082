import {
  CompleteTransactionPayload,
  COMPLETE_TRANSACTION,
  CompleteTransaction,

  SET_TRANSACTION_RESPONSE,
  SetTransactionResponse,
  RESET_PAYMENT_STATUS,
  ResetPaymentStatus,
} from "./types";

export const completeTransaction = (transactionResponse: any): CompleteTransaction => {
  return {
    type: COMPLETE_TRANSACTION,
    payload: { transactionResponse }
  };
};

export const setTransactionResponse = (transactionResponse: any): SetTransactionResponse => {
  return {
    type: SET_TRANSACTION_RESPONSE,
    payload: { transactionResponse }
  };
};

export const resetPaymentStatus = (): ResetPaymentStatus => {
  return {
    type: RESET_PAYMENT_STATUS,
  };
};
