import { takeLatest, put } from "redux-saga/effects";

import { TRIGGER_INITIALIZE, TriggerInitialize } from "./types";

import { initializeComplete } from "./actions";

function* handleTriggerInitialize(action: TriggerInitialize) {
  const { payload } = action;
  const { paymentProperties } = payload;
  const { nonCardOptions, displayMode } = paymentProperties;

  yield put(
    initializeComplete({
      displayMode: displayMode || "REDIRECT",
      paymentParams: paymentProperties,
      paymentChannels: nonCardOptions,
    })
  );
}

export function* paymentPropertiesSaga() {
  yield takeLatest(TRIGGER_INITIALIZE, handleTriggerInitialize);
}
