import { combineReducers } from "redux";
import CreditOtpReducer from "./otpPage/reducer";
import OffersPageReducer from "./offers/reducer";
import PhoneNumberSigninReducer from "./phoneNumberSignin/reducer";
import VerveWalletSigninReducer from "./verveWalletSignin/reducer";
import CreditCardDetailsReducer from "./cardDetails/reducer";
import SelectBanksPageReducer from "./selectBankPage/reducer";

export const CreditReducer = combineReducers({
  offers: OffersPageReducer,
  creditOtp: CreditOtpReducer,
  PhoneNumberSignin: PhoneNumberSigninReducer,
  VerveWalletSignin: VerveWalletSigninReducer,
  creditCardDetails: CreditCardDetailsReducer,
  selectBanksPage: SelectBanksPageReducer
});
