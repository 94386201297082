import { AxiosError } from "axios";
import { Bank } from "../../../domain/repositories/BanksRepository";
import * as Types from "./types";

export const setBanks = (payload: Bank[]): Types.setBanks => {
  return { type: Types.SET_BANKS, payload };
};

export const setBanksLoading = (payload: boolean): Types.setBanksLoading => {
  return { type: Types.SET_BANKS_LOADING, payload };
};

export const requestBanks = () => {
  return { type: Types.REQUEST_BANKS };
};

export const setBanksError = (error: AxiosError): Types.setBanksError => {
    return {
      type: Types.SET_BANKS_ERROR,
      payload: { error }
    };
};

export const setShowLoanOffers = (payload: boolean): Types.setBanksLoading => {
    return { type: Types.SET_SHOW_LOAN_OFFER, payload };
}

export const requestShowLoanOffers = (payload: boolean): Types.setBanksLoading => {
    return { type: Types.REQUEST_SHOW_LOAN_OFFER, payload };
}

export const setBankDetails = (payload: Types.BankDetailsProps): Types.setBankDetails => {
    return { type: Types.SET_BANK_DETAILS, payload };
}

export const requestBankDetails = (payload: Types.BankDetailsProps): Types.setBankDetails => {
    return { type: Types.REQUEST_BANK_DETAILS, payload };
}