import styled, { keyframes } from "styled-components";
import { PageViewAnimationStyles } from "../../../../components/Layout";

interface OfferListProps {
  selected?: boolean;
  hasPartPayment?: boolean;
}

export const Container = styled.div`
  width: 100%;
  ${PageViewAnimationStyles}
`;

const placeholderShimmer = keyframes`
  0% {
      background-position: -468px 0;
  }
  100% {
      background-position: 468px 0;
  }
`;

export const Shimmer = styled.div`
  width: 100%;
  height: 87px;
  margin-bottom: 20px;
  background: #f6f7f9;
  font-weight: 500;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: 800px 104px;
  position: relative;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${placeholderShimmer};
  -webkit-animation-timing-function: linear;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
`;

export const ErrorContainer = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  background-color: rgba(26, 167, 232, 0.04);
`;

export const ErrorIcon = styled.div`
  margin: auto;
  width: fit-content;
`;

const OfferText = styled.div`
  text-align: center;
  font-size: 12px;
`;

export const ErrorTitle = styled(OfferText)`
  margin-top: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: capitalize;
`;

export const ErrorSubTitle = styled(OfferText)`
  color: rgba(124, 130, 135, 1);
`;

export const OffersContainer = styled(Container)`
  max-height: 210px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 20px;
`;

export const OffersList = styled.ul`
  list-style-type: none;
  padding: 0px 10px 0px 0px;
  margin: 0;
`;

export const Offers = styled.li<OfferListProps>`
  margin: 10px 0px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in-out;

  ${({ selected }) => {
    return `
    background-color: ${selected ? "rgba(26, 167, 232, 0.04)" : "transparent"};
    border: 1px solid ${selected ? "rgba(26, 167, 232, 1)" : "rgb(220 220 220 / 60%)"};
    `;
  }}
  
  background-color: ${({ hasPartPayment }) => (hasPartPayment ? "#f3f4f5" : "transparent")};

  :hover {
    border: 1px solid rgba(26, 167, 232, 1);
    background-color: rgba(26, 167, 232, 0.04);
  }

  :first-child {
    margin-top: 0px;
  }

  :last-child {
    margin-bottom: 0px;
  }
`;

export const ProviderName = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const FinInstitutionBox = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #181b24;
  margin: 1.5rem;
`;

export const OfferDetails = styled.ul`
  padding: 0;
  margin: 1.5rem;
  display: flex;
  list-style-type: none;
  justify-content: space-between;
  flex-direction: column;

  .tooltip {
    .info-icon {
      marin-left: 10px;
      display: inline-block;
    }
    .tooltiptext {
      width: 190px;
      font-size: 12px;
      margin-left: -105px;
    }
  }
`;

export const OfferDetailsItem = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const OfferDetailsLabel = styled.p`
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.7;
  color: #181b24;
  font-weight: 500;
  margin-right: 3px;
`;

export const OfferDetailsValue = styled.p`
  font-size: 12px;
  color: #181b24;
  font-weight: 600;
`;

export const PinFieldContainer = styled.div`
  margin-bottom: 15px;
`;


export const Divider = styled.div`
  height: 0;
  width: 100%;
  border-bottom: solid 1px rgba(21, 42, 56, 0.1);
  margin: 1.3rem 0;
`;

export const StyledMoreOptionsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #252b33;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1rem 0;
  border-radius: 4px;
  border: solid 1px rgba(18, 18, 18, 0.11);
  padding: 0.45rem;
  cursor: pointer;

  .icon-up {
    font-size: 2.5rem;
    transform: rotate(-90deg);
    display: block;
  }

  .icon-down {
    font-size: 2.5rem;
    transform: rotate(90deg);
    display: block;
  }
`;