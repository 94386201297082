import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  ResendControl,
  FormContainer,
  OtpInputField,
  ResendMessage,
  OtpInputFieldContainer,
  ResendControlContainer
} from "./style";
import {
  RowContainer,
  FieldErrorText,
  FormControlLabel,
  LabelFieldContainer
} from "../../../../components/Layout";
import {
  StoreStateProps,
  StoreDispatchProps,
  GenerateCreditOtpCallback
} from "../../store/otpPage/types";
import { AxiosError } from "axios";
import { connect } from "react-redux";
import { Button } from "../../../../components/Button";
import { AppState } from "../../../../../store/RootReducer";
import { asyncDelay } from "../../../../../core/util/asyncUtil";
import { FieldValidationResult } from "../../../../../core/types";
import { OTP_REQUEST_INTERVAL_SEC } from "../../../../../config/properties";
import {
  triggerValidateCreditOtp,
  triggerGenerateCreditOtp,
  resetCreditOtpPage
} from "../../store/otpPage/actions";
import { ValidateOtpRequest, GenerateOtpRequest } from "../../../domain/repositories/OtpRepository";
import { OnSigninSuccessfulCallback } from "../../store/verveWalletSignin/types";
import { triggerShowNotification } from "../../../../components/NotificationWidget/store/actions";
import { TriggerShowNotificationPayload } from "../../../../components/NotificationWidget/store/types";
import { setCreditPageTitle, setCreditPageSubTitle } from "../../store/offers/actions";

export interface OtpProps {
  loading: boolean;
  error: AxiosError | null;
  triggerRequest: (otp?: string | null, onSuccessful?: () => void) => void;
}

interface OwnProps {
  buttonText: string;
  phoneNumber: string;
  generateOtpOptions?: OtpProps;
  validateOtpOptions?: OtpProps;
  backButtonCallback: () => void;
  onValidateOtpSuccessful?: OnSigninSuccessfulCallback;
  selectedOfferIndex?: number | null;
}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

const OTP_LENGTH = 6;
const OTP_INCOMPLETE_MESSAGE = "OTP is incomplete";
const OTP_EMPTY_MESSAGE = "Kindly enter otp sent to your phone number";

export function getIsOtpValid(otp: string): FieldValidationResult {
  if (!otp) return { isValid: false, message: OTP_EMPTY_MESSAGE };
  if (otp.length < OTP_LENGTH) return { isValid: false, message: OTP_INCOMPLETE_MESSAGE };
  return { isValid: true };
}

function OtpPage(props: Props) {
  const {
    reset,
    paymentId,
    buttonText,
    generateOtp,
    validateOtp,
    phoneNumber,
    setPageTitle,
    setPageSubTitle,
    backButtonCallback,
    generateOtpOptions,
    validateOtpOptions,
    onValidateOtpSuccessful,
    triggerShowNotification,
    bankProvider,
    offers,
    selectedOfferIndex,
    accountNumber,
    providerCode,
  } = props;
  const timerStopped = useRef(false);
  const isPageMounted = useRef(true);
  const showResendMessage = useRef(false);

  const [otpValue, setOtpValue] = useState("");
  const [timeToExpSec, setTimeToExpSec] = useState(OTP_REQUEST_INTERVAL_SEC);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [triggerBankOtpReset, setTriggerBankOtpReset] = useState<number>(0);
  const { isValid: isOtpValid, message: cardOtpErrorMessage } = getIsOtpValid(otpValue);

  const generateOtpError = generateOtpOptions ? generateOtpOptions.error : props.generateOtpError;
  const validateOtpError = validateOtpOptions ? validateOtpOptions.error : props.validateOtpError;
  const generateOtpLoading = generateOtpOptions && !bankProvider
    ? generateOtpOptions.loading
    : props.generateOtpLoading;
  const validateOtpLoading = validateOtpOptions
    ? validateOtpOptions.loading
    : props.validateOtpLoading;

  const offer = offers[selectedOfferIndex as number];
  

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter") validateOtpButtonHandler();
  };

  const validateOtpButtonHandler = () => {
    if (!isOtpValid) {
      setShowFormErrors(true);
      return;
    }

    const request = {
      paymentId,
      otp: otpValue,
      customerId: phoneNumber
    };

    if (isPageMounted.current) {
      if (validateOtpOptions) {
        validateOtpOptions.triggerRequest(otpValue);
      } else {
        validateOtp(request, onValidateOtpSuccessful);
      }
    }


    isPageMounted.current = false;
  };

  const generateOtpSuccessful = useCallback(() => {
    showResendMessage.current = true;
    setTimeToExpSec(OTP_REQUEST_INTERVAL_SEC);
    setTimeout(startTimer, 2000);
  }, []);

  const resendOtpHandler = () => {
    const request: GenerateOtpRequest = {
      paymentId,
      customerId: phoneNumber
    };

    if (generateOtpOptions) {
      generateOtpOptions.triggerRequest(null, generateOtpSuccessful);
      return;
    }

    generateOtp(request, generateOtpSuccessful);
  };

  const startTimer = async () => {
    timerStopped.current = false;
    showResendMessage.current = false;

    while (true) {
      if (!isPageMounted.current || timerStopped.current) break;

      setTimeToExpSec((seconds) => {
        const newSec = seconds - 1;

        if (newSec <= 0) {
          timerStopped.current = true;
          return 0;
        }
        return newSec;
      });
      await asyncDelay(1000);
    }
  };

  const backButtonHandler = () => {
    reset();
    backButtonCallback();
  };

  const handleGenerateOtpError = useCallback(() => {
    if (generateOtpError) {
      isPageMounted.current = true;

      if (!generateOtpError.response || generateOtpError.response.status !== 200) {
        triggerShowNotification({
          type: "ERROR",
          message: "Something went wrong. Please try again."
        });
        return;
      }

      const responseCode = generateOtpError.response.data.responseCode;
      if (responseCode === "LS1" || responseCode === "LS3") {
        triggerShowNotification({
          type: "ERROR",
          message: "Invalid phone number provided. Please try again."
        });
      }
    }
  }, [generateOtpError, triggerShowNotification]);

  const handleValidateOtpError = useCallback(() => {
    if (validateOtpError) {
      isPageMounted.current = true;

      if (!validateOtpError.response || validateOtpError.response?.status !== 200) {
        triggerShowNotification({
          type: "ERROR",
          message: "Something went wrong. Please try again."
        });
        return;
      }

      const { responseCode, responseDescription } = validateOtpError.response.data;
      if (responseCode === "T1") {
        let { message } = validateOtpError.response.data.error;
        if (message === "INVALID_TOKEN_SUPPLIED") {
          message = "OTP supplied is invalid. Please try again";
        }
        triggerShowNotification({ type: "ERROR", message });
        return;
      }

      triggerShowNotification({
        type: "ERROR",
        message: responseDescription
          ? responseDescription
          : "Something went wrong. Please try again."
      });
    }
  }, [triggerShowNotification, validateOtpError]);

  useEffect(() => {
    setPageTitle("Enter OTP");
    setPageSubTitle(`Enter the code sent to ${phoneNumber ? phoneNumber : "your phone number"}`);
    startTimer();
  }, [phoneNumber, setPageSubTitle, setPageTitle]);

  useEffect(() => handleGenerateOtpError(), [handleGenerateOtpError]);
  useEffect(() => handleValidateOtpError(), [handleValidateOtpError]);

  useEffect(() => {
    if(bankProvider){
      const request: GenerateOtpRequest = {
        offerId: offer.offerId,
        providerCode,
        accountNumber,
      };
      generateOtp(request, generateOtpSuccessful, bankProvider);
    }
  
  }, [accountNumber, bankProvider, generateOtp, generateOtpSuccessful, offer?.offerId, providerCode, triggerBankOtpReset]);

  return (
    <FormContainer>
      <LabelFieldContainer>
        <FormControlLabel>OTP</FormControlLabel>

        <OtpInputFieldContainer>
          <OtpInputField
            placeholder="000000"
            inputMode="numeric"
            value={otpValue}
            onKeyUp={onKeyUpHandler}
            onChange={(evt) => setOtpValue(evt.target.value)}
            error={showFormErrors && !isOtpValid}
          />

          {showFormErrors && !isOtpValid && <FieldErrorText>{cardOtpErrorMessage}</FieldErrorText>}

          <ResendControlContainer>
            {!showResendMessage.current && timeToExpSec > 0 && (
              <ResendMessage>
                { 
                  !generateOtpLoading &&
                  <>resend in <span>{timeToExpSec}</span></> 
                }
              </ResendMessage>
            )}

            {!generateOtpLoading && !showResendMessage.current && timeToExpSec === 0 && (
                bankProvider ?
                <ResendControl 
                  onClick={() => setTriggerBankOtpReset(Math.random())}
                >
                  resend code
                </ResendControl> :
                <ResendControl onClick={resendOtpHandler}>resend code</ResendControl> 
            )}

            {generateOtpLoading && <ResendMessage>sending otp...</ResendMessage>}

            {!generateOtpLoading && showResendMessage.current && (
              <ResendMessage>
                {!generateOtpError && "code sent"}
                {generateOtpError && "error"}
              </ResendMessage>
            )}
          </ResendControlContainer>
        </OtpInputFieldContainer>
      </LabelFieldContainer>

      <RowContainer>
        <Button
          text="Back"
          type="OUTLINE"
          color="SECONDARY"
          onClick={backButtonHandler}
          containerStyle={{ width: "30%", marginRight: "5px" }}
        />

        <Button
          text={buttonText}
          color="PRIMARY"
          loading={validateOtpLoading}
          onClick={validateOtpButtonHandler}
          containerStyle={{ width: "70%", marginLeft: "5px" }}
        />
      </RowContainer>
    </FormContainer>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => {
  return {
    generateOtpError: state.credit.creditOtp.generateOtpError,
    validateOtpError: state.credit.creditOtp.validateOtpError,
    generateOtpLoading: state.credit.creditOtp.generateOtpLoading,
    validateOtpLoading: state.credit.creditOtp.validateOtpLoading,
    paymentId: state.payment.paymentProperties.paymentParams?.paymentId,
    bankProvider: state.credit.offers.bankProvider,
    offers: state.credit.offers.offers,
    providerCode: state.credit.selectBanksPage.bankDetails.finInstitution,
    accountNumber: state.credit.selectBanksPage.bankDetails.accountNumber,
  };
};

const mapDispatchToProps = (dispatch: (action: any) => void): StoreDispatchProps => {
  return {
    reset: () => dispatch(resetCreditOtpPage()),
    setPageTitle: (title: string) => dispatch(setCreditPageTitle(title)),
    setPageSubTitle: (subtitle: string) => dispatch(setCreditPageSubTitle(subtitle)),

    generateOtp: (request: GenerateOtpRequest, onSuccessful: GenerateCreditOtpCallback, isBank: boolean | undefined) => {
      dispatch(triggerGenerateCreditOtp(request, onSuccessful, isBank));
    },

    validateOtp: (request: ValidateOtpRequest, onSuccessful?: OnSigninSuccessfulCallback) => {
      dispatch(triggerValidateCreditOtp(request, onSuccessful));
    },

    triggerShowNotification: (payload: TriggerShowNotificationPayload) => {
      dispatch(triggerShowNotification(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpPage);
