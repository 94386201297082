import * as React from "react";
import styled from "styled-components";
import { getFormattedPrice } from "../../../../../../util/currencyUtil";
import { Button } from "../../../../../components/Button";
import {
  FormControlLabel,
  FormFieldStyles,
  LabelFieldContainer,
} from "../../../../../components/Layout";

import { PaymentParams } from "../../../store/paymentProperties/types";
import Checkbox from "./Checkbox";
import Tooltip from "./Tooltip";

interface Props {
  paymentParams: PaymentParams;
  onDone: () => void;
}
export default function EscrowPopup(props: Props) {
  const { paymentParams, onDone } = props;

  const [termsAccepted, setTermsAccepted] = React.useState(false);

  return (
    <PopupContainer>
      <div className="info">
        <h4>Interswitch Escrow</h4>
        <p>
          Kindly see details of the transaction below and fill the form to
          proceed to payment
        </p>
        <BreakdownSection>
          <div className="row">
            <p className="row-name">
              <span>Delivery Period</span>
              <Tooltip
                className="tooltip"
                text="This is the duration (in days) where that it takes for you (as the buyer) to trigger a refund for any of the reasons listed under the Escrow terms and conditons"
              />
            </p>
            <p className="row-value">{paymentParams.refundDuration}</p>
          </div>
          <div className="row">
            <p className="row-name">
              <span>Cancellation Period</span>
              <Tooltip
                className="tooltip"
                text="This is the duration (in days) where that it takes for you (as the buyer) to trigger a cancelation for any of the reasons listed under the Escrow terms and conditons"
              />
            </p>
            <p className="row-value">{paymentParams.cancellationPeriod}</p>
          </div>
          <div className="row">
            <p className="row-name">
              <span>Escrow Fees</span>
            </p>
            <p className="row-value">
              {getFormattedPrice(
                Number(paymentParams.escrowFee || "0") * 100,
                paymentParams.currencyCode
              )}
            </p>
          </div>
        </BreakdownSection>
      </div>
      <FormSection>
        <LabelFieldContainer className="input-group">
          <FormControlLabel>Email</FormControlLabel>
          <InputField
            name="customerEmail"
            placeholder="email.interswitch.com"
            value={paymentParams.customerEmail}
            readOnly
            autoComplete='off'
          />
        </LabelFieldContainer>
        <LabelFieldContainer className="input-group">
          <FormControlLabel>Phone Number</FormControlLabel>
          <InputField
            inputMode="tel"
            name="mobileNumber"
            placeholder="0123456789"
            value={paymentParams.customerMobileNo}
            readOnly
            autoComplete='off'
          />
        </LabelFieldContainer>
        <Checkbox
          containerClassname="checkbox"
          label="I understand that the escrow fee is non-refundable and I agree to Interswitch Escrow’s terms of use and conditions"
          checked={termsAccepted}
          onChange={e => setTermsAccepted(e.target.checked)}
        />
        <Button
          color="PRIMARY"
          text="Continue to payment"
          containerStyle={{ width: "100%" }}
          disabled={!termsAccepted}
          onClick={onDone}
        />
      </FormSection>
    </PopupContainer>
  );
}

const PopupContainer = styled.div`
  &,
  * {
    box-sizing: border-box;
  }
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  z-index: 2;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  .info {
    padding: 20px;

    & > p {
      font-size: 1rem;
      color: #181b24;
    }

    & > h4 {
      margin: 12px 0 5px;
      font-size: 1.6rem;
      color: #023b58;
    }
  }

  .tooltip {
    .tooltiptext {
      width: 292px;
      margin-left: -115px;
      font-size: 1.2rem;
      &::after {
        left: 39%;
      }
    }
  }
`;

const BreakdownSection = styled.div`
  margin-top: 37px;

  p {
    font-size: 1.3rem;
  }
  .row {
    height: 43px;
    border-bottom: solid 1px rgba(21, 42, 56, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      border: none;
    }

    > .row-name {
      display: flex;
      align-items: flex-start;
      > span {
        margin-right: 10px;
      }
    }
    > .row-value {
      font-weight: bolder;
    }
  }
`;

const FormSection = styled.div`
  border-top: solid 1px rgba(21, 42, 56, 0.07);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 7px 9px -3px rgba(70, 70, 70, 0.06);
  background-color: #fafafa;
  padding: 20px;

  .checkbox {
    margin: 18px 0 32px;
    .text {
      margin-left: 10px;
    }
  }

  input:read-only {
    border: solid 0.8px #cdcdcd;
    background-color: #ebebeb;
    color: #33343e;
  }
`;
const InputField = styled.input`
  ${FormFieldStyles};
  font-size: 1.3rem;
`;
