import { AxiosError } from "axios";
import { CountryData, GetCountryListResponse, GetProvidersResponse, ProviderData } from "../../../domain/repositories/TerrapayRepository";

export const TRIGGER_GET_COUNTRY_LIST = 'WALLET.TRIGGER_GET_COUNTRY_LIST';
export const GET_COUNTRY_LIST_PENDING = 'WALLET.GET_COUNTRY_LIST_PENDING';
export const GET_COUNTRY_LIST_SUCCESS = 'WALLET.GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_ERROR = 'WALLET.GET_COUNTRY_LIST_ERROR';

export const RESET_GET_COUNTRY_LIST = 'WALLET.RESET_GET_COUNTRY_LIST';


export interface TriggerGetCountryList {
  type: typeof TRIGGER_GET_COUNTRY_LIST;
}

export interface GetCountryListPending {
  type: typeof GET_COUNTRY_LIST_PENDING;
}

export interface GetCountryListSuccess {
  type: typeof GET_COUNTRY_LIST_SUCCESS;
  payload: {
    response: GetCountryListResponse;
  }
}

export interface GetCountryListError {
  type: typeof GET_COUNTRY_LIST_ERROR;
  payload: {
    error: AxiosError;
  }
}

export interface ResetGetCountryList {
  type: typeof RESET_GET_COUNTRY_LIST;
}

export type GetCountryListActionType =
  TriggerGetCountryList
  | GetCountryListPending
  | GetCountryListSuccess
  | GetCountryListError
  | ResetGetCountryList;

export interface GetCountryListState {
  loading: boolean;
  loadingError: AxiosError | null;
  countries: CountryData[];
}