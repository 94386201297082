import styled from "styled-components";

import { PageView } from "../../../components/Layout";

export const Container = styled(PageView)`
  .header {
    h4 {
      font-size: 1.7rem;
      margin-bottom: 10px;
      color: #152a38;
    }
    p {
      font-size: 1.2rem;
      color: #181b24;
    }
  }

  #button-container {
    margin: 44px 0px 18px;
    width: 100%;
  }

  .loader-box {
    box-sizing: border-box;
    width: 100%;
    margin-top: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
`;
