import { WalletPaymentMethod } from "../../../../wallet/domain/repositories/WalletRepository";

export const SET_CACHED_CARD_DETAILS = 'CARD.SET_CACHED_CARD_DETAILS';
export const RESET_CACHED_CARD_DETAILS = 'CARD.RESET_CACHED_CARD_DETAILS';

export interface CachedCardParams {
  cardNumber: string;
  cardExpiry: string;
  cardCvv: string;
  pin?: string;

  paymentMethod?: WalletPaymentMethod;

  googlePayToken?: string;
}

export interface SetCachedCardDetails {
  type: typeof SET_CACHED_CARD_DETAILS;
  payload: {
    cardParams: CachedCardParams;
  }
}

export interface ResetCachedCardDetails {
  type: typeof RESET_CACHED_CARD_DETAILS;
}


export type CachedCardDetailsActionType = SetCachedCardDetails | ResetCachedCardDetails;

export interface CachedCardDetailsState {
  cachedCardParams: CachedCardParams | null;
}