import { SERVICE_BASE_URL } from "../../../../config/properties";
import httpClient, { HttpClient } from "../../../../core/api/httpClient";

export interface GetIssuersRequest {
  countryCode: string;
}

export interface GenerateUssdRequest {
  amount: number;
  bankCode: string;
  currencyCode: string;
  merchantCode: string;
  merchantTransactionReference: string;
  payableCode: string;
  surcharge: number;
}

export interface GenerateUssdResponse {
  bankShortCode: string;
  defaultShortCode: string;
  reference: string;
  responseCode: string;
  transactionReference: string;
}

export interface Issuer {
  cbnCode: string;
  code: string;
  name: string;
}


export interface GetTransactionStatusRequest {
  merchantCode: string;
  transactionReference: string;
}

export interface GetTransactionStatusResponse {
  responseCode: string;
  responseDescription: string;
}

interface UssdRepository {
  getIssuers: (request: GetIssuersRequest) => Promise<Issuer[]>;
  generateUssd: (request: GenerateUssdRequest) => Promise<GenerateUssdResponse>;
  getTransactionStatus: (request: GetTransactionStatusRequest) => Promise<GetTransactionStatusResponse>;
}

class UssdRepositoryImpl implements UssdRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }


  async getIssuers(request: GetIssuersRequest) {
    const { countryCode } = request;

    const response = await this.httpClient.get<Issuer[]>(
      `${SERVICE_BASE_URL}/api/v1/ussd/issuers/${countryCode}`
    );

    return response.data;
  }

  async generateUssd(request: GenerateUssdRequest): Promise<GenerateUssdResponse> {
    const response = await this.httpClient.post<GenerateUssdResponse>(
      `${SERVICE_BASE_URL}/api/v1/ussd/generate`,
      request
    );

    return response.data;
  }

  async getTransactionStatus(request: GetTransactionStatusRequest) {
    const response = await this.httpClient.get<GetTransactionStatusResponse>(
      `${SERVICE_BASE_URL}/api/v1/ussd/status`,
      { params: request }
    );

    return response.data;
  }
}

const ussdRepository = new UssdRepositoryImpl(httpClient);

export default ussdRepository;
