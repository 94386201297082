import {
  GET_INSTITUTIONS_ERROR,
  GET_INSTITUTIONS_PENDING,
  GET_INSTITUTIONS_SUCCESS,
  RESET_GET_INSTITUTIONS,
  SET_TIME_LEFT,
  GetInstitutionsState,
  GetInstitutionsActionType,
} from "./types";

const initialState: GetInstitutionsState = {
  paymentData: null,
  getInstitutionsError: false,
  getInstitutionsPending: false,
  timeLeft: undefined,
};

export function GetInstitutionsReducer(
  state: GetInstitutionsState = initialState,
  action: GetInstitutionsActionType
): GetInstitutionsState {
  switch (action.type) {
    case GET_INSTITUTIONS_PENDING: {
      return {
        ...state,
        getInstitutionsPending: true,
        getInstitutionsError: false,
      };
    }
    case GET_INSTITUTIONS_ERROR: {
      return {
        ...state,
        getInstitutionsPending: false,
        getInstitutionsError: true,
      };
    }
    case GET_INSTITUTIONS_SUCCESS: {
      const { payload } = action;
      const { response } = payload;
      return {
        getInstitutionsPending: false,
        getInstitutionsError: false,
        paymentData: response,
        timeLeft: 15 * 60 * 1000,
      };
    }
    case SET_TIME_LEFT: {
      const { payload } = action;
      const { time } = payload;
      return {
        ...state,
        timeLeft: time,
      };
    }
    case RESET_GET_INSTITUTIONS: {
      return initialState;
    }

    default:
      return state;
  }
}
