import { AxiosError } from "axios"
import { takeLatest, put } from "redux-saga/effects";
import cardRepository, { AuthenticateOtpResponse } from "../../../domain/repositories/CardRepository";

import {
  TRIGGER_AUTHENTICATE_OTP,
  TriggerAuthenticateOtp,

  RESET_AUTHENTICATE_OTP,
} from "./types";

import {
  authenticateOtpPending,
  authenticateOtpSuccess,
  authenticateOtpError,
} from "./actions";
import { createCancellableSaga } from "../../../../../store/cancellableSaga";


function* handleTriggerAuthenticateOtp(action: TriggerAuthenticateOtp) {
  const { payload } = action;
  const { request } = payload;

  yield put(authenticateOtpPending());

  let response: AuthenticateOtpResponse;

  try {
    response = yield cardRepository.authenticateOtp(request);
  } catch (err) {
    yield put(authenticateOtpError(err as AxiosError));
    return;
  }

  yield put(authenticateOtpSuccess(response));
}


function* watchAuthenticateOtp() {
  yield takeLatest(TRIGGER_AUTHENTICATE_OTP, handleTriggerAuthenticateOtp);
}

export const authenticateOtpSaga = createCancellableSaga(
  watchAuthenticateOtp, RESET_AUTHENTICATE_OTP
);