import {
  AUTHENTICATE_OTP_PENDING,
  AUTHENTICATE_OTP_SUCCESS,
  AUTHENTICATE_OTP_ERROR,
  RESET_AUTHENTICATE_OTP,
  AuthenticateOtpState,
  AuthenticateOtpActionType
} from "./types";

const initialState: AuthenticateOtpState = {
  authenticateOtpPending: false,
  authenticateOtpError: null,
  authenticateOtpResponse: null

}

export function AuthenticateOtpReducer(
  state: AuthenticateOtpState = initialState,
  action: AuthenticateOtpActionType
): AuthenticateOtpState {
  switch (action.type) {

    case AUTHENTICATE_OTP_PENDING: {
      return {
        ...state,
        authenticateOtpPending: true
      };
    }

    case AUTHENTICATE_OTP_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      return {
        ...state,
        authenticateOtpPending: false,
        authenticateOtpError: null,
        authenticateOtpResponse: response
      };
    }

    case AUTHENTICATE_OTP_ERROR: {
      const { payload } = action;
      const { error } = payload;

      return {
        ...state,
        authenticateOtpPending: false,
        authenticateOtpError: error,
      };
    }

    case RESET_AUTHENTICATE_OTP: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
