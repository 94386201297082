import {
  SET_PAYMENT_CHANNEL,
  SetPaymentChannel,
} from "./types";

export const setPaymentChannel = (channelType: any): SetPaymentChannel => {
  return {
    type: SET_PAYMENT_CHANNEL,
    payload: channelType
  };
};