import { takeLatest, put } from "redux-saga/effects";

import payWithAppRepository, {
  GetTokenResponse,
} from "../../../domain/repositories/PayWithAppRepository";

import { TriggerGetToken, TRIGGER_GET_TOKEN, RESET_GET_TOKEN } from "./types";

import { getTokenError, getTokenPending, getTokenSuccess } from "./actions";

import { createCancellableSaga } from "../../../../../store/cancellableSaga";

function* handleTriggerGetToken(action: TriggerGetToken) {
  const { payload } = action;
  const { request } = payload;

  yield put(getTokenPending());

  let response: GetTokenResponse;

  try {
    response = yield payWithAppRepository.getToken(request);
  } catch (e) {
    yield put(getTokenError());
    return;
  }

  yield put(getTokenSuccess(response));
}

function* watchGetToken() {
  yield takeLatest(TRIGGER_GET_TOKEN, handleTriggerGetToken);
}

export const getTokenSaga = createCancellableSaga(
  watchGetToken,
  RESET_GET_TOKEN
);
