import {
  GET_BIN_CONFIGURATION_PENDING,
  GET_BIN_CONFIGURATION_SUCCESS,
  GET_BIN_CONFIGURATION_ERROR,
  RESET_GET_BIN_CONFIGURATION,
  GetBinConfigurationState,
  GetBinConfigurationActionType,
} from "./types";

const initialState: GetBinConfigurationState = {
  fetchingBinConfiguration: false,
  fetchingBinConfigurationError: false,
  binConfigurationResponse: null,
}

export function GetBinConfigurationReducer(
  state: GetBinConfigurationState = initialState,
  action: GetBinConfigurationActionType
): GetBinConfigurationState {
  switch (action.type) {

    case GET_BIN_CONFIGURATION_PENDING: {
      return {
        ...state,
        fetchingBinConfiguration: true
      };
    }

    case GET_BIN_CONFIGURATION_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      return {
        ...state,
        fetchingBinConfiguration: false,
        fetchingBinConfigurationError: false,
        binConfigurationResponse: response
      };
    }

    case GET_BIN_CONFIGURATION_ERROR: {
      return {
        ...state,
        fetchingBinConfiguration: false,
        fetchingBinConfigurationError: true,
        errorMessage: action.payload
      };
    }

    case RESET_GET_BIN_CONFIGURATION: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
