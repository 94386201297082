import { SERVICE_BASE_URL } from "../../../../config/properties";
import httpClient, { HttpClient } from "../../../../core/api/httpClient";

export interface InitializeRequest {
  merchantCode: string;
  payableCode: string;
  transactionReference: string;
  currencyCode: string;
  paymentId: number;
}

export interface InitializeResponse {
  redirectUrl: string;
  transactionReference: string;
  payCode: string;
  responseCode: string;
  expiry: string;
}

export interface GetStatusRequest {
  transactionReference: string;
  merchantCode: string;
}

export interface GetStatusResponse {
  orderNo: string;
  transactionReference: string;
  message: string;
  responseCode: string;
  status: string;
}

interface PalmpayRepository {
  initialize: (request: InitializeRequest) => Promise<InitializeResponse>;
  getStatus: (request: GetStatusRequest) => Promise<GetStatusResponse>;
}

class PalmpayRepositoryImpl implements PalmpayRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async initialize(request: InitializeRequest): Promise<InitializeResponse> {
    const response = await this.httpClient.post<InitializeResponse>(
      `${SERVICE_BASE_URL}/api/v1/palmpay/initialize`,
      request
    );

    return response.data;
  }

  async getStatus(request: GetStatusRequest) {
    const response = await this.httpClient.post<GetStatusResponse>(
      `${SERVICE_BASE_URL}/api/v1/palmpay/status`,
      request,
      { timeout: 10000 }
    );

    return response.data;
  }
}

const palmpayRepository = new PalmpayRepositoryImpl(httpClient);

export default palmpayRepository;
