import { takeLatest, put } from "redux-saga/effects";

import payWithAppRepository, {
  GetInstitutionsResponse,
} from "../../../domain/repositories/PayWithAppRepository";

import {
  TRIGGER_GET_INSTITUTIONS,
  TriggerGetInsititutions,
  RESET_GET_INSTITUTIONS,
} from "./types";

import {
  getInstitutionsError,
  getInstitutionsPending,
  getInstitutionsSuccess,
} from "./actions";

import { createCancellableSaga } from "../../../../../store/cancellableSaga";

function* handleTriggerGetInstitutions(action: TriggerGetInsititutions) {
  const { payload } = action;
  const { request } = payload;

  yield put(getInstitutionsPending());

  let response: GetInstitutionsResponse;

  try {
    response = yield payWithAppRepository.getInstitutions(request);
  } catch (e) {
    yield put(getInstitutionsError());
    return;
  }

  yield put(getInstitutionsSuccess(response));
}

function* watchGetInstitutions() {
  yield takeLatest(TRIGGER_GET_INSTITUTIONS, handleTriggerGetInstitutions);
}

export const getInstitutionsSaga = createCancellableSaga(
  watchGetInstitutions,
  RESET_GET_INSTITUTIONS
);
