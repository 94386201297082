import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled, { keyframes } from 'styled-components';
import { asyncDelay } from '../../../core/util/asyncUtil';

import { ReactComponent as CopyIcon } from './copy-icon.svg';

const ContentContainer = styled.div`
  position: relative;
`;

const BUBBLE_DURATION_MS = 1000;


const CopyMessageBubble = styled.div`
  position: absolute;
  left: calc(50 % - 20px);
  top: -20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  background: #f2f2f2;
  border-radius: 2px;

  p {
    font-size: 1rem;
  }
`;

const IconContainer = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }
`;

interface Props {
  text: string;
}

export default function CopyWidget(props: Props) {
  const { text } = props;

  const [showBubble, setShowBubble] = useState(false);

  const onCopyHandler = async (text: string, result: boolean) => {
    if (!result) return;

    setShowBubble(true);

    await asyncDelay(BUBBLE_DURATION_MS);

    setShowBubble(false);
  };

  return (
    <CopyToClipboard text={text} onCopy={onCopyHandler}>
      <ContentContainer>
        {showBubble && (
          <CopyMessageBubble>
            <p>Copied!</p>
          </CopyMessageBubble>
        )}

        <IconContainer>
          <CopyIcon />
        </IconContainer>
      </ContentContainer>
    </CopyToClipboard>
  );
}