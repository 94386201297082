import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import {
  PAYMENT__PAY_WITH_APP,
  PAYMENT_METHODS__ROOT,
} from "../../../../Routes";
import { StoreStateProps, TParams, DispatchProps } from "./types";

import { AppState } from "../../../../../store/RootReducer";
import { setTimeLeft } from "../../store/getInstitutions/actions";

import {
  ViewContainer,
  TimerWrapper,
  SixDigitCodeContainer,
  ListContainer,
} from "../../components/Containers";
import { Spacer } from "../../components/Spacer";
import { Button } from "../../../../components/Button";
import ErrorView from "../../../../components/ErrorView";
import { ListOfBanks } from "../../components/ListOfBanks";
import { FiveDigitCode } from "../../components/FiveDigitCode";

import { ReactComponent as BackIcon } from "../../icons/backArrow.svg";
import useCountdownTimer from "../../../../../hooks/useCountdownTimer";

const FiveDigitCodeView = ({
  fiveDigitCode,
  timeout,
  banksList,
  setTimeLeft,
}: StoreStateProps & DispatchProps) => {
  const [isListVisible, setIsListVisible] = useState(false);
  const [isTimeOut, setIsTimeOut] = useState(false);
  const history = useHistory();
  const { device } = useParams<TParams>();

  const listOfBanks = Object.keys(banksList || {});

  const handleTimeOut = () => {
    setIsTimeOut(true);
  };

  const { time } = useCountdownTimer({
    timeout: timeout || 0,
    onCompleted: handleTimeOut,
  });
  const { minutes, seconds } = time;

  const timerText = `00h ${minutes > 9 ? minutes : "0" + minutes}m ${
    seconds > 9 ? seconds : "0" + seconds
  }s`;

  const handleNavigateBack = () => {
    setTimeLeft((minutes * 60 + seconds) * 1000);
    history.push(`${PAYMENT__PAY_WITH_APP}/deeplink/mobile/`);
  };

  const handleNavigateToTransactionStatus = () => {
    history.push(`${PAYMENT__PAY_WITH_APP}/transaction-status`);
  };

  const handleRestart = () => {
    history.push(PAYMENT_METHODS__ROOT);
  };

  const handleToggleList = () => {
    setIsListVisible((i) => !i);
  };

  return (
    <ViewContainer>
      {isListVisible && (
        <ListOfBanks data={listOfBanks} handleClose={handleToggleList} />
      )}
      {isTimeOut && (
        <ErrorView
          message="The code is not longer valid"
          actionText="Go back"
          action={handleRestart}
        />
      )}

      {!isTimeOut && (
        <SixDigitCodeContainer>
          <p>You have been assigned a code to complete this payment.</p>

          <p className="boldText">To use the code:</p>
          <FiveDigitCode code={fiveDigitCode?.toString() || ""} />
          <ListContainer>
            <li>
              Log in to your Mobile Banking Application{" "}
              <p onClick={handleToggleList}>(Eligible banks)</p>
            </li>
            <li>Select the “Pay with App” option</li>
            <li>Input the code to complete your payment</li>
            <li>Come back to this screen to confirm your payment</li>
          </ListContainer>
          <TimerWrapper top={26} bottom={26}>
            <p>The code is valid for</p>
            <p className="bold">{timerText}</p>
          </TimerWrapper>
          <Button
            color="PRIMARY"
            text="Confirm payment"
            onClick={handleNavigateToTransactionStatus}
            icon={<></>}
          />
          <Spacer height={16} />
          {device === "mobile" && (
            <Button
              type="OUTLINE"
              text="Go back"
              color="PRIMARY"
              icon={<BackIcon />}
              onClick={handleNavigateBack}
            />
          )}
        </SixDigitCodeContainer>
      )}
    </ViewContainer>
  );
};

const mapStateToProps = (state: AppState): StoreStateProps => ({
  fiveDigitCode: state.payWithApp.intitutions.paymentData?.reference,
  banksList: state.payWithApp.intitutions.paymentData?.deepLinks,
  timeout: state.payWithApp.intitutions.timeLeft,
});

const mapDispatchToProps = (
  dispatch: (action: any) => void
): DispatchProps => ({
  setTimeLeft(time: number) {
    dispatch(setTimeLeft(time));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FiveDigitCodeView);
