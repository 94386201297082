import {
  NotificationMessage,

  TRIGGER_SHOW_NOTIFICATION,
  TriggerShowNotification,
  TriggerShowNotificationPayload,

  SHOW_NOTIFICATION,
  ShowNotification,

  HIDE_NOTIFICATION,
  HideNotification,
} from "./types";

export const triggerShowNotification = (payload: TriggerShowNotificationPayload): TriggerShowNotification => {
  return {
    type: TRIGGER_SHOW_NOTIFICATION, payload
  };
};

export const showNotification = (notificationMessage: NotificationMessage): ShowNotification => {
  return {
    type: SHOW_NOTIFICATION,
    payload: { notificationMessage }
  };
};

export const hideNotification = (): HideNotification => {
  return {
    type: HIDE_NOTIFICATION
  };
};


