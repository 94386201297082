export const TRIGGER_GET_TRANSACTION_STATUS = 'USSD.TRIGGER_GET_TRANSACTION_STATUS';
export const GET_TRANSACTION_STATUS_PENDING = 'USSD.GET_TRANSACTION_STATUS_PENDING';
export const GET_TRANSACTION_STATUS_SUCCESS = 'USSD.GET_TRANSACTION_STATUS_SUCCESS';
export const GET_TRANSACTION_STATUS_ERROR = 'USSD.GET_TRANSACTION_STATUS_ERROR';

export const RESET_GET_TRANSACTION_STATUS = 'USSD.RESET_GET_TRANSACTION_STATUS';

export interface GetTransactionStatusPending {
  type: typeof GET_TRANSACTION_STATUS_PENDING;
}

export interface GetTransactionStatusSuccess {
  type: typeof GET_TRANSACTION_STATUS_SUCCESS;
  payload: {
    response: any;
  }
}

export interface GetTransactionStatusError {
  type: typeof GET_TRANSACTION_STATUS_ERROR;
}

export interface ResetGetTransactionStatus {
  type: typeof RESET_GET_TRANSACTION_STATUS;
}


export type GetTransactionStatusActionType =
  | GetTransactionStatusPending
  | GetTransactionStatusSuccess
  | GetTransactionStatusError
  | ResetGetTransactionStatus;


export interface GetTransactionStatusState {
  getTransactionStatusPending: boolean;
  getTransactionStatusError: boolean;
  getTransactionStatusResponse: any | null;
}