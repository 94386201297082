import { AxiosError } from "axios";
import { MakePaymentRequest, MakePaymentResponse } from "../../../domain/repositories/CardRepository";
import {
  TRIGGER_MAKE_PAYMENT,
  TriggerMakePayment,

  MAKE_PAYMENT_PENDING,
  MakePaymentPending,

  MAKE_PAYMENT_SUCCESS,
  MakePaymentSuccess,

  MAKE_PAYMENT_ERROR,
  MakePaymentError,

  RESET_MAKE_PAYMENT,
  ResetMakePayment,
} from "./types";

export const triggerMakePayment = (request: MakePaymentRequest): TriggerMakePayment => {
  return {
    type: TRIGGER_MAKE_PAYMENT,
    payload: { request }
  };
};

export const makePaymentPending = (): MakePaymentPending => {
  return {
    type: MAKE_PAYMENT_PENDING
  };
};

export const makePaymentSuccess = (response: MakePaymentResponse): MakePaymentSuccess => {
  return {
    type: MAKE_PAYMENT_SUCCESS,
    payload: { response }

  };
};

export const makePaymentError = (error: AxiosError): MakePaymentError => {
  return {
    type: MAKE_PAYMENT_ERROR,
    payload: { error }
  };
};

export const resetMakePayment = (): ResetMakePayment => {
  return {
    type: RESET_MAKE_PAYMENT,
  };
};