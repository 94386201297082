import { useCallback } from "react";
import {
  PAYMENT__CARD_ROOT,
  PAYMENT__WALLET_LOGIN,
  PAYMENT__TRANSFER_ROOT,
  PAYMENT__QR_ROOT,
  PAYMENT__USSD_ROOT,
  PAYMENT__CUSTOMER_WALLET_ROOT,
  PAYMENT__CREDIT,
  PAYMENT__GOOGLE_PAY,
  PAYMENT__PAY_WITH_APP,
  PAYMENT__UGANDA_MOBILE_MONEY,
  PAYMENT__OPAY_ROOT,
  PAYMENT__DRC_MOBILE_MONEY_ROOT
} from "../features/Routes";
import { useHistory } from "react-router-dom";
import { PaymentChannelType } from "../features/payment/presentation/store/paymentProperties/types";
import { setPaymentChannel } from "../features/payment/presentation/store/currentPaymentChannel/actions";
import { useDispatch } from "react-redux";
import { resetPaymentStatus } from "../features/payment/presentation/store/paymentStatus/actions";
import { resetMakePayment } from "../features/card/presentation/store/makePayment/actions";

const useOnMethodSelect = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onMethodSelect = useCallback(
    (channelType: PaymentChannelType, isSinglePaymentMode: boolean = false) => {
      // Reset previous selections
      dispatch(resetMakePayment());
      dispatch(resetPaymentStatus());

      dispatch(setPaymentChannel(channelType));

      if (channelType === "PAY_WITH_APP") {
        history.push({
          pathname: PAYMENT__PAY_WITH_APP,
          state: {
            isSinglePaymentMode,
          },
        });
      }

      if (channelType === "CARD") {
        history.push({
          pathname: PAYMENT__CARD_ROOT,
          state: {
            isSinglePaymentMode,
          },
        });
      }

      if (channelType === "WALLET") {
        history.push({
          pathname: PAYMENT__WALLET_LOGIN,
          state: { isSinglePaymentMode },
        });
      }

      if (channelType === "TRANSFER") {
        history.push({
          pathname: PAYMENT__TRANSFER_ROOT,
          state: { isSinglePaymentMode },
        });
      }

      if (channelType === "OPAY") {
        history.push({
          pathname: PAYMENT__OPAY_ROOT,
          state: { isSinglePaymentMode },
        });
      }

      if (channelType === "QR") {
        history.push({
          pathname: PAYMENT__QR_ROOT,
          state: {
            isSinglePaymentMode,
          },
        });
      }

      if (channelType === "USSD") {
        history.push({
          pathname: PAYMENT__USSD_ROOT,
          state: {
            isSinglePaymentMode,
          },
        });
      }

      if (channelType === "CUSTOMER_WALLET") {
        history.push({
          pathname: PAYMENT__CUSTOMER_WALLET_ROOT,
          state: {
            isSinglePaymentMode,
          },
        });
      }

      if (channelType === "CRD") {
        history.push({
          pathname: PAYMENT__CREDIT,
          state: {
            isSinglePaymentMode,
          },
        });
      }

      if (channelType === "GOOGLE_PAY") {
        history.push({
          pathname: PAYMENT__GOOGLE_PAY,
          state: {
            isSinglePaymentMode,
          },
        });
      }
      if (channelType === "UGANDA_MOBILE_MONEY") {
        history.push({
          pathname: PAYMENT__UGANDA_MOBILE_MONEY,
          state: {
            isSinglePaymentMode,
          },
        });
      }
      if (channelType === "DRC_MOBILE_MONEY") {
        history.push({
          pathname: PAYMENT__DRC_MOBILE_MONEY_ROOT,
          state: {
            isSinglePaymentMode
          }
        });
      }
    },
    [dispatch, history]
  );

  return onMethodSelect;
};

export default useOnMethodSelect;
