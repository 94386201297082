import { CardTypeCode } from "../../../../config/cardTypesConfig";
import { SERVICE_BASE_URL } from "../../../../config/properties";
import httpClient, { HttpClient } from "../../../../core/api/httpClient";

export interface SigninUserRequest {
  merchantCode: string;
  payableCode: string;
  paymentId: number;
  username: string;
  password: string;
}

export interface AuthenticateTokenRequest {
  merchantCode: string;
  payableCode: string;
  accessToken: string;
  accessTokenType: string;
}

export interface WalletUserData {
  active: boolean;
  admin: boolean;
  apps: null
  domainCode: null
  domainId: null
  domainName: null
  domains: null
  email: string;
  firstName: string;
  id: null
  lastName: string;
  mobileNo: string;
}

export interface WalletPaymentMethod {
  accountNumber: string;
  cardHash: string;
  cardIdentifier: string;
  cardTypeCode: CardTypeCode;
  cardTypeGatewayConfiguration: {
    cardTypeCode: CardTypeCode;
    cybersourceEnabled: boolean;
    enabled: boolean;
    id: number;
    loyaltyEnabled: boolean;
    loyaltyRedeemEnabled: boolean;
    supportsAccountType: boolean;
    supportsCardinalAuthentication: boolean;
    supportsCvv2: boolean;
    supportsCybersource20: boolean;
    supportsExpiryDate: boolean;
    supportsFingerPrintAuthorization: boolean;
    supportsOtp: boolean;
    supportsPin: boolean;
    supportsVisaAcceleratedConnectionPlatform: boolean;
  }
  cardTypeName: "VERVE"
  cookieKey: string;
  enableFingerPrint: boolean;
  expiryMonth?: string;
  expiryYear?: string;
  issuer: {
    cbnCode: string;
    code: string;
    countryCode: string;
    domainTypeId: number;
    id: number;
    name: string;
  }
  loyaltyRedemptionAllowed: boolean;
  maskToken: boolean;
  maskedPan: string;
  name: string;
  status: "limited"
  walletInstrumentIdentifier: string;
}

export interface SigninUserResponse {
  user?: WalletUserData;
  paymentMethods: WalletPaymentMethod[];
}

interface WalletRepository {
  signin: (request: SigninUserRequest) => Promise<SigninUserResponse>;
  authenticateToken: (request: AuthenticateTokenRequest) => Promise<SigninUserResponse>;
}

class WalletRepositoryImpl implements WalletRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async signin(request: SigninUserRequest) {
    const {
      merchantCode,
      payableCode,
      paymentId,
      username,
      password
    } = request;

    const response = await this.httpClient.post<SigninUserResponse>(
      `${SERVICE_BASE_URL}/api/v1/wallets?merchantCode=${merchantCode}&payableCode=${payableCode}&paymentId=${paymentId}`,
      {username, password}
    );

    return response.data;
  }

  async authenticateToken(request: AuthenticateTokenRequest) {
    const response = await this.httpClient.get<SigninUserResponse>(
      `${SERVICE_BASE_URL}/api/v1/wallets/authenticationToken`,
      { params: request }
    );

    return response.data;
  }
}

const walletRepository = new WalletRepositoryImpl(httpClient);

export default walletRepository;


