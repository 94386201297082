import { GetTransactionStatusRequest, GetTransactionStatusResponse } from "../../../domain/repositories/TransferRepository";

export const TRIGGER_GET_TRANSACTION_STATUS = 'TRANSFER.TRIGGER_GET_TRANSACTION_STATUS';
export const GET_TRANSACTION_STATUS_PENDING = 'TRANSFER.GET_TRANSACTION_STATUS_PENDING';
export const GET_TRANSACTION_STATUS_SUCCESS = 'TRANSFER.GET_TRANSACTION_STATUS_SUCCESS';
export const GET_TRANSACTION_STATUS_ERROR = 'TRANSFER.GET_TRANSACTION_STATUS_ERROR';

export const RESET_GET_TRANSACTION_STATUS = 'TRANSFER.RESET_GET_TRANSACTION_STATUS';

export interface TriggerGetTransactionStatus {
  type: typeof TRIGGER_GET_TRANSACTION_STATUS;
  payload: {
    request: GetTransactionStatusRequest;
  }
}

export interface GetTransactionStatusPending {
  type: typeof GET_TRANSACTION_STATUS_PENDING;
}

export interface GetTransactionStatusSuccess {
  type: typeof GET_TRANSACTION_STATUS_SUCCESS;
  payload: {
    response: GetTransactionStatusResponse;
  }
}

export interface GetTransactionStatusError {
  type: typeof GET_TRANSACTION_STATUS_ERROR;
  payload?: any;
}

export interface ResetGetTransactionStatus {
  type: typeof RESET_GET_TRANSACTION_STATUS;
}


export type GetTransactionStatusActionType =
  | TriggerGetTransactionStatus
  | GetTransactionStatusPending
  | GetTransactionStatusSuccess
  | GetTransactionStatusError
  | ResetGetTransactionStatus;


export interface GetTransactionStatusState {
  getTransactionStatusPending: boolean;
  getTransactionStatusError: boolean;
  errorMessage?: any;
  getTransactionStatusResponse: GetTransactionStatusResponse | null;
}