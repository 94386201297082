import { SERVICE_BASE_URL } from "../../../../config/properties";
import httpClient from "../../../../core/api/httpClient";

export interface GetBillersResponse {
  responseCode: string;
  responseMessage: string;
  response: {
    biller: string;
    billerId: string;
  }[];
}

export interface PaymentNotificationPayload {
  merchantCode: string;
  payableCode: string;
  billerId: string;
  customerId: string;
  transactionReference: string;
  paymentId: number;
}

export interface PaymentNotificationResponse {
  responseCode: string;
  responseMessage: string;
  response: {
    amount: number;
    surcharge: number;
    transactionReference: string;
  };
}

export interface GetStatusPayload {
  merchantCode: string;
  transactionReference: string;
}

export function getBillers() {
  return httpClient.post<GetBillersResponse>(
    `${SERVICE_BASE_URL}/api/v2/ug-mobile-money/biller-details`
  );
}

export function getPaymentItems(billerId: string) {
  return httpClient.get<GetBillersResponse>(
    `${SERVICE_BASE_URL}/api/v1/ug-mobile-money/payment-items`,
    {
      params: { billerId },
    }
  );
}

export function postPayment(payload: PaymentNotificationPayload) {
  return httpClient.post<PaymentNotificationResponse>(
    `${SERVICE_BASE_URL}/api/v2/ug-mobile-money/payment-notification`,
    payload
  );
}

export function getStatus(payload: GetStatusPayload) {
  return httpClient.post<PaymentNotificationResponse>(
    `${SERVICE_BASE_URL}/api/v1/ug-mobile-money/status`,
    payload
  );
}
