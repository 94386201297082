import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import QRCode from "qrcode.react";

import { PAYMENT_METHODS__ROOT } from "../../../../Routes";

import BackControl from "../../../../components/BackControl";
import {
  PageView,
  PageTitle,
  PageSubTitle,
} from "../../../../components/Layout";

import { AppState } from "../../../../../store/RootReducer";
import { PaymentParams } from "../../../../payment/presentation/store/paymentProperties/types";
import { connect, useDispatch } from "react-redux";
import qrRepository, {
  GenerateQrRequest,
  GenerateQrResponse,
} from "../../../domain/repositories/QrRepository";
import { triggerShowNotification } from "../../../../components/NotificationWidget/store/actions";
import { triggerGenerateQr } from "../../store/generateQr/actions";
import { TriggerShowNotificationPayload } from "../../../../components/NotificationWidget/store/types";
import LoadingView from "../../../../components/LoadingView";
import ErrorView from "../../../../components/ErrorView";
import { asyncDelay } from "../../../../../core/util/asyncUtil";
import { completeTransaction } from "../../../../payment/presentation/store/paymentStatus/actions";
import { PaymentUtil } from "../../../../payment/util/PaymentUtil";
import ConfirmStatusMessage from "../../../../components/ConfirmStatusMessage";
import {
  GET_TRANSACTION_STATUS_PENDING,
  GET_TRANSACTION_STATUS_SUCCESS,
} from "../../store/getTransactionStatus/types";

const Container = styled(PageView)``;

const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
`;

interface StoreStateProps {
  paymentParams: PaymentParams;

  generateQrPending: boolean;
  generateQrError: boolean;
  generateQrResponse: GenerateQrResponse | null;
}

interface StoreDispatchProps {
  showNotification: (payload: TriggerShowNotificationPayload) => void;
  completeTransaction: (transactionResponse: any) => void;
  generateQr: (request: GenerateQrRequest) => void;
}

interface OwnProps {}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function QrRootPage(props: Props) {
  const {
    paymentParams,

    generateQrPending,
    generateQrError,
    generateQrResponse,

    showNotification,
    completeTransaction,
    generateQr,
  } = props;

  const history = useHistory();

  const isPageMounted = useRef(true);

  const navigateToPaymentMethods = () => {
    history.push(PAYMENT_METHODS__ROOT);
  };

  const dispatch = useDispatch();

  const pollTransactionStatus = async () => {
    dispatch({ type: GET_TRANSACTION_STATUS_PENDING });

    while (true) {
      if (!isPageMounted.current) return;

      let response;

      try {
        response = await qrRepository.getTransactionStatus({
          merchantCode: paymentParams.merchantCode,
          transactionReference: paymentParams.merchantTransactionReference,
        });
      } catch (err) {
        await asyncDelay(2000);
        continue;
      }

      const { responseCode } = response;

      if (PaymentUtil.isTransactionComplete(responseCode)) {
        dispatch({
          type: GET_TRANSACTION_STATUS_SUCCESS,
          payload: { response },
        });

        completeTransaction(response);
        break;
      }

      await asyncDelay(2000);
    }
  };

  const callGenerateQr = () => {
    const {
      merchantCode,
      payableCode,
      currencyCode,
      amount,
      surcharge,
      merchantTransactionReference,
    } = paymentParams;

    generateQr({
      merchantCode,
      payableCode,
      currencyCode,
      amount,
      surcharge,
      merchantTransactionReference,
    });
  };

  useEffect(() => {
    return () => {
      isPageMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (generateQrPending || !!generateQrResponse) return;
    callGenerateQr();
  }, []);

  useEffect(() => {
    if (!generateQrResponse) return;
    pollTransactionStatus();
  }, [generateQrResponse]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_TRANSACTION_STATUS_SUCCESS,
        payload: { response: null },
      });
    };
  }, [dispatch]);

  return (
    <Container>
      <BackControl
        text="Change payment method"
        onClick={navigateToPaymentMethods}
      />

      {generateQrPending && <LoadingView />}

      {!generateQrPending && generateQrError && (
        <ErrorView action={callGenerateQr} />
      )}

      {!generateQrPending && !!generateQrResponse && (
        <>
          <PageTitle>Pay with QR</PageTitle>
          <PageSubTitle>
            Scan the QR code below with your bank app to pay.
          </PageSubTitle>

          <QrCodeContainer>
            <QRCode value={generateQrResponse.rawQRData} size={200} />
          </QrCodeContainer>

          <ConfirmStatusMessage />
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,

  generateQrPending: state.qr.generateQr.generateQrPending,
  generateQrError: state.qr.generateQr.generateQrError,
  generateQrResponse: state.qr.generateQr.generateQrResponse,
});

const mapDispatchToProps = (
  dispatch: (action: any) => void
): StoreDispatchProps => ({
  showNotification(payload: TriggerShowNotificationPayload) {
    dispatch(triggerShowNotification(payload));
  },
  completeTransaction(transactionResponse: any) {
    dispatch(completeTransaction(transactionResponse));
  },
  generateQr(request: GenerateQrRequest) {
    dispatch(triggerGenerateQr(request));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QrRootPage);
