import {
  GENERATE_QR_PENDING,
  GENERATE_QR_SUCCESS,
  GENERATE_QR_ERROR,
  RESET_GENERATE_QR,
  GenerateQrState,
  GenerateQrActionType,
} from "./types";

const initialState: GenerateQrState = {
  generateQrPending: false,
  generateQrError: false,
  generateQrResponse: null

}

export function GenerateQrReducer(
  state: GenerateQrState = initialState,
  action: GenerateQrActionType
): GenerateQrState {
  switch (action.type) {

    case GENERATE_QR_PENDING: {
      return {
        ...state,
        generateQrPending: true
      };
    }

    case GENERATE_QR_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      return {
        ...state,
        generateQrPending: false,
        generateQrError: false,
        generateQrResponse: response
      };
    }

    case GENERATE_QR_ERROR: {
      return {
        ...state,
        generateQrPending: false,
        generateQrError: true
      };
    }

    case RESET_GENERATE_QR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
