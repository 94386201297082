import * as Types from "./types";
import { AxiosError } from "axios";
import {
  AcceptOfferRequest,
  GetOffersRequest,
  Offer
} from "../../../domain/repositories/OffersRepository";

export const resetOffersPage = (): Types.ResetOffersPage => {
  return { type: Types.RESET_OFFERS_PAGE };
};

export const signinSuccessful = (
  response: Types.SigninSuccessfulResponse
): Types.SigninSuccessful => {
  return {
    type: Types.SIGNIN_SUCCESSFUL,
    payload: response
  };
};

export const triggerGetOffers = (
  request: GetOffersRequest,
  onSuccessful?: () => void
): Types.TriggerGetOffers => {
  return {
    type: Types.TRIGGER_GET_OFFERS,
    payload: { request, onSuccessful }
  };
};

export const getOffersPending = (): Types.GetOffersPending => {
  return { type: Types.GET_OFFERS_PENDING };
};

export const getOffersSuccessful = (offers: Offer[]): Types.GetOffersSuccessful => {
  return {
    type: Types.GET_OFFERS_SUCCESSFUL,
    payload: { offers }
  };
};

export const getOffersError = (error: AxiosError): Types.GetOffersError => {
  return {
    type: Types.GET_OFFERS_ERROR,
    payload: { error }
  };
};

export const triggerAcceptOffer = (
  request: AcceptOfferRequest,
  onSuccessful?: () => void
): Types.TriggerAcceptOffer => {
  return {
    type: Types.TRIGGER_ACCEPT_OFFER,
    payload: { request, onSuccessful }
  };
};

export const acceptOfferPending = (): Types.AcceptOfferPending => {
  return { type: Types.ACCEPT_OFFER_PENDING };
};

export const acceptOfferSuccessful = (transactionResponse: any): Types.AcceptOfferSuccessful => {
  return {
    type: Types.ACCEPT_OFFER_SUCCESSFUL,
    payload: { transactionResponse }
  };
};

export const acceptOfferError = (error: AxiosError): Types.AcceptOfferError => {
  return {
    type: Types.ACCEPT_OFFER_ERROR,
    payload: { error }
  };
};

export const setSelectedOffer = (selectedOffer: Offer): Types.SetSelectedOffer => {
  return {
    type: Types.SET_SELECTED_OFFER,
    payload: {selectedOffer}
  }
}

export const setCreditPageTitle = (title: string): Types.SetCreditPageTitle => {
  return {
    type: Types.SET_CREDIT_PAGE_TITLE,
    payload: { title }
  };
};

export const setCreditPageSubTitle = (subtitle: string): Types.SetCreditPageSubTitle => {
  return {
    type: Types.SET_CREDIT_PAGE_SUBTITLE,
    payload: { subtitle }
  };
};

export const setBankProvider = (bankProvider: boolean): Types.SetBankProvider => {
  return {
    type: Types.SET_BANK_PROVIDER,
    payload: bankProvider
  };
};

export const displayScreen = (screenType: Types.OffersScreenType): Types.DisplayScreen => {
  return {
    type: Types.DISPLAY_SCREEN,
    payload: { screenType }
  };
};

export const updateAcceptOfferRequest = (
  request: AcceptOfferRequest | null
): Types.UpdateAcceptOfferRequest => {
  return {
    type: Types.UPDATE_ACCEPT_OFFER_REQUEST,
    payload: { request }
  };
};
