import { takeLatest, put, delay } from "redux-saga/effects";

import {
  TRIGGER_SHOW_NOTIFICATION,
  TriggerShowNotification
} from "./types";

import {
  showNotification,
  hideNotification
} from "./actions";

const DEFAULT_NOTIFICATION_DURATION_MS = 5000;

function* handleTriggerShowNotification(action: TriggerShowNotification) {
  const { payload } = action;
  const { type, message, durationMs, hasDuration=true } = payload;

  yield put(showNotification({type, message}));

  if (hasDuration) {
    yield delay(durationMs || DEFAULT_NOTIFICATION_DURATION_MS);
    yield put(hideNotification());
  }
}


export function* notificationWidgetSaga() {
  yield takeLatest(TRIGGER_SHOW_NOTIFICATION, handleTriggerShowNotification);
}
