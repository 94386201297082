import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../../../../store/RootReducer";
import { getFormattedPrice } from "../../../../../../../../util/currencyUtil";
import {
  getIsCardPanValid,
  getIsCardExpiryValid,
  getIsCardCvvValid
} from "../../../../../../../card/utils/fieldValidators";
import { Button } from "../../../../../../../components/Button";
import CardInputField from "../../../../../../../components/CardInputField";
import CvvInputField from "../../../../../../../components/CvvInputField";
import ExpiryInputField from "../../../../../../../components/ExpiryInputField";
import {
  LabelFieldContainer,
  FieldLabel,
  FieldErrorText,
  FormFieldsRow,
  RowContainer
} from "../../../../../../../components/Layout";
import { PaymentParams } from "../../../../../../../payment/presentation/store/paymentProperties/types";
import { Form } from "../../../../../../../wallet/presentation/pages/WalletLoginPage/style";
import { Offer } from "../../../../../../domain/repositories/OffersRepository";
import { resetCreditCardDetails } from "../../../../../store/cardDetails/actions";
import {
  setCreditPageTitle,
  setCreditPageSubTitle,
  displayScreen
} from "../../../../../store/offers/actions";
import { OffersScreenType } from "../../../../../store/offers/types";
import { TriggerShowNotificationPayload } from "../../../../../../../components/NotificationWidget/store/types";
import { hideNotification, triggerShowNotification } from "../../../../../../../components/NotificationWidget/store/actions";

export interface CardDetails {
  expValue: string;
  cvvValue: string;
  cardPanValue: string;
}

interface OwnProps {
  offer: Offer;
  setShowCardPin: (value: boolean) => void;
  setCardDetails: (details: any) => void;
}

export interface CardRootStoreStateProps {
  paymentParams: PaymentParams | null;
  selectedOffer: Offer;
}

export interface CardRootStoreDispatchProps {
  reset: () => void;
  setPageTitle: (title: string) => void;
  setPageSubtitle: (subtitle: string) => void;
  displayScreen: (screenType: OffersScreenType) => void;
  showNotification: (payload: TriggerShowNotificationPayload) => void;
  hideNotification: () => void;
}

type Props = CardRootStoreStateProps & CardRootStoreDispatchProps & OwnProps;

function CardRootPage(props: Props) {
  const {
    setPageSubtitle,
    setPageTitle,
    offer,
    reset,
    paymentParams,
    setShowCardPin,
    setCardDetails,
    displayScreen,
    showNotification,
    selectedOffer,
    hideNotification,
  } = props;
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [cardPanValue, setCardPanValue] = useState("");
  const [expValue, setExpValue] = useState("");
  const [cvvValue, setCvvValue] = useState("");

  const { isValid: isCardNumberValid, message: cardNumberErrorMessage } =
    getIsCardPanValid(cardPanValue);

  const { isValid: isCardExpValid, message: cardExpErrorMessage } = getIsCardExpiryValid(
    expValue,
    new Date()
  );

  const { isValid: isCardCvvValid, message: cardCvvErrorMessage } = getIsCardCvvValid(cvvValue);

  const formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.nativeEvent.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    handleSubmit();
  };

  const handleSubmit = () => {
    setShowFormErrors(true);

    if (!isCardNumberValid || !isCardExpValid || !isCardCvvValid) {
      return;
    }

    setCardDetails({ cardPanValue, expValue, cvvValue });
    setShowCardPin(true);
  };

  const backButtonHandler = () => {
    reset();
    displayScreen(OffersScreenType.offer);
    hideNotification();
  };

  useEffect(() => {
    if (offer && paymentParams) {
      setPageTitle(
        `${offer.provider.name} - ${getFormattedPrice(
          offer.amountOffered,
          paymentParams.currencyCode
        )}`
      );
    }
    setPageSubtitle("Enter card details for loan repayment");
  }, [offer, paymentParams, setPageSubtitle, setPageTitle]);

  useEffect(() => {
    if(selectedOffer.initialPayment > 0){
      showNotification({
        type: "INFO",
        message: `Expect a partial debit of ${getFormattedPrice(selectedOffer.initialPayment, (paymentParams as PaymentParams).currencyCode)},
         followed by the remaining balance of ${getFormattedPrice(selectedOffer.amountOffered, (paymentParams as PaymentParams).currencyCode)}.`,
        hasDuration: false,
      });
    }
  }, []);

  return (
    <Form onSubmit={formSubmitHandler}>
      <LabelFieldContainer>
        <FieldLabel htmlFor="card-number">Card Number</FieldLabel>

        <CardInputField
          id="card-number"
          initialValue={cardPanValue}
          onValueChange={(value) => setCardPanValue(value)}
          error={showFormErrors && !isCardNumberValid}
        />

        {showFormErrors && !isCardNumberValid && (
          <FieldErrorText>{cardNumberErrorMessage}</FieldErrorText>
        )}
      </LabelFieldContainer>

      <FormFieldsRow>
        <LabelFieldContainer>
          <FieldLabel htmlFor="card-exp">Expiry</FieldLabel>

          <ExpiryInputField
            id="card-exp"
            initialValue={expValue}
            onValueChange={(value) => setExpValue(value)}
            error={showFormErrors && !isCardExpValid}
          />

          {showFormErrors && !isCardExpValid && (
            <FieldErrorText>{cardExpErrorMessage}</FieldErrorText>
          )}
        </LabelFieldContainer>

        <LabelFieldContainer>
          <FieldLabel htmlFor="card-cvv">CVV</FieldLabel>

          <CvvInputField
            id="card-cvv"
            value={cvvValue}
            onValueChange={(value) => setCvvValue(value)}
            error={showFormErrors && !isCardCvvValid}
          />

          {showFormErrors && !isCardCvvValid && (
            <FieldErrorText>{cardCvvErrorMessage}</FieldErrorText>
          )}
        </LabelFieldContainer>
      </FormFieldsRow>

      <RowContainer>
        <Button
          text="Back"
          type="OUTLINE"
          color="SECONDARY"
          onClick={backButtonHandler}
          containerStyle={{ width: "30%", marginRight: "5px" }}
        />

        <Button
          text="Continue"
          color="PRIMARY"
          loading={false}
          onClick={handleSubmit}
          containerStyle={{ width: "70%", marginLeft: "5px" }}
        />
      </RowContainer>
    </Form>
  );
}

const mapStateToProps = (state: AppState): CardRootStoreStateProps => {
  return {
    paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,
    selectedOffer: state.credit.offers.selectedOffer as Offer,
  };
};

const mapDispatchToProps = (dispatch: (action: any) => void): CardRootStoreDispatchProps => {
  return {
    reset: () => dispatch(resetCreditCardDetails()),
    setPageTitle: (title: string) => dispatch(setCreditPageTitle(title)),
    setPageSubtitle: (subtitle: string) => dispatch(setCreditPageSubTitle(subtitle)),
    displayScreen: (screenType: OffersScreenType) => dispatch(displayScreen(screenType)),
    showNotification: (payload: TriggerShowNotificationPayload) => {
      dispatch(triggerShowNotification(payload));
    },
    hideNotification() {
      dispatch(hideNotification());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardRootPage);
