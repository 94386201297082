import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../store/RootReducer";
import { completeTransaction } from "../../../../payment/presentation/store/paymentStatus/actions";
import { AcceptOfferRequest } from "../../../domain/repositories/OffersRepository";
import { GetTokenWithOtpRequest } from "../../../domain/repositories/OtpRepository";
import { triggerGetTokenWithOtp } from "../../store/cardDetails/actions";
import { displayScreen, triggerAcceptOffer } from "../../store/offers/actions";
import {
  OffersScreenType,
  OfferStoreDispatchProps,
  OfferStoreStateProps
} from "../../store/offers/types";
import OtpPage, { OtpProps } from "../OtpPage";
import AcceptOffer from "./components/AcceptOffer";
import GetOffers from "./components/GetOffers";

interface OwnProps {}
type Props = OfferStoreDispatchProps & OfferStoreStateProps & OwnProps;

const getOptions = (
  loading: boolean,
  error: AxiosError | null,
  request: (otp?: string | null, onSuccessful?: () => void) => void
): OtpProps => {
  return { loading, error, triggerRequest: request };
};

function Offers(props: Props) {
  const {
    screenType,
    displayScreen,
    customerId,
    completeTransaction,
    tokenizeCardDetails,
    acceptOfferRequest,
    acceptOffer,
    getTokenWithOtpPending,
    acceptOfferPending,
    getTokenWithOtpError,
    transactionResponse,
    acceptOfferError,
    bankProvider,
    bankAcceptOfferRequest,
    offers,
    paymentId,
    merchantCode,
    otpRequestReference,
  } = props;
  const [selectedOfferIndex, setSelectedOfferIndex] = useState<number | null>(null);

  const offer = offers[selectedOfferIndex as number];

  const generateOtp = (otp?: string | null, onSuccessful?: () => void) => {
    tokenizeCardDetails(acceptOfferRequest, onSuccessful);
  };

  const validateOtp = (otp?: string | null) => {
    if(bankProvider){
      const bankOfferRequest = {
        ...bankAcceptOfferRequest, 
        otp, 
        merchantCode,
        paymentId,
        offerId: offer.offerId,
        isBankOffer: true,
        otpRequestReference: otpRequestReference || otp,
      };
      acceptOffer(bankOfferRequest);
      return;
    }

    if (acceptOfferRequest) {
      if (otp) acceptOfferRequest.otp = otp;
      acceptOffer(acceptOfferRequest);
    }
  };

  const generateOtpOptions = getOptions(getTokenWithOtpPending, getTokenWithOtpError, generateOtp);
  const validateOtpOptions = getOptions(acceptOfferPending, acceptOfferError, validateOtp);

  useEffect(() => {
    if (transactionResponse) completeTransaction(transactionResponse);
  }, [completeTransaction, transactionResponse]);

  return (
    <>
      {screenType === OffersScreenType.offer && (
        <GetOffers onSelectOffer={setSelectedOfferIndex} selectedOfferIndex={selectedOfferIndex} />
      )}

      {screenType === OffersScreenType.cardDetails && selectedOfferIndex !== null && (
        <AcceptOffer selectedOfferIndex={selectedOfferIndex} />
      )}

      {screenType === OffersScreenType.otp && (
        <OtpPage
          selectedOfferIndex={selectedOfferIndex}
          buttonText="Authorize"
          generateOtpOptions={generateOtpOptions}
          validateOtpOptions={validateOtpOptions}
          phoneNumber={customerId ? customerId : ""}
          backButtonCallback={() => {
            if(bankProvider){
              displayScreen(OffersScreenType.offer);
            } else {
              displayScreen(OffersScreenType.cardDetails)
            }
          }} 
        />
      )}
    </>
  );
}

const mapStateToProps = (state: AppState): OfferStoreStateProps => {
  return {
    screenType: state.credit.offers.screenType,
    customerId: state.credit.offers.userData?.mobileNo,
    acceptOfferRequest: state.credit.offers.acceptOfferRequest,
    acceptOfferPending: state.credit.offers.acceptOfferPending,
    acceptOfferError: state.credit.offers.acceptOfferError,
    getTokenWithOtpPending: state.credit.creditCardDetails.getTokenWithOtpPending,
    getTokenWithOtpError: state.credit.creditCardDetails.getTokenWithOtpError,
    transactionResponse: state.credit.offers.transactionResponse,
    bankProvider: state.credit.offers.bankProvider,
    bankAcceptOfferRequest: {
      customerId: state.credit.offers.userData?.mobileNo,
      offerId: '',
      providerCode: state.credit.selectBanksPage.bankDetails.finInstitution,
      accountNumber: state.credit.selectBanksPage.bankDetails.accountNumber,
      bankCode: state.credit.selectBanksPage.bankDetails.bankCode,
    },
    offers: state.credit.offers.offers,
    merchantCode: state.payment.paymentProperties.paymentParams?.merchantCode,
    paymentId: state.payment.paymentProperties.paymentParams?.paymentId,
    otpRequestReference: state.credit.creditOtp.otpRequestReference,
  };
};

const mapDispatchToProps = (dispatch: (action: any) => void): OfferStoreDispatchProps => {
  return {
    displayScreen: (serviceType: OffersScreenType) => dispatch(displayScreen(serviceType)),

    completeTransaction: (transactionResponse: any) => {
      dispatch(completeTransaction(transactionResponse));
    },

    tokenizeCardDetails: (request: GetTokenWithOtpRequest, onSuccessful?: () => void, ) => {
      dispatch(triggerGetTokenWithOtp(request, onSuccessful));
    },

    acceptOffer: (request: AcceptOfferRequest, onSuccessful?: () => void) => {
      dispatch(triggerAcceptOffer(request, onSuccessful));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
