import * as Types from "./types"
import { AxiosError } from "axios"
import { SigninUserRequest } from "../../../../wallet/domain/repositories/WalletRepository"

export const resetVerveWalletSignin = (): Types.ResetVerveWalletSignin => {
    return { type: Types.RESET_VERVE_WALLET_SIGNIN }
}

export const triggerVerveWalletSignin = (request: SigninUserRequest, onSuccessful: Types.OnSigninSuccessfulCallback): Types.TriggerVerveWalletSignin => {
    return {
        type: Types.TRIGGER_VERVE_WALLET_SIGNIN,
        payload: { request, onSuccessful }
    }
}

export const verveWalletSigninPending = (): Types.VerveWalletSigninPending => {
    return { type: Types.VERVE_WALLET_SIGNIN_PENDING }
}

export const verveWalletSigninSuccessful = (): Types.VerveWalletSigninSuccessful => {
    return { 
        type: Types.VERVE_WALLET_SIGNIN_SUCCESSFUL
    }
}

export const verveWalletSigninError = (error: AxiosError): Types.VerveWalletSigninError => {
    return {
        type: Types.VERVE_WALLET_SIGNIN_ERROR,
        payload: { error }
    }
}

export const displayVerveWalletSignin = (): Types.DisplayVerveWalletSignin => {
    return { type: Types.DISPLAY_VERVE_WALLET_SIGNIN }
}

export const hideVerveWalletSignin = (): Types.HideVerveWalletSignin => {
    return { type: Types.HIDE_VERVE_WALLET_SIGNIN }
}