import { GenerateAccountRequest, GenerateAccountResponse } from "../../../domain/repositories/TransferRepository";

export const TRIGGER_GENERATE_ACCOUNT = 'TRANSFER.TRIGGER_GENERATE_ACCOUNT';
export const GENERATE_ACCOUNT_PENDING = 'TRANSFER.GENERATE_ACCOUNT_PENDING';
export const GENERATE_ACCOUNT_SUCCESS = 'TRANSFER.GENERATE_ACCOUNT_SUCCESS';
export const GENERATE_ACCOUNT_ERROR = 'TRANSFER.GENERATE_ACCOUNT_ERROR';

export const RESET_GENERATE_ACCOUNT = 'TRANSFER.RESET_GENERATE_ACCOUNT';

export interface TriggerGenerateAccount {
  type: typeof TRIGGER_GENERATE_ACCOUNT;
  payload: {
    request: GenerateAccountRequest;
  }
}

export interface GenerateAccountPending {
  type: typeof GENERATE_ACCOUNT_PENDING;
}

export interface GenerateAccountSuccess {
  type: typeof GENERATE_ACCOUNT_SUCCESS;
  payload: {
    response: GenerateAccountResponse;
  }
}

export interface GenerateAccountError {
  type: typeof GENERATE_ACCOUNT_ERROR;
}

export interface ResetGenerateAccount {
  type: typeof RESET_GENERATE_ACCOUNT;
}


export type GenerateAccountActionType =
  | TriggerGenerateAccount
  | GenerateAccountPending
  | GenerateAccountSuccess
  | GenerateAccountError
  | ResetGenerateAccount;


export interface GenerateAccountState {
  generateAccountPending: boolean;
  generateAccountError: boolean;
  generateAccountResponse: GenerateAccountResponse | null;
}