import { AxiosError } from "axios";
import {
  AuthorizeTokenWithOtpRequest,
  GetTokenWithOtpRequest
} from "../../../domain/repositories/OtpRepository";

export const GET_TOKEN_WITH_OTP_PENDING = "CREDIT.GET_TOKEN_WITH_OTP_PENDING";
export const GET_TOKEN_WITH_OTP_SUCCESSFUL = "CREDIT.GET_TOKEN_WITH_OTP_SUCCESSFUL";
export const GET_TOKEN_WITH_OTP_ERROR = "CREDIT.GET_TOKEN_WITH_OTP_ERROR";
export const TRIGGER_GET_TOKEN_WITH_OTP = "CREDIT.TRIGGER_GET_TOKEN_WITH_OTP";

export const AUTHORIZE_TOKEN_WITH_OTP_PENDING = "CREDIT.AUTHORIZE_TOKEN_WITH_OTP_PENDING";
export const AUTHORIZE_TOKEN_WITH_OTP_SUCCESSFUL = "CREDIT.AUTHORIZE_TOKEN_WITH_OTP_SUCCESSFUL";
export const AUTHORIZE_TOKEN_WITH_OTP_ERROR = "CREDIT.AUTHORIZE_TOKEN_WITH_OTP_ERROR";
export const TRIGGER_AUTHORIZE_TOKEN_WITH_OTP = "CREDIT.TRIGGER_AUTHORIZE_TOKEN_WITH_OTP";

export const RESET_CREDIT_CARD_DETAILS = "CREDIT.RESET_CREDIT_CARD_DETAILS";

export interface GetTokenWithOtpPending {
  type: typeof GET_TOKEN_WITH_OTP_PENDING;
}

export interface GetTokenWithOtpSuccessful {
  type: typeof GET_TOKEN_WITH_OTP_SUCCESSFUL;
}

export interface GetTokenWithOtpError {
  type: typeof GET_TOKEN_WITH_OTP_ERROR;
  payload: { error: AxiosError | null };
}

export interface TriggerGetTokenWithOtp {
  type: typeof TRIGGER_GET_TOKEN_WITH_OTP;
  payload: {
    request: GetTokenWithOtpRequest;
    onSuccessful?: () => void;
  };
}

export interface AuthorizeTokenWithOtpPending {
  type: typeof AUTHORIZE_TOKEN_WITH_OTP_PENDING;
}

export interface AuthorizeTokenWithOtpSuccessful {
  type: typeof AUTHORIZE_TOKEN_WITH_OTP_SUCCESSFUL;
}

export interface AuthorizeTokenWithOtpError {
  type: typeof AUTHORIZE_TOKEN_WITH_OTP_ERROR;
  payload: { error: AxiosError | null };
}

export interface TriggerAuthorizeTokenWithOtp {
  type: typeof TRIGGER_AUTHORIZE_TOKEN_WITH_OTP;
  payload: {
    request: AuthorizeTokenWithOtpRequest;
    onSuccessful?: () => void;
  };
}

export interface ResetCreditCardDetails {
  type: typeof RESET_CREDIT_CARD_DETAILS;
}

export interface CreditCardDetailsState {
  getTokenWithOtpError: AxiosError | null;
  getTokenWithOtpPending: boolean;

  authorizeTokenWithOtpError: AxiosError | null;
  authorizeTokenWithOtpPending: boolean;
}

export type CreditCardDetailsActionTypes =
  | GetTokenWithOtpPending
  | GetTokenWithOtpSuccessful
  | GetTokenWithOtpError
  | AuthorizeTokenWithOtpPending
  | AuthorizeTokenWithOtpSuccessful
  | AuthorizeTokenWithOtpError
  | ResetCreditCardDetails;
