import {
  InitializeRequest,
  InitializeResponse,
} from "../../../domain/repositories/EnairaRepository";
import {
  TRIGGER_INITIALIZE,
  TriggerInitialize,
  INITIALIZE_PENDING,
  InitializePending,
  INITIALIZE_SUCCESS,
  InitializeSuccess,
  INITIALIZE_ERROR,
  InitializeError,
} from "./types";

export const triggerInitialize = (
  request: InitializeRequest
): TriggerInitialize => {
  return {
    type: TRIGGER_INITIALIZE,
    payload: { request },
  };
};

export const initializePending = (): InitializePending => {
  return {
    type: INITIALIZE_PENDING,
  };
};

export const initializeSuccess = (
  response: InitializeResponse
): InitializeSuccess => {
  return {
    type: INITIALIZE_SUCCESS,
    payload: { response },
  };
};

export const initializeError = (): InitializeError => {
  return {
    type: INITIALIZE_ERROR,
  };
};
