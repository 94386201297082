import {
  GetInstitutionsRequest,
  GetInstitutionsResponse,
} from "../../../domain/repositories/PayWithAppRepository";

export const TRIGGER_GET_INSTITUTIONS = "PAY_WITH_APP.TRIGGER_GET_INSTITUTIONS";
export const GET_INSTITUTIONS_PENDING = "PAY_WITH_APP.GET_INSTITUTIONS_PENDING";
export const GET_INSTITUTIONS_SUCCESS = "PAY_WITH_APP.GET_INSTITUTIONS_SUCCESS";
export const GET_INSTITUTIONS_ERROR = "PAY_WITH_APP.GET_INSTITUTIONS_ERROR";
export const RESET_GET_INSTITUTIONS = "PAY_WITH_APP.RESET_GET_INSTITUTIONS";
export const SET_TIME_LEFT = "PAY_WITH_APP.SET_TIME_LEFT";

export interface TriggerGetInsititutions {
  type: typeof TRIGGER_GET_INSTITUTIONS;
  payload: {
    request: GetInstitutionsRequest;
  };
}

export interface GetInstitutionsPending {
  type: typeof GET_INSTITUTIONS_PENDING;
}

export interface GetInstitutionsError {
  type: typeof GET_INSTITUTIONS_ERROR;
}

export interface GetInstitutionsSuccess {
  type: typeof GET_INSTITUTIONS_SUCCESS;
  payload: {
    response: GetInstitutionsResponse;
  };
}

export interface ResetGetInstitution {
  type: typeof RESET_GET_INSTITUTIONS;
}

export interface SetTimeLeft {
  type: typeof SET_TIME_LEFT;
  payload: {
    time: number;
  };
}

export type GetInstitutionsActionType =
  | TriggerGetInsititutions
  | GetInstitutionsPending
  | GetInstitutionsSuccess
  | GetInstitutionsError
  | ResetGetInstitution
  | SetTimeLeft;

export interface GetInstitutionsState {
  getInstitutionsPending: boolean;
  getInstitutionsError: boolean;
  paymentData: GetInstitutionsResponse | null;
  timeLeft: number | undefined;
}
