import { compose, createStore, applyMiddleware } from 'redux';

import createSagaMiddleware from 'redux-saga';
import RootReducer from './RootReducer';
import RootSaga from './RootSaga';


// @ts-ignore
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose;


export const getStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(RootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(RootSaga, store.dispatch);

  return store;
};
