import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import BackControl from "../../../../components/BackControl";
import { PageView } from "../../../../components/Layout";

import LoadingView from "../../../../components/LoadingView";
import ErrorView from "../../../../components/ErrorView";

import { AppState } from "../../../../../store/RootReducer";
import { PaymentParams } from "../../../../payment/presentation/store/paymentProperties/types";
import { connect } from "react-redux";
import opayRepository, {
  InitializeRequest,
  InitializeResponse,
} from "../../../domain/repositories/OpayRepository";
import { triggerShowNotification } from "../../../../components/NotificationWidget/store/actions";
import { triggerInitialize } from "../../store/initialize/actions";
import { TriggerShowNotificationPayload } from "../../../../components/NotificationWidget/store/types";

import { asyncDelay } from "../../../../../core/util/asyncUtil";
import { completeTransaction } from "../../../../payment/presentation/store/paymentStatus/actions";
import { PaymentUtil } from "../../../../payment/util/PaymentUtil";

const Container = styled(PageView)``;

const LoadingViewContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: white;
`;

const IFrameContainer = styled.div`
  position: relative;
  min-height: 71vh;
  display: flex;
`;

interface StoreStateProps {
  paymentParams: PaymentParams;
  initializePending: boolean;
  initializeError: boolean;
  initializeResponse: InitializeResponse | null;
}

interface StoreDispatchProps {
  showNotification: (payload: TriggerShowNotificationPayload) => void;
  completeTransaction: (transactionResponse: any) => void;
  initialize: (request: InitializeRequest) => void;
}

interface OwnProps {}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function RootPage(props: Props) {
  const {
    paymentParams,
    initializePending,
    initializeError,
    initializeResponse,
    completeTransaction,
    initialize,
  } = props;

  const [isLoadingVisible, setIsLoadingVisible] = useState(true);

  const history = useHistory();

  const isPageMounted = useRef(true);

  const navigateToPaymentMethods = () => {
    history.goBack();
  };

  const [transactionProcessingFailed, setTransactionProcessingFailed] =
    useState(false);

  const pollTransactionStatus = async () => {
    while (true) {
      if (!isPageMounted.current) return;

      let getStatusResponse;

      try {
        getStatusResponse = await opayRepository.getStatus({
          merchantCode: paymentParams.merchantCode,
          reference: paymentParams.merchantTransactionReference,
        });
      } catch (err) {
        await asyncDelay(2000);
        continue;
      }

      const { responseCode } = getStatusResponse;

      if (responseCode === "01") {
        setTransactionProcessingFailed(true);
        break;
      }

      if (responseCode === "09") {
        await asyncDelay(2000);
        continue;
      }

      if (PaymentUtil.isTransactionSuccessful(responseCode)) {
        completeTransaction(getStatusResponse);
        break;
      }

      await asyncDelay(2000);
    }
  };

  const callInitialize = () => {
    const {
      merchantCode,
      payableCode,
      paymentId,
      merchantTransactionReference,
    } = paymentParams;

    initialize({
      merchantCode,
      payableCode,
      transactionReference: merchantTransactionReference,
      paymentId,
      currencyCode: paymentParams.currencyCode,
    });
  };

  const iframeOnLoadHandler = () => {
    setIsLoadingVisible(false);
  };

  useEffect(() => {
    return () => {
      isPageMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (initializePending || !!initializeResponse) return;
    callInitialize();
  }, []);

  useEffect(() => {
    if (!initializeResponse) return;

    const { responseCode } = initializeResponse;

    if (responseCode === "01") {
      setTransactionProcessingFailed(true);
      return;
    }

    pollTransactionStatus();
  }, [initializeResponse]);

  return (
    <Container>
      <BackControl text="Back" onClick={navigateToPaymentMethods} />

      {initializePending && <LoadingView />}

      {!initializePending && initializeError && (
        <ErrorView action={callInitialize} />
      )}

      {transactionProcessingFailed && (
        <ErrorView message="Failed to complete transaction. Please use a different payment method." />
      )}

      {!initializePending &&
        !!initializeResponse &&
        !transactionProcessingFailed && (
          <IFrameContainer>
            {isLoadingVisible && (
              <LoadingViewContainer>
                <LoadingView />
              </LoadingViewContainer>
            )}

            {initializeResponse && (
              <iframe
                style={{ width: "100%" }}
                onLoad={iframeOnLoadHandler}
                title="Opay"
                src={initializeResponse.redirectUrl}
                frameBorder="0"
              />
            )}
          </IFrameContainer>
        )}
    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,

  initializePending: state.opay.initialize.initializePending,
  initializeError: state.opay.initialize.initializeError,
  initializeResponse: state.opay.initialize.initializeResponse,
});

const mapDispatchToProps = (
  dispatch: (action: any) => void
): StoreDispatchProps => ({
  showNotification(payload: TriggerShowNotificationPayload) {
    dispatch(triggerShowNotification(payload));
  },
  completeTransaction(transactionResponse: any) {
    dispatch(completeTransaction(transactionResponse));
  },
  initialize(request: InitializeRequest) {
    dispatch(triggerInitialize(request));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
