import { SERVICE_BASE_URL } from "../../../../config/properties";
import httpClient, { HttpClient } from "../../../../core/api/httpClient";

export interface GenerateQrRequest {
  merchantCode: string;
  payableCode: string;
  currencyCode: string;
  amount: number;
  surcharge: number;
  merchantTransactionReference: string;
}

export interface GenerateQrResponse {
  paymentId: number;
  qrCodeId: string;
  qrCodeIdMasterPass: string;
  rawQRData: string;
  transactionReference: string;
}

export interface GetTransactionStatusRequest {
  merchantCode: string;
  transactionReference: string;
}

export interface GetTransactionStatusResponse {
  responseCode: string;
  responseDescription: string;
}

interface QrRepository {
  generateQr: (request: GenerateQrRequest) => Promise<GenerateQrResponse>;
  getTransactionStatus: (request: GetTransactionStatusRequest) => Promise<GetTransactionStatusResponse>;
}

class QrRepositoryImpl implements QrRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async generateQr(request: GenerateQrRequest): Promise<GenerateQrResponse> {
    const response = await this.httpClient.post<GenerateQrResponse>(
      `${SERVICE_BASE_URL}/api/v1/qr/generate`,
      request
    );

    return response.data;
  }

  async getTransactionStatus(request: GetTransactionStatusRequest) {
    const response = await this.httpClient.get<GetTransactionStatusResponse>(
      `${SERVICE_BASE_URL}/api/v1/qr/status`,
      { params: request }
    );

    return response.data;
  }

}

const qrRepository = new QrRepositoryImpl(httpClient);

export default qrRepository;
