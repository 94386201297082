import { AxiosError } from "axios";
import { ValidateRequestData, ValidateRequestResponse } from "../../../domain/repositories/TerrapayRepository";

export const TRIGGER_VALIDATE_REQUEST = 'WALLET.TRIGGER_VALIDATE_REQUEST';
export const VALIDATE_REQUEST_PENDING = 'WALLET.VALIDATE_REQUEST_PENDING';
export const VALIDATE_REQUEST_SUCCESS = 'WALLET.VALIDATE_REQUEST_SUCCESS';
export const VALIDATE_REQUEST_ERROR = 'WALLET.VALIDATE_REQUEST_ERROR';

export const RESET_VALIDATE_REQUEST = 'WALLET.RESET_VALIDATE_REQUEST';
export const SAVE_WALLET_ID = 'WALLET.SAVE_WALLET_ID';

export interface TriggerValidateRequest {
  type: typeof TRIGGER_VALIDATE_REQUEST;
  payload: {
    request: ValidateRequestData;
    onSuccessful?: ValidateRequestCallback;
  }
}

export interface ValidateRequestPending {
  type: typeof VALIDATE_REQUEST_PENDING;
}

export interface ValidateRequestSuccess {
  type: typeof VALIDATE_REQUEST_SUCCESS;
  payload: {
    response: ValidateRequestResponse;
  }
}

export interface ValidateRequestError {
  type: typeof VALIDATE_REQUEST_ERROR;
  payload: {
    error: AxiosError;
  }
}

export interface ResetValidateRequest {
  type: typeof RESET_VALIDATE_REQUEST;
}

export interface SaveWalletID {
  type: typeof SAVE_WALLET_ID;
  payload: string;
}

export type ValidateRequestCallback = () => void;

export type ValidateRequestActionType =
  TriggerValidateRequest
  | ValidateRequestPending
  | ValidateRequestSuccess
  | ValidateRequestError
  | ResetValidateRequest
  | SaveWalletID;

export interface ValidateRequestState {
  loading: boolean;
  loadingError: AxiosError | null;
  data: string;
  walletID: string
}