import * as React from "react";
import styled from "styled-components";

import { ReactComponent as Icon } from "../../../../../../assets/icons/question.svg";

interface Props {
  text: string;
  className?: string;
  icon?: React.ReactNode;
}
export default function Tooltip(props: Props) {
  const { text, className, icon } = props;

  return (
    <TooltipBox className={className}>
      {icon ||  <Icon />}
      <span className="tooltiptext">{text}</span>
    </TooltipBox>
  );
}

const TooltipBox = styled.div`
  position: relative;
  display: inline-block;

  > svg {
      width: 16px;
      height: 16px;
  }

  > .tooltiptext {
    visibility: hidden;
    background-color: #333333;
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
    padding: 10px;
    border-radius: 6px;

    position: absolute;
    z-index: 1;

    /* top position */
    min-width: 100px;
    bottom: 100%;
    left: 50%;
    margin-left: -146px;
  }

  > .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333333 transparent transparent transparent;
  }

  &:hover > .tooltiptext {
    visibility: visible;
  }
`;
