export function initCybersourceFingerprint(iswPaymentProps: any) {
  const { cybersourceOrgId, cybersourceMerchantId, paymentId } = iswPaymentProps;

  const cybersourceScript = document.createElement('script');
  const cybersourceURL = `https://h.online-metrix.net/fp/tags.js?org_id= ${cybersourceOrgId}&session_id=${cybersourceMerchantId}${paymentId}`;
  cybersourceScript.type = "text/javascript";
  cybersourceScript.src = cybersourceURL;
  document.head.appendChild(cybersourceScript);

  const cybersourceNoScript = document.createElement('noscript');
  const cybersourceIframe = document.createElement('iframe');
  cybersourceIframe.setAttribute("style", "width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;");
  cybersourceIframe.src = cybersourceURL;
  cybersourceNoScript.appendChild(cybersourceIframe);
}