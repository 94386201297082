import { takeLatest, put } from "redux-saga/effects";

import transferRepository, { GenerateAccountResponse } from "../../../domain/repositories/TransferRepository";

import {
  TRIGGER_GENERATE_ACCOUNT,
  TriggerGenerateAccount,

  RESET_GENERATE_ACCOUNT,
} from "./types";

import {
  generateAccountPending,
  generateAccountSuccess,
  generateAccountError
} from "./actions";
import { createCancellableSaga } from "../../../../../store/cancellableSaga";


function* handleTriggerGenerateAccount(action: TriggerGenerateAccount) {
  const { payload } = action;
  const { request } = payload;

  yield put(generateAccountPending());

  let response: GenerateAccountResponse;

  try {
    response = yield transferRepository.generateAccount(request);
  } catch (err) {
    yield put(generateAccountError());
    return;
  }

  yield put(generateAccountSuccess(response));
}



function* watchGenerateAccount() {
  yield takeLatest(TRIGGER_GENERATE_ACCOUNT, handleTriggerGenerateAccount);
}


export const generateAccountSaga = createCancellableSaga(
  watchGenerateAccount, RESET_GENERATE_ACCOUNT
);