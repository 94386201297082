import {
  AUTHENTICATE_CARDINAL_PENDING,
  AUTHENTICATE_CARDINAL_SUCCESS,
  AUTHENTICATE_CARDINAL_ERROR,
  RESET_AUTHENTICATE_CARDINAL,
  AuthenticateCardinalState,
  AuthenticateCardinalActionType
} from "./types";

const initialState: AuthenticateCardinalState = {
  authenticateCardinalPending: false,
  authenticateCardinalError: null,
  authenticateCardinalResponse: null

}

export function AuthenticateCardinalReducer(
  state: AuthenticateCardinalState = initialState,
  action: AuthenticateCardinalActionType
): AuthenticateCardinalState {
  switch (action.type) {

    case AUTHENTICATE_CARDINAL_PENDING: {
      return {
        ...state,
        authenticateCardinalPending: true
      };
    }

    case AUTHENTICATE_CARDINAL_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      return {
        ...state,
        authenticateCardinalPending: false,
        authenticateCardinalError: null,
        authenticateCardinalResponse: response
      };
    }

    case AUTHENTICATE_CARDINAL_ERROR: {
      const { payload } = action;
      const { error } = payload;

      return {
        ...state,
        authenticateCardinalPending: false,
        authenticateCardinalError: error,
      };
    }

    case RESET_AUTHENTICATE_CARDINAL: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
