import {
  GetProvidersState,
  GetProvidersActionType,
  GET_PROVIDERS_PENDING,
  GET_PROVIDERS_SUCCESS,
  RESET_GET_PROVIDERS,
  GET_PROVIDERS_ERROR,
  SET_PROVIDER,
} from "./types";

const initialState: GetProvidersState = {
  loading: false,
  loadingError: null,
  providers: [],
  selectedProvider: null
}

export function getProvidersReducer(
  state: GetProvidersState = initialState,
  action: GetProvidersActionType
): GetProvidersState {
  switch (action.type) {

    case GET_PROVIDERS_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_PROVIDERS_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      const { data } = response;

      return {
        ...state,
        loading: false,
        loadingError: null,
        providers: data
      };
    }

    case GET_PROVIDERS_ERROR: {
      const { payload } = action;
      const { error } = payload;

      return {
        ...state,
        loading: false,
        loadingError: error,
      };
    }

    case SET_PROVIDER: {
      const { payload: provider } = action;

      return {
        ...state,
        selectedProvider: provider
      };
    }

    case RESET_GET_PROVIDERS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
