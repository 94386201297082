import { InitializeRequest, InitializeResponse } from "../../../domain/repositories/OpayRepository";

export const TRIGGER_INITIALIZE = 'OPAY.TRIGGER_INITIALIZE';
export const INITIALIZE_PENDING = 'OPAY.INITIALIZE_PENDING';
export const INITIALIZE_SUCCESS = 'OPAY.INITIALIZE_SUCCESS';
export const INITIALIZE_ERROR = 'OPAY.INITIALIZE_ERROR';

export const RESET_INITIALIZE = 'OPAY.RESET_INITIALIZE';

export interface TriggerInitialize {
  type: typeof TRIGGER_INITIALIZE;
  payload: {
    request: InitializeRequest;
  }
}

export interface InitializePending {
  type: typeof INITIALIZE_PENDING;
}

export interface InitializeSuccess {
  type: typeof INITIALIZE_SUCCESS;
  payload: {
    response: InitializeResponse;
  }
}

export interface InitializeError {
  type: typeof INITIALIZE_ERROR;
}

export interface ResetInitialize {
  type: typeof RESET_INITIALIZE;
}


export type InitializeActionType =
  | TriggerInitialize
  | InitializePending
  | InitializeSuccess
  | InitializeError
  | ResetInitialize;


export interface InitializeState {
  initializePending: boolean;
  initializeError: boolean;
  initializeResponse: InitializeResponse | null;
}