import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { StoreStateProps, DispatchProps } from "./types";
import {
  PAYMENT__PAY_WITH_APP,
  PAYMENT_METHODS__ROOT,
} from "../../../../Routes";
import { AppState } from "../../../../../store/RootReducer";
import { setTimeLeft } from "../../store/getInstitutions/actions";

import {
  TimerWrapper,
  ViewContainer,
  DynamicLinkContainer,
  LoaderContainer,
} from "../../components/Containers";
import {
  LabelFieldContainer,
  FormControlLabel,
} from "../../../../components/Layout";
import { Spacer } from "../../components/Spacer";
import Loader from "../../../../components/Loader";
import { Dropdown } from "../../components/Dropdown";
import { Button } from "../../../../components/Button";
import ErrorView from "../../../../components/ErrorView";

import { ReactComponent as LaunchIcon } from "../../icons/launch.svg";
import { ReactComponent as BackIcon } from "../../icons/backArrow.svg";
import useCountdownTimer from "../../../../../hooks/useCountdownTimer";

const DeepLinkView = ({
  paymentData,
  timeout,
  setTimeLeft,
}: StoreStateProps & DispatchProps) => {
  const history = useHistory();
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [institution, setInstitution] = useState<string>("");
  const [transactionStarted, setTrasactionStarted] = useState<boolean>(false);

  const handleTimeOut = () => {
    setIsTimeOut(true);
  };

  const { time } = useCountdownTimer({
    timeout: timeout || 0,
    onCompleted: handleTimeOut,
  });

  const { minutes, seconds } = time;
  const timerText = `00h ${minutes > 9 ? minutes : "0" + minutes}m ${
    seconds > 9 ? seconds : "0" + seconds
  }s`;

  const dropdownData = Object.keys(paymentData?.deepLinks || {});

  const handleSelectInstitution = (
    evt: React.SyntheticEvent<HTMLSelectElement, Event>
  ) => {
    const institution = evt.currentTarget.value;
    setInstitution(institution);
  };

  const handleOpenURL = () => {
    if (paymentData?.deepLinks && institution) {
      const link = paymentData.deepLinks[institution];
      window.open(link);
      setTrasactionStarted(true);
    }
  };

  const handleNavigateToSixDigitCode = () => {
    setTimeLeft((minutes * 60 + seconds) * 1000);
    history.push(`${PAYMENT__PAY_WITH_APP}/five-digit-code/mobile`);
  };

  const handleNavigateToTransactionStatus = () => {
    history.push(`${PAYMENT__PAY_WITH_APP}/transaction-status`);
  };

  const handleRestart = () => {
    history.push(PAYMENT_METHODS__ROOT);
  };

  return (
    <ViewContainer>
      {isTimeOut && (
        <ErrorView
          message="The link is not longer valid. TimeOut"
          actionText="Go back"
          action={handleRestart}
        />
      )}

      {!transactionStarted && !isTimeOut && (
        <DynamicLinkContainer>
          <LabelFieldContainer>
            <FormControlLabel>Select Institution</FormControlLabel>
            <Dropdown
              data={dropdownData}
              value={institution}
              onChange={handleSelectInstitution}
            />
          </LabelFieldContainer>
          {institution && (
            <>
              <TimerWrapper top={80} bottom={16}>
                <p>Time left to complete payment </p>
                <p className="bold">{timerText}</p>
              </TimerWrapper>
              <Button
                color="PRIMARY"
                text="Tab to launch your app"
                icon={<LaunchIcon />}
                onClick={handleOpenURL}
              />
              <Spacer height={16} />
              <Button
                color="SECONDARY"
                text="Enter code manually instead"
                type="OUTLINE"
                onClick={handleNavigateToSixDigitCode}
              />
            </>
          )}
        </DynamicLinkContainer>
      )}

      {transactionStarted && !isTimeOut && (
        <DynamicLinkContainer>
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
          <p>
            Kindly complete this transaction using your app to continue. Tap the
            button below once you have made payment.
          </p>
          <TimerWrapper top={34} bottom={16}>
            <p>Time left to complete payment </p>
            <p className="bold">{timerText}</p>
          </TimerWrapper>
          <Button
            color="PRIMARY"
            text="Confirm transaction"
            onClick={handleNavigateToTransactionStatus}
            icon={<></>}
          />
          <Spacer height={16} />
          <Button
            type="OUTLINE"
            text="Go back"
            color="PRIMARY"
            icon={<BackIcon />}
            onClick={() => setTrasactionStarted(false)}
          />
        </DynamicLinkContainer>
      )}
    </ViewContainer>
  );
};

const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentData: state.payWithApp.intitutions.paymentData,
  timeout: state.payWithApp.intitutions.timeLeft,
});

const mapDispatchToProps = (
  dispatch: (action: any) => void
): DispatchProps => ({
  setTimeLeft(time: number) {
    dispatch(setTimeLeft(time));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeepLinkView);
