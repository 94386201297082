import { SERVICE_BASE_URL } from "../../../../config/properties";
import httpClient, { HttpClient } from "../../../../core/api/httpClient";

export interface GetBinConfigurationRequest {
  paymentId: number;
  payableCode: string;
  merchantCode: string;
  secureData: string;
  pinBlock: string;

  walletId?: string;
  walletIdentifier?: string;
  instrumentIdentifier?: string;
}

export interface CardinalAuthenticationInformation {
  accessToken: string;
  deviceDataCollectionUrl: string;
  referenceId: string;
}

export interface GetBinConfigurationResponse {
  cardType: {
    id: number;
    typeName: string;
    typeCode: string;
  };
  issuer: {
    id: number;
    name: string;
    code: string;
    domainTypeId: number;
    countryCode: string;
    cbnCode: string;
  };
  cardTypeGatewayConfiguration: {
    id: number;
    supportsPin: boolean;
    supportsCvv2: boolean;
    supportsExpiryDate: boolean;
    enabled: boolean;
    cardTypeCode: string;
    supportsOtp: boolean;
    supportsCardinalAuthentication: boolean;
    loyaltyRedeemEnabled: boolean;
    loyaltyEnabled: boolean;
    supportsFingerPrintAuthorization: boolean;
    supportsAccountType: boolean;
    supportsVisaAcceleratedConnectionPlatform: boolean;
    supportsCybersource20: boolean;
    cybersourceEnabled: boolean;
    cybersourceCardId?: string;
    supportThreeDsAuthentication?: boolean;
  };
  loyaltyRedemptionAllowed: boolean;
  maskToken: boolean;
  transactionReference: string;
  cardinalAuthenticationInformation?: CardinalAuthenticationInformation;
}

export interface CardinalDeviceInfo {
  httpBrowserLanguage: string;
  httpBrowserJavaEnabled: boolean;
  httpBrowserJavaScriptEnabled: boolean;
  httpBrowserColorDepth: string;
  httpBrowserScreenHeight: string;
  httpBrowserScreenWidth: string;
  httpBrowserTimeDifference: string;
  userAgentBrowserValue: string;
  deviceChannel: string;
}

export interface MakePaymentRequest {
  merchantCode: string;
  payableCode: string;
  paymentId: number;
  secureData: string;
  pinBlock: string;
  instrumentIdentifier?: string;
  walletInfo?: {
    walletCardName: string;
    walletId: string;
    walletIdentifier: string;
  };
  googlePayToken?: string;
  sessionId?: string;
  deviceInformation?: CardinalDeviceInfo;
}

export interface CardinalInfo {
  url: string;
  callBackUrl: string;
  transactionId: string;
  payLoad?: string;
  accessToken?: string;
  eciFlag?: string;
  customisedHtml?: string;
}

export interface MakePaymentResponse {
  allowTokenAutoEnrollment: boolean;
  cardinalAutorizationSuccessful: boolean;
  emailSent: boolean;
  fingerPrintData: {};
  mobileIdRequired: boolean;
  mobilePhoneNumber: string;
  plainTextSupportMessage: string;
  requiresCentinelAuthorization: boolean;
  requiresRegistration: boolean;
  requiresToken: true;
  requiresTokenAuthorization: boolean;
  responseCode: string;
  responseEnabled: boolean;
  smsSent: boolean;
  supportMessage: string;
  tokenAutorizationSuccessful: boolean;
  tokenType: "SMS";
  transactionReference: string;
  cardinalInfo?: CardinalInfo;
}

export interface AuthenticateOtpRequest {
  merchantCode: string;
  payableCode: string;
  paymentId: number;
  secureData: string;
  pinBlock: string;
  twoFactorAuthenticationCode: string;
  instrumentIdentifier?: string;
  walletInfo?: {
    walletCardName: string;
    walletId: string;
    walletIdentifier: string;
  };
  googlePayToken?: string;
}

export interface AuthenticateOtpResponse {
  amount: number;
  approvedAmount: number;
  authorizationMode: string;
  captureAmount: number;
  channelTransactionReference: string;
  fingerPrintData: {};
  quicktellerAdviceSent: boolean;
  responseCode: string;
  responseDescription: string;
  responseEnabled: boolean;
  retrievalReferenceNumber: string;
  transactionReference: string;
}

export interface GetCardinalTransactionStatusRequest {
  paymentId: number;
}

export interface GetCardinalTransactionStatusResponse {
  responseCode: string;
  responseDescription: string;
}

export interface AuthenticateCardinalRequest {
  paymentId: number;
  cardinalInfo: {
    eciFlag: string;
    transactionId: string;
  };
  secureData: string;
  pinBlock: string;
  instrumentIdentifier?: string;
  walletInfo?: {
    walletCardName: string;
    walletId: string;
    walletIdentifier: string;
  };
  googlePayToken?: string;
}

export interface AuthenticateCardinalResponse {
  amount: number;
  approvedAmount: number;
  authorizationMode: string;
  captureAmount: number;
  channelTransactionReference: string;
  cybersourceToken: string;
  earnLoyaltyResponse: {
    canEarnInstantDiscount: boolean;
    canEarnLoyaltyPoints: boolean;
    canRedeemRewardPoints: boolean;
    discountedAmount: number;
    earnedPoints: number;
    instantDicountReversed: boolean;
    isEarnPointsSuccessful: boolean;
    isLoyaltyDiscountSuccessful: boolean;
    isRedeemPointsSuccessful: boolean;
    redeemLoyaltyReversed: boolean;
    rewardPointsBalance: number;
  };
  fingerPrintData: {};
  quicktellerAdviceSent: string;
  responseCode: string;
  responseDescription: string;
  responseEnabled: boolean;
  retrievalReferenceNumber: string;
  transactionReference: string;
}

export interface CardRepository {
  getBinConfiguration: (
    request: GetBinConfigurationRequest
  ) => Promise<GetBinConfigurationResponse>;
  makePayment: (request: MakePaymentRequest) => Promise<MakePaymentResponse>;
  authenticateOtp: (
    request: AuthenticateOtpRequest
  ) => Promise<AuthenticateOtpResponse>;
  getCardinalTransactionStatus: (
    request: GetCardinalTransactionStatusRequest
  ) => Promise<GetCardinalTransactionStatusResponse>;
  authenticateCardinal: (
    request: AuthenticateCardinalRequest
  ) => Promise<AuthenticateCardinalResponse>;
}

class CardRepositoryImpl implements CardRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getBinConfiguration(request: GetBinConfigurationRequest) {
    try {
      const response = await this.httpClient.get<GetBinConfigurationResponse>(
        `${SERVICE_BASE_URL}/api/v1/merchantbins`,
        { params: request }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async makePayment(request: MakePaymentRequest) {
    const response = await this.httpClient.post<MakePaymentResponse>(
      `${SERVICE_BASE_URL}/api/v1/pay`,
      request
    );

    return response.data;
  }

  async authenticateOtp(request: AuthenticateOtpRequest) {
    const response = await this.httpClient.post<AuthenticateOtpResponse>(
      `${SERVICE_BASE_URL}/api/v1/pay/authenticateToken`,
      request
    );

    return response.data;
  }

  async getCardinalTransactionStatus(
    request: GetCardinalTransactionStatusRequest
  ) {
    const response =
      await this.httpClient.get<GetCardinalTransactionStatusResponse>(
        `${SERVICE_BASE_URL}/api/v1/payments/cardinalCallBackStatus`,
        { params: request }
      );

    return response.data;
  }

  async authenticateCardinal(request: AuthenticateCardinalRequest) {
    const response = await this.httpClient.post<AuthenticateCardinalResponse>(
      `${SERVICE_BASE_URL}/api/v1/pay/authenticateCardinal`,
      request
    );

    return response.data;
  }
}

const cardRepository = new CardRepositoryImpl(httpClient);

export default cardRepository;
