import eNairaIconPath from "../icons/enaira.webp";
import momoIcon from "../icons/momo.svg";
import pocketIcon from "../icons/pocket.svg";
import palmPayIcon from "../icons/palmpay.svg";
import { PAYMENT__ENAIRA_ROOT, PAYMENT__MOMO_ROOT, PAYMENT__PALMPAY_ROOT, PAYMENT__POCKET_ROOT } from "../../../../Routes";

export type CustomerWalletType =
  | "momo"
  | "enaira"
  | "palmpay"
  | "pocket"
  | "other";

export interface CustomerWalletOption {
  type: CustomerWalletType;
  title: string;
  iconPath: string;
  path: string;
}

export const walletOptions: CustomerWalletOption[] = [
  {
    type: "enaira",
    title: "eNaira",
    iconPath: eNairaIconPath,
    path: PAYMENT__ENAIRA_ROOT,
  },
  {
    type: "momo",
    title: "MoMo wallet",
    iconPath: momoIcon,
    path: PAYMENT__MOMO_ROOT,
  },
  {
    type: "pocket",
    title: "Pocket",
    iconPath: pocketIcon,
    path: PAYMENT__POCKET_ROOT,
  },
  {
    type: "palmpay",
    title: "PalmPay",
    iconPath: palmPayIcon,
    path: PAYMENT__PALMPAY_ROOT,
  },
];
