import { AxiosError } from "axios";
import { GetProvidersResponse, ProviderData } from "../../../domain/repositories/TerrapayRepository";

export const TRIGGER_GET_PROVIDERS = 'WALLET.TRIGGER_GET_PROVIDERS';
export const GET_PROVIDERS_PENDING = 'WALLET.GET_PROVIDERS_PENDING';
export const GET_PROVIDERS_SUCCESS = 'WALLET.GET_PROVIDERS_SUCCESS';
export const GET_PROVIDERS_ERROR = 'WALLET.GET_PROVIDERS_ERROR';

export const SET_PROVIDER = 'WALLET.SET_PROVIDER'

export const RESET_GET_PROVIDERS = 'WALLET.RESET_GET_PROVIDERS';

// Search
export const TRIGGER_GET_PROVIDERS_SEARCH = 'WALLET.TRIGGER_GET_PROVIDERS_SEARCH';

export interface TriggerGetProvidersSearch {
  type: typeof TRIGGER_GET_PROVIDERS_SEARCH;
  payload: string
}

export interface TriggerGetProviders {
  type: typeof TRIGGER_GET_PROVIDERS;
}

export interface SetProvider {
  type: typeof SET_PROVIDER;
  payload: ProviderData
}

export interface GetProvidersPending {
  type: typeof GET_PROVIDERS_PENDING;
}

export interface GetProvidersSuccess {
  type: typeof GET_PROVIDERS_SUCCESS;
  payload: {
    response: GetProvidersResponse;
  }
}

export interface GetProvidersError {
  type: typeof GET_PROVIDERS_ERROR;
  payload: {
    error: AxiosError;
  }
}

export interface ResetGetProviders {
  type: typeof RESET_GET_PROVIDERS;
}

export type GetProvidersActionType =
  TriggerGetProviders
  | TriggerGetProvidersSearch
  | SetProvider
  | GetProvidersPending
  | GetProvidersSuccess
  | GetProvidersError
  | ResetGetProviders;

export interface GetProvidersState {
  loading: boolean;
  loadingError: AxiosError | null;
  providers: ProviderData[] | null;
  selectedProvider: ProviderData | null;
}