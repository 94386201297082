import * as React from "react";
import styled from "styled-components";

import Select from "./Select";
import { Button } from "../../../../components/Button";
import { GetBillersResponse } from "../../../domain/repositories/UgandaRepository";
import {
  FormControlLabel,
  FormFieldStyles,
  LabelFieldContainer,
} from "../../../../components/Layout";
import usePay from "../hooks/usePay";

interface Props {
  billers: GetBillersResponse["response"];
}
export default function PaymentForm(props: Props) {
  const { billers } = props;
  const [form, setForm] = React.useState({
    billerId: "",
    customerId: "",
  });

  const updateValue = (key: string, val: string) => {
    setForm((f) => ({ ...f, [key]: val }));
  };

  const canSubmit = Boolean(form.billerId && form.customerId);

  const { loading: makingPayment, makePayment } = usePay();

  const onSubmit = () => {
    makePayment(form);
  };

  return (
    <Container>
      <Select
        label="Biller"
        placeholder="Select your biller..."
        value={form.billerId}
        options={billers.map((b) => ({ label: b.biller, value: b.billerId }))}
        onChange={(v) => updateValue("billerId", v)}
      />

      <LabelFieldContainer className="form-control">
        <FormControlLabel>Mobile Number or Wallet ID</FormControlLabel>

        <InputField
          inputMode="tel"
          name="customerId"
          placeholder="01023456789"
          value={form.customerId}
          onChange={(e) => updateValue("customerId", e.target.value)}
          autoComplete='off'
        />
      </LabelFieldContainer>

      <Button
        text={`Pay`}
        color="PRIMARY"
        className="submit"
        disabled={!canSubmit}
        loading={makingPayment}
        onClick={onSubmit}
      />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  .loader-line {
    margin-top: 16px;
    display: flex;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.5);
    span {
      margin-left: 6px;
    }
  }

  .form-control {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .submit {
    width: 100%;
    margin-top: 20px;
  }
`;

export const InputField = styled.input`
  ${FormFieldStyles};
`;
