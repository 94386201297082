
import {
  CachedCardParams,
  
  SET_CACHED_CARD_DETAILS,
  SetCachedCardDetails,

  RESET_CACHED_CARD_DETAILS,
  ResetCachedCardDetails,
} from "./types";

export const setCachedCardDetails = (cardParams: CachedCardParams): SetCachedCardDetails => {
  return {
    type: SET_CACHED_CARD_DETAILS,
    payload: { cardParams }
  };
};


export const resetCachedCardDetails = (): ResetCachedCardDetails => {
  return {
    type: RESET_CACHED_CARD_DETAILS,
  };
};