import { endOfMonth, parse as parseDate, isValid as isValidDate, isBefore } from 'date-fns';
import { FieldValidationResult } from '../../../core/types';



const CARD_EMPTY_MSG = 'Provide card number';
const CARD_INVALID_MSG = 'Card number invalid';

function passessLuhnCheck(cardNumberString: string): boolean {

  if (cardNumberString === '') {
    return false;
  }

  var luhn = (function (arr) {
    return function (ccNum: string): boolean {

      let len = ccNum.length
      let bit = 1;
      let sum = 0;
      let val;

      while (len) {
        val = parseInt(ccNum.charAt(--len), 10);
        sum += (bit ^= 1) ? arr[val] : val;
      }
      return sum != 0 && sum % 10 === 0;
    };
  }([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]));

  return luhn(cardNumberString);
}

export function getIsCardPanValid(cardNumber: string): FieldValidationResult {
  if (cardNumber === '') {
    return { isValid: false, message: CARD_EMPTY_MSG };
  }

  if (!passessLuhnCheck(cardNumber)) {
    return { isValid: false, message: CARD_INVALID_MSG };
  }

  return { isValid: true };
}




const EXP_EMPTY_MSG = 'Provide card expiry';
const EXP_INVALID_MSG = 'Invalid expiry date';
const EXP_EXPIRED_MSG = 'Card is expired';

export function getIsCardExpiryValid(expiryString: string, currentDate: Date): FieldValidationResult {
  if (expiryString === '') {
    return { isValid: false, message: EXP_EMPTY_MSG };
  }

  const monthString = expiryString.slice(0, 2);
  const yearString = expiryString.slice(2);



  if (monthString.length != 2) {
    return { isValid: false, message: EXP_INVALID_MSG };
  };

  const monthNum = parseInt(monthString);

  if (isNaN(monthNum) || monthNum < 1 || monthNum > 12) {
    return { isValid: false, message: EXP_INVALID_MSG };
  }



  if (yearString.length != 2 && yearString.length != 4) {
    return { isValid: false, message: EXP_INVALID_MSG };
  };

  let expDate;

  if (yearString.length === 2) {
    const referenceYear = (new Date()).getFullYear();
    const referenceYearHalfStr = referenceYear.toString().slice(0,2);

    expDate = parseDate(`01/${monthString}/${referenceYearHalfStr}${yearString}`, 'dd/MM/yyyy', Date.now());
  } else {
    expDate = parseDate(`01/${monthString}/${yearString}`, 'dd/MM/yyyy', Date.now());
  }

  if (!isValidDate(expDate)) {
    return { isValid: false, message: EXP_INVALID_MSG };
  }

  expDate = endOfMonth(expDate);

  if (isBefore(expDate, currentDate)) {
    return { isValid: false, message: EXP_EXPIRED_MSG };
  }


  return { isValid: true };
}




const CVV_EMPTY_MSG = 'Provide card CVV';
const CVV_INVALID_MSG = 'Cvv is 3 or 4 digits long';

export function getIsCardCvvValid(cardCvv: string): FieldValidationResult {
  if (cardCvv === '') {
    return { isValid: false, message: CVV_EMPTY_MSG };
  }

  if (cardCvv.length != 3 && cardCvv.length != 4) {
    return { isValid: false, message: CVV_INVALID_MSG };
  }

  return { isValid: true };
}




const PIN_EMPTY_MSG = 'Provide card Pin';
const PIN_INVALID_MSG = 'Card pin is 4 digits long';

export function getIsCardPinValid(cardPin: string): FieldValidationResult {
  if (cardPin === '') {
    return { isValid: false, message: PIN_EMPTY_MSG };
  }

  if (cardPin.length != 4) {
    return { isValid: false, message: PIN_INVALID_MSG };
  }

  return { isValid: true };
}


const OTP_EMPTY_MSG = 'Provide card otp';

export function getIsCardOtpValid(cardPin: string): FieldValidationResult {
  if (cardPin === '') {
    return { isValid: false, message: OTP_EMPTY_MSG };
  }

  return { isValid: true };
}


