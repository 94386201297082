import React, { CSSProperties, ReactNode } from "react";
import styled, { css } from "styled-components";
import { ColorTheme, FormControlsTheme } from "../../styling/theme";

import LoadingIndicator from "./LoadingIndicator";

type ButtonType = "FLAT" | "OUTLINE" | "GHOST";
type ButtonColorScheme = "PRIMARY" | "SECONDARY" | "DANGER";

interface ContainerProps {
  type: ButtonType;
  color: ButtonColorScheme;
  style: CSSProperties;
  $loading: boolean;
  $disabled: boolean;
}

const ButtonText = styled.p`
  font-size: 1.4rem;
  color: white;
  white-space: nowrap;
`;

const ContainerFlatStyles = (color: ButtonColorScheme) => {
  if (color === "PRIMARY") {
    return css`
      background-color: ${ColorTheme.primaryColor};
      border-color: transparent;

      & ${ButtonText} {
        color: white;
      }
    `;
  }

  if (color === "SECONDARY") {
    return css`
      background-color: #ebf7fd;
      border-color: #ebf7fd;

      & ${ButtonText} {
        color: #009fe4;
      }
    `;
  }

  if (color === "DANGER") {
    return css`
      background-color: #e55f5f;
      border-color: transparent;

      & ${ButtonText} {
        color: white;
      }
    `;
  }
};

const ContainerOutlineStyles = (color: ButtonColorScheme) => {
  if (color === "PRIMARY") {
    return css`
      background-color: white;
      border-color: ${ColorTheme.primaryColor};

      svg {
        width: 12px;
        height: 12px;
        fill: #1aa7e8;
      }

      & ${ButtonText} {
        color: ${ColorTheme.primaryColor};
      }
    `;
  }

  if (color === "SECONDARY") {
    return css`
      background-color: white;
      border-color: #ddd;

      & ${ButtonText} {
        color: black;
      }
    `;
  }
};

const ConatinerGhostStyles = (color: ButtonColorScheme) => {
  if (color === "PRIMARY") {
    return css`
      background-color: transparent;
      border: none;

      & ${ButtonText} {
        color: ${ColorTheme.primaryColor};
      }
    `;
  }

  if (color === "SECONDARY") {
    return css`
      background-color: transparent;
      border: none;
      :hover {
        background-color: #f5f5f5a1;
      }

      & ${ButtonText} {
        color: black;
      }
    `;
  }

  if (color === "DANGER") {
    return css`
      background-color: transparent;
      border: none;

      & ${ButtonText} {
        color: #e55f5f;
      }
    `;
  }
};

const Container = styled.div<ContainerProps>`
  display: inline-block;
  padding: 0px 15px;
  height: ${FormControlsTheme.controlHeight.default}px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  cursor: pointer;

  ${({ $loading, $disabled }) =>
    ($loading || $disabled) &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  ${({ type, color }) => {
    switch (type) {
      case "FLAT": {
        return ContainerFlatStyles(color);
      }
      case "OUTLINE": {
        return ContainerOutlineStyles(color);
      }
      case "GHOST": {
        return ConatinerGhostStyles(color);
      }
    }
  }};
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-right: 10px;
  font-size: 0px;
`;

const LoaderContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface Props {
  text: string;
  type?: ButtonType;
  color: ButtonColorScheme;
  containerStyle?: CSSProperties;
  icon?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export function Button(props: Props) {
  const {
    text,
    type,
    color,
    containerStyle,
    icon,
    loading = false,
    disabled = false,
    onClick,
  } = props;

  const onClickHandler = () => {
    !loading && !disabled && onClick && onClick();
  };

  return (
    <Container
      type={type || "FLAT"}
      onClick={onClickHandler}
      color={color}
      style={{ ...containerStyle }}
      $loading={loading}
      $disabled={disabled}
      className={props.className}
    >
      {!loading && (
        <ButtonContainer>
          {icon && <IconContainer>{icon}</IconContainer>}
          <ButtonText>{text}</ButtonText>
        </ButtonContainer>
      )}

      {loading && (
        <LoaderContainer>
          <LoadingIndicator />
        </LoaderContainer>
      )}
    </Container>
  );
}
