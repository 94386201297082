import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../store/RootReducer";
import { Button } from "../../../../components/Button";
import { LabelFieldContainer, FormControlLabel } from "../../../../components/Layout";
import { InputField } from "../../../../wallet/presentation/pages/WalletLoginPage/style";
import { Bank } from "../../../domain/repositories/BanksRepository";
import { setCreditPageSubTitle, setCreditPageTitle } from "../../store/offers/actions";
import { requestBanks, requestShowLoanOffers, setBankDetails } from "../../store/selectBankPage/actions";
import { 
    BankDetailsProps,
    StoreDispatchProps,
    StoreStateProps, 
} from "../../store/selectBankPage/types";
import Placeholder from "../Offers/components/GetOffers/Placeholder";
import { FormContainer } from "../OtpPage/style";
import { 
    SelectBankContainer,
    BanksDropdownContainer,
    BanksDropdownHeaderContainer,
    BanksDropdownItem,
    BanksDropdownBody,
    StyledSkipButton,
} from "./selectBankPage.style";
import { ReactComponent as DownIcon } from "./icons/down.svg";

const imageIcon = <DownIcon/>

interface OwnProps {}
type Props = StoreDispatchProps & OwnProps & StoreStateProps;

function SelectBankPage(props: Props) {
    const {
        setPageTitle,
        setPageSubTitle,
        getBanks,
        banks,
        banksLoading,
        setBankDetails,
        setShowLoanOffers,
    } = props;

    const [finInstitution, setFinInstitution] = useState<string>('');
    const [accountNumber, setAccountNumber] = useState<string>('');
    const [selectedBank, setSelectedBank] = useState<string>('');
    const [bankCode, setBankCode] = useState<string>('');
    const [disableSubmit, setDisbleSubmit] = useState<boolean>(true);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filtering, setFiltering] = useState<boolean>(false);
    const [filteredBanks, setFilteredBanks] = useState([] as typeof banks);
    const dropDownRef = useRef(null as any);
    const dropDownContainerRef = useRef(null as any);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    const handleItemClick = (item: Bank) => {
        setSelectedBank(item.code);
        setBankCode(item.bankCode);
        toggleDropdown();
        setFinInstitution(item.name);
        setFiltering(false);
    }

    const handleDropDownInputBlur = () => {
        const bankSelected = banks && banks.find(bank => bank.code === selectedBank);

        if(bankSelected){
            setFinInstitution(bankSelected.name);
            return;
        }

        setFinInstitution("");

    }

    const handleFilterBanks = (e: React.ChangeEvent<HTMLInputElement>) => {
        const filterBanks = banks.filter(bank => bank.name.toLowerCase().includes(e.target.value.toLowerCase()));
        setFilteredBanks(filterBanks);
    };

    const handleDropDownInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFinInstitution(e.target.value);

        if(e.target.value){
            setFiltering(true);
        } else {
            setFiltering(false);
        }

        handleFilterBanks(e);
    };

    const handleAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>)=> {
        const { value } = e.target;
        if(value.length < 11){
            setAccountNumber(value);
        };
    }

    const handleShowLoanOffers = () => {
        if(!finInstitution || !accountNumber) return;
        setShowLoanOffers(true);
    };

    useEffect(() => {
        if(selectedBank && accountNumber.length === 10){
            setDisbleSubmit(false);
        } else {
            setDisbleSubmit(true);
        }

        setBankDetails({finInstitution: selectedBank, accountNumber, bankCode});

    }, [accountNumber, bankCode, selectedBank, setBankDetails]);


    useEffect(()=> {
        if(banks.length === 0){
            getBanks();
        }
    }, [banks.length, getBanks]);

    useEffect(() => {
        if(banksLoading){
            setPageTitle("Loading...");
            setPageSubTitle("");
        } else {
            setPageTitle("Pay on Credit");
            setPageSubTitle("Enter your bank details below");
        }
    }, [banksLoading, setPageSubTitle, setPageTitle]);

    useEffect(()=> {
        if (!banksLoading && banks.length === 0) {
            setShowLoanOffers(true);
        }
    }, [banks.length, banksLoading, setShowLoanOffers]);

    useEffect(() => {
        const handleCloseDropDown =(e: MouseEvent) => {
            if (!dropDownRef.current?.contains(e.target) && !dropDownContainerRef.current?.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleCloseDropDown);
        return () => document.removeEventListener('mousedown', handleCloseDropDown);
    }, []);

    if(banksLoading){
        return <Placeholder />;
    }

    return (
        <SelectBankContainer>
            <FormContainer>
            <LabelFieldContainer>
                    <FormControlLabel>Financial Institution</FormControlLabel>
                    <BanksDropdownContainer role="dropdown">
                        <BanksDropdownHeaderContainer onClick={toggleDropdown} ref={dropDownContainerRef}>
                            <div className="fin-institution">
                                <input
                                    type="text"
                                    name="fin-institution"
                                    placeholder="Select an option"
                                    value={finInstitution}
                                    onBlur={handleDropDownInputBlur}
                                    onChange={handleDropDownInputChange}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="icon">
                                {imageIcon}
                            </div>
                        </BanksDropdownHeaderContainer>
                        {
                            isOpen && 
                            <BanksDropdownBody ref={dropDownRef}>
                                {
                                    banks && banks.length > 0 ?
                                    (
                                        !filtering && banks.length > 0 ?
                                        banks.map(bank => (
                                            <BanksDropdownItem key={bank.id} onClick={() => handleItemClick(bank)} id={bank.code}>
                                                <span>{bank.name}</span>
                                                <span className={`dropdown-item-dot ${selectedBank === bank.code && 'selected'}`}>&#x2713; </span>
                                            </BanksDropdownItem>
                                        )) : 
                                        filtering && filteredBanks.length > 0 ?
                                        filteredBanks.map(bank => (
                                            <BanksDropdownItem key={bank.id} onClick={() => handleItemClick(bank)} id={bank.code}>
                                                <span>{bank.name}</span>
                                                <span className={`dropdown-item-dot ${selectedBank === bank.code && 'selected'}`}>&#x2713; </span>
                                            </BanksDropdownItem>
                                        )) : 
                                        <BanksDropdownItem key={"no-data"}>
                                            <span className="no-data">{`'${finInstitution}' not found`}</span>
                                        </BanksDropdownItem>
                                    ) :
                                    <BanksDropdownItem key={"no-data"}>
                                        <span className="no-data">{banksLoading ? 'Loading banks...' : 'Could not fetch banks'}</span>
                                    </BanksDropdownItem>
                                }
                            </BanksDropdownBody>
                        }
                    </BanksDropdownContainer>
                </LabelFieldContainer>

                <LabelFieldContainer>
                    <FormControlLabel>Account Number</FormControlLabel>
                    <InputField
                        type="number"
                        value={accountNumber}
                        onChange={handleAccountNumberChange}
                        data-testid="account-number"
                        autoComplete='off'
                    />
                </LabelFieldContainer>

                <div>
                    <Button
                        disabled={disableSubmit}
                        onClick={handleShowLoanOffers}
                        text="Show loan deals"
                        color="PRIMARY"
                        containerStyle={{ width: "80%" }}
                    />
    
                    <StyledSkipButton onClick={() => setShowLoanOffers(true)}>
                        Skip
                    </StyledSkipButton>
                </div>
            </FormContainer>
        </SelectBankContainer>
    );
};

const mapStateToProps = (state: AppState): StoreStateProps => {
    return {
      banks: state.credit.selectBanksPage.banks,
      banksLoading: state.credit.selectBanksPage.banksLoading,
      showLoanOffers: state.credit.selectBanksPage.showLoanOffers,
    };
};

const mapDispatchToProps = (dispatch: (action: any) => void): StoreDispatchProps => {
    return {
        setPageTitle: (title: string) => dispatch(setCreditPageTitle(title)),
        setPageSubTitle: (subtitle: string) => dispatch(setCreditPageSubTitle(subtitle)),
        setShowLoanOffers: (status: boolean) => dispatch(requestShowLoanOffers(status)),
        getBanks: () => dispatch(requestBanks()),
        setBankDetails: (bankDetials: BankDetailsProps) => dispatch(setBankDetails(bankDetials)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectBankPage);