import { AxiosError } from "axios";
import { takeLatest, put } from "redux-saga/effects";
import walletRepository, {
  SigninUserResponse
} from "../../../domain/repositories/WalletRepository";

import { TRIGGER_SIGNIN_USER, TriggerSigninUser } from "./types";

import { signinUserPending, signinUserSuccess, signinUserError } from "./actions";

function* handleTriggerSigninUser(action: TriggerSigninUser) {
  const { payload } = action;
  const { request } = payload;

  yield put(signinUserPending());

  let response: SigninUserResponse;

  try {
    response = yield walletRepository.signin(request);
  } catch (err) {
    yield put(signinUserError(err as AxiosError));
    return;
  }

  yield put(signinUserSuccess(response));
}

export function* signinUserSaga() {
  yield takeLatest(TRIGGER_SIGNIN_USER, handleTriggerSigninUser);
}
