import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const LoaderAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const FadeDelay = keyframes`
  0% {
    opacity: 0;
  };
  39% {
    opacity: 0;
  };
  100% {
    opacity: 0;
  };
  40% {
    opacity: 1;
  };
`;



const Container = styled.div`
  position: relative;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: ${LoaderAnimation} 0.2s linear;
`;

interface FadingCircleProps {
  color: string;
}

const FadingCircle = styled.div<FadingCircleProps>`
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle;

  div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  div::before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: ${({ color }) => color}; 
    border-radius: 100%;
    animation: ${FadeDelay} 1.2s infinite ease-in-out both;
  }

  div:nth-child(2) {
    transform: rotate(30deg);
    
    &::before {
      animation-delay: -1.1s;
    }
  }

  div:nth-child(3) {
    transform: rotate(60deg);
    
    &::before {
      animation-delay: -1.0s;
    }
  }

  div:nth-child(4) {
    transform: rotate(90deg);
    
    &::before {
      animation-delay: -0.9s;
    }
  }

  div:nth-child(5) {
    transform: rotate(120deg);
    
    &::before {
      animation-delay: -0.8s;
    }
  }

  div:nth-child(6) {
    transform: rotate(150deg);
    
    &::before {
      animation-delay: -0.7s;
    }
  }

  div:nth-child(7) {
    transform: rotate(180deg);
    
    &::before {
      animation-delay: -0.6s;
    }
  }

  div:nth-child(8) {
    transform: rotate(210deg);
    
    &::before {
      animation-delay: -0.5s;
    }
  }

  div:nth-child(9) {
    transform: rotate(240deg);
    
    &::before {
      animation-delay: -0.4s;
    }
  }

  div:nth-child(10) {
    transform: rotate(270deg);
    
    &::before {
      animation-delay: -0.3s;
    }
  }

  div:nth-child(11) {
    transform: rotate(300deg);
    
    &::before {
      animation-delay: -0.2s;
    }
  }

  div:nth-child(12) {
    transform: rotate(330deg);      
    
    &::before {
      animation-delay: -0.1s;
    }
  }
`;

interface Props {
  size?: number;
  color?: string;
}

export default function LoadingIndicator(props: Props) {
  const { size, color = 'white' } = props;

  return (
    <Container style={{ width: size, height: size }}>
      <FadingCircle color={color}>
        {Array.from({ length: 12 }).map((_, index) => {
          return <div key={index} />
        })}
      </FadingCircle>
    </Container>
  );
}