import { GetBinConfigurationRequest, GetBinConfigurationResponse } from "../../../domain/repositories/CardRepository";
import {
  TRIGGER_GET_BIN_CONFIGURATION,
  TriggerGetBinConfiguration,

  GET_BIN_CONFIGURATION_PENDING,
  GetBinConfigurationPending,

  GET_BIN_CONFIGURATION_SUCCESS,
  GetBinConfigurationSuccess,

  GET_BIN_CONFIGURATION_ERROR,
  GetBinConfigurationError,

  RESET_GET_BIN_CONFIGURATION,
  ResetGetBinConfiguration,
} from "./types";

export const triggerGetBinConfiguration = (request: GetBinConfigurationRequest): TriggerGetBinConfiguration => {
  return {
    type: TRIGGER_GET_BIN_CONFIGURATION,
    payload: { request }
  };
};

export const getBinConfigurationPending = (): GetBinConfigurationPending => {
  return {
    type: GET_BIN_CONFIGURATION_PENDING
  };
};

export const getBinConfigurationSuccess = (response: GetBinConfigurationResponse): GetBinConfigurationSuccess => {
  return {
    type: GET_BIN_CONFIGURATION_SUCCESS,
    payload: {response}

  };
};

export const getBinConfigurationError = (errorMessage?: string): GetBinConfigurationError => {
  return {
    type: GET_BIN_CONFIGURATION_ERROR,
    payload: errorMessage
  };
};

export const resetGetBinConfiguration = (): ResetGetBinConfiguration => {
  return {
    type: RESET_GET_BIN_CONFIGURATION,
  };
};