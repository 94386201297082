import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import usePrevious from '../../../../../hooks/usePrevious';
import { AppState } from '../../../../../store/RootReducer';
import { PaymentParams } from '../../../../payment/presentation/store/paymentProperties/types';
import { PAYMENT_METHODS__ROOT, PAYMENT__WALLET_ROOT } from '../../../../Routes';
import { SigninUserRequest } from '../../../domain/repositories/WalletRepository';
import { triggerSigninUser } from '../../store/signinUser/actions';

import { PageTitle, PageSubTitle, LabelFieldContainer, FormControlLabel, FieldErrorText } from '../../../../components/Layout';
import BackControl from '../../../../components/BackControl';
import { Button } from '../../../../components/Button';
import { TriggerShowNotificationPayload } from '../../../../components/NotificationWidget/store/types';
import { triggerShowNotification } from '../../../../components/NotificationWidget/store/actions';
import { FieldValidationResult } from '../../../../../core/types';
import { Container, Form, InputField, PasswordContainer, PasswordControl } from './style';




const USERNAME_EMPTY_MSG = 'Username is required';

const getIsUsernameValid = (username: string): FieldValidationResult => {
  if (username === '') {
    return { isValid: false, message: USERNAME_EMPTY_MSG };
  }

  return { isValid: true };
}

const PASSWORD_EMPTY_MSG = 'Password is required';

const getIsPasswordValid = (password: string): FieldValidationResult => {
  if (password === '') {
    return { isValid: false, message: PASSWORD_EMPTY_MSG };
  }

  return { isValid: true };
}

interface StoreStateProps {
  paymentParams: PaymentParams;
  signinPending: boolean;
  signinError: AxiosError | null;
}

interface StoreDispatchProps {
  triggerShowNotification: (payload: TriggerShowNotificationPayload) => void;
  signinUser: (request: SigninUserRequest) => void;
}

interface OwnProps {

}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function WalletLoginPage(props: Props) {
  const {
    paymentParams,
    signinPending,
    signinError,

    triggerShowNotification,
    signinUser,
  } = props;

  const history = useHistory();

  const prevSigninPending = usePrevious(signinPending);

  const [usernameValue, setUsernameValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(val => !val);

  const [showFormErrors, setShowFormErrors] = useState(false);

  const navigateToPaymentMethods = () => {
    history.push(PAYMENT_METHODS__ROOT);
  }

  const {
    isValid: isUsernameValid,
    message: usernameErrorMessage
  } = getIsUsernameValid(usernameValue);

  const {
    isValid: isPasswordValid,
    message: passwordErrorMessage
  } = getIsPasswordValid(passwordValue);



  const loginButtonHandler = () => {
    setShowFormErrors(true);

    if (!isUsernameValid || !isPasswordValid) {
      return;
    }

    const { merchantCode, payableCode, paymentId } = paymentParams;

    signinUser({
      merchantCode,
      payableCode,
      paymentId,
      username: usernameValue,
      password: passwordValue
    });
  }

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') loginButtonHandler();
  };

  useEffect(() => {
    if (!(!signinPending && prevSigninPending)) return;

    if (signinError) {
      if (signinError.response?.status === 403) {
        triggerShowNotification({
          type: 'ERROR',
          message: 'Invalid login credentials. Please try again.',
        });
        return;
      }

      triggerShowNotification({ type: 'ERROR' });
      return;
    };

    history.push(PAYMENT__WALLET_ROOT);
  }, [signinPending]);

  return (
    <Container>
      <BackControl
        text="Change payment method"
        onClick={navigateToPaymentMethods}
      />

      <PageTitle>Pay with Quickteller</PageTitle>
      <PageSubTitle>Enter your account details below</PageSubTitle>

      <Form>
        <LabelFieldContainer>
          <FormControlLabel>Email or Phone Number</FormControlLabel>

          <InputField
            value={usernameValue}
            onChange={event => setUsernameValue(event.target.value)}
            onKeyUp={onKeyUpHandler}
            autoComplete='off'
          />

          {showFormErrors && !isUsernameValid && (
            <FieldErrorText>{usernameErrorMessage}</FieldErrorText>
          )}
        </LabelFieldContainer>

        <LabelFieldContainer>
          <FormControlLabel>Password</FormControlLabel>

          <PasswordContainer>
            <InputField
              type={showPassword ? 'text' : 'password'}
              value={passwordValue}

              onKeyUp={onKeyUpHandler}
              onChange={event => setPasswordValue(event.target.value)}
              autoComplete='off'
            />

            <PasswordControl onClick={toggleShowPassword}>
              {showPassword ? 'HIDE' : 'SHOW'}
            </PasswordControl>

          </PasswordContainer>

          {showFormErrors && !isPasswordValid && (
            <FieldErrorText>{passwordErrorMessage}</FieldErrorText>
          )}
        </LabelFieldContainer>

        <Button
          text={"Login"}
          color="PRIMARY"
          loading={signinPending}
          onClick={loginButtonHandler}
          containerStyle={{ width: '100%' }}
        />
      </Form>

    </Container>
  );
}


const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,
  signinPending: state.wallet.userWalletData.loading,
  signinError: state.wallet.userWalletData.loadingError,
});

const mapDispatchToProps = (dispatch: (action: any) => void): StoreDispatchProps => ({
  triggerShowNotification(payload: TriggerShowNotificationPayload) {
    dispatch(triggerShowNotification(payload))
  },
  signinUser(request: SigninUserRequest) {
    dispatch(triggerSigninUser(request))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletLoginPage);