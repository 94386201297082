import { AxiosError } from "axios";
import { put, call, takeLatest } from "redux-saga/effects";
import walletRepository, {
  SigninUserResponse
} from "../../../../wallet/domain/repositories/WalletRepository";
import { SigninSuccessfulResponse } from "../offers/types";
import {
  verveWalletSigninError,
  verveWalletSigninPending,
  verveWalletSigninSuccessful
} from "./actions";
import { TriggerVerveWalletSignin, TRIGGER_VERVE_WALLET_SIGNIN } from "./types";

function* verveWalletSignin(action: TriggerVerveWalletSignin) {
  const { payload } = action;
  const { request, onSuccessful } = payload;
  yield put(verveWalletSigninPending());

  let response: SigninUserResponse;
  try {
    response = yield walletRepository.signin(request);

    if (onSuccessful && typeof onSuccessful === "function") {
      if (response && response.user && !response.user.mobileNo) {
        if (Number(request.username)) response.user.mobileNo = request.username;
      }

      let data: SigninSuccessfulResponse = {
        userData: response.user,
        walletCards: response.paymentMethods
      };
      yield call(onSuccessful, data);
    }
  } catch (err) {
    yield put(verveWalletSigninError(err as AxiosError));
  }

  yield put(verveWalletSigninSuccessful());
}

export function* verveWalletSigninSaga() {
  yield takeLatest(TRIGGER_VERVE_WALLET_SIGNIN, verveWalletSignin);
}
