import httpClient from "../../../../core/api/httpClient";
import { SERVICE_BASE_URL } from "../../../../config/properties";

interface InitiatePayload {
  merchantCode: string;
  payableCode: string;
  currencyCode: string;
  transactionReference: string;
  paymentId: number;
  partyId: string;
}

interface StatusPayload {
  merchantCode: string;
  transactionReference: string;
}

const initiate = async (data: InitiatePayload) => {
  return httpClient.post<InitiatePayload, any>(
    `${SERVICE_BASE_URL}/api/v1/momo/initialize`,
    data
  );
};

const authorize = async (data: StatusPayload) => {
  return httpClient.post<StatusPayload, any>(
    `${SERVICE_BASE_URL}/api/v1/momo/status`,
    data
  );
};

const MomoRepository = {
  initiate,
  authorize,
};

export default MomoRepository;
