import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';

import RequestState from '../../../../payment/domain/RequestState';
import {
	RequeryResponse,
	requeryPayment,
} from '../../../domain/repositories/CongoRepository';
import useIsMountedRef from '../../../../../hooks/useIsMountedRef';
import { getAxiosErrorData } from '../../../../../core/api/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../store/RootReducer';
import { completeTransaction } from '../../../../payment/presentation/store/paymentStatus/actions';
import { triggerShowNotification } from '../../../../components/NotificationWidget/store/actions';
import { asyncDelay } from '../../../../../core/util/asyncUtil';
import useCountdownTimer from '../../../../../hooks/useCountdownTimer';
import { ssGet } from '../utils/sessionStorage';

export default function useRequery() {
	const [, setIsTimeOut] = useState(false);

	const { paymentParams } = useSelector((state: AppState) => ({
		paymentParams: state.payment.paymentProperties.paymentParams,
	}));

	const handleTimeOut = () => {
		setIsTimeOut(true);
	};

	const { time } = useCountdownTimer({
		timeout: 30000,
		onCompleted: handleTimeOut,
	});

	const dispatch = useDispatch();

	const [req, setReq] = useState<RequestState<RequeryResponse>>({
		loading: false,
	});

	const isMounted = useIsMountedRef().current;

	const message = 'Unable to requery payment. Please try again';

	let pollingTimeout = time.seconds;

	const handleError = useCallback(
		(error: any) => {
			if (!isMounted) return;

			setReq({
				loading: false,
				error: error
					? getAxiosErrorData(error as AxiosError)?.toString()
					: message,
			});

			dispatch(triggerShowNotification({ type: 'ERROR' }));
		},
		[dispatch, isMounted]
	);

	let billerName = ssGet('selectedBiller');

	const billerTernary = (payParams: any) => {
		return billerName === 'AFRIMONEY'
			? '3512224'
			: payParams.paymentId.toString();
	};

	const paymentIdValue = (payParams: any) => {
		return process.env.NODE_ENV === 'test' ||
			process.env.NODE_ENV === 'development'
			? billerTernary(payParams)
			: payParams.paymentId.toString();
	};

	const requeryTransaction = useCallback(async () => {
		if (!paymentParams) return;
		setReq({ loading: true });

		try {
			const res = await requeryPayment({
				paymentId: paymentIdValue(paymentParams),
				mobileWalletProvider: billerName.toUpperCase(),
			});

			if (isMounted) {
				const responseCode = res.data.code;

				switch (responseCode) {
					case '09':
						await asyncDelay(3000);
						requeryTransaction();
						return;
					case '00':
						dispatch(completeTransaction({ responseCode: res.data.code }));
						break;
					case '02':
						dispatch(completeTransaction({ responseCode: res.data.code }));
						setReq({ loading: false });
						break;
					default:
						dispatch(completeTransaction({ responseCode: res.data.code }));
						break;
				}
				setReq({ loading: false, data: res.data.data });
			}
		} catch (error) {
			handleError(error || message);
			setReq({ loading: false });
		}
	}, [paymentParams, isMounted, dispatch, handleError]);

	return { getStatus: requeryTransaction, ...req, pollingTimeout };
}
