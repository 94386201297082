import * as Types from "./types"

const initialState: Types.VerveWalletSigninState = {
    signinError: null,
    signinPending: false,
    displayVerveWalletSignin: false
}

export default function VerveWalletSigninReducer(state = initialState, action: Types.VerveWalletSigninActionTypes): Types.VerveWalletSigninState {

    switch(action.type) {
        case Types.VERVE_WALLET_SIGNIN_PENDING: {
            return { ...state, signinPending: true }
        }

        case Types.VERVE_WALLET_SIGNIN_SUCCESSFUL: {
            return { ...state, signinPending: false, signinError: null }
        }

        case Types.VERVE_WALLET_SIGNIN_ERROR: {
            const { payload } = action
            const { error } = payload
            return { ...state, signinPending: false, signinError: error }
        }

        case Types.DISPLAY_VERVE_WALLET_SIGNIN: {
            return { ...state, displayVerveWalletSignin: true }
        }

        case Types.HIDE_VERVE_WALLET_SIGNIN: {
            return { ...state, displayVerveWalletSignin: false }
        }

        case Types.RESET_VERVE_WALLET_SIGNIN: {
            return initialState
        }

        default: {
            return state
        }
    }
}