import * as Types from "./types";
import { OffersScreenType } from "./types";

const initialState: Types.OffersPageState = {
  offers: [],
  userData: null,
  customerId: null,
  walletCards: null,
  authenticated: false,

  getOffersError: null,
  acceptOfferError: null,

  getOffersPending: false,
  acceptOfferPending: false,

  pageTitle: null,
  pageSubtitle: null,

  screenType: OffersScreenType.offer,

  acceptOfferRequest: null,
  transactionResponse: null,

  bankProvider: false,

  selectedOffer: null,
};

export default function OffersPageReducer(
  state: Types.OffersPageState = initialState,
  action: Types.OffersPageActionTypes
): Types.OffersPageState {
  switch (action.type) {
    case Types.SIGNIN_SUCCESSFUL: {
      const { payload } = action;
      return { ...state, authenticated: true, ...payload };
    }

    case Types.GET_OFFERS_PENDING: {
      return { ...state, getOffersPending: true };
    }

    case Types.GET_OFFERS_SUCCESSFUL: {
      const { payload } = action;
      const offers = Object.freeze(payload.offers);
      return { ...state, offers, getOffersPending: false, getOffersError: null };
    }

    case Types.GET_OFFERS_ERROR: {
      const { payload } = action;
      const { error } = payload;
      return { ...state, getOffersPending: false, getOffersError: error };
    }

    case Types.ACCEPT_OFFER_PENDING: {
      return { ...state, acceptOfferPending: true };
    }

    case Types.ACCEPT_OFFER_SUCCESSFUL: {
      const { payload } = action;
      const { transactionResponse } = payload;
      return {
        ...state,
        transactionResponse,
        acceptOfferPending: false,
        acceptOfferError: null
      };
    }

    case Types.ACCEPT_OFFER_ERROR: {
      const { payload } = action;
      const { error } = payload;
      return { ...state, acceptOfferPending: false, acceptOfferError: error };
    }

    case Types.SET_CREDIT_PAGE_TITLE: {
      const { payload } = action;
      const { title } = payload;
      return { ...state, pageTitle: title };
    }

    case Types.SET_CREDIT_PAGE_SUBTITLE: {
      const { payload } = action;
      const { subtitle } = payload;
      return { ...state, pageSubtitle: subtitle };
    }

    case Types.SET_SELECTED_OFFER: {
      const { payload } = action;
      const { selectedOffer } = payload;
      return { ...state, selectedOffer };
    }

    case Types.SET_BANK_PROVIDER: {
      const { payload } = action;
      return { ...state, bankProvider: payload };
    }

    case Types.DISPLAY_SCREEN: {
      const { payload } = action;
      const { screenType } = payload;
      return { ...state, screenType };
    }

    case Types.UPDATE_ACCEPT_OFFER_REQUEST: {
      const { payload } = action;
      const { request } = payload;
      return { ...state, acceptOfferRequest: request };
    }

    case Types.RESET_OFFERS_PAGE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
