import { takeLatest, put } from "redux-saga/effects";

import transferRepository, { GetTransactionStatusResponse } from "../../../domain/repositories/TransferRepository";

import {
  TRIGGER_GET_TRANSACTION_STATUS,
  TriggerGetTransactionStatus
} from "./types";

import {
  getTransactionStatusPending,
  getTransactionStatusSuccess,
  getTransactionStatusError
} from "./actions";
import { getAxiosErrorData } from "../../../../../core/api/helpers";
import { AxiosError } from "axios";


function* handleTriggerGetTransactionStatus(action: TriggerGetTransactionStatus) {
  const { payload } = action;
  const { request } = payload;

  yield put(getTransactionStatusPending());

  let response: GetTransactionStatusResponse;

  try {
    response = yield transferRepository.getTransactionStatus(request);

    yield put(getTransactionStatusSuccess(response));
  } catch (err) {
    yield put(getTransactionStatusError());
    const error = getAxiosErrorData(err as AxiosError);
    const errorMessage = error ?? undefined;
    console.log({err, error, errorMessage});

    yield put(getTransactionStatusError(errorMessage));
    return;
  }

  yield put(getTransactionStatusSuccess(response));
}


export function* getTransactionStatusSaga() {
  yield takeLatest(TRIGGER_GET_TRANSACTION_STATUS, handleTriggerGetTransactionStatus);
}
