import { useState } from "react";
import MomoRepository from "../../../../domain/repositories/MomoRepository";
import { PaymentParams } from "../../../../../payment/presentation/store/paymentProperties/types";

export const useAuthorise = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const authorize = async (params: PaymentParams) => {
    try {
      setLoading(true);
      const res = await MomoRepository.authorize({
        merchantCode: params.merchantCode,
        transactionReference: params.merchantTransactionReference,
      });
      if (res.status === 200 && res.data.responseCode === "00") {
        setSuccess(true);
      } else {
        setError(
          "Something went wrong...Please go to the previous page and retry"
        );
      }
    } catch (error) {
      setLoading(false);
      setError(
        "Something went wrong...Please go to the previous page and retry"
      );
    }
  };

  return {
    loading,
    authorize,
    success,
    error,
  };
};
