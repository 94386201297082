import React, { ReactNode } from "react";
import { ReactComponent as CircleCaution } from "../../assets/icons/circle-caution.svg";
import styled from "styled-components";
import MethodItem from "./MethodItem";
import OPayIconPath from "../payment/presentation/pages/SelectPaymentMethodPage/icons/opay.svg";
import usePaymentChannelHelper from "../../hooks/usePaymentChannelHelper";
import useOnMethodSelect from "../../hooks/useOnMethodSelect";
import { PaymentChannelType } from "../payment/presentation/store/paymentProperties/types";
import RetryButton from "./RetryButton";

interface Props {
  icon?: ReactNode;
  title?: string;
  description?: string;
}

const Container = styled.div`
  .failed-payment-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .failed-payment-description {
    text-align: center;
    margin: 15px 0;
    font-size: 14px;
    color: #181b24;
  }

  .retry-btn p {
    font-weight: 600;
  }

  .failed-payment-divider {
    display: flex;
    align-items: center;
    margin-top: 30px;

    hr {
      width: 5%;
      border: 0;
      height: 1px;
      background-color: #dddddd;
    }

    &-text {
      font-size: 14px;
    }
  }

  .alternate-channels {
    max-height: 45vh;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 6px; /* width of the entire scrollbar */
    }

    ::-webkit-scrollbar-track {
      /* background: orange; color of the tracking area */
    }

    ::-webkit-scrollbar-thumb {
      background-color: #dddddd;
      border-radius: 20px;
      /* border: 3px solid orange; */
    }
  }
`;

const FailedPayment = (props: Props) => {
  const { icon, title, description } = props;

  const { enabledPaymentMinusCurrentData, currentPaymentChannel } = usePaymentChannelHelper();
  const onMethodSelect = useOnMethodSelect();

  return (
    <Container className="failed-payment">
      <div className="failed-payment-info">
        {icon || <CircleCaution />}

        <h1 className="failed-payment-title">
          {title ?? "Transaction Failed"}
        </h1>

        <div className="failed-payment-description">
          {description ??
            "The payment could not be completed with the selected payment method. Please choose an alternative method or try again."}
        </div>

        <RetryButton
          paymentChannel={currentPaymentChannel as PaymentChannelType}
        />
      </div>

      <div className="failed-payment-divider">
        <hr />
        <div className="failed-payment-divider-text">
          or select an alternative payment method
        </div>
        <hr />
      </div>

      <div className="alternate-channels">
        {enabledPaymentMinusCurrentData.map((method) => {
          const { type, title, imageIcon, description, plainIcon } = method;

          return (
            <MethodItem
              key={type}
              type={type}
              title={title}
              description={description}
              imageIcon={type !== "OPAY" ? imageIcon : undefined}
              iconPath={type === "OPAY" ? OPayIconPath : undefined}
              plainIcon={plainIcon}
              onSelect={() => onMethodSelect(type)}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default FailedPayment;
