import {
  GET_TOKEN_ERROR,
  GET_TOKEN_PENDING,
  GET_TOKEN_SUCCESS,
  RESET_GET_TOKEN,
  GetTokenState,
  GetTokenActionsType,
} from "./types";

const initialState: GetTokenState = {
  getTokenError: false,
  getTokenPending: false,
  getTokenResponse: null,
};

export function GetTokenReducer(
  state: GetTokenState = initialState,
  action: GetTokenActionsType
): GetTokenState {
  switch (action.type) {
    case GET_TOKEN_PENDING: {
      return {
        ...state,
        getTokenPending: true,
        getTokenError: false,
      };
    }
    case GET_TOKEN_ERROR: {
      return {
        ...state,
        getTokenPending: false,
        getTokenError: true,
      };
    }
    case GET_TOKEN_SUCCESS: {
      const { payload } = action;
      const { response } = payload;
      return {
        getTokenPending: false,
        getTokenError: false,
        getTokenResponse: response,
      };
    }
    case RESET_GET_TOKEN: {
      return initialState;
    }
    default:
      return state;
  }
}
