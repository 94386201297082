import { SERVICE_BASE_URL } from "../../../../config/properties";
import httpClient from "../../../../core/api/httpClient";
import {
  GetBinConfigurationRequest,
  GetBinConfigurationResponse,
  MakePaymentRequest,
  MakePaymentResponse,
} from "../../../card/domain/repositories/CardRepository";

export async function getBinConfiguration(
  request: GetBinConfigurationRequest & { googlePayToken: string }
) {
  const response = await httpClient.post<GetBinConfigurationResponse>(
    `${SERVICE_BASE_URL}/api/v1/merchantbins`,
    request
  );

  return response.data;
}

export async function makePayment(request: MakePaymentRequest) {
  const response = await httpClient.post<MakePaymentResponse>(
    `${SERVICE_BASE_URL}/api/v1/pay`,
    request
  );

  return response.data;
}
