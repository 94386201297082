import React from "react";
import { PaymentChannelType } from "../payment/presentation/store/paymentProperties/types";
import { Button } from "./Button";
import { ReactComponent as RetryIcon } from "../../assets/icons/retry.svg";
import usePaymentChannelHelper from "../../hooks/usePaymentChannelHelper";
import useOnMethodSelect from "../../hooks/useOnMethodSelect";
import { resetMakePayment } from "../card/presentation/store/makePayment/actions";
import { useDispatch } from "react-redux";
import { resetPaymentStatus } from "../payment/presentation/store/paymentStatus/actions";

interface Props {
  paymentChannel?: PaymentChannelType;
}

const RetryButton = (props: Props) => {
  const { paymentChannel } = props;
  const { currentPaymentChannel } = usePaymentChannelHelper();
  const onMethodSelect = useOnMethodSelect();
  const dispatch = useDispatch();

  const channel =
    paymentChannel ?? (currentPaymentChannel as PaymentChannelType);

  const retryCurrentPayment = () => {
    console.log({ channel });

    onMethodSelect(channel);
    dispatch(resetMakePayment());
    dispatch(resetPaymentStatus());
  };

  return (
    <Button
      type="OUTLINE"
      color="SECONDARY"
      text="Retry payment"
      className="retry-btn"
      icon={<RetryIcon />}
      onClick={retryCurrentPayment}
    />
  );
};

export default RetryButton;
