import { combineReducers } from "redux";

import { CachedCardDetailsReducer } from "./cachedCardDetails/reducer";
import { GetBinConfigurationReducer } from "./getBinConfiguration/reducer";
import { MakePaymentReducer } from "./makePayment/reducer";
import { AuthenticateOtpReducer } from "./authenticateOtp/reducer";
import { AuthenticateCardinalReducer } from "./authenticateCardinal/reducer";

export const CardReducer = combineReducers({
  cachedCardDetails: CachedCardDetailsReducer,
  getBinConfiguration: GetBinConfigurationReducer,
  makePayment: MakePaymentReducer,
  authenticateOtp: AuthenticateOtpReducer,
  authenticateCardinal: AuthenticateCardinalReducer,
});