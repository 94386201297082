import { PaymentChannelType } from "../paymentProperties/types";

export const SET_PAYMENT_CHANNEL = 'PAYMENT.CURRENT_PAYMENT_CHANNEL.SET_PAYMENT_CHANNEL';

export interface SetPaymentChannel {
  type: typeof SET_PAYMENT_CHANNEL;
  payload: PaymentChannelType;
}

export type CurrentPaymentChannelActionType = SetPaymentChannel;

export type CurrentPaymentChannelState = PaymentChannelType | null;