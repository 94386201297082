import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { AppState } from "../../../../../store/RootReducer";
import { triggerGetToken } from "../../store/getToken/actions";
import { triggetGetInstitutions } from "../../store/getInstitutions/actions";
import { PaymentParams } from "../../../../payment/presentation/store/paymentProperties/types";

import {
  GetInstitutionsRequest,
  GetTokenRequest,
} from "../../../domain/repositories/PayWithAppRepository";
import { mobileCheck } from "../../helpers";
import { PAYMENT__PAY_WITH_APP } from "../../../../Routes";
import { StoreDispatchProps, StoreStateProps, TDeviceType } from "./types";

import ErrorView from "../../../../components/ErrorView";
import { ViewContainer } from "../../components/Containers";
import LoadingView from "../../../../components/LoadingView";

const RootPage = ({
  getToken,
  token,
  tokenError,
  getInstitutions,
  fiveDigitCode,
  getInstitutionError,
  paymentParams,
}: StoreDispatchProps & StoreStateProps) => {
  const history = useHistory();

  const { amount, currencyCode, merchantCode, payableCode } = paymentParams;

  const handleGetToken = () => {
    getToken({ grand_type: "client_credentials", scope: "profile" });
  };

  const deviceType = useMemo<TDeviceType>(
    () => (mobileCheck() ? "mobile" : "web"),
    []
  );

  const handleGetInstitutions = useCallback(() => {
    getInstitutions({
      body: {
        amount,
        bankCode: "ISW",
        createTransaction: true,
        currencyCode,
        merchantCode,
        payableCode,
      },
      headers: {
        token: token || "",
      },
    });
  }, [amount, currencyCode, getInstitutions, merchantCode, payableCode, token]);

  useEffect(() => {
    handleGetToken();
  }, []);

  useEffect(() => {
    if (token) {
      handleGetInstitutions();
    }
  }, [handleGetInstitutions, token]);

  useEffect(() => {
    if (deviceType && token && fiveDigitCode) {
      if (deviceType === "mobile") {
        history.push(`${PAYMENT__PAY_WITH_APP}/deeplink/${deviceType}`);
      } else {
        history.push(`${PAYMENT__PAY_WITH_APP}/five-digit-code/${deviceType}`);
      }
    }
  }, [deviceType, fiveDigitCode, history, token]);

  return (
    <ViewContainer>
      {!tokenError && !getInstitutionError && <LoadingView />}

      {(tokenError || getInstitutionError) && (
        <ErrorView
          actionText="Try again"
          action={tokenError ? handleGetToken : handleGetInstitutions}
        />
      )}
    </ViewContainer>
  );
};

const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,
  token: state.payWithApp.token.getTokenResponse?.access_token,
  tokenError: state.payWithApp.token.getTokenError,
  fiveDigitCode: state.payWithApp.intitutions.paymentData?.reference,
  getInstitutionError: state.payWithApp.intitutions.getInstitutionsError,
});

const mapDispatchToProps = (dispatch: (action: any) => void): any => ({
  getToken(request: GetTokenRequest) {
    dispatch(triggerGetToken(request));
  },
  getInstitutions(request: GetInstitutionsRequest) {
    dispatch(triggetGetInstitutions(request));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
