import { SERVICE_BASE_URL } from "../../../../config/properties";
import httpClient, { HttpClient } from "../../../../core/api/httpClient";

export interface InitializeRequest {
  merchantCode: string;
  payableCode: string;
  transactionReference: string;
  currencyCode: string;
  paymentId: number;
}

export interface InitializeResponse {
  message: string;
  orderNo: string;
  redirectUrl: string;
  transactionReference: string;
  responseCode: "09" | "01";
  status: string;
}

export interface GetStatusRequest {
  merchantCode: string;
  reference: string;
}

export interface GetStatusResponse {
  orderNo: string;
  transactionReference: string;
  message: string;
  responseCode: "00" | "01" | "09";
  status: string;
}

interface OpayRepository {
  initialize: (request: InitializeRequest) => Promise<InitializeResponse>;
  getStatus: (request: GetStatusRequest) => Promise<GetStatusResponse>;
}

class OpayRepositoryImpl implements OpayRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async initialize(request: InitializeRequest): Promise<InitializeResponse> {
    const response = await this.httpClient.post<InitializeResponse>(
      `${SERVICE_BASE_URL}/api/v1/opay/initialize`,
      request
    );

    return response.data;
  }

  async getStatus(request: GetStatusRequest) {
    const response = await this.httpClient.post<GetStatusResponse>(
      `${SERVICE_BASE_URL}/api/v1/opay/status`,
      request,
      { timeout: 20000 }
    );

    return response.data;
  }
}

const opayRepository = new OpayRepositoryImpl(httpClient);

export default opayRepository;
