import { AxiosError } from "axios";
import { AuthenticateCardinalRequest, AuthenticateCardinalResponse } from "../../../domain/repositories/CardRepository";

import {
  TRIGGER_AUTHENTICATE_CARDINAL,
  TriggerAuthenticateCardinal,

  AUTHENTICATE_CARDINAL_PENDING,
  AuthenticateCardinalPending,

  AUTHENTICATE_CARDINAL_SUCCESS,
  AuthenticateCardinalSuccess,

  AUTHENTICATE_CARDINAL_ERROR,
  AuthenticateCardinalError,

  RESET_AUTHENTICATE_CARDINAL,
  ResetAuthenticateCardinal
} from "./types";

export const triggerAuthenticateCardinal = (request: AuthenticateCardinalRequest): TriggerAuthenticateCardinal => {
  return {
    type: TRIGGER_AUTHENTICATE_CARDINAL,
    payload: { request }
  };
};

export const authenticateCardinalPending = (): AuthenticateCardinalPending => {
  return {
    type: AUTHENTICATE_CARDINAL_PENDING
  };
};

export const authenticateCardinalSuccess = (response: AuthenticateCardinalResponse): AuthenticateCardinalSuccess => {
  return {
    type: AUTHENTICATE_CARDINAL_SUCCESS,
    payload: { response }

  };
};

export const authenticateCardinalError = (error: AxiosError): AuthenticateCardinalError => {
  return {
    type: AUTHENTICATE_CARDINAL_ERROR,
    payload: { error },
  };
};

export const resetAuthenticateCardinal = (): ResetAuthenticateCardinal => {
  return {
    type: RESET_AUTHENTICATE_CARDINAL,
  };
};