import { AxiosError } from "axios";
import {
  ResetCreditOtpPage,
  GenerateCreditOtpError,
  ValidateCreditOtpError,
  GenerateCreditOtpPending,
  TriggerGenerateCreditOtp,
  TriggerValidateCreditOtp,
  ValidateCreditOtpPending,
  ValidateCreditOtpSuccessful,
  GenerateCreditOtpSuccessful,
  GenerateCreditOtpCallback,
  RESET_CREDIT_OTP_PAGE,
  VALIDATE_CREDIT_OTP_ERROR,
  GENERATE_CREDIT_OTP_ERROR,
  GENERATE_CREDIT_OTP_PENDING,
  VALIDATE_CREDIT_OTP_PENDING,
  TRIGGER_GENERATE_CREDIT_OTP,
  TRIGGER_VALIDATE_CREDIT_OTP,
  GENERATE_CREDIT_OTP_SUCCESSFUL,
  VALIDATE_CREDIT_OTP_SUCCESSFUL,
  SET_OTP_REFERENCE,
  SetOtpReference,
} from "./types";
import { GenerateOtpRequest, ValidateOtpRequest } from "../../../domain/repositories/OtpRepository";
import { OnSigninSuccessfulCallback } from "../verveWalletSignin/types";

export const triggerGenerateCreditOtp = (
  request: GenerateOtpRequest,
  onSuccessful?: GenerateCreditOtpCallback,
  isBank?: boolean | undefined,
): TriggerGenerateCreditOtp => {
  return {
    type: TRIGGER_GENERATE_CREDIT_OTP,
    payload: { request, onSuccessful, isBank }
  };
};

export const setOtpReference = (reference: string): SetOtpReference => {
  return {
    type: SET_OTP_REFERENCE,
    payload: reference
  };
};

export const generateCreditOptPending = (): GenerateCreditOtpPending => {
  return { type: GENERATE_CREDIT_OTP_PENDING };
};

export const generateCreditOtpSuccessful = (): GenerateCreditOtpSuccessful => {
  return { type: GENERATE_CREDIT_OTP_SUCCESSFUL };
};

export const generateCreditOtpError = (error: AxiosError): GenerateCreditOtpError => {
  return {
    type: GENERATE_CREDIT_OTP_ERROR,
    payload: { error }
  };
};

export const triggerValidateCreditOtp = (
  request: ValidateOtpRequest,
  onSuccessful?: OnSigninSuccessfulCallback
): TriggerValidateCreditOtp => {
  return {
    type: TRIGGER_VALIDATE_CREDIT_OTP,
    payload: { request, onSuccessful }
  };
};

export const validateCreditOtpPending = (): ValidateCreditOtpPending => {
  return { type: VALIDATE_CREDIT_OTP_PENDING };
};

export const validateCreditOtpSuccessful = (): ValidateCreditOtpSuccessful => {
  return { type: VALIDATE_CREDIT_OTP_SUCCESSFUL };
};

export const validateCreditOtpError = (error: AxiosError): ValidateCreditOtpError => {
  return {
    type: VALIDATE_CREDIT_OTP_ERROR,
    payload: { error }
  };
};

export const resetCreditOtpPage = (): ResetCreditOtpPage => {
  return { type: RESET_CREDIT_OTP_PAGE };
};
