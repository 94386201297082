import * as React from "react";
import styled from "styled-components";

import { Button } from "../../../../../components/Button";
import {
  FormControlLabel,
  FormFieldStyles,
  LabelFieldContainer,
  FieldErrorText,
} from "../../../../../components/Layout";
import { ReactComponent as BackIcon } from "../icons/back.svg";
import { PaymentParams } from "../../../store/paymentProperties/types";
import { useEffect, useState } from "react";
import useTUCAction from "../hooks/useTUCAction";

interface Props {
  paymentParams: PaymentParams;
  onDone: () => void;
}
export default function TUCPopup(props: Props) {
  const { onDone, paymentParams } = props;

  const [phone, setPhone] = useState("");

  const [error, setError] = useState<string>();

  const { reqState, optInForTUC } = useTUCAction();

  const onSubmit = () => {
    if (!phone.trim()) {
      setError("Please provide a value");
      return;
    }
    optInForTUC(phone);
  };

  useEffect(() => {
    if(reqState.loading) {
      setError(undefined);
    }
  }, [reqState.loading])

  useEffect(() => {
    if(reqState.error) {
      setError(reqState.error);
    }
  }, [reqState.error])

  useEffect(() => {
    if(reqState.data) {
      onDone();
    }
  }, [reqState.data, onDone])

  return (
    <PopupContainer>
      <p>Congratulations!</p>
      <h6>You Qualify for Cashback</h6>
      <p className="description">{paymentParams.tucRewardDescription}</p>

      <LabelFieldContainer className="input-group">
        <FormControlLabel>Phone Number / ThankU Cash ID</FormControlLabel>
        <InputField
          inputMode="tel"
          name="mobileNumber"
          placeholder="0000 0000"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          autoComplete='off'
        />

        {error && <FieldErrorText>{error}</FieldErrorText>}
      </LabelFieldContainer>

      <Button
        color="PRIMARY"
        text="Continue to payment"
        containerStyle={{ width: "100%" }}
        onClick={onSubmit}
        loading={reqState.loading}
      />

      <SkipButton onClick={onDone}>
        <span>Skip this instead</span>
        <BackIcon />
      </SkipButton>
    </PopupContainer>
  );
}

const PopupContainer = styled.div`
  &,
  * {
    box-sizing: border-box;
  }
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  z-index: 1;
  padding: 20px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  & > p {
    font-size: 1.2rem;
    color: rgba(21, 42, 56, 0.65);
    &.description {
      font-size: 1.3rem;
      color: #555555;
    }
  }

  & > h6 {
    margin: 10px 0 24px;
    font-size: 1.6rem;
    color: #023b58;
  }

  & > .input-group {
    margin: 20px 0 30px;
  }
`;

const InputField = styled.input`
  ${FormFieldStyles};
`;

const SkipButton = styled.button`
  margin-top: 29px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #009fe4;
  font-family: inherit;
  font-weight: bolder;
  background-color: white;
  svg {
    margin-left: 10px;
  }
`;
