import { AxiosError } from "axios";
import { takeLatest, put } from "redux-saga/effects";
import cardRepository, { AuthenticateCardinalResponse } from "../../../domain/repositories/CardRepository";

import {
  TRIGGER_AUTHENTICATE_CARDINAL,
  TriggerAuthenticateCardinal,

  RESET_AUTHENTICATE_CARDINAL,
} from "./types";

import {
  authenticateCardinalPending,
  authenticateCardinalSuccess,
  authenticateCardinalError,
} from "./actions";
import { createCancellableSaga } from "../../../../../store/cancellableSaga";


function* handleTriggerMakePayment(action: TriggerAuthenticateCardinal) {
  const { payload } = action;
  const { request } = payload;

  yield put(authenticateCardinalPending());

  let response: AuthenticateCardinalResponse;

  try {
    response = yield cardRepository.authenticateCardinal(request);
  } catch (err) {
    yield put(authenticateCardinalError(err as AxiosError));
    return;
  }

  yield put(authenticateCardinalSuccess(response));
}




function* watchAuthenticateCardinal() {
  yield takeLatest(TRIGGER_AUTHENTICATE_CARDINAL, handleTriggerMakePayment);
}

export const authenticateCardinalSaga = createCancellableSaga(
  watchAuthenticateCardinal, RESET_AUTHENTICATE_CARDINAL
);