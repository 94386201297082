import React from "react";
import styled from "styled-components";

import { CardBrand } from "../../../../config/cardTypesConfig";

import MasterCardLogo from "../../../../assets/images/card_logos/mastercard.png";
import VisaLogo from "../../../../assets/images/card_logos/visa.png";
import VerveLogo from "../../../../assets/images/card_logos/verve.png";
import AmericanExpressLogo from "../../../../assets/images/card_logos/american-express.png";

const Container = styled.div`
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
`;

const LogoContainer = styled.div`
  img {
    height: 20px;
    transform: translateZ(0px);
  }
`;

const DetailsContainer = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const Title = styled.p`
  font-size: 1.4rem;
`;

const CardNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
`;

const CardNumber = styled.p`
  font-size: 1.4rem;
  margin-right: 10px;
`;

interface Props {
  cardBrand?: CardBrand;
  title: string;
  cardNumber: string;
  isEcash: boolean;
}

export default function WalletCardWidget(props: Props) {
  const { cardBrand, title, cardNumber, isEcash = false } = props;

  const cardNumberLastFour = cardNumber.slice(cardNumber.length - 4);

  return (
    <Container>
      <DetailsContainer>
        <Title>
          <b>{title}</b>
        </Title>

        {!isEcash && (
          <CardNumberContainer>
            <CardNumber>• • • • {cardNumberLastFour}</CardNumber>
          </CardNumberContainer>
        )}
      </DetailsContainer>

      <LogoContainer>
        {cardBrand === "MasterCard" && (
          <img src={MasterCardLogo} alt="mastercard" />
        )}
        {cardBrand === "Visa" && <img src={VisaLogo} alt="visa" />}
        {cardBrand === "Verve" && <img src={VerveLogo} alt="verve" />}
        {cardBrand === "AmericanExpress" && (
          <img src={AmericanExpressLogo} alt="american express" />
        )}
      </LogoContainer>
    </Container>
  );
}
