import {
  acceptOfferError,
  acceptOfferPending,
  acceptOfferSuccessful,
  getOffersError,
  getOffersPending,
  getOffersSuccessful
} from "./actions";
import {
  TriggerAcceptOffer,
  TriggerGetOffers,
  TRIGGER_ACCEPT_OFFER,
  TRIGGER_GET_OFFERS
} from "./types";
import { call, put, takeLeading } from "redux-saga/effects";
import offersRepository, {
  AcceptOfferResponse,
  GetOffersResponse
} from "../../../domain/repositories/OffersRepository";
import { AxiosError } from "axios";
import { initializeAxiosError } from "../../../../../core/api/httpClient";

function* getOffers(action: TriggerGetOffers) {
  const { payload } = action;
  const { request, onSuccessful } = payload;
  yield put(getOffersPending());
  let response: GetOffersResponse;

  try {
    response = yield offersRepository.getOffers(request);
  } catch (error) {
    yield put(getOffersError(error as AxiosError));
    return;
  }

  if (response.responseCode === "00") {
    if (response.offers.length <= 0) {
      let error: AxiosError = initializeAxiosError();
      if (error.response) error.response.data = response;
      yield put(getOffersError(error));
    } else {
      yield put(getOffersSuccessful(response.offers));
      if (onSuccessful && typeof onSuccessful === "function") yield call(onSuccessful);
    }
    return;
  }

  let error: AxiosError = initializeAxiosError();
  if (error.response) error.response.data = response;
  yield put(getOffersError(error));
}

function* acceptOffer(action: TriggerAcceptOffer) {
  const { payload } = action;
  const { request, onSuccessful } = payload;
  yield put(acceptOfferPending());
  let response: AcceptOfferResponse;

  try {
    response = yield offersRepository.acceptOffer(request);
  } catch (error) {
    yield put(acceptOfferError(error as AxiosError));
    return;
  }

  if (response.responseCode === "00") {
    yield put(acceptOfferSuccessful(response));
    if (onSuccessful && typeof onSuccessful === "function") yield call(onSuccessful);
  } else {
    let error: AxiosError = initializeAxiosError();
    if (error.response) error.response.data = response;
    yield put(acceptOfferError(error));
  }
}

export function* getOffersSaga() {
  yield takeLeading(TRIGGER_GET_OFFERS, getOffers);
}

export function* acceptOfferSaga() {
  yield takeLeading(TRIGGER_ACCEPT_OFFER, acceptOffer);
}
