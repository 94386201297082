import {
  GET_ISSUERS_PENDING,
  GET_ISSUERS_SUCCESS,
  GET_ISSUERS_ERROR,
  RESET_GET_ISSUERS,
  GetIssuersState,
  GetIssuersActionType,
} from "./types";

const initialState: GetIssuersState = {
  getIssuersPending: false,
  getIssuersError: false,
  issuers: null

}

export function GetIssuersReducer(
  state: GetIssuersState = initialState,
  action: GetIssuersActionType
): GetIssuersState {
  switch (action.type) {

    case GET_ISSUERS_PENDING: {
      return {
        ...state,
        getIssuersPending: true
      };
    }

    case GET_ISSUERS_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      return {
        ...state,
        getIssuersPending: false,
        getIssuersError: false,
        issuers: response
      };
    }

    case GET_ISSUERS_ERROR: {
      return {
        ...state,
        getIssuersPending: false,
        getIssuersError: true,
      };
    }

    case RESET_GET_ISSUERS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
