import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { StoreStateProps, StoreDispatchProps } from "./types";
import { AppState } from "../../../../../store/RootReducer";
import { PaymentUtil } from "../../../../payment/util/PaymentUtil";
import { PaymentParams } from "../../../../payment/presentation/store/paymentProperties/types";
import { completeTransaction } from "../../../../payment/presentation/store/paymentStatus/actions";
import payWithAppRepository from "../../../domain/repositories/PayWithAppRepository";
import { asyncDelay } from "../../../../../core/util/asyncUtil";

import {
  ViewContainer,
  TimerWrapper,
  LoaderContainer,
} from "../../components/Containers";
import Loader from "../../../../components/Loader";
import { Button } from "../../../../components/Button";
import useCountdownTimer from "../../../../../hooks/useCountdownTimer";

const Paragraph = styled.p`
  text-align: center;
  margin: 0 30px 50px 30px;
`;

const Time = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

const TransactionConfirm = ({
  completeTransaction,
  paymentParams,
  token,
}: StoreStateProps & StoreDispatchProps) => {
  const [expired, setExpired] = useState(false);
  const [extended, setExtended] = useState(false);
  const isPageMounted = useRef(true);

  const handleTimerExpired = () => {
    if (extended) {
      completeTransaction({ responseCode: "XS1" });
    } else {
      setExpired(true);
    }
  };

  const { time, restart: restartTimer } = useCountdownTimer({
    timeout: 5 * 60 * 1000,
    onCompleted: handleTimerExpired,
  });

  const handleExtendTimer = () => {
    setExpired(false);
    setExtended(true);
    restartTimer();
  };

  const { minutes, seconds } = time;

  const paragraphText = expired
    ? "We couldn’t confirm your transaction within the given period. What would you like to do?"
    : "Please hold on while we confirm your transaction";

  const timerText = `${minutes > 9 ? minutes : "0" + minutes}:${
    seconds > 9 ? seconds : "0" + seconds
  }`;

  const pollTransactionStatus = async () => {
    while (true) {
      if (!isPageMounted.current) return;

      let response;

      try {
        response = await payWithAppRepository.getTransactionStatus({
          body: {
            merchantCode: paymentParams.merchantCode,
            transactionReference: paymentParams.merchantTransactionReference,
          },
          headers: { token: token || "" },
        });
      } catch (err) {
        await asyncDelay(5000);
        continue;
      }

      const { responseCode } = response;

      if (PaymentUtil.isTransactionComplete(responseCode)) {
        completeTransaction(response);
        break;
      }

      await asyncDelay(5000);
    }
  };

  useEffect(() => {
    pollTransactionStatus();
    return () => {
      isPageMounted.current = false;
    };
  }, []);

  return (
    <ViewContainer>
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
      <Paragraph>{paragraphText}</Paragraph>
      {expired ? (
        <Button
          color="PRIMARY"
          text="Wait additional 5 Minutes"
          icon={<></>}
          onClick={handleExtendTimer}
        />
      ) : (
        <TimerWrapper top={0} bottom={0}>
          <Time>{timerText}</Time>
        </TimerWrapper>
      )}
    </ViewContainer>
  );
};

const mapStateToProps = (state: AppState): StoreStateProps => {
  return {
    paymentParams: state.payment.paymentProperties
      .paymentParams as PaymentParams,
    token: state.payWithApp.token.getTokenResponse?.access_token,
  };
};

const mapDispatchToProps = (
  dispatch: (action: any) => void
): StoreDispatchProps => ({
  completeTransaction(transactionResponse: any) {
    dispatch(completeTransaction(transactionResponse));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionConfirm);
