import React from "react";
import { Container, Shimmer } from "../../../style";

export default function Placeholder() {
  return (
    <Container>
      <Shimmer />
      <Shimmer />
    </Container>
  );
}
