import { Bank } from "../../../domain/repositories/BanksRepository";

export const SET_BANKS = "CREDIT.SET_BANKS";
export const REQUEST_BANKS = "CREDIT.REQUEST_BANKS";
export const SET_SHOW_LOAN_OFFER = "CREDIT.SET_SHOW_LOAN_OFFER";
export const REQUEST_SHOW_LOAN_OFFER = "CREDIT.REQUEST_SHOW_LOAN_OFFER";
export const SET_BANK_DETAILS = "CREDIT.SET_BANK_DETAILS";
export const REQUEST_BANK_DETAILS = "CREDIT.REQUEST_BANK_DETAILS";
export const SET_BANKS_LOADING = "CREDIT.SET_BANKS_LOADING";
export const SET_BANKS_ERROR = "CREDIT.GET_BANKS_ERROR";


export interface setBanks {
    type: typeof SET_BANKS | typeof REQUEST_BANKS;
    payload: Bank[];
}

export interface setBanksLoading {
    type: typeof SET_BANKS_LOADING | typeof SET_SHOW_LOAN_OFFER | typeof REQUEST_SHOW_LOAN_OFFER;
    payload: boolean;
}

export interface setBanksError {
    type: typeof SET_BANKS_ERROR;
    payload: any
}

export interface setBankDetails {
    type: typeof SET_BANK_DETAILS | typeof REQUEST_BANK_DETAILS;
    payload: BankDetailsProps
}

export interface StoreStateProps {
    banks: readonly Bank[];
    banksLoading: boolean;
    showLoanOffers: boolean;
}

export interface BankDetailsProps {
    finInstitution: string;
    accountNumber: string;
    bankCode: string;
}

export interface StoreDispatchProps {
    setPageTitle: (title: string) => void;
    setPageSubTitle: (subtitle: string) => void;
    setShowLoanOffers: (status: boolean) => void;
    getBanks: () => void;
    setBankDetails: (bankDetails: BankDetailsProps) => void;
}



export interface SelectBankPageState {
    banks: readonly Bank[];
    getBanksError: any;
    banksLoading: boolean;
    showLoanOffers: boolean;
    bankDetails: BankDetailsProps;
}

export type SelectBankPageActionTypes = setBanks | setBanksLoading | setBanksError | setBankDetails;