import React from "react";
import styled from "styled-components";
import MethodItem from "../../../../components/MethodItem";
import { useHistory } from "react-router-dom";
import BackControl from "../../../../components/BackControl";
import { PageViewAnimationStyles } from "../../../../components/Layout";
import { CustomerWalletOption, walletOptions } from "./utils";
import usePaymentChannelHelper from "../../../../../hooks/usePaymentChannelHelper";

export default function RootPage() {
  const history = useHistory();

  const {merchantPaymentProps} = usePaymentChannelHelper();

  const walletChannels = merchantPaymentProps.nonCardOptions.find(
    (channel) => channel.providerCode === "CUSTOMER_WALLET"
  );

  const availableWalletCodes =
    walletChannels?.additionalInformation?.providers.map(
      (wallet) => wallet.code
    );

  const availableDisplayOptions = walletOptions.filter((wallet) =>
    availableWalletCodes?.includes(wallet.type)
  );

  const onOptionSelect = (walletPath: CustomerWalletOption["path"]) => {
    history.push(walletPath);
  };

  const backControlHandler = () => {
    history.goBack();
  };

  return (
    <Container>
      <BackControl
        text="Change payment method"
        padded
        onClick={backControlHandler}
      />

      {availableDisplayOptions.map((option) => {
        const { type, title, iconPath, path } = option;

        return (
          <MethodItem
            key={type}
            type={type}
            title={title}
            iconPath={iconPath}
            onSelect={() => onOptionSelect(path)}
          />
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${PageViewAnimationStyles};
`;
