//Steps for How to Pay view for MoMo
export const BatchA = [
  "Download the Pocket app.",
  "Create an account and set a transaction PIN.",
  "Fund your Pocket Wallet from your Bank apps by transferring to the provided account number.",
];

export const BatchB = [
  "You will receive a notification on your phone prompting you to authorize the transaction using your Pocket PIN.",
  'When you have successfully authorized the payment, click on "I have authorized the transaction" to complete the payment.',
];
