import { SERVICE_BASE_URL } from "../../../../config/properties";
import httpClient, { HttpClient } from "../../../../core/api/httpClient";

export interface InitializeRequest {
  merchantCode: string;
  payableCode: string;
  transactionReference: string;
  currencyCode: string;
  paymentId: number;
}

export interface InitializeResponse {
  url: string;
  invoiceId: string;
  transactionReference: string;
  responseCode: string;
}

export interface ConfirmPaymentRequest {
  guid: string;
}

export interface ConfirmPaymentResponse {
  amount: number;
  transactionReference: string;
  responseCode: string;
}

export interface GetStatusRequest {
  guid: string;
}

export interface GetStatusResponse {
  amount: number;
  transactionReference: string;
  responseCode: string;
}

interface EnairaRepository {
  initialize: (request: InitializeRequest) => Promise<InitializeResponse>;
  getStatus: (request: GetStatusRequest) => Promise<GetStatusResponse>;
}

class EnairaRepositoryImpl implements EnairaRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async initialize(request: InitializeRequest): Promise<InitializeResponse> {
    const response = await this.httpClient.post<InitializeResponse>(
      `${SERVICE_BASE_URL}/api/v1/enaira-wallet/init`,
      request
    );

    // make call to confirm
    const confirmRequest: ConfirmPaymentRequest = {
      guid: response.data.invoiceId,
    };
    const confirmResponse = await this.httpClient.post<ConfirmPaymentResponse>(
      `${SERVICE_BASE_URL}/api/v1/enaira-wallet/confirm`,
      confirmRequest
    );

    const { data: confirmResponseData } = confirmResponse;

    return response.data;
  }

  async getStatus(request: GetStatusRequest) {
    const response = await this.httpClient.get<GetStatusResponse>(
      `${SERVICE_BASE_URL}/api/v1/enaira-wallet/check-status`,
      {params: request, timeout: 5000},
    );

    return response.data;
  }
}

const enairaRepository = new EnairaRepositoryImpl(httpClient);

export default enairaRepository;
