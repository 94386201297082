import {
  GENERATE_ACCOUNT_PENDING,
  GENERATE_ACCOUNT_SUCCESS,
  GENERATE_ACCOUNT_ERROR,
  RESET_GENERATE_ACCOUNT,
  GenerateAccountState,
  GenerateAccountActionType,
} from "./types";

const initialState: GenerateAccountState = {
  generateAccountPending: false,
  generateAccountError: false,
  generateAccountResponse: null

}

export function GenerateAccountReducer(
  state: GenerateAccountState = initialState,
  action: GenerateAccountActionType
): GenerateAccountState {
  switch (action.type) {

    case GENERATE_ACCOUNT_PENDING: {
      return {
        ...state,
        generateAccountPending: true,
        generateAccountResponse: null
      };
    }

    case GENERATE_ACCOUNT_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      return {
        ...state,
        generateAccountPending: false,
        generateAccountError: false,
        generateAccountResponse: response
      };
    }

    case GENERATE_ACCOUNT_ERROR: {
      return {
        ...state,
        generateAccountPending: false,
        generateAccountError: true
      };
    }

    case RESET_GENERATE_ACCOUNT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
