import { combineReducers } from "redux";
import { getProvidersReducer } from "./getProviders/reducer";
import { validateRequestReducer } from "./validateRequest/reducer";
import { createPaymentReducer } from "./createPayment/reducer"
import { getCountryListReducer } from "./getCountryList/reducer";

export const TerrapayWalletReducer = combineReducers({
  providersData: getProvidersReducer,
  countriesData: getCountryListReducer,
  validateRequestData: validateRequestReducer,
  createPaymentData: createPaymentReducer
});