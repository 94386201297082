import * as React from "react";
import styled from "styled-components";

import { Button } from "../../../../../components/Button";
// import useCancelPayment from "../hooks/useCancelPayment";
import Loader from "../../../../../components/Loader";
import { GenerateAccountResponse } from "../../../../domain/repositories/TransferRepository";

interface Props {
  onWait: (n: number) => void;
  hideRetry?: boolean;
  accountNumber: GenerateAccountResponse;
  time: {
    minutes: number;
    seconds: number;
  };
}
export default function TimerView(props: Props) {
  const { onWait, time, hideRetry = false, accountNumber } = props;

  //   const { cancelPayment, loading } = useCancelPayment();

  const timerDone = time.minutes === 0 && time.seconds === 0;

  return (
    <Container>
      <Loader animate={!timerDone} />

      {!timerDone && (
        <>
          <p>Please hold, while we confirm your transaction…</p>

          <BankInfo>
            <h4>{`${accountNumber.accountNumber}-${accountNumber.bankName}`}</h4>

            <p>{accountNumber.accountName}</p>
          </BankInfo>

          <h6>
            {`${time.minutes < 10 ? "0" : ""}${time.minutes}`}:
            {`${time.seconds < 10 ? "0" : ""}${time.seconds}`}
          </h6>
        </>
      )}

      {timerDone && !hideRetry && (
        <>
          <p className="retry-msg">
            We couldn't confirm your transaction within the given period. What
            would you like to do?
          </p>

          <Button
            color="PRIMARY"
            text="Wait for Additional 5 Minutes"
            className="btn wait-btn"
            onClick={() => onWait(5000 * 60)}
          />

          {/* <Button
            color="SECONDARY"
            text="Cancel Transaction"
            className="btn cancel-btn"
            loading={loading}
            onClick={cancelPayment}
          /> */}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding-top: 109px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    width: 204px;
    text-align: center;
    font-size: 1.2rem;
    margin-top: 23px;

    &.retry-msg {
      width: 288px;
    }
  }

  > h6 {
    font-size: 1.5rem;
    letter-spacing: 1.4px;
    margin-top: 42px;
    margin-bottom: 42px;
  }

  .btn {
    display: block;
    width: 100%;

    &.wait-btn {
      margin-top: 52px;
    }

    /* &.cancel-btn {
      background-color: white;
      border: none;
    } */
  }
`;

const BankInfo = styled.div`
  width: 341px;
  max-width: 100%;
  margin-top: 29px;

  border-radius: 6px;
  border: solid 1px #cdcdcd;

  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 26px 0px;

  > h4 {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 8px;
  }

  > p {
    font-size: 1.4rem;
  }
`;
