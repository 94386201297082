import { GenerateCreditOtpCallback } from "../otpPage/types";
import { GenerateOtpRequest } from "../../../domain/repositories/OtpRepository";
import { OnSigninSuccessfulCallback } from "../verveWalletSignin/types";
import { AxiosError } from "axios";
import { TriggerShowNotificationPayload } from "../../../../components/NotificationWidget/store/types";

export const HIDE_CREDIT_OTP_PAGE = "CREDIT.HIDE_CREDIT_OTP_PAGE";
export const DISPLAY_CREDIT_OTP_PAGE = "CREDIT.DISPLAY_CREDIT_OTP_PAGE";
export const HIDE_PHONE_NUMBER_SIGNIN = "CREDIT.HIDE_PHONE_NUMBER_SIGNIN";
export const DISPLAY_PHONE_NUMBER_SIGNIN = "CREDIT.DISPLAY_PHONE_NUMBER_SIGNIN";
export const RESET_PHONE_NUMBER_SIGNIN_PAGE = "CREDIT.RESET_PHONE_NUMBER_SIGNIN_PAGE";

export interface PhoneNumberSigninState {
  displayOtpPage: boolean;
  displayPhoneNumberSignin: boolean;
}

export interface HideCreditOtpPage {
  type: typeof HIDE_CREDIT_OTP_PAGE;
}

export interface DisplayCreditOtpPage {
  type: typeof DISPLAY_CREDIT_OTP_PAGE;
}

export interface HidePhoneNumberSignin {
  type: typeof HIDE_PHONE_NUMBER_SIGNIN;
}

export interface DisplayPhoneNumberSignin {
  type: typeof DISPLAY_PHONE_NUMBER_SIGNIN;
}

export interface ResetPhoneNumberSigninPage {
  type: typeof RESET_PHONE_NUMBER_SIGNIN_PAGE;
}

export interface StoreStateProps {
  paymentId?: number;
  signinPending: boolean;
  displayOtpPage: boolean;
  generateOtpError: AxiosError | null;
}

export interface StoreDispatchProps {
  hideCreditOtpPage: () => void;
  loginToVerveWallet: () => void;
  displayCreditOtpPage: () => void;
  setPageTitle: (title: string) => void;
  setPageSubTitle: (subtitle: string) => void;
  phoneNumberSigninSuccessful: OnSigninSuccessfulCallback;
  triggerShowNotification: (payload: TriggerShowNotificationPayload) => void;
  generateOtp: (request: GenerateOtpRequest, onSuccessful: GenerateCreditOtpCallback) => void;
}

export type PhoneNumberSigninActionTypes =
  | DisplayCreditOtpPage
  | HideCreditOtpPage
  | DisplayPhoneNumberSignin
  | HidePhoneNumberSignin
  | ResetPhoneNumberSigninPage;
