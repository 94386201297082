import {
  GetTokenRequest,
  GetTokenResponse,
} from "../../../domain/repositories/PayWithAppRepository";

import {
  GET_TOKEN_ERROR,
  GetTokenError,
  GET_TOKEN_PENDING,
  GetTokenPending,
  GET_TOKEN_SUCCESS,
  GetTokenSuccess,
  TRIGGER_GET_TOKEN,
  TriggerGetToken,
  RESET_GET_TOKEN,
  ResetGetToken,
} from "./types";

export const triggerGetToken = (request: GetTokenRequest): TriggerGetToken => {
  return {
    type: TRIGGER_GET_TOKEN,
    payload: { request },
  };
};

export const getTokenPending = (): GetTokenPending => {
  return {
    type: GET_TOKEN_PENDING,
  };
};

export const getTokenError = (): GetTokenError => {
  return {
    type: GET_TOKEN_ERROR,
  };
};

export const resetGetToken = (): ResetGetToken => {
  return {
    type: RESET_GET_TOKEN,
  };
};

export const getTokenSuccess = (
  response: GetTokenResponse
): GetTokenSuccess => {
  return {
    type: GET_TOKEN_SUCCESS,
    payload: { response },
  };
};
