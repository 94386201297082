import React, { useMemo } from 'react';
import styled from 'styled-components';

import { PageView } from '../../components/Layout';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success-icon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-icon.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-icon.svg';
import { AppDisplayMode } from './store/paymentProperties/types';
import FailedPayment from '../../components/FailedPayment';
import { getTransactionStatus } from '../util/PaymentUtil';

const Container = styled(PageView)`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-bottom: 20px;

  svg {
    width: 32px;
    height: 32px;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 10px;
  
  h6 {
    font-size: 1.6rem;
  }
`;

const Message = styled.p`
  text-align: center;
`;

interface Props {
  appDisplayMode: AppDisplayMode;
  merchantName: string;
  transactionResponse: any;
}

export default function PaymentCompleteView(props: Props) {
  const {
    appDisplayMode,
    merchantName,
    transactionResponse
  } = props;

  const transactionStatus = getTransactionStatus(
    transactionResponse.responseCode
  );

  const getTransactionStatusTitle = useMemo(()=> {
    switch (transactionStatus.status) {
      case "SUCCESS":
        return "Transaction in Progress"
      case "IN_PROGRESS":
        return "Transaction in Progress"
      case "EXPIRED":
        return "Transaction Expired"
      case "CANCELLED":
        return "Payment Cancelled"
      case "FAILURE":
        return "Payment Failed"
    
      default:
        break;
    }
  }, [transactionStatus])

  return (
    <Container>
      {transactionStatus.isFailed ? (
        <FailedPayment
          icon={
            <IconContainer>
              {transactionStatus.status === "CANCELLED" && <WarningIcon />}

              {transactionStatus.status === "EXPIRED" && <ErrorIcon />}
              {transactionStatus.status === "FAILURE" && <ErrorIcon />}
            </IconContainer>
          }
          title={getTransactionStatusTitle}
        />
      ) : (
        <>
          <IconContainer>{transactionStatus.isSuccessful && <SuccessIcon />}</IconContainer>
          <IconContainer>{transactionStatus.isPending && <WarningIcon />}</IconContainer>

          <TitleContainer>
            <h6>{getTransactionStatusTitle}</h6>
          </TitleContainer>
          {appDisplayMode === "REDIRECT" && (
            <Message>
              You’re being redirected to <b>{merchantName}</b>, <br />
              please do not close this page.
            </Message>
          )}

          {appDisplayMode === "INLINE" && (
            <Message>
              We’re taking you back to <b>{merchantName}</b>, <br />
              please do not close this page.
            </Message>
          )}
        </>
      )}
    </Container>
  );
}