import { AxiosError } from "axios"
import { takeLatest, put } from "redux-saga/effects";
import cardRepository, { MakePaymentResponse } from "../../../domain/repositories/CardRepository";

import { 
  TRIGGER_MAKE_PAYMENT, 
  TriggerMakePayment,

  RESET_MAKE_PAYMENT,
} from "./types";

import {
  makePaymentPending,
  makePaymentSuccess,
  makePaymentError
} from "./actions";
import { createCancellableSaga } from "../../../../../store/cancellableSaga";


function* handleTriggerMakePayment(action: TriggerMakePayment) {
  const { payload} = action;
  const { request } = payload;

  yield put(makePaymentPending());

  let response: MakePaymentResponse;

  try {
    response = yield cardRepository.makePayment(request) ;
  } catch (err) {
    yield put(makePaymentError(err as AxiosError));
    return;
  }

  yield put(makePaymentSuccess(response));
}


function* watchMakePayment() {
  yield takeLatest(TRIGGER_MAKE_PAYMENT, handleTriggerMakePayment);
}

export const makePaymentSaga = createCancellableSaga(
  watchMakePayment, RESET_MAKE_PAYMENT
);