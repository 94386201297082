import React from "react";
import { useHistory } from "react-router-dom";
import BackControl from "./BackControl";
import styled from "styled-components";
import { PageView } from "./Layout";

export default function CustomerDisclaimerView() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Container>
      <BackControl onClick={handleGoBack} text="Back" />
      <p>
        Welcome to Zivastores (“the Platform”). Your continued use of this
        Platform implies that you agree to comply with and shall be bound by the
        following Terms of Service which governs Zivastores’s (“Zivastores”)
        relationship with you or the Merchants (“Merchants”) in relation to this
        Platform.
      </p>
      <br />
      <p>
        Zivastores provides no guarantees, warranties or representations whether
        express, implied or by conduct or trade practice as to the accuracy,
        timeliness, performance, completeness or suitability of the information
        and materials found or offered on the Merchants’ webpage or this
        Platform for any particular purpose. You acknowledge that such
        information and materials may contain inaccuracies or errors and we
        expressly exclude liability for any such inaccuracies or errors to the
        fullest extent permitted by law. Your use of any information or
        materials on this Platform is entirely at your own risk, for which
        Zivastores shall not be liable to defend or respond to. It shall be your
        own responsibility to ensure that any products, services or information
        available through this Platform meet your specific requirements and
        under no circumstance shall Zivastores be responsible for your reliance
        on any information obtained through the Platform.
      </p>
      <br />
      <p>
        From time to time, this Platform may also include links to other
        Websites. These links are provided for your convenience to provide
        further information. They do not signify that we endorse the Website(s)
        or constitute an endorsement of any Merchant’s products and services. We
        shall also have no responsibility for the content of the linked
        Website(s).
      </p>
      <br />
      <p>
        This Platform may also contain information provided by third parties and
        Zivastores makes no representations or warranties regarding the said
        third parties’ information.
      </p>
      <br />
      <p>
        Zivastores does not warrant that the function or operation of this
        Platform will be error-free and uninterrupted. Without prejudice to the
        generality of the foregoing paragraphs Zivastores does not also warrant
        that this Platform shall be constantly available or available at all.
        Zivastores shall not accept responsibility for content, accuracy or
        compliance with applicable laws.
      </p>
      <br />
      <p>
        Under no circumstance shall Zivastores be liable for any direct,
        indirect, special, incidental or consequential loss or damage arising
        from loss of data or profits arising out of, or in connection with the
        use of this Platform or the infringements of any proprietary rights
        whether registered or unregistered.
      </p>
      <br />
      <p>
        Zivastores has no control over the Merchants’ Websites and accepts no
        responsibility whatsoever for the availability, functionality, quality,
        defects, timely delivery of goods or services and confirmation of
        payment made through the Merchants’ Platform, neither does links to
        Merchants’ Websites constitute an endorsement of Merchants’ product and
        services.
      </p>
      <br />
      <p>
        You hereby irrevocably and unconditionally indemnify and shall keep
        Zivastores fully indemnified, defend and hold Zivastores, its Directors,
        Officers, Employees, Agents or any of its Representatives harmless from
        and against any and all claims, liabilities, losses, demands, actions,
        proceedings, losses, costs, attorney’s fees (including reasonable legal
        costs), charges, fees, including without limitation to any direct or
        indirect, special, incidental or consequential losses either arising out
        of or relation to the use of the Merchants’ Platform/Websites or any
        website which you may be directed to from time to time.
      </p>
      <br />
      <p>
        Zivastores shall not accept responsibility for the content or
        accuracy/correctness of the Merchant’s online store(s)/Website(s),
        compliance with applicable laws nor shall Zivastores be responsible for
        any defects in the goods/services purchased, nor the merchantability or
        fitness for purpose of the goods or the exercise of reasonable care and
        skill by the Merchant(s).
      </p>
      <br />
      <p>
        Any dispute arising between the Merchant and any User of the Platform is
        ordinarily to be settled between the Merchant and the User in any manner
        they may choose to resolve same. In the event of any dispute arising
        however which the Merchant and the User are unable to resolve,
        Zivastores shall upon receipt of written notice of the dispute,
        endeavour to independently investigate the cause of the dispute and make
        reasonable efforts to resolve same as an independent third party for the
        purpose of maintaining the integrity of the Zivastores Platform and to
        protect the interest of the users from the unscrupulous Merchants.
      </p>
      <br />
      <p>
        Zivastores may revise or review this disclaimer from time to time in
        line with prevailing market realities. The revised or reviewed
        disclaimer shall automatically take effect and apply to the use of the
        Platform from the date of its publication on the Platform.
      </p>
      <br />
      <p>
        Your use of this Platform and any dispute arising there from shall be
        subject to the Laws of the Federal Republic of Nigeria and to the
        exclusive jurisdiction of the Courts in Nigeria.
      </p>
      <br />
      <p>
        Zivastores shall not be liable to you in any way or in relation to the
        contents of or use of, or otherwise in connection with the platform
        except as may be required by Law and nothing in the platform
        constitutes, or is meant to constitute advice of any kind, whether
        professional or otherwise.
      </p>
    </Container>
  );
}

const Container = styled(PageView)``;
