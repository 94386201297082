import React, { useEffect, useRef } from 'react';

interface Props {
  url: string;
  paymentId: number;
  callBackUrl: string;
  payload?: string;
  jwt?: string;
  onFrameLoad: () => void;
  customisedHtml?: string;
}

export default function RootIFrame(props: Props) {
  const {
    url, paymentId, callBackUrl, payload, jwt, onFrameLoad, customisedHtml,
  } = props;

  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const init = () => {
    const iFrameEl = iFrameRef.current;

    if (!iFrameEl) return;
    let formEl = document.createElement("form");

    if(!customisedHtml){
      formEl.method = "POST";
      formEl.action = url;
      formEl.style.display = "none";
  
      let formContentString = '';
      formContentString += `<input name="MD" value="${paymentId}" />`;
      formContentString += `<input name="TermUrl" value="${callBackUrl}" />`;
  
      if (payload) {
        formContentString += `<input name="PaReq" value="${payload}" />`;
      }
  
      if (jwt) {
        formContentString += `<input name="JWT" value="${jwt}" />`;
      }
  
      formEl.innerHTML = formContentString;

      iFrameEl.contentDocument?.body.appendChild(formEl);
      
      formEl.submit();

    } else {
      // let divEl = document.createElement("div");
      // divEl.innerHTML = customisedHtml;

      // var cardinalEl = new DOMParser().parseFromString(customisedHtml, "text/html");
      // iFrameEl.contentDocument?.body.appendChild(cardinalEl);

      var iframeDoc = iFrameEl.contentWindow?.document;

      if(iframeDoc){
        iframeDoc.open();
        iframeDoc.write(customisedHtml);
        iframeDoc.close();
      }

    }

  };

  useEffect(() => {
    init();
  }, []);

  return (
    <iframe
      ref={iFrameRef}
      style={{ flex: 1, width: '100%', border: 'none' }}
      onLoad={onFrameLoad}
    />
  );

}