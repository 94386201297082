import React, { CSSProperties, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { eventIsNumberKey, isKeyboardAction } from '../../core/util/eventUtils';
import { getDigitsOnly, isDigitNumber } from '../../core/util/numUtil';
import { FormFieldErrorStyles, FormLabelFieldStyles } from './Layout';

interface InputFieldProps {
  error: boolean;
}

export const InputField = styled.input<InputFieldProps>`
  ${FormLabelFieldStyles};
  ${({ error }) => error && FormFieldErrorStyles};
`;

interface OwnProps {
  initialValue: string;
  error?: boolean;
  containerStyle?: CSSProperties;
  onValueChange: (value: string) => void;
}

type Props = OwnProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export default function ExpiryInputField(props: Props) {
  const { initialValue, onValueChange, error = false, ...inputProps } = props;

  const { ref, ...extractedInputProps } = inputProps;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const lastValueRef = useRef(initialValue);

  const cursorSymbol = '_';

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isKeyboardAction(event)) {
      return;
    }

    let keyValid: boolean = eventIsNumberKey(event);

    if (!keyValid) {
      event.preventDefault();
      return;
    }
  };

  const onInputHandler = (event: React.FormEvent<HTMLInputElement>) => {
    if (!inputRef.current) return;

    const inputEl = inputRef.current;

    const lastValue = lastValueRef.current;

    let currentValue = inputRef.current.value;

    let cursorIndex = inputRef.current.selectionStart as number;

    let characterArray = currentValue.split('');

    //Mark cursor position
    characterArray.splice(cursorIndex, 0, cursorSymbol);

    // Insert "0" if character supplied for month exceeds 12
    if (
      lastValue === ""
      && characterArray.length === 2
      && !isNaN(parseInt(characterArray[0]))
      && parseInt(characterArray[0]) > 1
    ) {
      characterArray.unshift("0");
      cursorIndex++;
    }

    // Filter out non-number characters    
    characterArray = characterArray.filter((character, index) => {

      if (index === cursorIndex) return true;

      return isDigitNumber(character);
    });

    let formattedCharacterArray = [];

    let digitCount = 0;
    let separatorInserted = false;

    for (let i = 0; i < characterArray.length; i++) {

      let character = characterArray[i];

      if (isDigitNumber(character)) {
        digitCount++;
      }

      formattedCharacterArray.push(character);

      if (digitCount === 2 && !separatorInserted) {
        formattedCharacterArray.push(' ', '/', ' ');
        separatorInserted = true;
      }
    }

    characterArray = formattedCharacterArray;


    let cursorSymbolIndex = characterArray.indexOf(cursorSymbol);

    characterArray.splice(cursorSymbolIndex, 1);

    let newValue = characterArray.join('');


    if (newValue === lastValue && newValue.indexOf('/') > 0) {
      newValue = newValue.slice(0, newValue.length - 3);
    }

    inputEl.value = newValue;
    inputEl.selectionStart = cursorSymbolIndex;
    inputEl.selectionEnd = cursorSymbolIndex;

    lastValueRef.current = newValue;


    onValueChange(getDigitsOnly(newValue));
  };

  useEffect(() => {
    if (!inputRef.current) return;

    const inputEl = inputRef.current;

    inputEl.value = initialValue || '';
    
    inputEl.dispatchEvent(new Event('input', { bubbles: true }));
    inputEl.dispatchEvent(new Event('blur', { bubbles: true }));
  }, []);

  return (
    <InputField
      ref={inputRef}
      inputMode="numeric"
      placeholder="MM / YY"
      autoComplete="cc-exp"
      onKeyDown={onKeyDownHandler}
      onInput={onInputHandler}
      maxLength={7}
      error={error}
      {...extractedInputProps}
    />
  );
}