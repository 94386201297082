import { GetBinConfigurationRequest, GetBinConfigurationResponse } from "../../../domain/repositories/CardRepository";

export const TRIGGER_GET_BIN_CONFIGURATION = 'CARD.TRIGGER_GET_BIN_CONFIGURATION';
export const GET_BIN_CONFIGURATION_PENDING = 'CARD.GET_BIN_CONFIGURATION_PENDING';
export const GET_BIN_CONFIGURATION_SUCCESS = 'CARD.GET_BIN_CONFIGURATION_SUCCESS';
export const GET_BIN_CONFIGURATION_ERROR = 'CARD.GET_BIN_CONFIGURATION_ERROR';

export const RESET_GET_BIN_CONFIGURATION = 'CARD.RESET_GET_BIN_CONFIGURATION';

export interface TriggerGetBinConfiguration {
  type: typeof TRIGGER_GET_BIN_CONFIGURATION;
  payload: {
    request: GetBinConfigurationRequest;
  }
}

export interface GetBinConfigurationPending {
  type: typeof GET_BIN_CONFIGURATION_PENDING;
}

export interface GetBinConfigurationSuccess {
  type: typeof GET_BIN_CONFIGURATION_SUCCESS;
  payload: {
    response: GetBinConfigurationResponse;
  }
}

export interface GetBinConfigurationError {
  type: typeof GET_BIN_CONFIGURATION_ERROR;
  payload?: string;
}

export interface ResetGetBinConfiguration {
  type: typeof RESET_GET_BIN_CONFIGURATION;
}


export type GetBinConfigurationActionType =
  | TriggerGetBinConfiguration
  | GetBinConfigurationPending
  | GetBinConfigurationSuccess
  | GetBinConfigurationError
  | ResetGetBinConfiguration;


export interface GetBinConfigurationState {
  fetchingBinConfiguration: boolean;
  fetchingBinConfigurationError: boolean;
  binConfigurationResponse: GetBinConfigurationResponse | null;
  errorMessage?: string;
}