import { 
    CreditOtpState,
    CreditOtpActionTypes,
    
    RESET_CREDIT_OTP_PAGE,
    VALIDATE_CREDIT_OTP_ERROR,
    GENERATE_CREDIT_OTP_ERROR,
    GENERATE_CREDIT_OTP_PENDING,
    VALIDATE_CREDIT_OTP_PENDING,
    GENERATE_CREDIT_OTP_SUCCESSFUL,
    VALIDATE_CREDIT_OTP_SUCCESSFUL,
    SET_OTP_REFERENCE,
} from "./types"

const initialState: CreditOtpState = {
    generateOtpError: null,
    validateOtpError: null,
    generateOtpLoading: false,
    validateOtpLoading: false,
    otpRequestReference: null,
}

export default function CreditOtpReducer(state: CreditOtpState = initialState, action: CreditOtpActionTypes): CreditOtpState {

    switch(action.type) {
        case GENERATE_CREDIT_OTP_PENDING: {
            return { ...state, generateOtpLoading: true}
        }

        case GENERATE_CREDIT_OTP_SUCCESSFUL: {
            return { ...state, generateOtpLoading: false, generateOtpError: null }
        }

        case GENERATE_CREDIT_OTP_ERROR: {
            const { payload } = action
            const { error } = payload
            return { ...state, generateOtpLoading: false, generateOtpError: error }
        }

        case SET_OTP_REFERENCE: {
            const { payload } = action
            return { ...state, otpRequestReference: payload }
        }

        case VALIDATE_CREDIT_OTP_PENDING: {
            return { ...state, validateOtpLoading: true}
        }

        case VALIDATE_CREDIT_OTP_SUCCESSFUL: {
            return { ...state, validateOtpLoading: false, validateOtpError: null }
        }

        case VALIDATE_CREDIT_OTP_ERROR: {
            const { payload } = action
            const { error } = payload
            return { ...state, validateOtpLoading: false,  validateOtpError: error }
        }

        case RESET_CREDIT_OTP_PAGE: {
            return initialState
        }

        default: {
            return state
        }
    }
}