import { Response } from "./OtpRepository";
import httpClient, { HttpClient } from "../../../../core/api/httpClient";
import { SERVICE_BASE_URL } from "../../../../config/properties";

export interface Offer {
  tenure: number;
  offerId: string;
  currency: string;
  interest: number;
  description: string;
  amountOffered: number;
  amountPayable: number;
  provider: {
    code: string;
    name: string;
    description: string;
    isBank: boolean;
  };
  initialPayment: number;
}

export interface GetOffersRequest {
  amount?: number;
  paymentId?: number;
  customerId?: string;
  merchantCode?: string;
  payableCode?: string;
  accountNumber?: string;
  providerCode?: string;
  merchantTransactionReference?: string;
}

export interface AcceptOfferRequest {
  offerId: string;
  authData?: string;
  cardHash?: string;
  customerId: string | undefined | null;
  otp?: string | null;
  providerCode: string;
  transactionRef?: string;
  accountNumber?: string;
  bankCode?: string;
}

export interface GetOffersResponse extends Response {
  offers: Offer[];
}

export type AcceptOfferResponse = Response;

interface OffersRepository {
  getOffers: (request: GetOffersRequest) => Promise<GetOffersResponse>;
  acceptOffer: (request: AcceptOfferRequest) => Promise<AcceptOfferResponse>;
}

class OffersRepositoryImpl implements OffersRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getOffers(request: GetOffersRequest): Promise<GetOffersResponse> {
    const response = await this.httpClient.post<GetOffersResponse>(
      `${SERVICE_BASE_URL}/api/v1/credit`,
      request
    );
    return response.data;
  }

  async acceptOffer(request: AcceptOfferRequest): Promise<AcceptOfferResponse> {
    const response = await this.httpClient.post<AcceptOfferResponse>(
      `${SERVICE_BASE_URL}/api/v1/credit/token/validate-pay`,
      request
    );
    return response.data;
  }
}

export default new OffersRepositoryImpl(httpClient);
