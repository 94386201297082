import { AxiosError } from "axios";
import { TriggerShowNotificationPayload } from "../../../../components/NotificationWidget/store/types";
import {
  WalletPaymentMethod,
  WalletUserData
} from "../../../../wallet/domain/repositories/WalletRepository";
import {
  AcceptOfferRequest,
  GetOffersRequest,
  Offer
} from "../../../domain/repositories/OffersRepository";
import { GetTokenWithOtpRequest } from "../../../domain/repositories/OtpRepository";

export const RESET_OFFERS_PAGE = "CREDIT.RESET_OFFERS_PAGE";
export const SIGNIN_SUCCESSFUL = "CREDIT.SIGNIN_SUCCESSFUL";

export const TRIGGER_GET_OFFERS = "CREDIT.TRIGGER_GET_OFFERS";
export const GET_OFFERS_PENDING = "CREDIT.GET_OFFERS_PENDING";
export const GET_OFFERS_SUCCESSFUL = "CREDIT.GET_OFFERS_SUCCESSFUL";
export const GET_OFFERS_ERROR = "CREDIT.GET_OFFERS_ERROR";

export const TRIGGER_ACCEPT_OFFER = "CREDIT.TRIGGER_ACCEPT_OFFER";
export const ACCEPT_OFFER_PENDING = "CREDIT.ACCEPT_OFFER_PENDING";
export const ACCEPT_OFFER_SUCCESSFUL = "CREDIT.ACCEPT_OFFER_SUCCESSFUL";
export const ACCEPT_OFFER_ERROR = "CREDIT.ACCEPT_OFFER_ERROR";

export const SET_CREDIT_PAGE_TITLE = "CREDIT.SET_CREDIT_PAGE_TITLE";
export const SET_CREDIT_PAGE_SUBTITLE = "CREDIT.SET_CREDIT_PAGE_SUBTITLE";
export const SET_BANK_PROVIDER = "CREDIT.SET_BANK_PROVIDER";

export const SET_SELECTED_OFFER = "CREDIT.SET_SELECTED_OFFER";

export const DISPLAY_SCREEN = "CREDIT.DISPLAY_SCREEN";

export const UPDATE_ACCEPT_OFFER_REQUEST = "CREDIT.UPDATE_ACCEPT_OFFER_REQUEST";

export interface SigninSuccessfulResponse {
  customerId?: string | null;
  userData?: WalletUserData | null;
  walletCards?: WalletPaymentMethod[] | null;
}

export interface OffersPageState extends SigninSuccessfulResponse {
  offers: readonly Offer[];
  authenticated: boolean;
  getOffersPending: boolean;
  pageTitle: string | null;
  pageSubtitle: string | null;
  acceptOfferPending: boolean;
  screenType: OffersScreenType | null;
  getOffersError: AxiosError | null;
  acceptOfferError: AxiosError | null;
  acceptOfferRequest: AcceptOfferRequest | null;
  transactionResponse: any;
  bankProvider: boolean;
  selectedOffer: Offer | null;
}

export interface ResetOffersPage {
  type: typeof RESET_OFFERS_PAGE;
}

export interface SigninSuccessful {
  type: typeof SIGNIN_SUCCESSFUL;
  payload: SigninSuccessfulResponse;
}

export interface TriggerGetOffers {
  type: typeof TRIGGER_GET_OFFERS;
  payload: {
    request: GetOffersRequest;
    onSuccessful?: () => void;
  };
}

export interface GetOffersPending {
  type: typeof GET_OFFERS_PENDING;
}

export interface GetOffersSuccessful {
  type: typeof GET_OFFERS_SUCCESSFUL;
  payload: {
    offers: Offer[];
  };
}

export interface GetOffersError {
  type: typeof GET_OFFERS_ERROR;
  payload: { error: AxiosError };
}

export interface TriggerAcceptOffer {
  type: typeof TRIGGER_ACCEPT_OFFER;
  payload: {
    request: AcceptOfferRequest;
    onSuccessful?: () => void;
  };
}

export interface AcceptOfferPending {
  type: typeof ACCEPT_OFFER_PENDING;
}

export interface AcceptOfferSuccessful {
  type: typeof ACCEPT_OFFER_SUCCESSFUL;
  payload: { transactionResponse: any };
}

export interface AcceptOfferError {
  type: typeof ACCEPT_OFFER_ERROR;
  payload: { error: AxiosError };
}

export interface SetCreditPageTitle {
  type: typeof SET_CREDIT_PAGE_TITLE;
  payload: { title: string };
}

export interface SetCreditPageSubTitle {
  type: typeof SET_CREDIT_PAGE_SUBTITLE;
  payload: { subtitle: string };
}

export interface SetBankProvider {
  type: typeof SET_BANK_PROVIDER;
  payload: boolean;
}

export interface SetSelectedOffer {
  type: typeof SET_SELECTED_OFFER;
  payload: {selectedOffer: Offer};
}

export enum OffersScreenType {
  otp,
  offer,
  cardDetails
}

export interface DisplayScreen {
  type: typeof DISPLAY_SCREEN;
  payload: { screenType: OffersScreenType };
}

export interface UpdateAcceptOfferRequest {
  type: typeof UPDATE_ACCEPT_OFFER_REQUEST;
  payload: { request: AcceptOfferRequest | null };
}

export interface GetOffersStoreStateProps {
  loading: boolean;
  currencyCode: string;
  offers: readonly Offer[];
  request: GetOffersRequest;
  getOffersError: AxiosError | null;
  bankProvider: boolean;
}

export interface GetOffersStoreDispatchProps {
  setPageTitle: (title: string) => void;
  setPageSubtitle: (subtitle: string) => void;
  displayScreen: (screenType: OffersScreenType) => void;
  getOffers: (request: GetOffersRequest, onSuccessful?: () => void) => void;
  triggerShowNotification: (payload: TriggerShowNotificationPayload) => void;
  setCreditPageBankProvider: (bankProvider: boolean) => void;
  setSelectedOffer: (selectedOffer: Offer) => void
}

export interface AcceptOffersStoreStateProps {
  offers: readonly Offer[];
}

export interface AcceptOffersStoreDispatchProps {
  displayScreen: (screenType: OffersScreenType) => void;
}

export interface ErrorStoreStateProps {
  getOffersLoading: boolean;
}

export interface ErrorStoreDispatchProps {
  reset: () => void;
  getOffers: (request: GetOffersRequest, onSuccessful?: () => void) => void;
}

export interface OfferStoreStateProps {
  transactionResponse: any;
  customerId?: string | null;
  getTokenWithOtpPending: boolean;
  screenType: OffersScreenType | null;
  acceptOfferPending: boolean;
  getTokenWithOtpError: AxiosError | null;
  acceptOfferError: AxiosError | null;
  acceptOfferRequest: AcceptOfferRequest | null;
  bankProvider: boolean;
  bankAcceptOfferRequest: AcceptOfferRequest;
  offers: readonly Offer[];
  paymentId?: number;
  merchantCode?: string;
  otpRequestReference?: string | null;
}

export interface OfferStoreDispatchProps {
  displayScreen: (serviceType: OffersScreenType) => void;
  completeTransaction: (transactionResponse: any) => void;
  acceptOffer: (request: AcceptOfferRequest, onSuccessful?: () => void) => void;
  tokenizeCardDetails: (request: GetTokenWithOtpRequest, onSuccessful?: () => void) => void;
}

export type OffersPageActionTypes =
  | ResetOffersPage
  | SigninSuccessful
  | TriggerGetOffers
  | GetOffersPending
  | GetOffersSuccessful
  | GetOffersError
  | TriggerAcceptOffer
  | AcceptOfferPending
  | AcceptOfferSuccessful
  | AcceptOfferError
  | SetCreditPageTitle
  | SetCreditPageSubTitle
  | DisplayScreen
  | UpdateAcceptOfferRequest
  | SetBankProvider | SetSelectedOffer;
