import axios, { AxiosError, AxiosInstance } from "axios";

export type HttpClient = AxiosInstance;

function createHttpClient() {
  const instance = axios.create();
  return instance;
}

export function initializeAxiosError(): AxiosError {
  const error = {
    isAxiosError: false,
    toJSON: function (): object {
      return error;
    },
    name: "",
    message: "",
    config: {},
    response: {
      data: null,
      status: 200,
      statusText: "",
      headers: null,
      config: {}
    }
  };
  return error;
}

const httpClient = createHttpClient();

export default httpClient;
