export const ColorTheme = {
  primaryColor: '#1aa7e8',
  primaryColorLight: '#007e9b',
  primaryColorDark: '#003c58',
  backgroundColor: '#eef2f7',
  font: {
    gray: 'rgba(24, 27, 36, 0.5)',
    dark_blue: '#152a38',
    placeholder_color: '#c2c3c5'
  },
  sectionDividerColor: 'rgba(21, 42, 56, 0.05)'
}

export const FormControlsTheme = {
  controlHeight: {
    default: 45,
  },
  padding: {
    side: 15
  },
  fontSize: {
    default: '1.6rem'
  },
  borderColor: '#e2e2e2',
  borderRadius: 4
}