import React, { ReactElement } from "react";
import styled from "styled-components";

interface ContainerProps {
  bgColor?: string;
  textColor?: string;
}

interface Props {
  bgColor?: string;
  textColor?: string;
  icon?: ReactElement | string;
  text: ReactElement | string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor || "#34C759"};
  border-radius: 5px;
  padding: 2px 5px;
  margin-bottom: 8px;

  .iconWrapper {
    margin-right: 4px;
    margin-top: 4px;
  }
  .text {
    color: ${(props) => props.textColor || "#ffffff"};
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
`;

const ChannelTag = (props: Props) => {
  return (
    <Container bgColor={props.bgColor} textColor={props.textColor}>
      <div className="iconWrapper">{props.icon}</div>
      <div className="text">{props.text}</div>
    </Container>
  );
};

export default ChannelTag;
