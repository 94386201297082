import { Bank } from "../../../domain/repositories/BanksRepository";
import * as Types from "./types";

const initialState: Types.SelectBankPageState = {
  banks: [] as Bank[],
  getBanksError: null,
  banksLoading: true,
  showLoanOffers: false,
  bankDetails: {} as Types.BankDetailsProps,
};

export default function SelectBanksPageReducer(
  state: Types.SelectBankPageState = initialState,
  action: Types.SelectBankPageActionTypes
): Types.SelectBankPageState {
  switch (action.type) {
    case Types.SET_BANKS: {
        const { payload } = action;
        return { 
            ...state, 
            banks: payload
        };
    }

    case Types.SET_BANKS_LOADING: {
        const { payload } = action;
        return { 
            ...state, 
            banksLoading: payload
        };
    }

    case Types.SET_SHOW_LOAN_OFFER: {
        const { payload } = action;
        return { 
            ...state, 
            showLoanOffers: payload
        };
    }

    case Types.SET_BANK_DETAILS: {
        const { payload } = action;
        return { 
            ...state, 
            bankDetails: payload 
        };
    }

    case Types.SET_BANKS_ERROR: {
        const { payload } = action;
        return { 
            ...state, 
            getBanksError: payload
        };
    }

    default: {
      return state;
    }
  }
}
