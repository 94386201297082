import { useState } from "react";
import PocketRepository from "../../../../domain/repositories/PocketRepository";
import { PaymentParams } from "../../../../../payment/presentation/store/paymentProperties/types";

export const useValidatePocketId = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const validateId = async (pocketId: string, params: PaymentParams) => {
    if (pocketId.trim().length === 0) {
      setError("Please enter your Pocket ID");
      return;
    }
    try {
      setLoading(true);
      const res = await PocketRepository.initialize({
        merchantCode: params.merchantCode,
        payableCode: params.payableCode,
        currencyCode: params.currencyCode,
        transactionReference: params.merchantTransactionReference,
        paymentId: params.paymentId,
        walletId: pocketId,
      });
      if (res.status === 200) {
        setSuccess(true);
      } else {
        setError("Failed to initiate payment");
      }
    } catch (error) {
      setError("Failed to initiate payment");
      setLoading(false);
    }
  };

  return {
    loading,
    validateId,
    success,
    error,
    setError,
  };
};
