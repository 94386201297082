import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import usePrevious from '../../../../../hooks/usePrevious';
import { AppState } from '../../../../../store/RootReducer';
import { PaymentChannelType, PaymentParams } from '../../../../payment/presentation/store/paymentProperties/types';
import { AuthenticateOtpResponse, AuthenticateOtpRequest, MakePaymentRequest, MakePaymentResponse } from '../../../domain/repositories/CardRepository';
import { getIsCardOtpValid } from '../../../utils/fieldValidators';
import { generateSecureData } from '../../../utils/secureGenerator';
import { resetAuthenticateOtp, triggerAuthenticateOtp } from '../../store/authenticateOtp/actions';
import { CachedCardParams } from '../../store/cachedCardDetails/types';

import { PageView, PageTitle, PageSubTitle, LabelFieldContainer, FieldErrorText, FormFieldStyles, FormControlLabel } from '../../../../components/Layout';
import BackControl from '../../../../components/BackControl';
import { Button } from '../../../../components/Button';
import { ColorTheme } from '../../../../../styling/theme';
import { OTP_REQUEST_INTERVAL_SEC } from '../../../../../config/properties';
import { asyncDelay } from '../../../../../core/util/asyncUtil';
import { resetMakePayment, triggerMakePayment } from '../../store/makePayment/actions';
import { completeTransaction } from '../../../../payment/presentation/store/paymentStatus/actions';
import { triggerShowNotification } from '../../../../components/NotificationWidget/store/actions';
import { TriggerShowNotificationPayload } from '../../../../components/NotificationWidget/store/types';
import { PaymentUtil } from '../../../../payment/util/PaymentUtil';
import { WalletUserData } from '../../../../wallet/domain/repositories/WalletRepository';
import { getResponseCodeDescription } from '../../../utils/CardUtils';
import { AxiosError } from 'axios';
import { getAxiosErrorData } from '../../../../../core/api/helpers';

const Container = styled(PageView)`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  width: 100%;
`;

const OtpInputFieldContainer = styled.div`
  position: relative;
`;

const ResendControlContainer = styled.div`
  position: absolute;
  top: calc(50% - (25px / 2));
  right: 15px;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ResendMessage = styled.p`
  font-family: monospace;
  font-size: 1.4rem;
  color: #a2a2a2;

  span {
    width: 15px;
    display: inline-block;
    text-align: center;
  }
`;

const ResendControl = styled.p`
  font-size: 1.4rem;
  color: ${ColorTheme.primaryColor};
  cursor: pointer;
`;


interface OtpInputFieldProps {
  error: boolean;
}

const OtpInputField = styled.input<OtpInputFieldProps>`
  ${FormFieldStyles};
`;


interface StoreStateProps {
  paymentParams: PaymentParams;
  currentPaymentChannel: PaymentChannelType;
  cachedCardParams: CachedCardParams;
  userData: WalletUserData | null;

  makePaymentPending: boolean;
  makePaymentError: AxiosError | null;
  makePaymentResponse: MakePaymentResponse | null;

  authenticateOtpPending: boolean;
  authenticateOtpError: AxiosError | null;
  authenticateOtpResponse: AuthenticateOtpResponse | null;
}

interface StoreDispatchProps {
  showNotification: (payload: TriggerShowNotificationPayload) => void;
  completeTransaction: (transactionResponse: any) => void;
  makePayment: (request: MakePaymentRequest) => void;
  authenticateOtp: (request: AuthenticateOtpRequest) => void;
  resetMakePayment: () => void;
  resetAuthenticateOtp: () => void;
}

interface OwnProps {

}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function CardOtpPage(props: Props) {
  const {
    paymentParams,
    currentPaymentChannel,
    cachedCardParams,
    userData,

    makePaymentPending,
    makePaymentError,
    makePaymentResponse,

    authenticateOtpPending,
    authenticateOtpError,
    authenticateOtpResponse,

    showNotification,
    completeTransaction,
    makePayment,
    authenticateOtp,

    resetMakePayment,
    resetAuthenticateOtp,
  } = props;

  const isPageMounted = useRef(true);
  const timerStopped = useRef(true);


  const prevMakePaymentPending = usePrevious(makePaymentPending);
  const prevAuthenticateOtpPending = usePrevious(authenticateOtpPending);

  const history = useHistory();

  const [otpValue, setOtpValue] = useState('');

  const [showFormErrors, setShowFormErrors] = useState(false);

  const [timeToExpSec, setTimeToExpSec] = useState(OTP_REQUEST_INTERVAL_SEC);

  const [showResendMessage, setShowResendMessage] = useState(false);

  const backControlHandler = () => {
    history.goBack();
  }

  const startTimer = async () => {
    timerStopped.current = false;

    while (true) {
      if (!isPageMounted.current || timerStopped.current) break;

      setTimeToExpSec(seconds => {
        const newSec = seconds - 1;

        if (newSec <= 0) {
          timerStopped.current = true;
          return 0;
        }

        return newSec;
      });

      await asyncDelay(1000);
    }
  };

  const resendOtp = () => {
    const { secure, pinBlock } = generateSecureData(
      cachedCardParams.cardNumber,
      cachedCardParams.cardExpiry,
      cachedCardParams.cardCvv,
      cachedCardParams.pin,
    );

    const { paymentMethod } = cachedCardParams;

    if (currentPaymentChannel === "WALLET" && paymentMethod) {
      if (!userData) return;

      const { mobileNo } = userData;
      const { name: walletCardName, cardIdentifier, walletInstrumentIdentifier } = paymentMethod;

      makePayment({
        secureData: secure,
        pinBlock: pinBlock,
        merchantCode: paymentParams.merchantCode,
        payableCode: paymentParams.payableCode,
        paymentId: paymentParams.paymentId,
        instrumentIdentifier: cardIdentifier,
        walletInfo: {
          walletId: mobileNo,
          walletCardName: walletCardName,
          walletIdentifier: walletInstrumentIdentifier,
        },
      });

      return;
    }

    makePayment({
      secureData: secure,
      pinBlock: pinBlock,
      merchantCode: paymentParams.merchantCode,
      payableCode: paymentParams.payableCode,
      paymentId: paymentParams.paymentId,
      googlePayToken: cachedCardParams.googlePayToken
    });
  };

  const {
    isValid: isCardOtpValid,
    message: cardOtpErrorMessage
  } = getIsCardOtpValid(otpValue);

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') authenticateOtpButtonHandler();
  };

  const authenticateOtpButtonHandler = () => {
    setShowFormErrors(true);

    if (!isCardOtpValid) {
      return;
    }

    const { secure, pinBlock } = generateSecureData(
      cachedCardParams.cardNumber,
      cachedCardParams.cardExpiry,
      cachedCardParams.cardCvv,
      cachedCardParams.pin
    );

    const { paymentMethod } = cachedCardParams;

    if (currentPaymentChannel === "WALLET" && paymentMethod) {
      if (!userData) return;

      const { mobileNo } = userData;
      const { name: walletCardName, cardIdentifier, walletInstrumentIdentifier } = paymentMethod;

      authenticateOtp({
        merchantCode: paymentParams.merchantCode,
        payableCode: paymentParams.payableCode,
        paymentId: paymentParams.paymentId,
        secureData: secure,
        pinBlock: pinBlock,
        twoFactorAuthenticationCode: otpValue,
        instrumentIdentifier: cardIdentifier,
        walletInfo: {
          walletId: mobileNo,
          walletCardName: walletCardName,
          walletIdentifier: walletInstrumentIdentifier,
        },
      });

      return;
    }

    authenticateOtp({
      merchantCode: paymentParams.merchantCode,
      payableCode: paymentParams.payableCode,
      paymentId: paymentParams.paymentId,
      secureData: secure,
      pinBlock: pinBlock,
      twoFactorAuthenticationCode: otpValue,
      googlePayToken: cachedCardParams.googlePayToken
    });
  };

  const handleOtpResendResponse = async () => {
    setShowResendMessage(true);
    await asyncDelay(3000);
    setShowResendMessage(false);

    if (makePaymentError) {
      const responseData = getAxiosErrorData(makePaymentError);

      if (!responseData) return;

      const { responseCode } = responseData;

      if (PaymentUtil.isTransactionComplete(responseCode)) {
        completeTransaction(responseData);
      }

      return;
    }

    setTimeToExpSec(OTP_REQUEST_INTERVAL_SEC);
    startTimer();
  };


  useEffect(() => {
    startTimer();

    return () => {
      resetMakePayment();
      resetAuthenticateOtp();
    };
  }, []);

  useEffect(() => {
    if (!(prevMakePaymentPending && !makePaymentPending)) return;

    handleOtpResendResponse();
  }, [makePaymentPending]);

  useEffect(() => {
    if (!(!authenticateOtpPending && prevAuthenticateOtpPending)) return;

    if (authenticateOtpError) {
      const responseData = getAxiosErrorData(authenticateOtpError);

      if (!responseData) {
        showNotification({ type: 'ERROR' });
        return;
      }

      const { responseCode } = responseData;

      if (PaymentUtil.isTransactionComplete(responseCode)) {
        completeTransaction(responseData);
        return;
      }

      showNotification({ type: 'ERROR' });
      return;
    }


    if (!authenticateOtpResponse) return;

    const { responseCode } = authenticateOtpResponse;

    if (PaymentUtil.isTransactionComplete(responseCode)) {
      completeTransaction(authenticateOtpResponse);
      return;
    }

    if (responseCode === 'T1') {
      showNotification({ type: 'ERROR', message: 'Invalid OTP provided', });
      return;
    }

    showNotification({
      type: 'ERROR',
      message: getResponseCodeDescription(responseCode),
    });
  }, [authenticateOtpPending]);

  let smsSent = false;
  let otpPhoneNumber: string | null = null;

  let emailSent = false;
  let otpEmail: string | null = null;

  if (makePaymentResponse && makePaymentResponse.smsSent) {
    smsSent = true;
    otpPhoneNumber = makePaymentResponse.mobilePhoneNumber || null;
  }

  if (makePaymentResponse && makePaymentResponse.emailSent) {
    emailSent = true;
    otpEmail = '';
  }

  let paymentProps = window.iswPaymentProps;
  const { acquiredBy } = paymentProps;

  return (
    <Container>
      <BackControl
        text="Back"
        onClick={backControlHandler}
      />

      <PageTitle>Enter OTP</PageTitle>

      {!smsSent && !emailSent && (
        <PageSubTitle>Enter the code sent to your phone, email or hardware token.</PageSubTitle>
      )}

      {smsSent && otpPhoneNumber && (
        <PageSubTitle>Enter the code sent to your phone number {otpPhoneNumber}</PageSubTitle>
      )}


      <FormContainer>
        <LabelFieldContainer>
          <FormControlLabel>OTP</FormControlLabel>

          <OtpInputFieldContainer>
            <OtpInputField
              placeholder="000000"
              inputMode="numeric"
              value={otpValue}
              onKeyUp={onKeyUpHandler}
              onChange={(evt) => setOtpValue(evt.target.value)}
              error={showFormErrors && !isCardOtpValid}
            />

            <ResendControlContainer>
              {timeToExpSec > 0 && (
                <ResendMessage>
                  resend in <span>{timeToExpSec}</span>
                </ResendMessage>
              )}

              {!makePaymentPending && !showResendMessage && timeToExpSec === 0 && (
                <ResendControl onClick={resendOtp}>
                  resend code
                </ResendControl>
              )}

              {makePaymentPending && (
                <ResendMessage>
                  sending
                </ResendMessage>
              )}

              {showResendMessage && (
                <ResendMessage>
                  {!makePaymentError && 'code sent'}
                  {makePaymentError && 'error'}
                </ResendMessage>
              )}
            </ResendControlContainer>
          </OtpInputFieldContainer>

          {showFormErrors && !isCardOtpValid && (
            <FieldErrorText>{cardOtpErrorMessage}</FieldErrorText>
          )}
        </LabelFieldContainer>

        <Button
          text={"Authorize"}
          color={acquiredBy === 'ZIB' ? "DANGER" : "PRIMARY"}
          onClick={authenticateOtpButtonHandler}
          loading={authenticateOtpPending}
          containerStyle={{ width: '100%' }} />
      </FormContainer>

    </Container>
  );
}



const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,
  currentPaymentChannel: state.payment.currentPaymentChannel as PaymentChannelType,
  cachedCardParams: state.card.cachedCardDetails.cachedCardParams as CachedCardParams,
  userData: state.wallet.userWalletData.userData,

  makePaymentPending: state.card.makePayment.makePaymentPending,
  makePaymentError: state.card.makePayment.makePaymentError,
  makePaymentResponse: state.card.makePayment.makePaymentResponse,

  authenticateOtpPending: state.card.authenticateOtp.authenticateOtpPending,
  authenticateOtpError: state.card.authenticateOtp.authenticateOtpError,
  authenticateOtpResponse: state.card.authenticateOtp.authenticateOtpResponse,
});

const mapDispatchToProps = (dispatch: (action: any) => void): StoreDispatchProps => ({
  showNotification(payload: TriggerShowNotificationPayload) {
    dispatch(triggerShowNotification(payload));
  },
  completeTransaction(transactionResponse: any) {
    dispatch(completeTransaction(transactionResponse));
  },
  makePayment(request: MakePaymentRequest) {
    dispatch(triggerMakePayment(request));
  },
  authenticateOtp(request: AuthenticateOtpRequest) {
    dispatch(triggerAuthenticateOtp(request));
  },
  resetMakePayment() {
    dispatch(resetMakePayment());
  },
  resetAuthenticateOtp() {
    dispatch(resetAuthenticateOtp());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CardOtpPage);