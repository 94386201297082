import * as React from "react";

/**
 * @param timeout milliseconds till countdown
 */
export interface CountdownProps {
  timeout: number;
  onCompleted?: () => void;
}

const calcTimeRemaining = (timeout: number) => {
  const now = new Date().getTime();
  const distance = timeout - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return {
    days: Math.max(0, days),
    hours: Math.max(0, hours),
    minutes: Math.max(0, minutes),
    seconds: Math.max(0, seconds),
  };
};

export default function useCountdownTimer(
  props: CountdownProps,
  enabled = true
) {
  const { timeout: _timeout, onCompleted } = props;

  const [time, setTime] = React.useState(() =>
    calcTimeRemaining(new Date().getTime() + _timeout)
  );

  const intervalID = React.useRef<any>();

  React.useEffect(() => {
    if (!enabled || intervalID.current) return;

    const timeout = new Date().getTime() + _timeout;

    setTime(calcTimeRemaining(timeout));

    intervalID.current = setInterval(() => {
      setTime(calcTimeRemaining(timeout));
    }, 1000);

    return () => clearInterval(intervalID.current);
  }, [enabled, _timeout]);

  React.useEffect(() => {
    const { days, hours, minutes, seconds } = time;

    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      clearInterval(intervalID.current);

      if (onCompleted) onCompleted();
    }
  }, [time, onCompleted]);

  const restart = React.useCallback(
    (__timeout?: number) => {
      clearInterval(intervalID.current);

      const timeout = new Date().getTime() + (__timeout ?? _timeout);

      intervalID.current = setInterval(() => {
        setTime(calcTimeRemaining(timeout));
      }, 1000);

      setTime(calcTimeRemaining(timeout));

      return () => clearInterval(intervalID.current);
    },
    [_timeout]
  );

  return {
    time,
    restart,
  };
}
