import { GetTransactionStatusRequest, GetTransactionStatusResponse } from "../../../domain/repositories/QrRepository";

export const TRIGGER_GET_TRANSACTION_STATUS = 'QR.TRIGGER_GET_TRANSACTION_STATUS';
export const GET_TRANSACTION_STATUS_PENDING = 'QR.GET_TRANSACTION_STATUS_PENDING';
export const GET_TRANSACTION_STATUS_SUCCESS = 'QR.GET_TRANSACTION_STATUS_SUCCESS';
export const GET_TRANSACTION_STATUS_ERROR = 'QR.GET_TRANSACTION_STATUS_ERROR';

export const RESET_GET_TRANSACTION_STATUS = 'QR.RESET_GET_TRANSACTION_STATUS';

export interface TriggerGetTransactionStatus {
  type: typeof TRIGGER_GET_TRANSACTION_STATUS;
  payload: {
    request: GetTransactionStatusRequest;
  }
}

export interface GetTransactionStatusPending {
  type: typeof GET_TRANSACTION_STATUS_PENDING;
}

export interface GetTransactionStatusSuccess {
  type: typeof GET_TRANSACTION_STATUS_SUCCESS;
  payload: {
    response: GetTransactionStatusResponse;
  }
}

export interface GetTransactionStatusError {
  type: typeof GET_TRANSACTION_STATUS_ERROR;
}

export interface ResetGetTransactionStatus {
  type: typeof RESET_GET_TRANSACTION_STATUS;
}


export type GetTransactionStatusActionType =
  | TriggerGetTransactionStatus
  | GetTransactionStatusPending
  | GetTransactionStatusSuccess
  | GetTransactionStatusError
  | ResetGetTransactionStatus;


export interface GetTransactionStatusState {
  getTransactionStatusPending: boolean;
  getTransactionStatusError: boolean;
  getTransactionStatusResponse: GetTransactionStatusResponse | null;
}