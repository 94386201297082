import { AxiosError } from "axios";
import { AuthenticateOtpRequest, AuthenticateOtpResponse } from "../../../domain/repositories/CardRepository";

export const TRIGGER_AUTHENTICATE_OTP = 'CARD.TRIGGER_AUTHENTICATE_OTP';
export const AUTHENTICATE_OTP_PENDING = 'CARD.AUTHENTICATE_OTP_PENDING';
export const AUTHENTICATE_OTP_SUCCESS = 'CARD.AUTHENTICATE_OTP_SUCCESS';
export const AUTHENTICATE_OTP_ERROR = 'CARD.AUTHENTICATE_OTP_ERROR';

export const RESET_AUTHENTICATE_OTP = 'CARD.RESET_AUTHENTICATE_OTP';

export interface TriggerAuthenticateOtp {
  type: typeof TRIGGER_AUTHENTICATE_OTP;
  payload: {
    request: AuthenticateOtpRequest;
  }
}

export interface AuthenticateOtpPending {
  type: typeof AUTHENTICATE_OTP_PENDING;
}

export interface AuthenticateOtpSuccess {
  type: typeof AUTHENTICATE_OTP_SUCCESS;
  payload: {
    response: AuthenticateOtpResponse;
  }
}

export interface AuthenticateOtpError {
  type: typeof AUTHENTICATE_OTP_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface ResetAuthenticateOtp {
  type: typeof RESET_AUTHENTICATE_OTP;
}


export type AuthenticateOtpActionType =
  | TriggerAuthenticateOtp
  | AuthenticateOtpPending
  | AuthenticateOtpSuccess
  | AuthenticateOtpError
  | ResetAuthenticateOtp;


export interface AuthenticateOtpState {
  authenticateOtpPending: boolean;
  authenticateOtpError: AxiosError | null;
  authenticateOtpResponse: AuthenticateOtpResponse | null;
}