import { CardinalDeviceInfo } from "../domain/repositories/CardRepository";

export default function getCardinalDeviceInformation(): CardinalDeviceInfo {
  return {
    // ipAddress: "",
    // httpAcceptBrowserValue: "",
    // httpAcceptContent: "",
    httpBrowserLanguage: window.navigator.language,
    httpBrowserJavaEnabled: window.navigator.javaEnabled(),
    httpBrowserJavaScriptEnabled: true,
    httpBrowserColorDepth: window.screen.colorDepth.toString(),
    httpBrowserScreenHeight: window.screen.height.toString(),
    httpBrowserScreenWidth: window.screen.width.toString(),
    httpBrowserTimeDifference: "",
    userAgentBrowserValue: window.navigator.userAgent,
    deviceChannel: "browser",
  };
}
