import styled from "styled-components";
import { ColorTheme } from "../../../../../styling/theme";
import { PageView, FormFieldStyles } from '../../../../components/Layout';

export const Container = styled(PageView)`
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 100%;
`;

export const InputField = styled.input`
  ${FormFieldStyles};
`;

export const PasswordContainer = styled.div`
  position: relative;
`;

export const PasswordControl = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  right: 10px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 5px;
  color: ${ColorTheme.primaryColor};
  letter-spacing: 1px;
  cursor: pointer;
  user-select: none;
`;

