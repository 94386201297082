import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { shuffleArray } from '../../../core/util/arrayUtils';
import { FormControlsTheme } from '../../../styling/theme';

import { ReactComponent as BackspaceIcon } from './backspace-icon.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface InputsContainerProps {
  error: boolean;
}

const InputsContainer = styled.div<InputsContainerProps>`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;

  input {
    min-width: 0px;
    width: 100%;
    height: 20px;
    flex: 1;
    display: flex;
    margin-right: 20px;
    outline: none;
    border: none;
    border-bottom: 2px solid ${FormControlsTheme.borderColor};
    text-align: center;
    font-size: 1.8rem;
    padding: 15px 0px;
    font-size: 3rem;

    ${({ error }) => error && css`
      border-bottom-color: red;
    `}
  }
`;

const KeysContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const KEY_BUTTON_MARGIN_RIGHT = 15;

const KeyButton = styled.div`
  flex-shrink: 0;
  width: calc(${95 / 6}% - ${KEY_BUTTON_MARGIN_RIGHT * 5 / 6}px);
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-right: ${KEY_BUTTON_MARGIN_RIGHT}px;
  background-color: #f1f1f1;
  box-shadow: 0 2px 0 0 #c0d5e2;
  transition: box-shadow 0.1s linear;
  user-select: none;
  cursor: pointer;

  p {
    font-weight: bold;
  }

  &:active {
    opacity: 0.6;
  }

  &:nth-child(6n) {
    p {
      font-size: 1.4rem;
    }
  }

  svg {
    height: 23px;
  }
`;

type PinPadKey = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "DEL" | "CLR";

function generateRandomNumKeys(): PinPadKey[] {
  const numKeysRandom: PinPadKey[] = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  shuffleArray(numKeysRandom);
  return numKeysRandom;
}

interface Props {
  value: string;
  lengthLimit: number;
  error: boolean;
  onValueChange: (value: string) => void;
}

export default function PinInputField(props: Props) {
  const { value, onValueChange, lengthLimit, error } = props;

  const numKeysRef = useRef(generateRandomNumKeys());
  const numKeys = numKeysRef.current;

  const numPadKeys: PinPadKey[] = [
    ...numKeys.slice(0, numKeys.length / 2),
    "DEL",
    ...numKeys.slice(numKeys.length / 2),
    "CLR"
  ];

  const keyButtonHanlder = (key: PinPadKey) => {
    // Vibrate keypad press on mobile
    if(navigator && navigator.vibrate) {
      navigator.vibrate(30);
    }

    if (key === "DEL") {
      onValueChange(value.slice(0, value.length - 1));
      return;
    }

    if (key === "CLR") {
      onValueChange("");
      return;
    }

    // Prevent more keys if limit hit
    if (value.length >= lengthLimit) {
      return;
    }

    onValueChange(value + key);
  };

  return (
    <Container>
      <InputsContainer error={error}>
        {[0, 1, 2, 3].map(index => {
          return (
            <input
              key={index}
              value={value[index] || ''}
              type="password"
              inputMode="numeric"
              maxLength={1}
              readOnly
              autoComplete='off'
            />
          );
        })}
      </InputsContainer>

      <KeysContainer>
        {numPadKeys.map(numPadKey => {
          return (
            <KeyButton
              key={numPadKey}
              aria-label={`pinpad-button-${numPadKey}`}
              role="button"
              onClick={() => keyButtonHanlder(numPadKey)}
            >

              {numPadKey !== "DEL" && (
                <p>{numPadKey}</p>
              )}

              {numPadKey === "DEL" && (
                <BackspaceIcon />
              )}

            </KeyButton>
          );
        })}
      </KeysContainer>
    </Container>
  );
}