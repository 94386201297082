import {
  GET_STATUS_PENDING,
  GET_STATUS_SUCCESS,
  GET_STATUS_ERROR,
  RESET_GET_STATUS,
  GetStatusState,
  GetStatusActionType,
} from "./types";

const initialState: GetStatusState = {
  getStatusPending: false,
  getStatusError: false,
  getStatusResponse: null,
};

export function GetStatusReducer(
  state: GetStatusState = initialState,
  action: GetStatusActionType
): GetStatusState {
  switch (action.type) {
    case GET_STATUS_PENDING: {
      return {
        ...state,
        getStatusPending: true,
      };
    }

    case GET_STATUS_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      return {
        ...state,
        getStatusPending: false,
        getStatusError: false,
        getStatusResponse: response,
      };
    }

    case GET_STATUS_ERROR: {
      return {
        ...state,
        getStatusPending: false,
        getStatusError: true,
      };
    }

    case RESET_GET_STATUS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
