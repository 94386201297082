import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncDelay } from "../../../../../core/util/asyncUtil";

import { AppState } from "../../../../../store/RootReducer";
import {
  getCurrencyString,
  getFormattedPrice,
  getMajorAmountString,
} from "../../../../../util/currencyUtil";
import { showNotification } from "../../../../components/NotificationWidget/store/actions";
import { NonCardOption } from "../../../../payment/presentation/store/paymentProperties/types";
import useLoadScript from "./useLoadScript";

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
};
// const allowedCardNetworks: google.payments.api.CardNetwork[] = [
//   "MASTERCARD",
//   "VISA",
// ];
const allowedCardAuthMethods: google.payments.api.CardAuthMethod[] = [
  "PAN_ONLY",
  "CRYPTOGRAM_3DS",
];
// const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification =
//   {
//     type: "PAYMENT_GATEWAY",
//     parameters: {
//       gateway: "interswitch",
//       gatewayMerchantId: "googletest",
//     },
//   };

// const cardPaymentMethod:
//   | google.payments.api.IsReadyToPayPaymentMethodSpecification
//   | google.payments.api.PaymentMethodSpecification = {
//   type: "CARD",
//   parameters: {
//     allowedAuthMethods: allowedCardAuthMethods,
//     allowedCardNetworks: allowedCardNetworks,
//   },
//   tokenizationSpecification: tokenizationSpecification,
// };

// const googlePayConfig:
//   | google.payments.api.IsReadyToPayRequest
//   | google.payments.api.PaymentDataRequest = {
//   ...baseRequest,
//   allowedPaymentMethods: [cardPaymentMethod],
// };

type GooglePayAdditionalInfoConfig = {
  googlePay: {
    allowedCardNetworks: google.payments.api.CardNetwork[];
    environment: google.payments.api.Environment;
    gateway: string;
    gatewayMerchantId: string;
  };
};

export default function useInitGooglePay() {
  const [googlePayIsLoaded, setGooglePayIsLoaded] = React.useState(false);
  const [paymentData, setPaymentData] =
    React.useState<google.payments.api.PaymentMethodData>();

  const googlePayClient = React.useRef<google.payments.api.PaymentsClient>();

  const {
    paymentParams,
    paymentChannel: {
      additionalInformation: { googlePay },
    },
  } = useSelector((state: AppState) => ({
    paymentParams: state.payment.paymentProperties.paymentParams,
    paymentChannel: state.payment.paymentProperties.paymentChannels?.find(
      (channel) => channel.providerCode === state.payment.currentPaymentChannel
    ) as NonCardOption & {
      additionalInformation: GooglePayAdditionalInfoConfig;
    },
  }));

  const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification =
    React.useMemo(
      () => ({
        type: "PAYMENT_GATEWAY",
        parameters: {
          gateway: googlePay.gateway,
          gatewayMerchantId: googlePay.gatewayMerchantId,
        },
      }),
      [googlePay]
    );

  const cardPaymentMethod:
    | google.payments.api.IsReadyToPayPaymentMethodSpecification
    | google.payments.api.PaymentMethodSpecification = React.useMemo(
    () => ({
      type: "CARD",
      parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: googlePay.allowedCardNetworks,
      },
      tokenizationSpecification: tokenizationSpecification,
    }),
    [googlePay, tokenizationSpecification]
  );

  const googlePayConfig:
    | google.payments.api.IsReadyToPayRequest
    | google.payments.api.PaymentDataRequest = React.useMemo(
    () => ({
      ...baseRequest,
      allowedPaymentMethods: [cardPaymentMethod],
    }),
    [cardPaymentMethod]
  );

  const dispatch = useDispatch();

  const paymentDataRequest = React.useMemo<
    google.payments.api.PaymentDataRequest | undefined
  >(
    () =>
      paymentParams
        ? {
            ...(googlePayConfig as google.payments.api.PaymentDataRequest),

            merchantInfo: { merchantId: googlePay.gatewayMerchantId },

            transactionInfo: {
              currencyCode: getCurrencyString(paymentParams.currencyCode),
              totalPrice: getMajorAmountString(paymentParams.checkoutAmount),
              totalPriceStatus: "FINAL",
            },
          }
        : undefined,
    [paymentParams, googlePayConfig, googlePay]
  );

  const processPayment = async (
    paymentData: google.payments.api.PaymentMethodData
  ) => {
    let attempts = 0;
    asyncDelay(500);
    setPaymentData(paymentData);

    if (attempts++ % 2 === 0) {
      return Promise.reject(
        new Error("Every other attempt fails, next one should succeed")
      );
    } else {
      return Promise.resolve({});
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onPaymentAuthorized = React.useCallback(function onPaymentAuthorized(
    paymentData: google.payments.api.PaymentData
  ): Promise<google.payments.api.PaymentAuthorizationResult> {
    return new Promise(function (resolve, reject) {
      // handle the response
      processPayment(paymentData.paymentMethodData)
        .then(function () {
          resolve({ transactionState: "SUCCESS" });
        })
        .catch(function () {
          resolve({
            transactionState: "ERROR",
            error: {
              intent: "PAYMENT_AUTHORIZATION",
              message:
                "An error occurred, kindly use a different payment option",
              reason: "PAYMENT_DATA_INVALID",
            },
          });
        });
    });
  },
  []);

  const onGooglePayButtonClicked = React.useCallback(async () => {
    if (!paymentDataRequest || !googlePayClient.current) return;
    console.log("Pay!!!");

    try {
      const response = await googlePayClient.current.loadPaymentData({
        ...paymentDataRequest,
      });

      if (response?.paymentMethodData) {
        setPaymentData(response.paymentMethodData);
      }
    } catch (error) {
      console.error({ error });
    }
  }, [paymentDataRequest]);

  const prefetchGooglePaymentData = React.useCallback(() => {
    if (!paymentDataRequest || !googlePayClient.current) return;

    googlePayClient.current.prefetchPaymentData(paymentDataRequest);
  }, [paymentDataRequest]);

  const onGooglePayLoaded = React.useCallback(async () => {
    setGooglePayIsLoaded(true);
    console.log("Google pay loaded!!");

    googlePayClient.current = new google.payments.api.PaymentsClient({
      environment: googlePay.environment,
      // paymentDataCallbacks: { onPaymentAuthorized },
    });

    try {
      const response = await googlePayClient.current.isReadyToPay(
        googlePayConfig
      );
      if (response.result) {
        prefetchGooglePaymentData();

        const googlePayButton = googlePayClient.current.createButton({
          buttonSizeMode: "fill",
          onClick: onGooglePayButtonClicked,
          allowedPaymentMethods: [cardPaymentMethod],
        });
        if (googlePayButton) {
          document
            .getElementById("button-container")
            ?.appendChild(googlePayButton);
        }
      } else {
        // @todo show some message
        dispatch(showNotification({ type: "ERROR" }));
      }
    } catch (error) {
      // show error in developer console for debugging
      console.error({ error });
    }
  }, [
    onGooglePayButtonClicked,
    prefetchGooglePaymentData,
    dispatch,
    googlePay,
    googlePayConfig,
    cardPaymentMethod,
    // onPaymentAuthorized
  ]);

  const cleanupGoogleIframe = () => {
    const iframes = Array.from(document.getElementsByTagName("iframe"));
    iframes.forEach((iframe) => {
      if (iframe.src.includes("https://pay.google.com/gp/p/ui/payframe")) {
        iframe.remove();
      }
    });
  };

  useLoadScript(
    React.useMemo(
      () => ({
        url: "https://pay.google.com/gp/p/js/pay.js",
        onLoad: onGooglePayLoaded,
        cleanup: cleanupGoogleIframe,
      }),
      [onGooglePayLoaded]
    )
  );

  return {
    googlePayIsLoaded,
    amountString: paymentParams
      // ? getFormattedPrice(paymentParams.checkoutAmount, paymentParams.currencyCode)
      ? getFormattedPrice(paymentParams.amount, paymentParams.currencyCode)
      : undefined,
    paymentData,
  };
}
