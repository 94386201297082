import { AxiosError } from "axios";
import { CreatePaymentRequest, CreatePaymentResponse } from "../../../domain/repositories/TerrapayRepository";

import {
  TRIGGER_CREATE_PAYMENT,
  TriggerCreatePayment,

  CREATE_PAYMENT_PENDING,
  CreatePaymentPending,

  CREATE_PAYMENT_SUCCESS,
  CreatePaymentSuccess,

  CREATE_PAYMENT_ERROR,
  CreatePaymentError,

  RESET_CREATE_PAYMENT,
  ResetCreatePayment,
  CreatePaymentCallback
} from "./types";

export const triggerCreatePayment = (request: CreatePaymentRequest ): TriggerCreatePayment => {
  return {
    type: TRIGGER_CREATE_PAYMENT,
    payload: { request }
  };
};

export const createPaymentPending = (): CreatePaymentPending => {
  return {
    type: CREATE_PAYMENT_PENDING
  };
};

export const createPaymentSuccess = (response: CreatePaymentResponse): CreatePaymentSuccess => {
  return {
    type: CREATE_PAYMENT_SUCCESS,
    payload: { response }
  };
};

export const createPaymentError = (error: AxiosError): CreatePaymentError => {
  return {
    type: CREATE_PAYMENT_ERROR,
    payload: { error },
  };
};

export const resetCreatePayment = (): ResetCreatePayment => {
  return {
    type: RESET_CREATE_PAYMENT,
  };
};