import { takeLatest, put } from "redux-saga/effects";
import { AxiosError } from "axios";

import cardRepository, { GetBinConfigurationResponse } from "../../../domain/repositories/CardRepository";

import { 
  TRIGGER_GET_BIN_CONFIGURATION, 
  TriggerGetBinConfiguration,

  RESET_GET_BIN_CONFIGURATION,
} from "./types";

import {
  getBinConfigurationPending,
  getBinConfigurationSuccess,
  getBinConfigurationError
} from "./actions";
import { createCancellableSaga } from "../../../../../store/cancellableSaga";
import { getAxiosErrorData } from "../../../../../core/api/helpers";
import { getResponseCodeDescription } from '../../../utils/CardUtils';


function* handleTriggerFetchStoreDetails(action: TriggerGetBinConfiguration) {
  const { payload} = action;
  const { request } = payload;

  yield put(getBinConfigurationPending());

  let response: GetBinConfigurationResponse;

  try {
    response = yield cardRepository.getBinConfiguration(request) ;
  } catch (err) {
    const error = getAxiosErrorData(err as AxiosError);
    const errorMessage = error ? getResponseCodeDescription(error.responseCode) : undefined
    console.log({err, error, errorMessage});

    yield put(getBinConfigurationError(errorMessage));
    return;
  }

  yield put(getBinConfigurationSuccess(response));
}



function* watchGetBinConfiguration() {
  yield takeLatest(TRIGGER_GET_BIN_CONFIGURATION, handleTriggerFetchStoreDetails);
}

export const getBinConfigurationSaga = createCancellableSaga(
  watchGetBinConfiguration, RESET_GET_BIN_CONFIGURATION
);