import { AxiosError } from "axios";
import { ValidateRequestData, ValidateRequestResponse } from "../../../domain/repositories/TerrapayRepository";

import {
  TRIGGER_VALIDATE_REQUEST,
  TriggerValidateRequest,

  VALIDATE_REQUEST_PENDING,
  ValidateRequestPending,

  VALIDATE_REQUEST_SUCCESS,
  ValidateRequestSuccess,

  VALIDATE_REQUEST_ERROR,
  ValidateRequestError,

  RESET_VALIDATE_REQUEST,
  ResetValidateRequest,
  ValidateRequestCallback,
  SaveWalletID,
  SAVE_WALLET_ID
} from "./types";


export const triggerValidateRequest = (request: ValidateRequestData, onSuccessful?: ValidateRequestCallback ): TriggerValidateRequest => {
  return {
    type: TRIGGER_VALIDATE_REQUEST,
    payload: { request, onSuccessful }
  };
};

export const validateRequestPending = (): ValidateRequestPending => {
  return {
    type: VALIDATE_REQUEST_PENDING
  };
};

export const validateRequestSuccess = (response: ValidateRequestResponse): ValidateRequestSuccess => {
  return {
    type: VALIDATE_REQUEST_SUCCESS,
    payload: { response }
  };
};

export const validateRequestError = (error: AxiosError): ValidateRequestError => {
  return {
    type: VALIDATE_REQUEST_ERROR,
    payload: { error },
  };
};

export const resetValidateRequest = (): ResetValidateRequest => {
  return {
    type: RESET_VALIDATE_REQUEST,
  };
};

export const saveWalletID = (walletID: string): SaveWalletID => {
  return {
    type: SAVE_WALLET_ID,
    payload: walletID
  };
};

