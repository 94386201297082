import React from "react";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "../icons/close.svg";

type TProps = {
  data: Array<string>;
  handleClose: () => void;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #00000075;
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .wrapper {
    width: 290px;
    height: 340px;
    background-color: #fff;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 16px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.13);
  > h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  > .icon {
    cursor: pointer;
  }
`;

const DataWrapper = styled.div`
  box-sizing: border-box;
  padding: 24px;

  > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 10px;
  }
  > ul {
    > li {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
  }
`;
export const ListOfBanks = ({ data, handleClose }: TProps) => {
  return (
    <Container>
      <div className="wrapper">
        <TopWrapper>
          <h4>Eligible Apps</h4>
          <CloseIcon className="icon" onClick={handleClose} />
        </TopWrapper>
        <DataWrapper>
          <p>
            The following bank apps are currently available for use with this
            feature:
          </p>
          <ul>
            {data.map((i) => (
              <li key={i}>{i}</li>
            ))}
          </ul>
        </DataWrapper>
      </div>
    </Container>
  );
};
