import React, { CSSProperties, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { PAGE_SIDE_PADDING } from '../../../styling/constants';
import { NotificationType } from './store/types';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg';
import { DEFAULT_ERROR_MESSAGE } from '../../../config/properties';

const FadeInAnimation = keyframes`
  0% {
    opacity: 0;
  };
  100% {
    opacity: 1;
  };
`;

const Container = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  animation: ${FadeInAnimation} 0.3s ease-out;
`;


interface ContentContainerProps {
  type: NotificationType;
}


const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 20px ${PAGE_SIDE_PADDING}px;
  background: ${({type}) => type === 'INFO' ? '#1aa7e8' : '#cf2d2d'};
  border-radius: 3px;
`;

const Message = styled.div`
  flex: 1;
  margin-right: 20px;

  p {
    color: white;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
`;

const CloseControl = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
    fill: white;
  }
`;

interface Props {
  type: NotificationType;
  message?: string;
  closeHandler: () => void;
}

export default function NotificationWidget(props: Props) {
  const { message, type, closeHandler, } = props;

  let notifcationMessage = message;

  if (!message && type === 'ERROR') {
    notifcationMessage = DEFAULT_ERROR_MESSAGE;
  }

  if (!message && type === 'WARNING') {
    notifcationMessage = DEFAULT_ERROR_MESSAGE;
  }

  return (
    <Container>
      <ContentContainer type={type}>
        <Message>
          <p>{notifcationMessage}</p>
        </Message>

        <CloseControl onClick={closeHandler}>
          <CloseIcon />
        </CloseControl>
      </ContentContainer>
    </Container>
  );
}