import * as React from "react";
import { useHistory } from "react-router-dom";

import {
  getIsCardCvvValid,
  // getIsCardExpiryValid,
} from "../../../card/utils/fieldValidators";
import BackControl from "../../../components/BackControl";
import { Button } from "../../../components/Button";
import CvvInputField from "../../../components/CvvInputField";
// import ExpiryInputField from "../../../components/ExpiryInputField";
import {
  FieldErrorText,
  FieldLabel,
  FormFieldsRow,
  LabelFieldContainer,
} from "../../../components/Layout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { PAYMENT_METHODS__ROOT } from "../../../Routes";
import useInitGooglePay from "./hooks/useInitGooglePay";
import usePay from "./hooks/usePay";
import { Container } from "./styles";

export default function GooglePayRootPage() {
  const history = useHistory();

  const goBack = () => {
    history.push(PAYMENT_METHODS__ROOT);
  };

  const { amountString, paymentData, googlePayIsLoaded } = useInitGooglePay();

  const [cvv, setCVV] = React.useState("");
  // const [exp, setExp] = React.useState("");
  const [showFormError, setShowFormError] = React.useState(false);

  const { isValid: isCardCvvValid, message: cardCvvErrorMessage } =
    React.useMemo(() => getIsCardCvvValid(cvv), [cvv]);

  // const { isValid: isCardExpValid, message: cardExpErrorMessage } =
  //   React.useMemo(() => getIsCardExpiryValid(exp, new Date()), [exp]);

  const { pay, loading } = usePay();

  const onSubmit = () => {
    setShowFormError(true);
    if (!isCardCvvValid || !paymentData) return;

    pay({ ...paymentData, cvv });
  };

  return (
    <Container>
      <BackControl text="Change payment method" onClick={goBack} />
      <div className="header">
        <h4>Pay with Google Pay</h4>
        <p>Click on the button below to start payment</p>
      </div>

      {googlePayIsLoaded ? (
        <div id="button-container"></div>
      ) : (
        <div className="loader-box">
          <LoadingIndicator color="#1aa7e8" />
        </div>
      )}

      {paymentData && (
        <>
          <FormFieldsRow>
            <LabelFieldContainer>
              <FieldLabel htmlFor="card-cvv">CVV</FieldLabel>

              <CvvInputField
                id="card-cvv"
                autoFocus
                value={cvv}
                onValueChange={setCVV}
                error={showFormError && !isCardCvvValid}
              />

              {showFormError && !isCardCvvValid && (
                <FieldErrorText>{cardCvvErrorMessage}</FieldErrorText>
              )}
            </LabelFieldContainer>
          </FormFieldsRow>
          <Button
            color="PRIMARY"
            text={`Pay ${amountString ?? ""}`}
            onClick={onSubmit}
            loading={loading}
          />
        </>
      )}
    </Container>
  );
}
