import { AxiosError } from "axios";
import { GetCountryListResponse, CountryData } from "../../../domain/repositories/TerrapayRepository";
// import { SigninUserRequest, SigninUserResponse } from "../../../domain/repositories/WalletRepository";

import {
  TRIGGER_GET_COUNTRY_LIST,
  TriggerGetCountryList,

  GET_COUNTRY_LIST_PENDING,
  GetCountryListPending,

  GET_COUNTRY_LIST_SUCCESS,
  GetCountryListSuccess,

  GET_COUNTRY_LIST_ERROR,
  GetCountryListError,

  RESET_GET_COUNTRY_LIST,
  ResetGetCountryList,
} from "./types";

export const triggerGetCountryList = (): TriggerGetCountryList => {
  return {
    type: TRIGGER_GET_COUNTRY_LIST,
  };
};

export const getCountryListPending = (): GetCountryListPending => {
  return {
    type: GET_COUNTRY_LIST_PENDING
  };
};

export const getCountryListSuccess = (response: GetCountryListResponse): GetCountryListSuccess => {
  return {
    type: GET_COUNTRY_LIST_SUCCESS,
    payload: { response }
  };
};

export const getCountryListError = (error: AxiosError): GetCountryListError => {
  return {
    type: GET_COUNTRY_LIST_ERROR,
    payload: { error },
  };
};

export const resetGetCountryList = (): ResetGetCountryList => {
  return {
    type: RESET_GET_COUNTRY_LIST,
  };
};