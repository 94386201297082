import {
  VALIDATE_REQUEST_PENDING,
  VALIDATE_REQUEST_SUCCESS,
  VALIDATE_REQUEST_ERROR,
  RESET_VALIDATE_REQUEST,
  ValidateRequestState,
  ValidateRequestActionType,
  SAVE_WALLET_ID,
} from "./types";

const initialState: ValidateRequestState = {
  loading: false,
  loadingError: null,
  data: "",
  walletID: ""
}

export function validateRequestReducer(
  state: ValidateRequestState = initialState,
  action: ValidateRequestActionType
): ValidateRequestState {
  switch (action.type) {

    case VALIDATE_REQUEST_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case VALIDATE_REQUEST_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      const { data } = response;

      return {
        ...state,
        loading: false,
        loadingError: null,
        data: data,
      };
    }

    case VALIDATE_REQUEST_ERROR: {
      const { payload } = action;
      const { error } = payload;

      return {
        ...state,
        loading: false,
        loadingError: error,
      };
    }

    case SAVE_WALLET_ID: {
      const { payload } = action;
      return {
        ...state,
        walletID: payload
      };
    }

    case RESET_VALIDATE_REQUEST: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
