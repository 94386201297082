import {
  GET_TRANSACTION_STATUS_PENDING,
  GET_TRANSACTION_STATUS_SUCCESS,
  GET_TRANSACTION_STATUS_ERROR,
  RESET_GET_TRANSACTION_STATUS,
  GetTransactionStatusState,
  GetTransactionStatusActionType,
} from "./types";

const initialState: GetTransactionStatusState = {
  getTransactionStatusPending: false,
  getTransactionStatusError: false,
  getTransactionStatusResponse: null,
  errorMessage: undefined
}

export function GetTransactionStatusReducer(
  state: GetTransactionStatusState = initialState,
  action: GetTransactionStatusActionType
): GetTransactionStatusState {
  switch (action.type) {

    case GET_TRANSACTION_STATUS_PENDING: {
      return {
        ...state,
        getTransactionStatusPending: true
      };
    }

    case GET_TRANSACTION_STATUS_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      return {
        ...state,
        getTransactionStatusPending: false,
        getTransactionStatusError: false,
        errorMessage: undefined,
        getTransactionStatusResponse: response
      };
    }

    case GET_TRANSACTION_STATUS_ERROR: {
      return {
        ...state,
        getTransactionStatusPending: false,
        getTransactionStatusError: true,
        errorMessage: action.payload
      };
    }

    case RESET_GET_TRANSACTION_STATUS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
