import {
  INITIALIZE_PENDING,
  INITIALIZE_SUCCESS,
  INITIALIZE_ERROR,
  RESET_INITIALIZE,
  InitializeState,
  InitializeActionType,
} from "./types";

const initialState: InitializeState = {
  initializePending: false,
  initializeError: false,
  initializeResponse: null,
  initializeDate: null,
};

export function InitializeReducer(
  state: InitializeState = initialState,
  action: InitializeActionType
): InitializeState {
  switch (action.type) {
    case INITIALIZE_PENDING: {
      return {
        ...state,
        initializePending: true,
      };
    }

    case INITIALIZE_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      return {
        ...state,
        initializePending: false,
        initializeError: false,
        initializeResponse: response,
        initializeDate: new Date(),
      };
    }

    case INITIALIZE_ERROR: {
      return {
        ...state,
        initializePending: false,
        initializeError: true,
      };
    }

    case RESET_INITIALIZE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
