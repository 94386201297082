
import {
  GetCountryListState,
  GetCountryListActionType,
  GET_COUNTRY_LIST_PENDING,
  GET_COUNTRY_LIST_SUCCESS,
  RESET_GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_ERROR,
} from "./types";

const initialState: GetCountryListState = {
  loading: false,
  loadingError: null,
  countries: [
    {
        id: "0",
        label: "234",
        countryCode: "234",
        icon: "https://www.worldometers.info/img/flags/ni-flag.gif"
    },
    {
        id: "1",
        label: "233",
        countryCode: "233",
        icon: "https://www.worldometers.info/img/flags/gh-flag.gif"
    }
  ],
}

export function getCountryListReducer(
  state: GetCountryListState = initialState,
  action: GetCountryListActionType
): GetCountryListState {
  switch (action.type) {

    case GET_COUNTRY_LIST_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_COUNTRY_LIST_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      const { data } = response;

      return {
        ...state,
        loading: false,
        loadingError: null,
        countries: data
      };
    }

    case GET_COUNTRY_LIST_ERROR: {
      const { payload } = action;
      const { error } = payload;

      return {
        ...state,
        loading: false,
        loadingError: error,
      };
    }

    case RESET_GET_COUNTRY_LIST: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
