//Steps for How to Pay view for MoMo
export const BatchA = [
  "Register your MTN mobile number on MoMo using either the MoMo mobile app or USSD code.",
  "Create your unique PIN for MoMo",
  "Fund your MoMo wallet from your bank apps by transferring to MoMo Payment Service bank, using your phone number as the account number",
];

export const BatchB = [
  "Enter your registered phone number.",
  "You will receive a notification on your phone prompting you to authorize the transaction using your MoMo PIN.",
  'When you have successfully authorized the payment, click on "I have authorized the transaction" to complete the payment.',
];
