import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ColorTheme } from '../../styling/theme';

const DEFAULT_MESSAGE = 'Oops, something went wrong...';
const DEFAULT_ACTION_TEXT = 'Retry';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Message = styled.p`
  text-align: center;
`;

const ActionControl = styled.div`
  padding: 10px 20px;
  cursor: pointer;
`;

const ActionText = styled.p`
  font-size: 1.4rem;
  color: ${ColorTheme.primaryColor};
`;

interface Props {
  message?: string;
  actionText?: string;
  action?: () => void;
}
export default function ErrorView(props: Props) {
  const { message, actionText, action } = props;

  return (
    <Container>
      <Message>
        {message || DEFAULT_MESSAGE}
      </Message>

      {action && (
        <ActionControl onClick={action}>
          <ActionText>
            {actionText || DEFAULT_ACTION_TEXT}
          </ActionText>
        </ActionControl>
      )}
    </Container>
  );
}