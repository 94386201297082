import * as React from "react";

type loadScriptParams = {
  url: string;
  onLoad?: () => void;
  onError?: (...arg: any) => void;
  cleanup?: () => void;
};

const getScriptByUrl = (url: string) => {
  const scripts = document.getElementsByTagName("script");

  for (var i = 0; i < scripts.length; i++) {
    if (scripts[i].getAttribute("src") === url) {
      return scripts[i];
    }
  }

  return null;
};

export default function useLoadScript(params: loadScriptParams) {
  React.useEffect(() => {
    const { url, onLoad, onError, cleanup } = params;

    let script = getScriptByUrl(url);
    const exists = script !== null;

    if (!script) {
      script = document.createElement("script");
      script.src = url;
      script.async = true;
    } else {
      if (script.hasAttribute("loaded") && onLoad) {
        onLoad();
      }

      if (script.hasAttribute("error") && onError) {
        onError();
      }
    }

    if (onLoad) {
      script.addEventListener("load", () => {
        script?.setAttribute("loaded", "true");
        onLoad();
      });
    }

    if (onError) {
      script.addEventListener("error", () => {
        script?.setAttribute("error", "true");
        onError();
      });
    }

    if (!exists) {
      // document.currentScript?.parentNode?.appendChild(script);
      document.body.appendChild(script);
    }

    return () => {
      //   document.currentScript?.parentElement?.removeChild(script);
      if (!script) return;
      console.log("unmounting ...");
      document.body.removeChild(script);
      cleanup && cleanup();
    };
  }, [params]);
}
