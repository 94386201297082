import { combineReducers } from "redux";

import { GenerateQrReducer } from "./generateQr/reducer";
import { GetTransactionStatusReducer } from "./getTransactionStatus/reducer";



export const QrReducer = combineReducers({
  generateQr: GenerateQrReducer,
  getTransactionStatus: GetTransactionStatusReducer,
});
