import * as Types from "./types";

const initialState = {
  getTokenWithOtpError: null,
  getTokenWithOtpPending: false,

  authorizeTokenWithOtpError: null,
  authorizeTokenWithOtpPending: false
};

export default function CreditCardDetailsReducer(
  state: Types.CreditCardDetailsState = initialState,
  action: Types.CreditCardDetailsActionTypes
): Types.CreditCardDetailsState {
  switch (action.type) {
    case Types.GET_TOKEN_WITH_OTP_PENDING: {
      return { ...state, getTokenWithOtpPending: true };
    }

    case Types.GET_TOKEN_WITH_OTP_SUCCESSFUL: {
      return { ...state, getTokenWithOtpPending: false };
    }

    case Types.GET_TOKEN_WITH_OTP_ERROR: {
      const { payload } = action;
      const { error } = payload;
      return { ...state, getTokenWithOtpError: error, getTokenWithOtpPending: false };
    }

    case Types.AUTHORIZE_TOKEN_WITH_OTP_PENDING: {
      return { ...state, authorizeTokenWithOtpPending: true };
    }

    case Types.AUTHORIZE_TOKEN_WITH_OTP_SUCCESSFUL: {
      return { ...state, authorizeTokenWithOtpPending: false };
    }

    case Types.AUTHORIZE_TOKEN_WITH_OTP_ERROR: {
      const { payload } = action;
      const { error } = payload;
      return { ...state, authorizeTokenWithOtpError: error, authorizeTokenWithOtpPending: false };
    }

    case Types.RESET_CREDIT_CARD_DETAILS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
