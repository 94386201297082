import React, { useState } from "react";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import { ReactComponent as CopyIcon } from "../icons/copy.svg";
import { ReactComponent as SuccessIcon } from "../icons/success.svg";

type TProps = {
  code: string;
};

const Container = styled.div`
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: #f1f4f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-style: dashed;
  border-color: #bbbbbb;
  border-width: 1px;
  margin-top: 20px;
  margin-bottom: 25px;
  > h2 {
    font-size: 23px;
    font-weight: 700;
    letter-spacing: 10px;
  }
  > .ico {
    position: absolute;
    top: 50%;
    right: 21px;
    transform: translate(0, -50%);
    cursor: pointer;
  }
`;

export const FiveDigitCode = ({ code }: TProps) => {
  const [textCopied, setTextCopied] = useState(false);

  const handleCopyToClipboard = () => {
    setTextCopied(true);
  };

  return (
    <Container>
      <h2>{code}</h2>
      {textCopied ? (
        <SuccessIcon className="ico" />
      ) : (
        <CopyToClipboard text={code} onCopy={handleCopyToClipboard}>
          <CopyIcon className="ico" />
        </CopyToClipboard>
      )}
    </Container>
  );
};
