import {
  GetInstitutionsRequest,
  GetInstitutionsResponse,
} from "../../../domain/repositories/PayWithAppRepository";

import {
  GET_INSTITUTIONS_ERROR,
  GetInstitutionsError,
  GET_INSTITUTIONS_PENDING,
  GetInstitutionsPending,
  GET_INSTITUTIONS_SUCCESS,
  GetInstitutionsSuccess,
  TRIGGER_GET_INSTITUTIONS,
  TriggerGetInsititutions,
  RESET_GET_INSTITUTIONS,
  ResetGetInstitution,
  SET_TIME_LEFT,
  SetTimeLeft,
} from "./types";

export const triggetGetInstitutions = (
  request: GetInstitutionsRequest
): TriggerGetInsititutions => {
  return {
    type: TRIGGER_GET_INSTITUTIONS,
    payload: { request },
  };
};

export const getInstitutionsPending = (): GetInstitutionsPending => {
  return {
    type: GET_INSTITUTIONS_PENDING,
  };
};

export const getInstitutionsError = (): GetInstitutionsError => {
  return {
    type: GET_INSTITUTIONS_ERROR,
  };
};

export const resetInstitutions = (): ResetGetInstitution => {
  return {
    type: RESET_GET_INSTITUTIONS,
  };
};
export const setTimeLeft = (time: number): SetTimeLeft => {
  return {
    type: SET_TIME_LEFT,
    payload: { time },
  };
};

export const getInstitutionsSuccess = (
  response: GetInstitutionsResponse
): GetInstitutionsSuccess => {
  return {
    type: GET_INSTITUTIONS_SUCCESS,
    payload: { response },
  };
};
