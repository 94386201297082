import { combineReducers } from "redux";

import { PaymentPropertiesReducer } from "./paymentProperties/reducer";
import { PaymentStatusReducer } from "./paymentStatus/reducer";
import { CurrentPaymentChannelReducer } from "./currentPaymentChannel/reducer";

export const PaymentReducer = combineReducers({
  paymentProperties: PaymentPropertiesReducer,
  paymentStatus: PaymentStatusReducer,
  currentPaymentChannel: CurrentPaymentChannelReducer,
});