import { GenerateUssdRequest, GenerateUssdResponse } from "../../../domain/repositories/UssdRepository";

export const TRIGGER_GENERATE_USSD = 'USSD.TRIGGER_GENERATE_USSD';
export const GENERATE_USSD_PENDING = 'USSD.GENERATE_USSD_PENDING';
export const GENERATE_USSD_SUCCESS = 'USSD.GENERATE_USSD_SUCCESS';
export const GENERATE_USSD_ERROR = 'USSD.GENERATE_USSD_ERROR';

export const RESET_GENERATE_USSD = 'USSD.RESET_GENERATE_USSD';

export interface TriggerGenerateUssd {
  type: typeof TRIGGER_GENERATE_USSD;
  payload: {
    request: GenerateUssdRequest;
  }
}

export interface GenerateUssdPending {
  type: typeof GENERATE_USSD_PENDING;
}

export interface GenerateUssdSuccess {
  type: typeof GENERATE_USSD_SUCCESS;
  payload: {
    response: GenerateUssdResponse;
  }
}

export interface GenerateUssdError {
  type: typeof GENERATE_USSD_ERROR;
}

export interface ResetGenerateUssd {
  type: typeof RESET_GENERATE_USSD;
}


export type GenerateUssdActionType =
  | TriggerGenerateUssd
  | GenerateUssdPending
  | GenerateUssdSuccess
  | GenerateUssdError
  | ResetGenerateUssd;


export interface GenerateUssdState {
  generateUssdPending: boolean;
  generateUssdError: boolean;
  generateUssdResponse: GenerateUssdResponse | null;
}