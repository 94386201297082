import {
    HideCreditOtpPage,
    DisplayCreditOtpPage,
    HidePhoneNumberSignin,
    DisplayPhoneNumberSignin,
    ResetPhoneNumberSigninPage,

    HIDE_CREDIT_OTP_PAGE,
    DISPLAY_CREDIT_OTP_PAGE,
    HIDE_PHONE_NUMBER_SIGNIN,
    DISPLAY_PHONE_NUMBER_SIGNIN,
    RESET_PHONE_NUMBER_SIGNIN_PAGE
} from "./types"

export const hideCreditOtpPage = (): HideCreditOtpPage  => {
    return { type: HIDE_CREDIT_OTP_PAGE }
}

export const displayCreditOtpPage = (): DisplayCreditOtpPage => {
    return { type: DISPLAY_CREDIT_OTP_PAGE }
}

export const hidePhoneNumberSigninPage = (): HidePhoneNumberSignin  => {
    return { type: HIDE_PHONE_NUMBER_SIGNIN }
}

export const displayPhoneNumberSigninPage = (): DisplayPhoneNumberSignin => {
    return { type: DISPLAY_PHONE_NUMBER_SIGNIN }
}

export const resetPhoneNumberSigninPage = (): ResetPhoneNumberSigninPage => {
    return { type: RESET_PHONE_NUMBER_SIGNIN_PAGE }
}