/**
 * Checks if the current keyboard event is one of the following actions
 * 1) Cursor movement via arrow keys
 * 2) Content selection (Ctrl C or Ctrl A)
 * 3) Content pasting (Ctrl V)
 * 4) Content removal (Delete, Backspace, Ctrl X) 
 */
export function isKeyboardAction(event: React.KeyboardEvent<HTMLInputElement>): boolean {
  if (event.ctrlKey && event.key === 'Control') {
    return true;
  }

  if (event.shiftKey && event.key === 'Shift') {
    return true;
  }

  //Allow arrows
  if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
    return true;
  }

  //Allow Tab
  if (event.key === 'Tab') {
    return true;
  }

  //Allow copy, cut and paste and select all
  if (event.ctrlKey && (event.key === 'c' || event.key === 'x' || event.key === 'v' || event.key === 'a')) {
    return true;
  }

  //Allow Delete and Backspace
  if (event.key === 'Delete' || event.key === 'Backspace') {
    return true;
  }

  return false;
}


/**
 * Checks if the current keyboard event is a number
 */
export function eventIsNumberKey(event: React.KeyboardEvent<HTMLInputElement>): boolean {
  const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  let isallowedKey;

  allowedKeys.forEach(allowedKey => {
    if (event.key === allowedKey) isallowedKey = true;
  });

  if (isallowedKey !== true) {
    return false;
  }

  return true;
}



/**
 * Checks if event is a valid input event. Workaround for IE11 triggering input
 * event handler on focus and blur
 *  @param event Event object
 *  @param window Window where the event occured
 */
export function isValidInputEvent(event: React.FormEvent<HTMLInputElement>, window: Window) {
  //First check if browser runs on the Trident engine (used by IE)
  const engine = window.navigator.userAgent;

  //if not running Trident
  if (!engine.match(/Trident/)) {
    return true;
  }

  const { activeElement } = document;
  const { target } = event;

  if (activeElement !== target) {
    return false;
  }

  return true;
}
