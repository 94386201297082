import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { CardBrand, CardBrandConfiguration, cardBrandConfigurations } from '../../config/cardTypesConfig';
import { eventIsNumberKey, isKeyboardAction, isValidInputEvent } from '../../core/util/eventUtils';
import { getDigitsOnly, isDigitNumber } from '../../core/util/numUtil';
import { FIELD_TOP_PADDING, FormFieldErrorStyles, FormLabelFieldStyles } from './Layout';

import MasterCardLogo from '../../assets/images/card_logos/mastercard.png';
import VerveLogo from '../../assets/images/card_logos/verve.png';
import VisaLogo from '../../assets/images/card_logos/visa.png';
import AmericanExpressLogo from '../../assets/images/card_logos/american-express.png';

export const Container = styled.div`
  position: relative;
`;

interface InputFieldProps {
  error: boolean;
}

export const InputField = styled.input<InputFieldProps>`
  ${FormLabelFieldStyles};

  ${({ error }) => error && FormFieldErrorStyles};
`;


export const IconFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 20px;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${FIELD_TOP_PADDING}px;
  box-sizing: border-box;
  animation: ${IconFadeIn} 0.15s ease-in;

  img {
    height: 20px;
    transform: translateZ(0px);
  }
`;



interface CardBrandLogo {
  name: CardBrand;
  imgUri: string;
}

const cardBrandLogos: CardBrandLogo[] = [
  {
    name: 'MasterCard',
    imgUri: MasterCardLogo
  },
  {
    name: 'Verve',
    imgUri: VerveLogo
  },
  {
    name: 'Visa',
    imgUri: VisaLogo
  },
  {
    name: 'AmericanExpress',
    imgUri: AmericanExpressLogo
  }
];

export function getCardBrandConfiguration(numberString: string): CardBrandConfiguration | null {

  for (let i = 0; i < cardBrandConfigurations.length; i++) {

    let cardBrandConfiguration = cardBrandConfigurations[i];

    let range = cardBrandConfiguration.range;

    let sortedRange = range.slice().sort();

    let rangeSize = sortedRange[0].toString().length;

    if (numberString.length < rangeSize) {
      continue;
    }

    let matchSample = parseInt(numberString.slice(0, rangeSize));

    if (sortedRange.length === 1 && matchSample === sortedRange[0]) {
      return cardBrandConfiguration;
    }

    if (matchSample >= sortedRange[0] && matchSample <= sortedRange[1]) {
      return cardBrandConfiguration;
    }
  }

  return null;
}

interface OwnProps {
  initialValue?: string;
  error?: boolean;
  containerStyle?: CSSProperties;
  onValueChange: (value: string) => void;
}

type Props = OwnProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export default function CardInputField(props: Props) {
  const { initialValue, onValueChange, error = false, containerStyle, ...inputProps } = props;

  const { ref, ...extractedInputProps } = inputProps;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [brandConfiguration, setBrandConfiguration] = useState<CardBrandConfiguration | null>(null)

  const lastValueRef = useRef('');

  const cursorSymbol = '_';

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isKeyboardAction(event)) {
      return;
    }

    let keyValid: boolean = eventIsNumberKey(event);

    if (!keyValid) {
      event.preventDefault();
      return;
    }
  };

  const onInputHandler = (event: React.FormEvent<HTMLInputElement>) => {
    if (!isValidInputEvent(event, window)) return;

    if (!inputRef.current) return;

    const inputEl = inputRef.current;

    const lastValue = lastValueRef.current;

    let currentValue = inputRef.current.value;

    let cursorIndex = inputRef.current.selectionStart as number;

    if (lastValue[lastValue.length - 1] === ' ' && lastValue.slice(0, lastValue.length - 1) === currentValue) {
      currentValue = currentValue.slice(0, currentValue.length - 1);
      cursorIndex--;
    }


    let characterArray = currentValue.split('');

    //Mark cursor position
    characterArray.splice(cursorIndex, 0, cursorSymbol);

    characterArray = characterArray.filter((character, index) => {

      if (index === cursorIndex) return true;

      return isDigitNumber(character);
    });

    let numberString = '';

    characterArray.map(char => {
      if (isDigitNumber(char)) {
        numberString = numberString.concat(char);
      }
    });

    let configuration: CardBrandConfiguration | null = getCardBrandConfiguration(numberString);

    if (configuration != null) {

      let cardLengthLimit;

      if (configuration.numberLength.length === 1) {
        cardLengthLimit = configuration.numberLength[0];
      } else {
        const lengthRange = configuration.numberLength.slice();
        cardLengthLimit = Math.max(lengthRange[0], lengthRange[1]);
      }

      let formattedCharacterArray = [];

      let digitCount = 0;
      let totalDigitCount = 0;

      for (let i = 0; i < characterArray.length; i++) {

        let character = characterArray[i];

        if (isDigitNumber(character)) {
          if (totalDigitCount === cardLengthLimit) {
            continue;
          } else {
            digitCount++;
            totalDigitCount++;
          }
        }

        formattedCharacterArray.push(character);

        if (digitCount === 4 && totalDigitCount != cardLengthLimit) {
          formattedCharacterArray.push(' ');
          digitCount = 0;
        }
      }

      characterArray = formattedCharacterArray;
    }


    let cursorSymbolIndex = characterArray.indexOf(cursorSymbol);

    characterArray.splice(cursorSymbolIndex, 1);

    let newValue = characterArray.join('');

    inputEl.value = newValue;
    inputEl.selectionStart = cursorSymbolIndex;
    inputEl.selectionEnd = cursorSymbolIndex;

    lastValueRef.current = newValue;

    // set brand config for displaying logo
    setBrandConfiguration(configuration);

    onValueChange(getDigitsOnly(newValue));
  };



  let cardBrandLogo;

  if (brandConfiguration !== null) {
    cardBrandLogo = cardBrandLogos.find(logo => {
      return brandConfiguration?.name === logo.name;
    });
  }

  useEffect(() => {
    if (!inputRef.current) return;

    const inputEl = inputRef.current;

    const inputVal = initialValue || '';
    inputEl.value = inputVal;

    inputEl.dispatchEvent(new Event('input', { bubbles: true }));
    inputEl.dispatchEvent(new Event('blur', { bubbles: true }));

    let configuration: CardBrandConfiguration | null = getCardBrandConfiguration(inputVal);
    
    setBrandConfiguration(configuration);
  }, []);

  return (
    <Container>
      <InputField
        ref={inputRef}
        inputMode="numeric"
        placeholder="0000 0000 0000 0000"
        maxLength={30}
        autoComplete="cc-number"
        error={error}
        onKeyDown={onKeyDownHandler}
        onInput={onInputHandler}
        {...extractedInputProps}
      />

      {cardBrandLogo && (
        <IconContainer>
          <img src={cardBrandLogo.imgUri} />
        </IconContainer>
      )}


    </Container>
  );
}
