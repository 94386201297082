import { all } from "redux-saga/effects";

import { notificationWidgetSaga } from "../features/components/NotificationWidget/store/saga";

import { paymentPropertiesSaga } from "../features/payment/presentation/store/paymentProperties/saga";

import { getBinConfigurationSaga } from "../features/card/presentation/store/getBinConfiguration/saga";
import { makePaymentSaga } from "../features/card/presentation/store/makePayment/saga";
import { authenticateOtpSaga } from "../features/card/presentation/store/authenticateOtp/saga";
import { authenticateCardinalSaga } from "../features/card/presentation/store/authenticateCardinal/saga";

import { signinUserSaga } from "../features/wallet/presentation/store/signinUser/saga";

import { getProvidersSaga } from "../features/customerWallet/presentation/store/getProviders/saga";
import { validateRequestSaga } from "../features/customerWallet/presentation/store/validateRequest/saga";
import { createPaymentSaga } from "../features/customerWallet/presentation/store/createPayment/saga";

import { generateAccountSaga } from "../features/transfer/presentation/store/generateAccount/saga";
import { getTransactionStatusSaga } from "../features/transfer/presentation/store/getTransactionStatus/saga";

import { generateQrSaga } from "../features/qr/presentation/store/generateQr/saga";

import { getIssuersSaga } from "../features/ussd/presentation/store/getIssuers/saga";
import { generateUssdSaga } from "../features/ussd/presentation/store/generateUssd/saga";

import {
  generateCreditOtpSaga,
  validateCreditOtpSaga,
} from "../features/credit/presentation/store/otpPage/saga";
import { verveWalletSigninSaga } from "../features/credit/presentation/store/verveWalletSignin/saga";
import {
  getOffersSaga,
  acceptOfferSaga,
} from "../features/credit/presentation/store/offers/saga";
import {
  getTokenWithOtpSaga,
  authorizeTokenWithOtpSaga,
} from "../features/credit/presentation/store/cardDetails/saga";
import { getCountryListSaga } from "../features/customerWallet/presentation/store/getCountryList/saga";
import {
  watchGetBanks,
  watchShowLoanOffers,
} from "../features/credit/presentation/store/selectBankPage/saga";

// PayWithApp
import { getInstitutionsSaga } from "../features/payWithApp/presentation/store/getInstitutions/saga";
import { getTokenSaga } from "../features/payWithApp/presentation/store/getToken/saga";

// Opay
import { initializeSaga as opayInitializeSaga } from "../features/opay/presentation/store/initialize/saga";

// Enaira
import { initializeSaga as enairaInitializeSaga } from "../features/enaira/presentation/store/initialize/saga";

// Palmpay
import { initializeSaga as palmpayInitializeSaga } from "../features/palmpay/presentation/store/initialize/saga";

export default function* RootSaga(dispatch: any) {
  yield all([
    notificationWidgetSaga(),
    // payment saga
    paymentPropertiesSaga(),

    // card saga
    getBinConfigurationSaga(),
    makePaymentSaga(),
    authenticateOtpSaga(),
    authenticateCardinalSaga(),

    // wallet saga
    signinUserSaga(),

    // Terrapay saga
    validateRequestSaga(),
    getProvidersSaga(),
    getCountryListSaga(),
    createPaymentSaga(),

    // transfer saga
    generateAccountSaga(),
    getTransactionStatusSaga(),

    // qr saga
    generateQrSaga(),

    // ussd saga
    getIssuersSaga(),
    generateUssdSaga(),

    //credit saga
    generateCreditOtpSaga(),
    validateCreditOtpSaga(),
    verveWalletSigninSaga(),
    getOffersSaga(),
    acceptOfferSaga(),
    getTokenWithOtpSaga(),
    authorizeTokenWithOtpSaga(),

    //pay with app saga
    getInstitutionsSaga(),
    getTokenSaga(),

    //select bank page saga
    watchGetBanks(),
    watchShowLoanOffers(),

    // opay saga
    opayInitializeSaga(),

    // enaira saga
    enairaInitializeSaga(),

    // palmpay saga
    palmpayInitializeSaga(),
  ]);
}
