import {
  GENERATE_USSD_PENDING,
  GENERATE_USSD_SUCCESS,
  GENERATE_USSD_ERROR,
  RESET_GENERATE_USSD,
  GenerateUssdState,
  GenerateUssdActionType,
} from "./types";

const initialState: GenerateUssdState = {
  generateUssdPending: false,
  generateUssdError: false,
  generateUssdResponse: null

}

export function GenerateUssdReducer(
  state: GenerateUssdState = initialState,
  action: GenerateUssdActionType
): GenerateUssdState {
  switch (action.type) {

    case GENERATE_USSD_PENDING: {
      return {
        ...state,
        generateUssdPending: true
      };
    }

    case GENERATE_USSD_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      return {
        ...state,
        generateUssdPending: false,
        generateUssdError: false,
        generateUssdResponse: response
      };
    }

    case GENERATE_USSD_ERROR: {
      return {
        ...state,
        generateUssdPending: false,
        generateUssdError: true
      };
    }

    case RESET_GENERATE_USSD: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
