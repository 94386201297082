import styled, { css, keyframes } from "styled-components";
import {
  PAGE_VERTICAL_PADDING,
  PAGE_SIDE_PADDING,
} from "../../styling/constants";
import { ColorTheme, FormControlsTheme } from "../../styling/theme";

export const FIELD_TOP_PADDING = 20;

export const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PageViewAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const PageViewAnimationStyles = css`
  animation: ${PageViewAnimation} 0.3s linear;
`;

export const PageView = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${PAGE_VERTICAL_PADDING}px ${PAGE_SIDE_PADDING}px;
  ${PageViewAnimationStyles};
`;

export const PageTitle = styled.h4`
  font-size: 1.7rem;
  color: ${ColorTheme.font.dark_blue};
  margin-bottom: 5px;
`;

export const PageSubTitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 40px;
`;

export const LabelFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
`;

export const FormFieldsRow = styled.div`
  display: flex;
  flex-direction: row;

  & > * {
    flex: 1;
    margin-right: 15px;
  }

  & > *:last-child {
    margin-right: 0px;
  }
`;

export const FormControlLabel = styled.label`
  font-size: 1.2rem;
  color: ${ColorTheme.font.dark_blue};
  margin-bottom: 10px;
`;

export const FieldLabel = styled(FormControlLabel)`
  position: absolute;
  left: ${FormControlsTheme.padding.side}px;
  top: 10px;
  z-index: 10;
`;

export const FormFieldStyles = css`
  height: 45px;
  width: 100%;
  background-color: white;
  border: solid 1px ${FormControlsTheme.borderColor};
  border-radius: 4px;
  padding: 0px ${FormControlsTheme.padding.side}px;
  outline: none;
  box-sizing: border-box;

  &::placeholder {
    color: ${ColorTheme.font.placeholder_color};
  }

  &:focus {
    border: solid 0.4px #009fe4;
  }
`;

// Form field internal label styles
export const FormLabelFieldStyles = css`
  ${FormFieldStyles};
  height: 65px;
  padding-top: ${FIELD_TOP_PADDING}px;
`;

// Form field error message styles
export const FormFieldErrorStyles = css`
  border: solid 1px red;
`;

export const FieldErrorText = styled.p`
  color: red;
  font-size: 1.2rem;
  margin-top: 5px;
`;

// Customer disclaimer styles
export const DisclaimerContainer = styled.div`
  margin-bottom: 10px;
  font-size: 1.1rem;
`;
