import React, { useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../../../store/RootReducer";
import { Offer } from "../../../../../domain/repositories/OffersRepository";
import { displayScreen } from "../../../../store/offers/actions";
import {
  AcceptOffersStoreStateProps,
  AcceptOffersStoreDispatchProps,
  OffersScreenType
} from "../../../../store/offers/types";
import CardPinPage from "./CardPinPage";
import CardRootPage, { CardDetails } from "./CardRootPage";

interface OwnProps {
  selectedOfferIndex: number;
}

type Props = AcceptOffersStoreStateProps & AcceptOffersStoreDispatchProps & OwnProps;

function AcceptOffer(props: Props) {
  const { offers, selectedOfferIndex } = props;
  const [cardDetails, setCardDetails] = useState<CardDetails | null>(null);
  const [showCardPin, setShowCardPin] = useState(false);
  const offer: Offer = offers[selectedOfferIndex];

  return (
    <>
      {!showCardPin && (
        <CardRootPage
          offer={offer}
          setShowCardPin={setShowCardPin}
          setCardDetails={setCardDetails}
        />
      )}

      {showCardPin && (
        <CardPinPage cardDetails={cardDetails} offer={offer} setShowCardPin={setShowCardPin} />
      )}
    </>
  );
}

const mapStateToProps = (state: AppState): AcceptOffersStoreStateProps => {
  return {
    offers: state.credit.offers.offers
  };
};

const mapDispatchToProps = (dispatch: (action: any) => void): AcceptOffersStoreDispatchProps => {
  return {
    displayScreen: (screenType: OffersScreenType) => dispatch(displayScreen(screenType))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptOffer);
