import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../../../store/RootReducer";
import { getFormattedPrice } from "../../../../../../../util/currencyUtil";
import { Button } from "../../../../../../components/Button";
import { triggerShowNotification } from "../../../../../../components/NotificationWidget/store/actions";
import { TriggerShowNotificationPayload } from "../../../../../../components/NotificationWidget/store/types";
import { GetOffersRequest, Offer } from "../../../../../domain/repositories/OffersRepository";
import {
  displayScreen,
  setBankProvider,
  setCreditPageSubTitle,
  setCreditPageTitle,
  setSelectedOffer,
  triggerGetOffers
} from "../../../../store/offers/actions";
import {
  GetOffersStoreDispatchProps,
  GetOffersStoreStateProps,
  OffersScreenType
} from "../../../../store/offers/types";
import {
  OffersContainer,
  Offers,
  OffersList,
  ProviderName,
  OfferDetails,
  OfferDetailsItem,
  OfferDetailsLabel,
  OfferDetailsValue,
  FinInstitutionBox,
  Divider,
  StyledMoreOptionsBox,
} from "../../style";
import Error from "./Error";
import Placeholder from "./Placeholder";
import { ReactComponent as Icon } from "../../../../../../../assets/icons/info-blue.svg";
import Tooltip from "../../../../../../payment/presentation/pages/SelectPaymentMethodPage/components/Tooltip";


interface OwnProps {
  selectedOfferIndex: number | null;
  onSelectOffer: (index: number) => void;
}

type Props = GetOffersStoreStateProps & GetOffersStoreDispatchProps & OwnProps;

function GetOffers(props: Props) {
  const {
    setPageTitle,
    setPageSubtitle,
    loading,
    getOffersError,
    getOffers,
    request,
    offers,
    currencyCode,
    onSelectOffer,
    displayScreen,
    selectedOfferIndex,
    triggerShowNotification,
    bankProvider,
    setCreditPageBankProvider,
    setSelectedOffer
  } = props;

  const [seeeMore, setSeemore] = useState<boolean>(false);

  const continueButtonHandler = () => {
    if (selectedOfferIndex !== null && offers.length > 0) {
      if(bankProvider){
        displayScreen(OffersScreenType.otp);
      } else {
        displayScreen(OffersScreenType.cardDetails);
      }
    } else {
      triggerShowNotification({
        type: "ERROR",
        message: "Kindly select an offer to continue"
      });
    }
  };

  useEffect(() => {
    setPageTitle("Available Offers");
    if (loading) setPageSubtitle("Fetching available offers for your credit.");
    else setPageSubtitle("Select one of the loan offers available below to proceed");
  }, [loading, setPageSubtitle, setPageTitle]);

  useEffect(() => {
    if (!loading && !getOffersError && offers.length === 0) getOffers(request);
  }, [getOffers, getOffersError, loading, offers, request]);

  const bankOffers = offers.filter(offer => (String(offer.provider.isBank) === 'true'));
  const fintechOffers = offers.filter(offer => (String(offer.provider.isBank) !== 'true'));
  const filteredOffers = !seeeMore && bankOffers.length > 0 ? bankOffers : offers;

  return (
    <>
      {loading && <Placeholder />}

      {!loading && getOffersError && <Error error={getOffersError} getOffersRequest={request} />}

      {!loading && !getOffersError && offers && (
        <>
          <OffersContainer>
            <OffersList>
              {filteredOffers?.map((offer, index) => (
                <Offers
                  key={index}
                  selected={index === selectedOfferIndex}
                  hasPartPayment={offer.initialPayment > 0}
                  >
                  
                  <FinInstitutionBox>
                    <div>
                      <div>Financial Institution</div>
                      <ProviderName>{offer.provider.name}</ProviderName>
                    </div>

                    <div>
                      <Button
                        text="Select"
                        color="PRIMARY"
                        loading={false}
                        onClick={() => {
                          onSelectOffer(index);
                          setCreditPageBankProvider(String(offer.provider.isBank) === 'true');
                          setSelectedOffer(offer)
                        }}
                        containerStyle={{ width: "100%", height: "32px" }}
                      />
                    </div>
                  </FinInstitutionBox>

                  <Divider />

                  <OfferDetails>
                    <OfferDetailsItem>
                      <OfferDetailsLabel>Amount Offered</OfferDetailsLabel>
                      <OfferDetailsValue>
                        {getFormattedPrice(offer.amountOffered, currencyCode)}
                      </OfferDetailsValue>
                    </OfferDetailsItem>
                    {offer.initialPayment > 0  && <OfferDetailsItem>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                      >
                        <OfferDetailsLabel>Part Payment</OfferDetailsLabel>
                        <Tooltip
                          icon={<Icon className="info-icon" />}
                          className="tooltip"
                          text="Make an initial payment now to reduce the amount you'll need to pay back on the due date"
                        />
                      </div>
                      <OfferDetailsValue>
                        {getFormattedPrice(offer.initialPayment, currencyCode)}
                      </OfferDetailsValue>
                    </OfferDetailsItem>}

                    <OfferDetailsItem>
                      <OfferDetailsLabel>Payback Amount</OfferDetailsLabel>
                      <OfferDetailsValue>
                        {getFormattedPrice(offer.amountPayable, currencyCode)}
                      </OfferDetailsValue>
                    </OfferDetailsItem>

                    <OfferDetailsItem>
                      <OfferDetailsLabel>Payback Time</OfferDetailsLabel>
                      <OfferDetailsValue>{offer.tenure} Days</OfferDetailsValue>
                    </OfferDetailsItem>
                  </OfferDetails>
                </Offers>
              ))}
            </OffersList>
          </OffersContainer>

          {
            bankOffers.length > 0 && fintechOffers.length > 0 &&
            <StyledMoreOptionsBox onClick={() => setSeemore(!seeeMore)}>
              See {
                !seeeMore ? <> more options <span className="icon-down">&#x203A;</span></> :
                <> less options <span className="icon-up">&#x203A;</span></> 
              } 
            </StyledMoreOptionsBox>
          }

          <Button
            text={"Continue"}
            color="PRIMARY"
            loading={false}
            onClick={continueButtonHandler}
            containerStyle={{ width: "100%" }}
          />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: AppState): GetOffersStoreStateProps => {
  const { paymentParams } = state.payment.paymentProperties;
  const { offers, getOffersPending, getOffersError, userData, bankProvider } = state.credit.offers;
 
  return {
    offers,
    getOffersError,
    bankProvider,
    loading: getOffersPending,
    currencyCode: paymentParams?.currencyCode || "",
    request: {
      amount: paymentParams?.amount,
      paymentId: paymentParams?.paymentId,
      customerId: userData?.mobileNo,
      merchantCode: paymentParams?.merchantCode,
      payableCode: paymentParams?.payableCode,
      merchantTransactionReference: paymentParams?.merchantTransactionReference,
      accountNumber: state.credit.selectBanksPage.bankDetails.accountNumber,
      providerCode: state.credit.selectBanksPage.bankDetails.finInstitution,
    }
  };
};

const mapDispatchToProps = (dispatch: (action: any) => void): GetOffersStoreDispatchProps => {
  return {
    setPageTitle: (title: string) => dispatch(setCreditPageTitle(title)),
    setPageSubtitle: (subtitle: string) => dispatch(setCreditPageSubTitle(subtitle)),
    displayScreen: (screenType: OffersScreenType) => dispatch(displayScreen(screenType)),
    getOffers: (request: GetOffersRequest, onSuccessful?: () => void) => {
      dispatch(triggerGetOffers(request, onSuccessful));
    },
    triggerShowNotification: (payload: TriggerShowNotificationPayload) => {
      dispatch(triggerShowNotification(payload));
    }, 
    setCreditPageBankProvider: (bankProvider: boolean) => dispatch(setBankProvider(bankProvider)),
    setSelectedOffer: (selectedOffer: Offer) => dispatch(setSelectedOffer(selectedOffer))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetOffers);
