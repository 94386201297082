import { takeLatest, put } from "redux-saga/effects";

import ussdRepository, { GenerateUssdResponse } from "../../../domain/repositories/UssdRepository";

import {
  TRIGGER_GENERATE_USSD,
  TriggerGenerateUssd,

  RESET_GENERATE_USSD,
} from "./types";

import {
  generateUssdPending,
  generateUssdSuccess,
  generateUssdError
} from "./actions";
import { createCancellableSaga } from "../../../../../store/cancellableSaga";


function* handleTriggerGenerateUssd(action: TriggerGenerateUssd) {
  const { payload } = action;
  const { request } = payload;

  yield put(generateUssdPending());

  let response: GenerateUssdResponse;

  try {
    response = yield ussdRepository.generateUssd(request);
  } catch (err) {
    yield put(generateUssdError());
    return;
  }

  yield put(generateUssdSuccess(response));
}



function* watchGenerateUssd() {
  yield takeLatest(TRIGGER_GENERATE_USSD, handleTriggerGenerateUssd);
}


export const generateUssdSaga = createCancellableSaga(
  watchGenerateUssd, RESET_GENERATE_USSD
);