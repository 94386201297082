import { GetTransactionStatusRequest, GetTransactionStatusResponse } from "../../../domain/repositories/TransferRepository";
import {
  TRIGGER_GET_TRANSACTION_STATUS,
  TriggerGetTransactionStatus,

  GET_TRANSACTION_STATUS_PENDING,
  GetTransactionStatusPending,

  GET_TRANSACTION_STATUS_SUCCESS,
  GetTransactionStatusSuccess,

  GET_TRANSACTION_STATUS_ERROR,
  GetTransactionStatusError
} from "./types";

export const triggerGetTransactionStatus = (request: GetTransactionStatusRequest): TriggerGetTransactionStatus => {
  return {
    type: TRIGGER_GET_TRANSACTION_STATUS,
    payload: { request }
  };
};

export const getTransactionStatusPending = (): GetTransactionStatusPending => {
  return {
    type: GET_TRANSACTION_STATUS_PENDING
  };
};

export const getTransactionStatusSuccess = (response: GetTransactionStatusResponse): GetTransactionStatusSuccess => {
  return {
    type: GET_TRANSACTION_STATUS_SUCCESS,
    payload: {response}

  };
};

export const getTransactionStatusError = (error?: any): GetTransactionStatusError => {
  return {
    type: GET_TRANSACTION_STATUS_ERROR,
    payload: error
  };
};