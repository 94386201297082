import React from "react";
import styled from "styled-components";

import { ReactComponent as AlertIcon } from "./icons/alert-icon.svg";

const Container = styled.div<{
  bg?: string;
  color?: string;
  borderColor?: string;
}>`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.bg || "#fcf8e3"};

  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.borderColor || "#8a6d3b"};

  p {
    font-size: 1.3rem;
    color: ${(props) => props.color || "#8a6d3b"};
  }
`;

const IconContainer = styled.div<{ color?: string }>`
  padding-top: 5px;
  margin-right: 10px;
  box-sizing: border-box;

  svg {
    width: 14px;
    height: 14px;
    color: ${(props) => props.color || "#8a6d3b"};
  }
`;

interface Props {
  message: string;
  bg?: string;
  color?: string;
  borderColor?: string;
}

export default function WarningMessage({
  message,
  bg,
  color,
  borderColor,
}: Props) {
  return (
    <Container bg={bg} color={color} borderColor={borderColor}>
      <IconContainer color={color}>
        <AlertIcon />
      </IconContainer>

      <p style={{ fontWeight: 500 }}>{message}</p>
    </Container>
  );
}
