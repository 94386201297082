import React, { useState, useEffect, useCallback } from "react";
import OtpPage from "../OtpPage";
import { connect } from "react-redux";
import { TextDivider } from "../style";
import { FormContainer } from "../OtpPage/style";
import { Button } from "../../../../components/Button";
import { AppState } from "../../../../../store/RootReducer";
import {
  setCreditPageSubTitle,
  setCreditPageTitle,
  signinSuccessful
} from "../../store/offers/actions";
import { FieldValidationResult } from "../../../../../core/types";
import { SigninSuccessfulResponse } from "../../store/offers/types";
import { GenerateCreditOtpCallback } from "../../store/otpPage/types";
import { GenerateOtpRequest } from "../../../domain/repositories/OtpRepository";
import { displayVerveWalletSignin } from "../../store/verveWalletSignin/actions";
import { InputField } from "../../../../wallet/presentation/pages/WalletLoginPage/style";
import { StoreStateProps, StoreDispatchProps } from "../../store/phoneNumberSignin/types";
import { triggerGenerateCreditOtp, resetCreditOtpPage } from "../../store/otpPage/actions";
import {
  LabelFieldContainer,
  FormControlLabel,
  FieldErrorText
} from "../../../../components/Layout";
import {
  hideCreditOtpPage,
  displayCreditOtpPage,
  resetPhoneNumberSigninPage,
  hidePhoneNumberSigninPage
} from "../../store/phoneNumberSignin/actions";
import { TriggerShowNotificationPayload } from "../../../../components/NotificationWidget/store/types";
import { triggerShowNotification } from "../../../../components/NotificationWidget/store/actions";

interface OwnProps {}
type Props = StoreStateProps & StoreDispatchProps & OwnProps;

const PHONE_NUMBER_MINIMUM_LENGTH = 11;
const PHONE_NUMBER_EMPTY_MESSAGE = "Phone number is required";
const PHONE_NUMBER_INCOMPLETE_MESSAGE = "Phone number is incomplete";

const getIsPhoneNumberValid = (phoneNumber: string): FieldValidationResult => {
  if (!phoneNumber) return { isValid: false, message: PHONE_NUMBER_EMPTY_MESSAGE };
  if (phoneNumber.length < PHONE_NUMBER_MINIMUM_LENGTH) {
    return { isValid: false, message: PHONE_NUMBER_INCOMPLETE_MESSAGE };
  }
  return { isValid: true };
};

function PhoneNumberSignin(props: Props) {
  const {
    setPageTitle,
    setPageSubTitle,
    signinPending,
    generateOtp,
    paymentId,
    displayOtpPage,
    generateOtpError,
    hideCreditOtpPage,
    loginToVerveWallet,
    displayCreditOtpPage,
    triggerShowNotification,
    phoneNumberSigninSuccessful
  } = props;
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const { isValid: isPhoneNumberValid, message: phoneNumberErrorMessage } =
    getIsPhoneNumberValid(phoneNumberValue);

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.code === "Enter") signinButtonHandler();
  };

  const signinButtonHandler = () => {
    if (!isPhoneNumberValid) {
      setShowFormErrors(true);
      return;
    }

    const request: GenerateOtpRequest = {
      paymentId,
      customerId: phoneNumberValue
    };
    generateOtp(request, displayCreditOtpPage);
  };

  const initializePageTitle = useCallback(() => {
    setPageTitle("Pay on Credit");
    setPageSubTitle("Enter your phone number below");
  }, [setPageTitle, setPageSubTitle]);

  const handleError = useCallback(() => {
    if (generateOtpError) {
      if (!generateOtpError.response || generateOtpError.response.status !== 200) {
        triggerShowNotification({
          type: "ERROR",
          message: "Something went wrong. Please try again."
        });
        return;
      }

      if (generateOtpError.response.data.responseCode === "LS1") {
        triggerShowNotification({
          type: "ERROR",
          message: "Invalid phone number provided. Please try again."
        });
        return;
      }
    }
  }, [generateOtpError, triggerShowNotification]);

  const hideOtpPage = () => {
    hideCreditOtpPage();
    initializePageTitle();
  };

  const onSigninSuccessful = (response: SigninSuccessfulResponse) => {
    response.userData = {
      active: true,
      admin: false,
      apps: null,
      domainCode: null,
      domainId: null,
      domainName: null,
      domains: null,
      email: "",
      firstName: "",
      id: null,
      lastName: "",
      mobileNo: response.customerId || ""
    };
    phoneNumberSigninSuccessful(response);
  };

  useEffect(() => {
    initializePageTitle();
    handleError();
  }, [handleError, initializePageTitle]);

  return (
    <>
      {displayOtpPage && (
        <OtpPage
          buttonText="Login"
          phoneNumber={phoneNumberValue}
          backButtonCallback={hideOtpPage}
          onValidateOtpSuccessful={onSigninSuccessful}
        />
      )}

      {!displayOtpPage && (
        <>
          <FormContainer>
            <LabelFieldContainer>
              <FormControlLabel>Phone Number</FormControlLabel>
              <InputField
                type="tel"
                value={phoneNumberValue}
                data-testid="signin-phone-number"
                onChange={(event) => setPhoneNumberValue(event.target.value)}
                onKeyUp={onKeyUpHandler}
                autoComplete='off'
              />

              {showFormErrors && !isPhoneNumberValid && (
                <FieldErrorText>{phoneNumberErrorMessage}</FieldErrorText>
              )}
            </LabelFieldContainer>

            <Button
              text={"Continue"}
              color="PRIMARY"
              loading={signinPending}
              onClick={signinButtonHandler}
              containerStyle={{ width: "100%" }}
            />
          </FormContainer>

          <TextDivider>Or</TextDivider>

          <Button
            text={"Login to Verve eWallet"}
            color="SECONDARY"
            onClick={loginToVerveWallet}
            containerStyle={{ width: "100%" }}
          />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => {
  return {
    signinPending: state.credit.creditOtp.generateOtpLoading,
    generateOtpError: state.credit.creditOtp.generateOtpError,
    displayOtpPage: state.credit.PhoneNumberSignin.displayOtpPage,
    paymentId: state.payment.paymentProperties.paymentParams?.paymentId
  };
};

const mapdispatchToProps = (dispatch: (action: any) => void): StoreDispatchProps => {
  return {
    displayCreditOtpPage: () => dispatch(displayCreditOtpPage()),
    setPageTitle: (title: string) => dispatch(setCreditPageTitle(title)),
    setPageSubTitle: (subtitle: string) => dispatch(setCreditPageSubTitle(subtitle)),

    generateOtp: (request: GenerateOtpRequest, onSuccessful: GenerateCreditOtpCallback) => {
      dispatch(triggerGenerateCreditOtp(request, onSuccessful));
    },

    hideCreditOtpPage: () => {
      dispatch(hideCreditOtpPage());
      dispatch(resetCreditOtpPage());
    },

    phoneNumberSigninSuccessful: (response: SigninSuccessfulResponse) => {
      dispatch(resetCreditOtpPage());
      dispatch(resetPhoneNumberSigninPage());
      dispatch(signinSuccessful(response));
    },

    loginToVerveWallet: () => {
      dispatch(hidePhoneNumberSigninPage());
      dispatch(displayVerveWalletSignin());
    },

    triggerShowNotification: (payload: TriggerShowNotificationPayload) => {
      dispatch(triggerShowNotification(payload));
    }
  };
};

export default connect(mapStateToProps, mapdispatchToProps)(PhoneNumberSignin);
