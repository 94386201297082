import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ZIB__CUSTOMER_DISCLAIMER } from "../Routes";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  p {
    font-size: 1.1rem;
  }
`;

const Link = styled.button`
  color: #e55f5f;
  text-decoration: underline;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.1rem;
  padding: 0;
  font-family: POPPINS, sans-serif;

  &:hover {
    cursor: pointer;
  }
`;

export default function DisclaimerView() {
  const history = useHistory();

  const handleDisclaimerView = () => {
    history.push(ZIB__CUSTOMER_DISCLAIMER);
  };

  return (
    <Container>
      <div>
        <p>
          By proceeding, you agree to our{" "}
          <Link onClick={handleDisclaimerView}>Terms of Use</Link>
        </p>
      </div>
    </Container>
  );
}
