import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../store/RootReducer";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { PageView } from "../components/Layout";
import BackControl from "../components/BackControl";
import Pocket from "../customerWallet/presentation/pages/Pocket";
import { PaymentParams } from "../payment/presentation/store/paymentProperties/types";

const Container = styled(PageView)``;

interface StoreStateProps {
  paymentParams: PaymentParams;
}

const PocketRootPage = ({ paymentParams }: StoreStateProps) => {
  const history = useHistory();

  return (
    <Container>
      <BackControl text="Back" onClick={() => history.goBack()} />
      <Pocket paymentParams={paymentParams} />
    </Container>
  );
};

const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,
});

export default connect(mapStateToProps, null)(PocketRootPage);
