import { AxiosError } from "axios";
import { CreatePaymentRequest, CreatePaymentResponse } from "../../../domain/repositories/TerrapayRepository";

export const TRIGGER_CREATE_PAYMENT = 'WALLET.TRIGGER_CREATE_PAYMENT';
export const CREATE_PAYMENT_PENDING = 'WALLET.CREATE_PAYMENT_PENDING';
export const CREATE_PAYMENT_SUCCESS = 'WALLET.CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_ERROR = 'WALLET.CREATE_PAYMENT_ERROR';

export const RESET_CREATE_PAYMENT = 'WALLET.RESET_CREATE_PAYMENT';

export interface TriggerCreatePayment {
  type: typeof TRIGGER_CREATE_PAYMENT;
  payload: {
    request: CreatePaymentRequest;
    onSuccessful?: CreatePaymentCallback
  }
}

export interface CreatePaymentPending {
  type: typeof CREATE_PAYMENT_PENDING;
}

export interface CreatePaymentSuccess {
  type: typeof CREATE_PAYMENT_SUCCESS;
  payload: {
    response: CreatePaymentResponse;
  }
}

export interface CreatePaymentError {
  type: typeof CREATE_PAYMENT_ERROR;
  payload: {
    error: AxiosError;
  }
}

export interface ResetCreatePayment {
  type: typeof RESET_CREATE_PAYMENT;
}

export type CreatePaymentCallback = () => any;

export type CreatePaymentActionType =
  TriggerCreatePayment
  | CreatePaymentPending
  | CreatePaymentSuccess
  | CreatePaymentError
  | ResetCreatePayment;

export interface CreatePaymentState {
  loading: boolean;
  loadingError: AxiosError | null;
  data: string;
  transactionResponse: any
}

// responseData: any