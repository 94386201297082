import styled from "styled-components"
import { ColorTheme } from '../../../../../styling/theme';
import { FormFieldStyles } from '../../../../components/Layout';

export const FormContainer = styled.div`
  width: 100%;
`;

export const OtpInputFieldContainer = styled.div`
  position: relative;
`;

export const ResendControlContainer = styled.div`
  position: absolute;
  top: calc(50% - (25px / 2));
  right: 15px;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const ResendMessage = styled.p`
  font-family: monospace;
  font-size: 1.4rem;
  color: #a2a2a2;

  span {
    width: 15px;
    display: inline-block;
    text-align: center;
  }
`;

export const ResendControl = styled.p`
  font-size: 1.4rem;
  color: ${ColorTheme.primaryColor};
  cursor: pointer;
`;


export interface OtpInputFieldProps {
  error: boolean;
}

export const OtpInputField = styled.input<OtpInputFieldProps>`
  ${FormFieldStyles};
`;
