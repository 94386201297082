import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { connect } from "react-redux";
import { AppState } from "../../../../../../../../store/RootReducer";
import { Button } from "../../../../../../../components/Button";
import { Container, ErrorContainer, ErrorIcon, ErrorSubTitle, ErrorTitle } from "../../../style";
import { ReactComponent as AlertIcon } from "./icons/alert.svg";
import { ErrorStoreDispatchProps, ErrorStoreStateProps } from "../../../../../store/offers/types";
import { GetOffersRequest } from "../../../../../../domain/repositories/OffersRepository";
import { resetOffersPage, triggerGetOffers } from "../../../../../store/offers/actions";
import { PAYMENT_METHODS__ROOT } from "../../../../../../../Routes";
import { useHistory } from "react-router-dom";
import { resetCreditOtpPage } from "../../../../../store/otpPage/actions";
import { resetPhoneNumberSigninPage } from "../../../../../store/phoneNumberSignin/actions";
import { resetVerveWalletSignin } from "../../../../../store/verveWalletSignin/actions";

interface Ownprops {
  error: AxiosError | null;
  getOffersRequest: GetOffersRequest;
}

type Props = ErrorStoreStateProps & ErrorStoreDispatchProps & Ownprops;

function Error(props: Props) {
  const { error, getOffersLoading, getOffers, getOffersRequest, reset } = props;
  const [title, setTitle] = useState("Failed to get offers");
  const [subtitle, setSubtitle] = useState("Something went wrong, please try again");
  const [buttonText, setButtonText] = useState("Retry");
  const history = useHistory();

  const navigateToPaymentMethods = () => {
    reset();
    history.push(PAYMENT_METHODS__ROOT);
  };

  const handleErrorButtonClick = () => {
    if (buttonText === "Retry") getOffers(getOffersRequest);
    else navigateToPaymentMethods();
  };

  useEffect(() => {
    if (error) {
      if (error?.response?.data.offers && error?.response?.data.offers.length <= 0) {
        setButtonText("Try Other Payment Methods");
        setTitle("No Offer Available");
        setSubtitle("You curently do not have any offer available for the credit amount");
        return;
      }

      if (error?.response?.data.description) {
        setSubtitle(error.response.data.description);
        return;
      }

      if (error?.response?.data.responseDescription) {
        setButtonText("Try Other Payment Methods");
        setSubtitle(error.response.data.responseDescription);
      }
    }
  }, [setTitle, setSubtitle, error, setButtonText]);

  return (
    <Container>
      <ErrorContainer>
        <ErrorIcon>
          <AlertIcon />
        </ErrorIcon>
        <ErrorTitle>{title}</ErrorTitle>
        <ErrorSubTitle>{subtitle}</ErrorSubTitle>
      </ErrorContainer>

      <Button
        text={buttonText}
        color="PRIMARY"
        loading={getOffersLoading}
        onClick={handleErrorButtonClick}
        containerStyle={{ width: "100%" }}
      />
    </Container>
  );
}

const mapStateToProps = (state: AppState): ErrorStoreStateProps => {
  return {
    getOffersLoading: state.credit.offers.getOffersPending
  };
};

const mapDispatchToProps = (dispatch: (action: any) => void): ErrorStoreDispatchProps => {
  return {
    getOffers: (request: GetOffersRequest, onSuccessful?: () => void) => {
      dispatch(triggerGetOffers(request, onSuccessful));
    },
    reset: () => {
      dispatch(resetOffersPage());
      dispatch(resetCreditOtpPage());
      dispatch(resetPhoneNumberSigninPage());
      dispatch(resetVerveWalletSignin());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Error);
