import { takeLatest, put, call } from "redux-saga/effects";
import TerrapayRepository, {  ValidateRequestResponse } from "../../../domain/repositories/TerrapayRepository";
import { AxiosError } from "axios";

import {
  TriggerValidateRequest,
  TRIGGER_VALIDATE_REQUEST
} from "./types";

import {
  validateRequestPending,
  validateRequestError,
  validateRequestSuccess
} from "./actions";


function* handleTriggerValidateRequest(action: TriggerValidateRequest) {
  const { payload } = action;
  const { request, onSuccessful } = payload;

  yield put(validateRequestPending());

  let response: ValidateRequestResponse;
 
  try {
    response = yield TerrapayRepository.validateRequest(request);
    if (response){
      yield put(validateRequestSuccess(response));
      if (onSuccessful && typeof onSuccessful === "function") yield call(onSuccessful);
    }
  } catch (err) {
    yield put(validateRequestError(err as AxiosError));
    return;
  }

}


export function* validateRequestSaga() {
  yield takeLatest(TRIGGER_VALIDATE_REQUEST, handleTriggerValidateRequest);
}
