import { SERVICE_BASE_URL } from '../../../../config/properties';
import httpClient from '../../../../core/api/httpClient';

export interface PaymentRequest {
	orderId: string;
	orderAmount: number;
	orderCurrency: string;
	customerMobileNumber: string;
	mobileWalletProvider: string;
	paymentId: string;
	payableCode: string;
	merchantCode: string;
	transactionReference: string;
	merchantId?: string;
	merchantTillId?: string;
	transType?: string;
}
export interface PaymentResponse {
	data: any;
	code: string;
	responseMessage: string;
}

export interface RequeryRequest {
	mobileWalletProvider: string;
	paymentId: string;
}

export interface RequeryResponse {
	data: any;
	code: string;
	responseMessage: string;
}

export interface ProvidersInfo {
	id: string;
	provider: string;
}

export interface CountryDetails {
	id: string;
	label: string;
	countryCode: string;
	icon: string;
	providers: ProvidersInfo[];
}

export interface GetProvidersResponse {
	responseMessage: string;
	data: CountryDetails[];
	code: string;
}

export function getProviders() {
	return httpClient.get<GetProvidersResponse>(
		`${SERVICE_BASE_URL}/api/v1/countries/countryList?country=CD`
	);
}

export function postPayment(payload: any) {
	return httpClient.post<PaymentResponse>(
		`${SERVICE_BASE_URL}/api/v2/mobile-wallet/payment`,
		payload
	);
}

export function requeryPayment(payload: RequeryRequest) {
	return httpClient.post<RequeryResponse>(
		`${SERVICE_BASE_URL}/api/v2/mobile-wallet/query`,
		payload
	);
}
