import styled from "styled-components";
import { PageView } from "../../../../components/Layout";

export const Container = styled(PageView)``;

export const CodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed rgba(60, 149, 249, 0.2);
  border-radius: 16px;
  padding: 25px 0px;
  margin-bottom: 20px;
`;

export const CodeHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const CodeValue = styled.p`
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 2px;
  cursor: pointer;
  margin-right: 5px;
`;

export const CodeDescription = styled.p`
  font-size: 12px;
  text-align: center;

  b {
    color: red;
  }
`;

export const InstructionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InstructionContentContainer = styled.div`
  margin-left: 10px;
  
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;

    li {
      margin-bottom: 5px;
    }
  }
`;

export const InstuctionHeader = styled.h6`
  font-size: 12px;
  margin-bottom: 8px;
`;
