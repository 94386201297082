import { GenerateAccountRequest, GenerateAccountResponse } from "../../../domain/repositories/TransferRepository";
import {
  TRIGGER_GENERATE_ACCOUNT,
  TriggerGenerateAccount,

  GENERATE_ACCOUNT_PENDING,
  GenerateAccountPending,

  GENERATE_ACCOUNT_SUCCESS,
  GenerateAccountSuccess,

  GENERATE_ACCOUNT_ERROR,
  GenerateAccountError
} from "./types";

export const triggerGenerateAccount = (request: GenerateAccountRequest): TriggerGenerateAccount => {
  return {
    type: TRIGGER_GENERATE_ACCOUNT,
    payload: { request }
  };
};

export const generateAccountPending = (): GenerateAccountPending => {
  return {
    type: GENERATE_ACCOUNT_PENDING
  };
};

export const generateAccountSuccess = (response: GenerateAccountResponse): GenerateAccountSuccess => {
  return {
    type: GENERATE_ACCOUNT_SUCCESS,
    payload: {response}

  };
};

export const generateAccountError = (): GenerateAccountError => {
  return {
    type: GENERATE_ACCOUNT_ERROR,
  };
};