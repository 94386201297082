import React from "react";
import styled from "styled-components";
import { FormFieldStyles } from "../../../components/Layout";

type TProps = {
  data: Array<string>;
  value: string;
  onChange?: (evt: React.SyntheticEvent<HTMLSelectElement, Event>) => void;
};

const Select = styled.select`
  ${FormFieldStyles}
`;

export const Dropdown = ({ data, value, onChange }: TProps) => {
  return (
    <Select value={value} onChange={onChange}>
      <option value={""} disabled>
        Select your bank
      </option>
      {data.map((v) => (
        <option value={v} key={v}>
          {v}
        </option>
      ))}
    </Select>
  );
};
