import * as React from "react";
import styled from "styled-components";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  containerClassname?: string;
}
export default function Checkbox(props: Props) {
  const { label, containerClassname, ...rest } = props;
  return (
    <Container className={containerClassname}>
      <input type="checkbox" id={rest.name} aria-label={rest.name} {...rest} />
      <Checkmark />
      {!!label && <Text className="text">{label}</Text>}
    </Container>
  );
}

export const Text = styled.span`
  margin-left: 4px;
  font-size: 1.2rem;
`;

export const Checkmark = styled.span`
  flex-shrink: 0;
  position: relative;
  display: block;
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border: solid 1px rgba(18, 18, 18, 0.2);
  box-sizing: border-box;
  border-radius: 4px;

  transition: background-color 100ms linear, box-shadow 100ms linear;

  &::after {
    content: "";
    display: none;
    position: absolute;
    left: 4px;
    top: 1px;
    width: 3px;
    height: 7px;
    border-radius: 2px;
    border: solid #333333;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(4odeg);
  }
`;

export const Container = styled.label`
  display: flex;
  align-items: center;

  & > input {
    display: none;
    &:checked ~ ${Checkmark} {
      background-color:#ffffff;
      border: 1.5px solid #333333;
    }

    &:checked ~ ${Checkmark}::after {
      display: block;
    }
    &:disabled ~ ${Checkmark}, &:disabled ~ ${Text} {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
`;
