import { AxiosError } from "axios";
import { SigninUserRequest, SigninUserResponse } from "../../../domain/repositories/WalletRepository";

import {
  TRIGGER_SIGNIN_USER,
  TriggerSigninUser,

  SIGNIN_USER_PENDING,
  SigninUserPending,

  SIGNIN_USER_SUCCESS,
  SigninUserSuccess,

  SIGNIN_USER_ERROR,
  SigninUserError,

  RESET_SIGNIN_USER,
  ResetSigninUser
} from "./types";

export const triggerSigninUser = (request: SigninUserRequest): TriggerSigninUser => {
  return {
    type: TRIGGER_SIGNIN_USER,
    payload: { request }
  };
};

export const signinUserPending = (): SigninUserPending => {
  return {
    type: SIGNIN_USER_PENDING
  };
};

export const signinUserSuccess = (response: SigninUserResponse): SigninUserSuccess => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: { response }

  };
};

export const signinUserError = (error: AxiosError): SigninUserError => {
  return {
    type: SIGNIN_USER_ERROR,
    payload: { error },
  };
};

export const resetSigninUser = (): ResetSigninUser => {
  return {
    type: RESET_SIGNIN_USER,
  };
};