import {
  MAKE_PAYMENT_PENDING,
  MAKE_PAYMENT_SUCCESS,
  MAKE_PAYMENT_ERROR,
  RESET_MAKE_PAYMENT,
  MakePaymentState,
  MakePaymentActionType,
} from "./types";

const initialState: MakePaymentState = {
  makePaymentPending: false,
  makePaymentError: null,
  makePaymentResponse: null

}

export function MakePaymentReducer(
  state: MakePaymentState = initialState,
  action: MakePaymentActionType
): MakePaymentState {
  switch (action.type) {

    case MAKE_PAYMENT_PENDING: {
      return {
        ...state,
        makePaymentPending: true
      };
    }

    case MAKE_PAYMENT_SUCCESS: {
      const { payload } = action;
      const { response } = payload;

      return {
        ...state,
        makePaymentPending: false,
        makePaymentError: null,
        makePaymentResponse: response
      };
    }

    case MAKE_PAYMENT_ERROR: {
      const {payload} = action;
      const { error} = payload;

      return {
        ...state,
        makePaymentPending: false,
        makePaymentError: error,
      };
    }

    case RESET_MAKE_PAYMENT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
