import {
  GetStatusRequest,
  GetStatusResponse,
} from "../../../domain/repositories/EnairaRepository";

export const TRIGGER_GET_STATUS = "OPAY.TRIGGER_GET_STATUS";
export const GET_STATUS_PENDING = "OPAY.GET_STATUS_PENDING";
export const GET_STATUS_SUCCESS = "OPAY.GET_STATUS_SUCCESS";
export const GET_STATUS_ERROR = "OPAY.GET_STATUS_ERROR";

export const RESET_GET_STATUS = "OPAY.RESET_GET_STATUS";

export interface TriggerGetStatus {
  type: typeof TRIGGER_GET_STATUS;
  payload: {
    request: GetStatusRequest;
  };
}

export interface GetStatusPending {
  type: typeof GET_STATUS_PENDING;
}

export interface GetStatusSuccess {
  type: typeof GET_STATUS_SUCCESS;
  payload: {
    response: GetStatusResponse;
  };
}

export interface GetStatusError {
  type: typeof GET_STATUS_ERROR;
}

export interface ResetGetStatus {
  type: typeof RESET_GET_STATUS;
}

export type GetStatusActionType =
  | TriggerGetStatus
  | GetStatusPending
  | GetStatusSuccess
  | GetStatusError
  | ResetGetStatus;

export interface GetStatusState {
  getStatusPending: boolean;
  getStatusError: boolean;
  getStatusResponse: GetStatusResponse | null;
}
