import * as React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { PageView } from "../../../components/Layout";
import { PAYMENT_METHODS__ROOT } from "../../../Routes";
import BackControl from "../../../components/BackControl";
import PaymentForm from "./components/PaymentForm";
import useGetBillers from "./hooks/useGetBillers";
import ErrorView from "../../../components/ErrorView";
import Loader from "../../../components/Loader";

export default function UgandaMobileMoney() {
  const history = useHistory();

  const goBack = () => {
    history.push(PAYMENT_METHODS__ROOT);
  };

  const { loading, getBillers, data, error } = useGetBillers();

  return (
    <Container>
      <BackControl text="Change payment method" onClick={goBack} />

      <div className="header">
        <h4>Pay with Mobile Money</h4>
        <p>Enter your mobile money details below to continue</p>
      </div>

      {loading && (
        <div className="loader-box">
          <Loader />
        </div>
      )}

      {error && <ErrorView action={getBillers} />}

      {data && <PaymentForm billers={data.response} />}
    </Container>
  );
}

const Container = styled(PageView)`
  .header {
    h4 {
      font-size: 1.7rem;
      margin-bottom: 2px;
      color: #152a38;
    }
    p {
      font-size: 1.2rem;
      color: #616161;
    }
  }

  .loader-box {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
