import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch, Switch, Route } from "react-router-dom";

import { DispatchProps } from "./types";
import { PAYMENT_METHODS__ROOT } from "../../../Routes";

import { resetGetToken } from "../store/getToken/actions";
import { resetInstitutions } from "../store/getInstitutions/actions";

import { Container } from "../components/Containers";
import { PageTitle } from "../../../components/Layout";
import BackControl from "../../../components/BackControl";

import RootPage from "./rootPage";
import DeepLinkView from "./deeplink";
import FiveDigitCodeView from "./fiveDigitCode";
import TransactionConfirm from "./transactionConfirm";

const PayWithAppRootView = ({
  handleResetInstitutions,
  handleResetToken,
}: DispatchProps) => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleGoBack = () => {
    history.push(PAYMENT_METHODS__ROOT);
  };

  useEffect(() => {
    return () => {
      handleResetToken();
      handleResetInstitutions();
    };
  }, [handleResetInstitutions, handleResetToken]);

  return (
    <Container>
      <BackControl onClick={handleGoBack} text="Change Payment method" />
      <PageTitle>Pay with App</PageTitle>
      <Switch>
        <Route exact path={path} component={RootPage} />
        <Route path={`${path}/deeplink/:device`} component={DeepLinkView} />
        <Route
          path={`${path}/five-digit-code/:device`}
          component={FiveDigitCodeView}
        />
        <Route
          path={`${path}/transaction-status`}
          component={TransactionConfirm}
        />
      </Switch>
    </Container>
  );
};

const mapDispatchToProps = (
  dispatch: (action: any) => void
): DispatchProps => ({
  handleResetInstitutions: () => {
    dispatch(resetInstitutions());
  },
  handleResetToken: () => {
    dispatch(resetGetToken());
  },
});

export default connect(null, mapDispatchToProps)(PayWithAppRootView);
