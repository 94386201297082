import { AxiosError } from "axios";
import { AuthenticateCardinalRequest, AuthenticateCardinalResponse } from "../../../domain/repositories/CardRepository";

export const TRIGGER_AUTHENTICATE_CARDINAL = 'CARD.TRIGGER_AUTHENTICATE_CARDINAL';
export const AUTHENTICATE_CARDINAL_PENDING = 'CARD.AUTHENTICATE_CARDINAL_PENDING';
export const AUTHENTICATE_CARDINAL_SUCCESS = 'CARD.AUTHENTICATE_CARDINAL_SUCCESS';
export const AUTHENTICATE_CARDINAL_ERROR = 'CARD.AUTHENTICATE_CARDINAL_ERROR';

export const RESET_AUTHENTICATE_CARDINAL = 'CARD.RESET_AUTHENTICATE_CARDINAL';

export interface TriggerAuthenticateCardinal {
  type: typeof TRIGGER_AUTHENTICATE_CARDINAL;
  payload: {
    request: AuthenticateCardinalRequest;
  }
}

export interface AuthenticateCardinalPending {
  type: typeof AUTHENTICATE_CARDINAL_PENDING;
}

export interface AuthenticateCardinalSuccess {
  type: typeof AUTHENTICATE_CARDINAL_SUCCESS;
  payload: {
    response: AuthenticateCardinalResponse;
  }
}

export interface AuthenticateCardinalError {
  type: typeof AUTHENTICATE_CARDINAL_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface ResetAuthenticateCardinal {
  type: typeof RESET_AUTHENTICATE_CARDINAL;
}


export type AuthenticateCardinalActionType =
  | TriggerAuthenticateCardinal
  | AuthenticateCardinalPending
  | AuthenticateCardinalSuccess
  | AuthenticateCardinalError
  | ResetAuthenticateCardinal;


export interface AuthenticateCardinalState {
  authenticateCardinalPending: boolean;
  authenticateCardinalError: AxiosError | null;
  authenticateCardinalResponse: AuthenticateCardinalResponse | null;
}