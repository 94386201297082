import React, { useEffect } from "react";
import styled from "styled-components";
import { PocketView } from "..";
import { Button } from "../../../../../components/Button";
import Loader from "../../../../../components/Loader";
import { useAuthorise } from "../hooks/useAuthorise";
import { PaymentParams } from "../../../../../payment/presentation/store/paymentProperties/types";

interface Props {
  setView: React.Dispatch<React.SetStateAction<PocketView>>;
  params: PaymentParams;
}

export const Authorization = ({ setView, params }: Props) => {
  const { loading, authorize, success, error } = useAuthorise();

  useEffect(() => {
    if (success) {
      setView("success");
    }
  }, [success, setView]);

  return (
    <Container>
      {error ? (
        <small style={{ textAlign: "center", ...errorTextStyle }}>
          {error}
        </small>
      ) : (
        <>
          <div style={{ marginTop: "16px" }}>
            <Loader animate={true} />
          </div>

          <Button
            type="FLAT"
            color="PRIMARY"
            text="I have authorized this payment"
            containerStyle={{ ...ButtonStyles, margin: "32px 0 8px" }}
            loading={loading}
            onClick={() => authorize(params)}
          />

          <Button
            type="GHOST"
            color="SECONDARY"
            text="Cancel"
            containerStyle={ButtonStyles}
            onClick={() => setView("pocket-id")}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const ButtonStyles = {
  width: "100%",
};

const errorTextStyle = {
  color: "red",
  fontSize: "13px",
  marginTop: "32px",
};
