import { combineReducers } from "redux";

import { GetIssuersReducer } from "./getIssuers/reducer";
import { GenerateUssdReducer } from "./generateUssd/reducer";
import { GetTransactionStatusReducer } from "./getTransactionStatus/reducer";

export const UssdReducer = combineReducers({
  getIssuers: GetIssuersReducer,
  generateUssd: GenerateUssdReducer,
  getTransactionStatus: GetTransactionStatusReducer
});
