import { GenerateQrRequest, GenerateQrResponse } from "../../../domain/repositories/QrRepository";
import {
  TRIGGER_GENERATE_QR,
  TriggerGenerateQr,

  GENERATE_QR_PENDING,
  GenerateQrPending,

  GENERATE_QR_SUCCESS,
  GenerateQrSuccess,

  GENERATE_QR_ERROR,
  GenerateQrError
} from "./types";

export const triggerGenerateQr = (request: GenerateQrRequest): TriggerGenerateQr => {
  return {
    type: TRIGGER_GENERATE_QR,
    payload: { request }
  };
};

export const generateQrPending = (): GenerateQrPending => {
  return {
    type: GENERATE_QR_PENDING
  };
};

export const generateQrSuccess = (response: GenerateQrResponse): GenerateQrSuccess => {
  return {
    type: GENERATE_QR_SUCCESS,
    payload: {response}

  };
};

export const generateQrError = (): GenerateQrError => {
  return {
    type: GENERATE_QR_ERROR,
  };
};