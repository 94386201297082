import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FieldValidationResult } from "../../../../../core/types";
import { AppState } from "../../../../../store/RootReducer";
import { Button } from "../../../../components/Button";
import {
  FieldErrorText,
  FormControlLabel,
  LabelFieldContainer,
  RowContainer
} from "../../../../components/Layout";
import { triggerShowNotification } from "../../../../components/NotificationWidget/store/actions";
import { TriggerShowNotificationPayload } from "../../../../components/NotificationWidget/store/types";
import { PaymentParams } from "../../../../payment/presentation/store/paymentProperties/types";
import { SigninUserRequest } from "../../../../wallet/domain/repositories/WalletRepository";
import {
  InputField,
  PasswordContainer,
  PasswordControl
} from "../../../../wallet/presentation/pages/WalletLoginPage/style";
import {
  setCreditPageSubTitle,
  setCreditPageTitle,
  signinSuccessful
} from "../../store/offers/actions";
import { SigninSuccessfulResponse } from "../../store/offers/types";
import { resetCreditOtpPage } from "../../store/otpPage/actions";
import { resetPhoneNumberSigninPage } from "../../store/phoneNumberSignin/actions";
import { triggerVerveWalletSignin } from "../../store/verveWalletSignin/actions";
import {
  OnSigninSuccessfulCallback,
  StoreDispatchProps,
  StoreStateProps
} from "../../store/verveWalletSignin/types";
import { FormContainer } from "../OtpPage/style";

interface OwnProps {
  backButtonCallback: () => void;
}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

const USERNAME_EMPTY_MESSAGE = "Username is required";
const PASSWORD_EMPTY_MESSAGE = "Password is required";

const getIsUsernameValid = (username: string): FieldValidationResult => {
  if (username === "") {
    return { isValid: false, message: USERNAME_EMPTY_MESSAGE };
  }
  return { isValid: true };
};

const getIsPasswordValid = (password: string): FieldValidationResult => {
  if (password === "") {
    return { isValid: false, message: PASSWORD_EMPTY_MESSAGE };
  }
  return { isValid: true };
};

function VerveWalletSignin(props: Props) {
  const {
    reset,
    setPageTitle,
    setPageSubTitle,
    backButtonCallback,
    signinPending,
    paymentParams,
    signinUser,
    signinError,
    triggerShowNotification,
    verveWalletSigninSuccessful
  } = props;
  const [usernameValue, setUsernameValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);

  const toggleShowPassword = () => setShowPassword((val) => !val);

  const { isValid: isUsernameValid, message: usernameErrorMessage } =
    getIsUsernameValid(usernameValue);

  const { isValid: isPasswordValid, message: passwordErrorMessage } =
    getIsPasswordValid(passwordValue);

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter") loginButtonHandler();
  };

  const loginButtonHandler = () => {
    setShowFormErrors(true);
    if (!isUsernameValid || !isPasswordValid) return;

    const { merchantCode, payableCode, paymentId } = paymentParams;
    const request = {
      merchantCode,
      payableCode,
      paymentId,
      username: usernameValue,
      password: passwordValue
    };

    signinUser(request, verveWalletSigninSuccessful);
  };

  const backButtonHandler = () => {
    reset();
    backButtonCallback();
  };

  const handleSigninError = useCallback(() => {
    if (signinError) {
      if (!signinError.response || signinError.response?.status !== 200) {
        triggerShowNotification({
          type: "ERROR",
          message: "Something went wrong. Please try again."
        });
        return;
      }

      if (signinError.response.data.responseCode === "T1") {
        let { message } = signinError.response.data.responseCode.error;
        if (message === "INVALID_TOKEN_SUPPLIED") {
          message = "OTP supplied is invalid. Please try again";
        }

        triggerShowNotification({
          type: "ERROR",
          message
        });
      }
    }
  }, [signinError, triggerShowNotification]);

  useEffect(() => {
    setPageTitle("Login to Verve eWallet");
    setPageSubTitle("Provide details to Login to eWallet");
    handleSigninError();
  });

  return (
    <FormContainer>
      <LabelFieldContainer>
        <FormControlLabel>Email or Phone Number</FormControlLabel>

        <InputField
          value={usernameValue}
          data-testid="signin-username"
          onChange={(event) => setUsernameValue(event.target.value)}
          onKeyUp={onKeyUpHandler}
          autoComplete='off'
        />

        {showFormErrors && !isUsernameValid && (
          <FieldErrorText>{usernameErrorMessage}</FieldErrorText>
        )}
      </LabelFieldContainer>

      <LabelFieldContainer>
        <FormControlLabel>Password</FormControlLabel>

        <PasswordContainer>
          <InputField
            type={showPassword ? "text" : "password"}
            value={passwordValue}
            onKeyUp={onKeyUpHandler}
            data-testid="signin-password"
            onChange={(event) => setPasswordValue(event.target.value)}
            autoComplete='off'
          />

          <PasswordControl onClick={toggleShowPassword}>
            {showPassword ? "HIDE" : "SHOW"}
          </PasswordControl>
        </PasswordContainer>

        {showFormErrors && !isPasswordValid && (
          <FieldErrorText>{passwordErrorMessage}</FieldErrorText>
        )}
      </LabelFieldContainer>

      <RowContainer>
        <Button
          text="Back"
          type="OUTLINE"
          color="SECONDARY"
          onClick={backButtonHandler}
          containerStyle={{ width: "30%", marginRight: "5px" }}
        />

        <Button
          text="Login"
          color="PRIMARY"
          loading={signinPending}
          onClick={loginButtonHandler}
          containerStyle={{ width: "70%", marginLeft: "5px" }}
        />
      </RowContainer>
    </FormContainer>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => {
  return {
    signinError: state.credit.VerveWalletSignin.signinError,
    signinPending: state.credit.VerveWalletSignin.signinPending,
    paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams
  };
};

const mapDispatchToProps = (dispatch: (action: any) => void): StoreDispatchProps => {
  return {
    reset: () => dispatch(resetPhoneNumberSigninPage()),
    setPageTitle: (title: string) => dispatch(setCreditPageTitle(title)),
    setPageSubTitle: (subtitle: string) => dispatch(setCreditPageSubTitle(subtitle)),

    signinUser: (request: SigninUserRequest, onSuccessful: OnSigninSuccessfulCallback) => {
      dispatch(triggerVerveWalletSignin(request, onSuccessful));
    },

    verveWalletSigninSuccessful: (response: SigninSuccessfulResponse) => {
      dispatch(resetCreditOtpPage());
      dispatch(resetPhoneNumberSigninPage());
      dispatch(signinSuccessful(response));
    },

    triggerShowNotification: (payload: TriggerShowNotificationPayload) => {
      dispatch(triggerShowNotification(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerveWalletSignin);
