import {
  SET_PAYMENT_CHANNEL,
  CurrentPaymentChannelState,
  CurrentPaymentChannelActionType
} from "./types";

const initialState: CurrentPaymentChannelState = null;

export function CurrentPaymentChannelReducer(
  state: CurrentPaymentChannelState = initialState,
  action: CurrentPaymentChannelActionType
): CurrentPaymentChannelState {
  switch (action.type) {
    case SET_PAYMENT_CHANNEL: {
      const { payload } = action;

      return payload;
    }

    default: {
      return state;
    }
  }
}
