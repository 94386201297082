import { useCallback, useEffect, useState } from "react";
import { AxiosError } from "axios";

import RequestState from "../../../../payment/domain/RequestState";
import {
  GetBillersResponse,
  getBillers,
} from "../../../domain/repositories/UgandaRepository";
import useIsMountedRef from "../../../../../hooks/useIsMountedRef";
import { getAxiosErrorData } from "../../../../../core/api/helpers";

export default function useGetBillers() {
  const [req, setReq] = useState<RequestState<GetBillersResponse>>({
    loading: false,
  });

  const isMounted = useIsMountedRef().current;

  const fetchBillers = useCallback(async () => {
    setReq({ loading: true });

    try {
      const res = await getBillers();

      if (isMounted) {
        setReq({ loading: false, data: res.data });
      }
    } catch (error) {
      setReq({
        loading: false,
        error: getAxiosErrorData(error as AxiosError).toString(),
      });
    }
  }, [isMounted]);

  useEffect(() => {
    fetchBillers();
  }, [fetchBillers]);

  return { getBillers: fetchBillers, ...req };
}
