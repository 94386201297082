import { AxiosError } from "axios";
import {
  AuthorizeTokenWithOtpRequest,
  GetTokenWithOtpRequest
} from "../../../domain/repositories/OtpRepository";
import * as Types from "./types";

export const triggerGetTokenWithOtp = (
  request: GetTokenWithOtpRequest,
  onSuccessful?: () => void
): Types.TriggerGetTokenWithOtp => {
  return {
    type: Types.TRIGGER_GET_TOKEN_WITH_OTP,
    payload: { request, onSuccessful }
  };
};

export const getTokenWithOtpPending = (): Types.GetTokenWithOtpPending => {
  return { type: Types.GET_TOKEN_WITH_OTP_PENDING };
};

export const getTokenWithOtpSuccessful = (): Types.GetTokenWithOtpSuccessful => {
  return {
    type: Types.GET_TOKEN_WITH_OTP_SUCCESSFUL
  };
};

export const getTokenWithOtpError = (error: AxiosError): Types.GetTokenWithOtpError => {
  return {
    type: Types.GET_TOKEN_WITH_OTP_ERROR,
    payload: { error }
  };
};

export const triggerAuthorizeTokenWithOtp = (
  request: AuthorizeTokenWithOtpRequest,
  onSuccessful?: () => void
): Types.TriggerAuthorizeTokenWithOtp => {
  return {
    type: Types.TRIGGER_AUTHORIZE_TOKEN_WITH_OTP,
    payload: { request, onSuccessful }
  };
};

export const authorizeTokenWithOtpPending = (): Types.AuthorizeTokenWithOtpPending => {
  return { type: Types.AUTHORIZE_TOKEN_WITH_OTP_PENDING };
};

export const authorizeTokenWithOtpSuccessful = (): Types.AuthorizeTokenWithOtpSuccessful => {
  return { type: Types.AUTHORIZE_TOKEN_WITH_OTP_SUCCESSFUL };
};

export const authorizeTokenWithOtpError = (error: AxiosError): Types.AuthorizeTokenWithOtpError => {
  return {
    type: Types.AUTHORIZE_TOKEN_WITH_OTP_ERROR,
    payload: { error }
  };
};

export const resetCreditCardDetails = (): Types.ResetCreditCardDetails => {
  return { type: Types.RESET_CREDIT_CARD_DETAILS };
};
