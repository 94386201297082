import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { PAYMENT_METHODS__ROOT, PAYMENT__CARD_PIN } from "../../../../Routes";

import BackControl from "../../../../components/BackControl";
import {
  PageView,
  PageTitle,
  PageSubTitle,
  LabelFieldContainer,
  FormControlLabel,
  FieldErrorText,
  FormFieldStyles,
} from "../../../../components/Layout";
import { ColorTheme } from "../../../../../styling/theme";
import CopyWidget from "../../../../components/CopyWidget";
import { AppState } from "../../../../../store/RootReducer";
import {
  resetGetIssuers,
  triggerGetIssuers,
} from "../../store/getIssuers/actions";
import ussdRepository, {
  GenerateUssdRequest,
  GenerateUssdResponse,
  GetIssuersRequest,
  GetTransactionStatusRequest,
  GetTransactionStatusResponse,
  Issuer,
} from "../../../domain/repositories/UssdRepository";
import { connect, useDispatch } from "react-redux";
import { PaymentParams } from "../../../../payment/presentation/store/paymentProperties/types";
import LoadingView from "../../../../components/LoadingView";
import {
  resetGenerateUssd,
  triggerGenerateUssd,
} from "../../store/generateUssd/actions";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import ErrorView from "../../../../components/ErrorView";
import ConfirmStatusMessage from "../../../../components/ConfirmStatusMessage";
import { asyncDelay } from "../../../../../core/util/asyncUtil";
import { PaymentUtil } from "../../../../payment/util/PaymentUtil";
import { completeTransaction } from "../../../../payment/presentation/store/paymentStatus/actions";
import {
  GET_TRANSACTION_STATUS_PENDING,
  GET_TRANSACTION_STATUS_SUCCESS,
} from "../../store/getTransactionStatus/types";

const Container = styled(PageView)``;

const BankDropdown = styled.select`
  ${FormFieldStyles};
`;

const UssdCodeContainer = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(60, 149, 249, 0.05);
  border: 1px dashed rgba(60, 149, 249, 0.2);
  margin-bottom: 20px;
`;

const ReloadControl = styled.p`
  font-size: 1.4rem;
  color: ${ColorTheme.primaryColor};
  cursor: pointer;
`;

const UssdCode = styled.p`
  font-size: 1.8rem;
  font-weight: 100;
  letter-spacing: 2px;
  cursor: pointer;
  color: ${ColorTheme.primaryColor};
  margin-right: 10px;
`;

interface StoreStateProps {
  paymentParams: PaymentParams;

  getIssuersPending: boolean;
  getIssuersError: boolean;
  issuers: Issuer[] | null;

  generateUssdPending: boolean;
  generateUssdError: boolean;
  generateUssdResponse: GenerateUssdResponse | null;
}

interface StoreDispatchProps {
  completeTransaction: (transactionResponse: any) => void;
  getIssuers: (request: GetIssuersRequest) => void;
  generateUssd: (request: GenerateUssdRequest) => void;
  resetGetIssuers: () => void;
  resetGenerateUssd: () => void;
}

interface OwnProps {}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function UssdRootPage(props: Props) {
  const {
    paymentParams,

    getIssuersPending,
    getIssuersError,
    issuers,

    generateUssdPending,
    generateUssdError,
    generateUssdResponse,

    completeTransaction,
    getIssuers,
    generateUssd,
    resetGetIssuers,
    resetGenerateUssd,
  } = props;

  const history = useHistory();

  const isPageMounted = useRef(true);

  const [bankCode, setBankCde] = useState("");

  const navigateToPaymentMethods = () => {
    history.push(PAYMENT_METHODS__ROOT);
  };

  const onBankCodeSelect = (
    evt: React.SyntheticEvent<HTMLSelectElement, Event>
  ) => {
    const bankCode = evt.currentTarget.value;
    setBankCde(bankCode);
  };

  const callGetIssuers = () => {
    getIssuers({ countryCode: "NG" });
  };

  const callGenerateUssd = () => {
    if (!bankCode) return;

    const {
      merchantCode,
      payableCode,
      currencyCode,
      amount,
      surcharge,
      merchantTransactionReference,
    } = paymentParams;

    generateUssd({
      merchantCode,
      payableCode,
      currencyCode,
      amount,
      surcharge,
      merchantTransactionReference,
      bankCode: bankCode,
    });
  };

  const ussdCodeOnClickHandler = () => {
    if (!generateUssdResponse) return;

    const { bankShortCode, defaultShortCode } = generateUssdResponse;

    let ussdCode = bankShortCode || defaultShortCode;

    const escapedUssdCode = ussdCode.replace(/#/g, "%23");
    window.open(`tel:${escapedUssdCode}`);
  };

  const dispatch = useDispatch();

  const pollTransactionStatus = async () => {
    dispatch({ type: GET_TRANSACTION_STATUS_PENDING });

    while (true) {
      if (!isPageMounted.current) return;

      let response;

      try {
        response = await ussdRepository.getTransactionStatus({
          merchantCode: paymentParams.merchantCode,
          transactionReference: paymentParams.merchantTransactionReference,
        });
      } catch (err) {
        await asyncDelay(20000);
        continue;
      }

      const { responseCode } = response;

      if (PaymentUtil.isTransactionComplete(responseCode)) {
        dispatch({
          type: GET_TRANSACTION_STATUS_SUCCESS,
          payload: { response },
        });

        completeTransaction({
          ...response,
          transactionReference: paymentParams.merchantTransactionReference,
        });
        break;
      }

      await asyncDelay(20000);
    }
  };

  useEffect(() => {
    pollTransactionStatus();

    if (issuers !== null) return;
    callGetIssuers();

    return () => {
      isPageMounted.current = false;
      resetGetIssuers();
      resetGenerateUssd();
    };
  }, []);

  useEffect(() => {
    callGenerateUssd();
  }, [bankCode]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_TRANSACTION_STATUS_SUCCESS,
        payload: { response: null },
      });
    };
  }, [dispatch]);

  let ussdCode = "";
  if (generateUssdResponse) {
    ussdCode =
      generateUssdResponse.bankShortCode ||
      generateUssdResponse.defaultShortCode;
  }

  return (
    <Container>
      <BackControl
        text="Change payment method"
        onClick={navigateToPaymentMethods}
      />

      {getIssuersPending && <LoadingView />}

      {!getIssuersPending && getIssuersError && (
        <ErrorView action={callGetIssuers} />
      )}

      {!getIssuersPending && issuers && (
        <>
          <PageTitle>Pay with USSD</PageTitle>
          <PageSubTitle>
            <b>Select</b> your <b>bank</b> from the list below and{" "}
            <b>dial the code generated</b> to complete the payment.
          </PageSubTitle>

          <LabelFieldContainer>
            <FormControlLabel>Bank</FormControlLabel>

            <BankDropdown
              id="ussd-issuer"
              value={bankCode}
              onChange={onBankCodeSelect}
            >
              <option value="" disabled>
                Select your bank
              </option>

              {issuers.map((issuer) => {
                const { name, cbnCode } = issuer;
                return (
                  <option key={cbnCode} value={cbnCode}>
                    {name}
                  </option>
                );
              })}
            </BankDropdown>
          </LabelFieldContainer>

          <UssdCodeContainer>
            {!bankCode && !generateUssdPending && !generateUssdError && (
              <p>-</p>
            )}

            {generateUssdPending && <LoadingIndicator color="#666" />}

            {!generateUssdPending && generateUssdError && (
              <ReloadControl onClick={callGenerateUssd}>Reload</ReloadControl>
            )}

            {!generateUssdPending && generateUssdResponse && bankCode && (
              <>
                <UssdCode onClick={ussdCodeOnClickHandler}>{ussdCode}</UssdCode>
                <CopyWidget text={ussdCode} />
              </>
            )}
          </UssdCodeContainer>

          <ConfirmStatusMessage />
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,

  getIssuersPending: state.ussd.getIssuers.getIssuersPending,
  getIssuersError: state.ussd.getIssuers.getIssuersError,
  issuers: state.ussd.getIssuers.issuers,

  generateUssdPending: state.ussd.generateUssd.generateUssdPending,
  generateUssdError: state.ussd.generateUssd.generateUssdError,
  generateUssdResponse: state.ussd.generateUssd.generateUssdResponse,
});

const mapDispatchToProps = (
  dispatch: (action: any) => void
): StoreDispatchProps => ({
  completeTransaction(transactionResponse: any) {
    dispatch(completeTransaction(transactionResponse));
  },
  getIssuers(request: GetIssuersRequest) {
    dispatch(triggerGetIssuers(request));
  },
  generateUssd(request: GenerateUssdRequest) {
    dispatch(triggerGenerateUssd(request));
  },
  resetGenerateUssd() {
    dispatch(resetGenerateUssd());
  },
  resetGetIssuers() {
    dispatch(resetGetIssuers());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UssdRootPage);
