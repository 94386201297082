import { GetIssuersRequest, Issuer } from "../../../domain/repositories/UssdRepository";

export const TRIGGER_GET_ISSUERS = 'USSD.TRIGGER_GET_ISSUERS';
export const GET_ISSUERS_PENDING = 'USSD.GET_ISSUERS_PENDING';
export const GET_ISSUERS_SUCCESS = 'USSD.GET_ISSUERS_SUCCESS';
export const GET_ISSUERS_ERROR = 'USSD.GET_ISSUERS_ERROR';

export const RESET_GET_ISSUERS = 'USSD.RESET_GET_ISSUERS';

export interface TriggerGetIssuers {
  type: typeof TRIGGER_GET_ISSUERS;
  payload: {
    request: GetIssuersRequest;
  }
}

export interface GetIssuersPending {
  type: typeof GET_ISSUERS_PENDING;
}

export interface GetIssuersSuccess {
  type: typeof GET_ISSUERS_SUCCESS;
  payload: {
    response: Issuer[];
  }
}

export interface GetIssuersError {
  type: typeof GET_ISSUERS_ERROR;
}

export interface ResetGetIssuers {
  type: typeof RESET_GET_ISSUERS;
}


export type GetIssuersActionType =
  | TriggerGetIssuers
  | GetIssuersPending
  | GetIssuersSuccess
  | GetIssuersError
  | ResetGetIssuers;


export interface GetIssuersState {
  getIssuersPending: boolean;
  getIssuersError: boolean;
  issuers: Issuer[] | null;
}