import { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import RequestState from '../../../../payment/domain/RequestState';
import {
	GetProvidersResponse,
	getProviders,
} from '../../../domain/repositories/CongoRepository';
import useIsMountedRef from '../../../../../hooks/useIsMountedRef';
import { getAxiosErrorData } from '../../../../../core/api/helpers';
import { triggerShowNotification } from '../../../../components/NotificationWidget/store/actions';

export default function useGetProviders() {
	const [req, setReq] = useState<RequestState<GetProvidersResponse>>({
		loading: false,
	});

	const isMounted = useIsMountedRef().current;

	const fetchProviders = useCallback(async () => {
		setReq({ loading: true });

		try {
			const res = await getProviders();

			if (isMounted) {
				setReq({ loading: false, data: res.data });
			}
		} catch (error) {
			setReq({
				loading: false,
				error: (
					getAxiosErrorData(error as AxiosError) || 'Something went wrong'
				).toString(),
			});

			triggerShowNotification({ type: 'ERROR' });
		}
	}, [isMounted]);

	useEffect(() => {
		fetchProviders();
	}, [fetchProviders]);

	return { getProviders: fetchProviders, ...req };
}
