import { AxiosError } from "axios";
import { TriggerShowNotificationPayload } from "../../../../components/NotificationWidget/store/types";
import { Offer } from "../../../domain/repositories/OffersRepository";
import { GenerateOtpRequest, ValidateOtpRequest } from "../../../domain/repositories/OtpRepository";
import { PageTitleProps } from "../../pages";
import { OnSigninSuccessfulCallback } from "../verveWalletSignin/types";

export const RESET_CREDIT_OTP_PAGE = "CREDIT.RESET_CREDIT_OTP_PAGE";
export const GENERATE_CREDIT_OTP_ERROR = "CREDIT.GENERATE_CREDIT_OTP_ERROR";
export const TRIGGER_GENERATE_CREDIT_OTP = "CREDIT.TRIGGER_GENERATE_CREDIT_OTP";
export const GENERATE_CREDIT_OTP_PENDING = "CREDIT.GENERATE_CREDIT_OTP_PENDING";
export const GENERATE_CREDIT_OTP_SUCCESSFUL = "CREDIT.GENERATE_CREDIT_OTP_SUCCESSFUL";

export const VALIDATE_CREDIT_OTP_ERROR = "CREDIT.VALIDATE_CREDIT_OTP_ERROR";
export const TRIGGER_VALIDATE_CREDIT_OTP = "CREDIT.TRIGGER_VALIDATE_CREDIT_OTP";
export const VALIDATE_CREDIT_OTP_PENDING = "CREDIT.VALIDATE_CREDIT_OTP_PENDING";
export const VALIDATE_CREDIT_OTP_SUCCESSFUL = "CREDIT.VALIDATE_CREDIT_OTP_SUCCESSFUL";

export const SET_OTP_REFERENCE = "CREDIT.SET_OTP_REFERENCE";

export interface GenerateCreditOtpPending {
  type: typeof GENERATE_CREDIT_OTP_PENDING;
}

export interface GenerateCreditOtpSuccessful {
  type: typeof GENERATE_CREDIT_OTP_SUCCESSFUL;
}

export interface GenerateCreditOtpError {
  type: typeof GENERATE_CREDIT_OTP_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface TriggerGenerateCreditOtp {
  type: typeof TRIGGER_GENERATE_CREDIT_OTP;
  payload: {
    request: GenerateOtpRequest;
    onSuccessful?: GenerateCreditOtpCallback;
    isBank?: boolean;
  };
}

export interface ValidateCreditOtpSuccessful {
  type: typeof VALIDATE_CREDIT_OTP_SUCCESSFUL;
}

export interface ValidateCreditOtpPending {
  type: typeof VALIDATE_CREDIT_OTP_PENDING;
}

export interface ValidateCreditOtpError {
  type: typeof VALIDATE_CREDIT_OTP_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface SetOtpReference {
  type: typeof SET_OTP_REFERENCE;
  payload: string;
}

export interface TriggerValidateCreditOtp {
  type: typeof TRIGGER_VALIDATE_CREDIT_OTP;
  payload: {
    request: ValidateOtpRequest;
    onSuccessful?: OnSigninSuccessfulCallback;
  };
}

export interface CreditOtpState {
  validateOtpLoading: boolean;
  generateOtpLoading: boolean;
  validateOtpError: AxiosError | null;
  generateOtpError: AxiosError | null;
  otpRequestReference: string | null;
}

export interface ResetCreditOtpPage {
  type: typeof RESET_CREDIT_OTP_PAGE;
}

export interface StoreStateProps {
  paymentId?: number;
  generateOtpLoading: boolean;
  validateOtpLoading: boolean;
  validateOtpError: AxiosError | null;
  generateOtpError: AxiosError | null;
  bankProvider: boolean;
  offers: readonly Offer[];
  accountNumber: string;
  providerCode: string;
}

export interface StoreDispatchProps extends PageTitleProps {
  reset: () => void;
  triggerShowNotification: (payload: TriggerShowNotificationPayload) => void;
  generateOtp: (request: GenerateOtpRequest, onSuccessful: GenerateCreditOtpCallback, isBank?: boolean) => void;
  validateOtp: (request: ValidateOtpRequest, onSuccessful?: OnSigninSuccessfulCallback) => void;
}

export type GenerateCreditOtpCallback = () => void;

export type CreditOtpActionTypes =
  | GenerateCreditOtpPending
  | GenerateCreditOtpSuccessful
  | GenerateCreditOtpError
  | ValidateCreditOtpPending
  | ValidateCreditOtpSuccessful
  | ValidateCreditOtpError
  | ResetCreditOtpPage
  | SetOtpReference;
